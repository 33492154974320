import { Paper, Typography } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const TireItemChipMain = ({ isOnSale = false, promotion = {} }) => {
  const { SitePromoIcon } = promotion;
  return (
    <>
      {isOnSale && (
        <Paper
          elevation={3}
          style={{
            backgroundColor: promotion?.sitePromoColors?.secondary,
            color: theme.palette.primary.contrastText,
            padding: 5,
            paddingLeft: 7,
            paddingRight: 7,
            width: "fit-content",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SitePromoIcon style={{ marginRight: 8 }} />
          <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
            {promotion?.displayText?.tireSaleName}
          </Typography>
        </Paper>
      )}
    </>
  );
};

export default TireItemChipMain;
