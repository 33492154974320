import { Box } from "@material-ui/core";

const AnimatedThumbs = ({ thumbsUp = true }) => {
  return (
    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <img
        style={{ width: 250 }}
        src={
          thumbsUp
            ? "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/easy-survey-thumbs-up.gif"
            : "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/easy-survey-thumbs-down.gif"
        }
      />
    </Box>
  );
};

export default AnimatedThumbs;
