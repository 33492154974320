import create from "zustand";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import CLIENT_STORAGE from "./storage/client-storage";
import { upsertClientDevice } from "../../../services/venom-service";
import LocationStore, { useLocationStore } from "../location-store";
import SessionTimerStore from "./session-timer-store";

let _sessionId;
let _storeNumber;

const ClientTrackingStore = create((set, get) => {
  const initialState = {
    isReady: false,
    clientDeviceGuid: null,
  };

  const initStore = () => {
    const clientDeviceGuid =
      CLIENT_STORAGE.clientDeviceGuid.getItem() ?? uuidv4();
    CLIENT_STORAGE.clientDeviceGuid.setItem(clientDeviceGuid);

    set({ clientDeviceGuid });
  };

  const sendClientDeviceToDb = async () => {
    const sessionId = SessionTimerStore.getState().sessionId;
    const { storeNumber } = (
      await LocationStore.getState().getLocationStoreAsync()
    ).userStore;

    if (_sessionId !== sessionId || _storeNumber !== storeNumber) {
      _sessionId = sessionId;
      _storeNumber = storeNumber;
      set({ isReady: false });
    }

    const _state = get();

    if (!_state.isReady) {
      await upsertClientDevice({
        storeNumber,
        clientDeviceGuid: _state.clientDeviceGuid,
      });
      set({ isReady: true });
    }
  };

  const getEdgeClient = () => ({
    ClientDeviceGUID: get()?.clientDeviceGuid ?? "",
  });

  return {
    ...initialState,
    initStore,
    sendClientDeviceToDb,
    getEdgeClient,
  };
});

export const useClientTrackingStore = () => {
  const [initStore, sendClientDeviceToDb] = ClientTrackingStore((st) => [
    st.initStore,
    st.sendClientDeviceToDb,
  ]);

  useEffect(() => {
    initStore();
  }, []);

  const [sessionId] = SessionTimerStore((st) => [st.sessionId]);
  const [storeNumber] = useLocationStore((st) => [st.userStore?.storeNumber]);

  useEffect(() => {
    if (sessionId && storeNumber) {
      sendClientDeviceToDb();
    }
  }, [sessionId, storeNumber]);
};

export default ClientTrackingStore;
