import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useVehicleStore } from '../../hooks/store/vehicle-store';
import { WHEEL_VISUALIZER } from "../../routes"
import useOpenVehicleSelector from '../../hooks/util/useOpenVehicleSelector';

const WheelVisualizerButton = () => {
  const history = useHistory();
  const openVehicleSelector = useOpenVehicleSelector();
  const [isSelectingVehicle, setIsSelectingVehicle] = useState(false);
  const userVehicle = useVehicleStore(state => state.userVehicle);

  const handleClick = () => {
    if (userVehicle) {
      history.push(WHEEL_VISUALIZER);
      return;
    }

    setIsSelectingVehicle(true);
    openVehicleSelector();
  };

  useEffect(() => {
    if (isSelectingVehicle && userVehicle) {
      setIsSelectingVehicle(false);
      history.push(WHEEL_VISUALIZER);
    }
  }, [isSelectingVehicle, userVehicle]);

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      style={{ height: 50, width: 300 }}
    >
      Start Wheel Visualizer
    </Button>
  );
};

export default WheelVisualizerButton;