import create from "zustand";
import { useEffect } from "react";
import TireItemsStore from "../../hooks/store/tire-items-store";
import { Grid, Paper, Button, Box, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import APP_ENV from "../../appEnvironment";
import TireImage from "../tires/widgets/tire-image";
import ReviewStars from "../tires/widgets/review-stars";

const { theme } = APP_ENV;

const EV_Tires = [
  {
    brandEndPoint: "michelin",
    itemModelEndPoint: "energy-saver-as",
    partNumberEndPoint: "11987",
    description:
      "Exhilarating Electric Vehicle Performance with the Michelin quality you expect.",
  },
  {
    brandEndPoint: "goodyear",
    itemModelEndPoint: "electricdrive-gt",
    partNumberEndPoint: "484186656",
    description: "Comfortable Elegance Paired with Unparalleled Efficiency.",
  },
  {
    brandEndPoint: "michelin",
    itemModelEndPoint: "pilot-sport-4s",
    partNumberEndPoint: "32617",
    description: "Dedicated to Maximizing Battery Range to the Fullest.",
  },
];

const evTireStore = create((set, get) => {
  const initState = {
    isReady: false,
    evTires: [],
  };

  const getEvTireStoreAsync = async () => {
    if (get().isReady) return;

    const { getActiveTireItems } = TireItemsStore.getState();

    const evTires = (
      await Promise.allSettled(
        EV_Tires.map((payload) =>
          (async () => ({
            ...((await getActiveTireItems(payload))?.[0] ?? {}),
            ...payload,
          }))()
        )
      )
    )
      .map(({ value }) => value)
      .filter((i) => i?.brandImage);

    set({ isReady: true, evTires });
  };

  return {
    ...initState,
    getEvTireStoreAsync,
  };
});

const EvRecommendedTires = () => {
  const [evTires, getEvTireStoreAsync] = evTireStore((st) => [
    st.evTires,
    st.getEvTireStoreAsync,
  ]);

  useEffect(() => {
    getEvTireStoreAsync();
  }, []);

  return (
    <Grid container style={{ justifyContent: "center" }}>
      {evTires.map(
        (
          {
            brandName,
            brandImage,
            itemModelName,
            itemModelImage,
            itemModelSlug,
            reviewAvg,
            reviewCount,
            description,
          },
          i
        ) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={i}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: 15,
                marginBottom: 15,
              }}
            >
              <Paper
                component={Button}
                elevation={5}
                style={{
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <Grid
                  item
                  style={{
                    padding: 15,
                    display: "block",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    <TireImage itemModelImage={itemModelImage} />
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    <Box>
                      <img src={brandImage} />
                    </Box>
                    <Box>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {brandName}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h4">{itemModelName}</Typography>
                    </Box>
                    <Box
                      style={{
                        marginTop: "5px",
                      }}
                    >
                      <ReviewStars
                        reviewCount={reviewCount}
                        reviewAvg={reviewAvg}
                        linkHref={itemModelSlug}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="body1"
                        style={{
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      >
                        {description}
                      </Typography>
                    </Box>
                    <Button
                      component={RouterLink}
                      to={itemModelSlug}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      style={{
                        border: "2px solid",
                        marginTop: 10,
                        padding: 10,
                      }}
                    >
                      {`Learn More`}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default EvRecommendedTires;
