import { TextField, InputAdornment } from "@material-ui/core";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import APP_ENV from "../../../../appEnvironment";

const { theme } = APP_ENV;

const SelectionFieldMobile = ({
  isDisabled,
  label,
  selection,
  setActive,
  hasError,
}) => {
  return (
    <TextField
      fullWidth
      disabled={isDisabled}
      error={hasError}
      focused={false}
      label={label}
      type="button"
      size={"medium"}
      value={selection?.label ?? label}
      onClick={setActive}
      style={{ backgroundColor: theme.palette.background.paper }}
      InputProps={{
        style: {
          cursor: "pointer",
        },
        endAdornment: (
          <InputAdornment position="end">
            <ArrowForwardIosRoundedIcon style={{ fontSize: 12 }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SelectionFieldMobile;
