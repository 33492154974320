import { Box, Typography, Button, Link } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const ReviewUsOn = ({ customerFirstName = "", externalReviewOptions = [] }) => {
  return (
    <Box>
      <Typography
        variant="h5"
        style={{ margin: 10, marginBottom: 25 }}
      >{`Thank you ${customerFirstName}! Would you mind leaving us an online review?`}</Typography>
      {externalReviewOptions.map(({ name, url, onClick }, i) => {
        return (
          <Box key={i}>
            <Button
              onClick={onClick}
              color="primary"
              variant="contained"
              component={Link}
              href={url}
              target="_blank"
              style={{
                width: 300,
                height: 50,
                marginBottom: 25,
                color: theme.palette.primary.contrastText,
              }}
            >
              {`Review us on ${name}`}
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

export default ReviewUsOn;
