import { useEffect, useState, Fragment } from "react";
import {
  Grid,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Button,
  Checkbox,
} from "@material-ui/core";
import MetaTags from "../components/reusable/ReactMetaTags";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { StandardWidthContainer } from "../components/generic/containers";
import APP_ENV from "../appEnvironment";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { useLocationStore } from "../hooks/store/location-store";
import dayjs from "dayjs";
import Skeleton from "@material-ui/lab/Skeleton";
import StandardPageHeader from "../components/generic/standard-page-header";

const { theme } = APP_ENV;

const SITE_WHO_WE_ARE = {
  1: `Big Brand Tire & Service has been a leader 
   in automotive services and tires in Central 
   and Southern California since 1969. With more 
   than 50 years of success, we are continuing 
   to grow and add new locations throughout the 
   Southwestern United States. Our continued growth 
   and innovation in the industry has been driven 
   by the great people that make up our team, 
   and now is the best time in our history to join us.`,
  2: `Tire World Auto Repair, based in Colorado Springs, Colorado, 
   is a tire retailer and automotive service center. 
   Since 1969, Tire World Auto Repair has provided exceptional 
   automotive services, tires, and wheels to our loyal customers. 
   We are a Big Brand Tire & Service Company which has been a 
   leader in automotive services and tires for over 50 years as well. 
   We have locations throughout California, Arizona, Colorado, 
   and Nevada and are continuing to grow and add new locations 
   throughout the Southwestern United States.`,
  3: `American Tire Depot has nearly 100 service centers in 
   California and boasts one of the highest rated reviews in 
   our tire and auto repair category, by Google and Yelp. 
   American Tire offers discount tire prices and complete auto repair. 
   In 2022 we joined forces with Big Brand Tire & Service, 
   and together we have over 50 years of success. 
   We are continuing to grow and add new locations 
   throughout the Southwestern United States.`,
  4: `Tires To You, based in Austin Texas, 
   is a tire retailer and automotive service center. 
   Since 1969, Tires To You has provided exceptional 
   automotive services, tires, and wheels to our loyal customers. 
   We are a Big Brand Tire & Service Company which has been a 
   leader in automotive services and tires for over 50 years as well. 
   We have locations throughout California, Arizona, Colorado, 
   and Nevada and are continuing to grow and add new locations 
   throughout the Southwestern United States.`,
};

const DEPT_TYPES = {
  store: "Service Center",
  corp: "Corporate",
};

const QUICK_APPLY_LINKS = {
  1: "https://bigbrandtire.applytojob.com/apply/ASLAiOaCme/Big-Brand-Tire-Service-Quick-Application",
  2: "https://bigbrandtire.applytojob.com/apply/v7RMxVUMX1/Tire-World-Quick-Application",
  3: "https://americantiredepot.applytojob.com/apply/M47UkMCXAO/American-Tire-Depot-Quick-Application",
  4: "https://bigbrandtire.applytojob.com/apply/v7RMxVUMX1/Tire-World-Quick-Application",
};

const CareersMain = () => {
  const [expanedPanels, setExpanedPanels] = useState([]);

  const [state, setState] = useState({
    isLoading: false,
    allJobs: [],
    viewJobs: [],
    allStates: [],
    viewStates: [],
    allDepts: [],
    viewDepts: [],
  });

  const [locations] = useLocationStore((st) => [st.locations]);

  useEffect(() => {
    if (locations.length) {
      const JAZZHR_API_KEY =
        APP_ENV.appBrandId == 3
          ? "WD9Ge79tmJmd7sr2hE3UrvxMtiT5a3t6"
          : "p26YJ8h63eLzHiDxnbljkci9ut2KQWwB";

      (async () => {
        setState((prev) => ({ ...prev, isLoading: true }));
        const jobPages = await Promise.all(
          [...new Array(6)].map((_, i) =>
            (async () => {
              const res = await fetch(
                `https://api.resumatorapi.com/v1/jobs/status/open/page/${
                  i + 1
                }?apikey=${JAZZHR_API_KEY}`
              );
              return await res.json();
            })()
          )
        );

        const allJobs = jobPages
          .flat()
          .filter(({ send_to_job_boards }) => send_to_job_boards == "Yes")
          .filter(({ department }) => department)
          .map((job) => {
            const isCorp =
              job.department.length == 2 || ["1095"].includes(job.department);
            return {
              ...job,
              deptType: isCorp ? DEPT_TYPES.corp : DEPT_TYPES.store,
            };
          })
          .filter(
            ({ department, deptType }) =>
              locations
                .map(({ storeNumber }) => storeNumber)
                .includes(department) || deptType == DEPT_TYPES.corp
          )
          .sort((a, b) =>
            dayjs(a.original_open_date).diff(dayjs(b.original_open_date))
          );

        const allStates = [
          ...new Set(allJobs.map(({ state }) => state)),
        ].sort();
        const allDepts = [...new Set(allJobs.map(({ deptType }) => deptType))];

        setState((prev) => ({
          ...prev,
          isLoading: false,
          allJobs,
          allStates,
          viewStates: allStates,
          allDepts,
          viewDepts: allDepts,
        }));
      })();
    }
  }, [locations]);

  const viewJobs = state.allJobs
    .filter((item) => state.viewStates.includes(item.state))
    .filter((item) => state.viewDepts.includes(item.deptType));

  return (
    <>
      <MetaTags
        description="We're hiring! We have many open positions and are excited for you to join our team."
        canonical="/careers"
        title={`Careers at ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title={`Careers at ${APP_ENV.appBrandName}`}
        subtitle="We invest in the lives of our hard working team."
      />
      <StandardWidthContainer
        backgroundColor={theme.palette.background.default}
      >
        <Grid
          container
          spacing={5}
          style={{ paddingTop: 25, paddingBottom: 50 }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {[
              [
                "Who We Are",
                <>
                  <Typography style={{ marginBottom: 15 }}>
                    {SITE_WHO_WE_ARE[APP_ENV.appBrandId]}
                  </Typography>
                  <Typography>
                    {`Our success depends on the quality of our people, 
                   and they are why we are driven by our core values, 
                   respect our teams, and keep our guests moving. 
                   Our teams are composed of experienced customer 
                   service professionals, skilled technicians who can 
                   diagnose, repair and maintain the most advanced vehicles
                    on the road, and motivated team members just beginning 
                    their careers in automotive services and retail management.`}
                  </Typography>
                </>,
              ],
              [
                "Our Vision",
                <Typography>
                  {`To be recognized as the best one-stop tire and auto repair provider. 
               We deliver our customers the highest level of satisfaction while 
               investing in the lives of the hardworking, dedicated people who make up our team.`}
                </Typography>,
              ],
              [
                "Benefits",
                <>
                  {[
                    "Competitive Pay and Incentive Programs",
                    "Career Development and Promotion Opportunities",
                    "Employee Discount Program",
                    "Employee Referral Bonus",
                    "Paid Time Off",
                    "Health Insurance",
                    "401k with Company Match",
                    "Company-paid Life Insurance",
                    "Voluntary Life, Accident and Short-Term Disability Insurance",
                    "Flexible Spending Accounts",
                  ].map((item, i) => {
                    return (
                      <Box key={i} style={{ display: "flex", marginBottom: 8 }}>
                        <FiberManualRecord
                          style={{
                            marginRight: 10,
                            fontSize: 12,
                            marginTop: 5,
                            color: theme.palette.primary.main,
                          }}
                        />
                        <Typography style={{ fontSize: 15 }}>{item}</Typography>
                      </Box>
                    );
                  })}
                </>,
              ],
            ].map(([title, desc], i) => {
              const isExpanded = expanedPanels.includes(i);

              return (
                <Accordion
                  key={title}
                  expanded={isExpanded}
                  onChange={() =>
                    isExpanded
                      ? setExpanedPanels(expanedPanels.filter((p) => p !== i))
                      : setExpanedPanels([...expanedPanels, i])
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      style={{ fontSize: 25, fontWeight: 600, marginLeft: 5 }}
                    >
                      {title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 25, paddingTop: 0 }}>
                    <Box>{desc}</Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            {APP_ENV.appBrandId == 1 && (
              <Box style={{ marginTop: 25 }}>
                <div>
                  <iframe
                    width="100%"
                    height="315"
                    src={
                      "https://www.youtube.com/embed/y-ZEW6uiIEI?autoplay=1&mute=1"
                    }
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </Box>
            )}
            <Paper
              style={{
                width: "100%",
                marginTop: 15,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
                textAlign: "center",
              }}
            >
              <Typography
                style={{ fontSize: 25, fontWeight: "bold", marginBottom: 5 }}
              >
                Connect with our Hiring Experts
              </Typography>
              <Typography style={{ fontSize: 16, marginBottom: 5 }}>
                Submit your info here if you would like more information about
                our available postions.
              </Typography>

              <Button
                style={{
                  width: 250,
                  height: 50,
                  marginTop: 15,
                  marginBottom: 15,
                }}
                variant="contained"
                color="primary"
                href={QUICK_APPLY_LINKS[APP_ENV.appBrandId]}
                target="_blank"
              >
                Contact Me
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Paper
              style={{
                marginBottom: 15,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              <Typography style={{ fontSize: 25, fontWeight: "bold" }}>
                Join Our Team
              </Typography>
              <Typography style={{ fontSize: 16, marginBottom: 8 }}>
                Explore our Career Opportunities
              </Typography>

              {state.isLoading && (
                <>
                  <Skeleton variant="rect" width={200} height={25} />
                  <Skeleton
                    variant="rect"
                    width={400}
                    height={200}
                    style={{ marginTop: 20 }}
                  />
                </>
              )}
              {!state.isLoading && (
                <>
                  <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                    {`${state.allJobs.length} Open Positions`}
                  </Typography>

                  <Grid
                    container
                    style={{
                      backgroundColor: theme.palette.background.default,
                      marginTop: 20,
                    }}
                  >
                    {Boolean(state.allStates.length) && (
                      <Grid
                        item
                        xs={5}
                        style={{ padding: 15, fontWeight: 600 }}
                      >
                        State
                        <List dense>
                          {state.allStates.map((st, i) => {
                            const isSelected = state.viewStates.includes(st);
                            const handleSelection = () => {
                              if (isSelected) {
                                setState((prev) => ({
                                  ...prev,
                                  viewStates: prev.viewStates.filter(
                                    (item) => item !== st
                                  ),
                                }));
                              } else {
                                setState((prev) => ({
                                  ...prev,
                                  viewStates: [...prev.viewStates, st],
                                }));
                              }
                            };
                            const isLastItem = state.allStates.length === i + 1;
                            return (
                              <ListItem
                                key={st}
                                onClick={handleSelection}
                                button
                                style={{
                                  backgroundColor:
                                    theme.palette.background.paper,
                                  borderBottom: !isLastItem
                                    ? `1px solid ${theme.palette.divider}`
                                    : "",
                                }}
                              >
                                <ListItemText>
                                  <Typography>{st}</Typography>
                                </ListItemText>

                                <Checkbox edge="start" checked={isSelected} />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                    )}

                    {Boolean(state.allDepts.length) && (
                      <Grid
                        item
                        xs={7}
                        style={{ padding: 15, fontWeight: 600 }}
                      >
                        Type
                        <List dense>
                          {state.allDepts.map((dept, i) => {
                            const isSelected = state.viewDepts.includes(dept);
                            const handleSelection = () => {
                              if (isSelected) {
                                setState((prev) => ({
                                  ...prev,
                                  viewDepts: prev.viewDepts.filter(
                                    (item) => item !== dept
                                  ),
                                }));
                              } else {
                                setState((prev) => ({
                                  ...prev,
                                  viewDepts: [...prev.viewDepts, dept],
                                }));
                              }
                            };
                            const isLastItem = state.allStates.length === i + 1;
                            return (
                              <ListItem
                                key={dept}
                                onClick={handleSelection}
                                button
                                style={{
                                  backgroundColor:
                                    theme.palette.background.paper,
                                  borderBottom: !isLastItem
                                    ? `1px solid ${theme.palette.divider}`
                                    : "",
                                }}
                              >
                                <ListItemText>
                                  <Typography style={{ fontSize: 14 }}>
                                    {dept}
                                  </Typography>
                                </ListItemText>

                                <Checkbox edge="start" checked={isSelected} />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </Paper>
            <Paper>
              <Box
                style={{
                  width: "100%",
                  padding: 15,
                  backgroundColor: theme.palette.footer2.main,
                  color: "white",
                }}
              >
                <Typography>Open Positions</Typography>
              </Box>
              {state.isLoading && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 20,
                  }}
                >
                  <Box>
                    {[...new Array(3)].map((_, i) => (
                      <Skeleton
                        key={i}
                        variant="rect"
                        width={400}
                        height={100}
                        style={{ marginTop: 20 }}
                      />
                    ))}
                  </Box>
                </Box>
              )}

              <List>
                {viewJobs.map((job, i) => {
                  const OUT_BOUND_LINKS = {
                    1: "bigbrandtire",
                    2: "bigbrandtire",
                    3: "americantiredepot",
                    4: "bigbrandtire",
                  };

                  return (
                    <Fragment key={i}>
                      <ListItem key={i} button>
                        <ListItemText>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography style={{ fontSize: 13 }}>
                              {`${job.deptType} ${job.department}`}
                            </Typography>
                            <Chip
                              label={job.type}
                              size="small"
                              color={
                                job.type == "Full Time"
                                  ? "secondary"
                                  : "default"
                              }
                            />
                          </Box>
                          <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                            {job.title}
                          </Typography>
                          <Typography style={{ fontSize: 13 }}>
                            {`${job.city}, ${job.state} ${job.zip}`}
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginTop: 15, fontSize: 15 }}
                              href={`https://${
                                OUT_BOUND_LINKS[APP_ENV.appBrandId]
                              }.applytojob.com/apply/${job.board_code}`}
                              target="_blank"
                            >
                              Apply Now
                            </Button>
                          </Box>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                    </Fragment>
                  );
                })}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </StandardWidthContainer>
    </>
  );
};

export default CareersMain;
