import { Box, Typography, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const StackedDisplayName = ({ displayCity, displayCityEndPoint, webAlias }) => {
  return (
    <Box>
      <Link
        component={RouterLink}
        to={`/tire-shop/${displayCityEndPoint}`}
        variant="h4"
        style={{ fontWeight: "bold", color: theme.palette.primary.main }}
      >
        {displayCity}
      </Link>
      <Typography variant="h5" style={{ fontWeight: "bold" }}>
        {webAlias}
      </Typography>
    </Box>
  );
};

export default StackedDisplayName;
