import create from "zustand";
import {
  getVehicleByVehicleId,
  getVehicleTireFitments,
} from "../../services/whi-service";
import CLIENT_STORAGE from "./client/storage/client-storage";

let isInit;
let isReady;

const VehicleStore = create((set, get) => {
  const initialState = {
    userVehicle: null,
  };

  let promise;

  const getUserVehicle = async () => {
    if (!isReady && !promise) {
      promise = (async () => {
        const vehCookie = CLIENT_STORAGE.vehicle.getItem();
        if (vehCookie) {
          const [vehicleId, subModelId, engineId] = vehCookie.split("_");
          if (vehicleId != 0) {
            const vehicle = await getVehicleByVehicleId(
              vehicleId,
              subModelId,
              engineId
            );
            setUserVehicle(vehicle);
          }
        }
        promise = null;
        isReady = true;
        return;
      })();
    }

    if (!isReady) {
      await promise;
    }

    return get().userVehicle;
  };

  const setUserVehicle = (userVehicle, selectedValues = null) => {
    const { vehicleId, subModelId, engineId } = userVehicle;

    const vehicleIds = [vehicleId, subModelId, engineId].filter((v) => v);
    let vehicleCookie = null;
    if (vehicleIds.length === 3) {
      vehicleCookie = vehicleIds.join("_");
      CLIENT_STORAGE.vehicle.setItem(vehicleCookie);
    }

    let clientSideUserVehicle = userVehicle;

    if (selectedValues) {
      clientSideUserVehicle = {
        ...clientSideUserVehicle,
        ...Object.assign(
          {},
          ...[
            ["year", "year"],
            ["makeId", "makeName"],
            ["modelId", "modelName"],
            ["subModelId", "subModelName"],
            ["engineId", "engineName"],
          ]
            .filter(([key]) => Object.keys(selectedValues).includes(key))
            .map(([key, labelKey]) => {
              const { value, label } = selectedValues[key];
              return [{ [key]: value }, { [labelKey]: label }];
            })
            .flat()
        ),
      };
    }
    set({
      userVehicle: {
        ...clientSideUserVehicle,
        vehicleCookie,
        getTireFitments: async () =>
          await getVehicleTireFitments(clientSideUserVehicle),
        getEdgeVehicle: () => {
          const v = userVehicle;
          return {
            AcesVehicleId: v?.vehicleId ?? 0,
            BaseVehicleId: v?.baseVehicleId ?? 0,
            VehicleYear: v?.year ?? 0,
            VehicleMakeId: v?.makeId ?? 0,
            VehicleMakeName: v?.makeName ?? "",
            VehicleModelId: v?.modelId ?? 0,
            VehicleModelName: v?.modelName ?? "",
            VehicleSubModelId: v?.subModelId ?? 0,
            VehicleSubModelName: v?.subModelName ?? "",
            VehicleEngineId: v?.engineId ?? 0,
            VehicleEngineName: v?.engineName ?? "",
          };
        },
      },
    });

    return getUserVehicle();
  };

  return {
    ...initialState,
    getUserVehicle,
    setUserVehicle,
  };
});

export const useVehicleStore = (selector = (store) => store) => {
  const [getUserVehicle] = VehicleStore((st) => [st.getUserVehicle]);

  if (!isInit) {
    isInit = true;
    getUserVehicle();
  }

  return VehicleStore(selector);
};

export default VehicleStore;
