import { useState } from "react";
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DefaultContainer from "../../components/generic/default-container";
import StandardPageHeader from "../../components/generic/standard-page-header";
import MetaTags from "../../components/reusable/ReactMetaTags";
import OilChangeNeedHelpBox from "../../components/oil-change/oil-change-need-help-box";
import OilChangeCardNoPrice from "../../components/oil-change/oil-change-card-no-price";
import { STATIC_CART_ITEMS } from "../../static/cart-items";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const {
  OIL_CONSULTATION_CONVENTIONAL,
  OIL_CONSULTATION_HIGHMILEAGE,
  OIL_CONSULTATION_FULLSYNTHETIC,
} = STATIC_CART_ITEMS;

const oilChangeTypes = [
  [
    "Premium Conventional",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/oil-change/oil-conventional.jpg",
    "Keep the moving parts within your engine protected.",
    OIL_CONSULTATION_CONVENTIONAL,
    `For as long as there have been cars, there's been a guy under the hood looking for a few more horses. 
     Hard at work creating the next great oil for the next great engine. Premium Conventional was one 
     of the first all-climate motor oils ever created, and still represents unending commitment to excellence and innovation.`,
  ],
  [
    "High Mileage",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/oil-change/oil-blend.jpg",
    "Specifically formulated for vehicles with over 75,000 miles.",
    OIL_CONSULTATION_HIGHMILEAGE,
    `High Mileage Oil Change is especially designed for vehicles that have exceeded the 75,000 mile mark.
     Through its advanced synthetic blend formulation, it supplies boosted wear protection, special seal 
     conditioners, added detergents and anti-oxidant additives that help to maximize the life of an engine. 
     Specially formulated to meet the needs of cars as they age past 75,000 miles, and its advanced formula 
     can also be utilized in newer cars to help prevent the causes of engine breakdown before they ever begin.`,
  ],
  [
    "Full Synthetic",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/oil-change/oil-synthetic.jpg",
    "Advanced formulation for maximum engine performance and protection.",
    OIL_CONSULTATION_FULLSYNTHETIC,
    `Full Synthetic motor oil is specially formulated to provide enhanced engine protection against heat, deposits 
    and wear. Through its advanced formulation, Full Synthetic provides extra detergents and dispersants that provide 
    maximum sludge and deposit protection, along with premium additives that hold up to high temperatures and friction
    under severe driving conditions.`,
  ],
].map(([title, image, description, cartItem, longDescription]) => ({
  title,
  image,
  description,
  cartItem,
  longDescription,
}));

const OilChangeMain = () => {
  const [state, setState] = useState({
    isOpen: false,
    dialogSelectionIndex: 0,
  });

  const openDialog = (dialogSelectionIndex) => {
    setState((prev) => {
      return {
        ...prev,
        isOpen: true,
        dialogSelectionIndex,
      };
    });
  };

  const closeDialog = () => {
    setState((prev) => {
      return {
        ...prev,
        isOpen: false,
        dialogSelectionIndex: 0,
      };
    });
  };

  const { title, subtitle, longDescription } = state.isOpen
    ? oilChangeTypes[state.dialogSelectionIndex]
    : {};

  return (
    <>
      <MetaTags
        description={`${APP_ENV.appBrandName} provides expert oil change service and same day appointments. 
        Oil Change includes free tire rotation, free underhood inspection, and
        free brake inspection. Shop oil changes now.`}
        canonical="/oilrecommendations"
        title={`${APP_ENV.appBrandName} | Car Oil Change and Auto Services`}
      />
      <StandardPageHeader
        title="Shop Oil Change"
        subtitle=" Regular oil changes are easily the least expensive maintenance
      you can do to maximize engine life and reduce repairs. Check
      your vehicle's owner's manual for the suggested service
      intervals."
      >
        <Grid container style={{ paddingLeft: 10, paddingRight: 10 }}>
          <OilChangeNeedHelpBox />
        </Grid>
      </StandardPageHeader>
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid container style={{ paddingTop: 25, paddingBottom: 25 }}>
          {oilChangeTypes.map(({ title, image, description, cartItem }, i) => {
            return (
              <OilChangeCardNoPrice
                label={title}
                image={image}
                description={description}
                key={title}
                openDialog={() => openDialog(i)}
                isBest={i === 2}
                addToCart={cartItem.addToCart}
              />
            );
          })}
        </Grid>
      </DefaultContainer>
      <Dialog open={state.isOpen} onClose={closeDialog}>
        <DialogTitle>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                {title}
              </Typography>
              <Typography style={{ color: theme.palette.gray[700] }}>
                {subtitle}
              </Typography>
            </Box>
            <IconButton onClick={closeDialog}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              color: theme.palette.common.black,
            }}
          >
            {longDescription}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OilChangeMain;
