import { useState } from "react";
import { Grid, Button, Typography, Paper, Box, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useTireBrandStore } from "../../hooks/store/tire-brand-store";
import DefaultContainer from "../../components/generic/default-container";
import { StandardWidthContainer } from "../../components/generic/containers";
import MetaTags from "../../components/reusable/ReactMetaTags";
import APP_ENV from "../../appEnvironment";
import useOpenTireSizeSelector from "../../hooks/util/useOpenTireSizeSelector";
import LayoutStore from "../../hooks/store/client/layout-store";
import { useLocationStore } from "../../hooks/store/location-store";
import Skeleton from "@material-ui/lab/Skeleton";

const { theme } = APP_ENV;

const TiresMain = () => {
  const [tireBrands, isReady] = useTireBrandStore((st) => [
    st.tireBrands,
    st.isReady,
  ]);

  const ALL_BRANDS = tireBrands.filter(
    ({ brandImage, brandEndPoint }) =>
      brandImage && !["discontinued-parts", "take-offs"].includes(brandEndPoint)
  );

  const PREFERRED_BRANDS = ALL_BRANDS.filter(({ brandEndPoint }) =>
    [
      "bf-goodrich",
      "michelin",
      "nexen",
      "nokian",
      "pirelli",
      "summit",
    ].includes(brandEndPoint)
  );

  const [showAllBrands, setShowAllBrands] = useState(false);

  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: false });

  const [isMobile] = LayoutStore((st) => [st.isMobile]);
  const [promotion] = useLocationStore((st) => [st.userStore?.promotion]);

  let image = "";
  if (promotion) {
    image = isMobile ? promotion.images.main : promotion.images.secondary;
  }

  return (
    <>
      <MetaTags
        description={`Shop ${APP_ENV.appBrandName}. The best prices on top-rated brands including deals on All-Season, UHP, Run Flat, All Terrain tires & more.`}
        canonical="/tires"
        title={`Tires: Shop Tires, Discounts, & Deals at ${APP_ENV.appBrandName}`}
      />

      <StandardWidthContainer>
        <Grid
          container
          style={{
            marginTop: 10,
            marginBottom: 10,
            direction: "row",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <Grid
            onClick={openTireSizeModal}
            item
            xs={12}
            sm={12}
            md={6}
            style={{
              objectFit: "contain",
              padding: 8,
              paddingTop: 25,
            }}
          >
            <img
              src={image}
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} style={{}}>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <Typography variant="h3">Schedule A Tire Appointment</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <div>
                <div
                  style={{
                    padding: 10,
                  }}
                >
                  <Button
                    onClick={openTireSizeModal}
                    variant="contained"
                    color="primary"
                    style={{
                      height: 50,
                      width: 300,
                    }}
                  >
                    Shop Tires Now
                  </Button>
                </div>
                {[
                  {
                    buttontext: "Quick Appointment",
                    action: {
                      component: RouterLink,
                      to: "/car-appointment",
                    },
                  },
                  {
                    buttontext: "Call Now",
                    action: {
                      href: `tel:${APP_ENV.appPhoneNumber}`,
                    },
                  },
                ].map(({ buttontext, action }, i) => (
                  <div
                    key={i}
                    style={{
                      padding: 10,
                    }}
                  >
                    <Button
                      {...action}
                      variant="contained"
                      color="secondary"
                      style={{
                        height: 50,
                        width: 300,
                      }}
                    >
                      {buttontext}
                    </Button>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </StandardWidthContainer>

      <DefaultContainer backgroundColor={theme.palette.background.paper}>
        <Box style={{ paddingTop: 10 }}>
          <Grid
            container
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              marginBottom: 20,
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box style={{ maxWidth: 450, textAlign: "center" }}>
                <Typography
                  variant="h3"
                  style={{
                    marginBottom: 20,
                  }}
                >
                  Shop By Tire Brand
                </Typography>

                <Typography variant="body1">
                  {`${APP_ENV.appBrandName} offers a wide variety of tire brands for
                sale. We provide quick and quality tire installation and repairs. Schedule a`}
                  <Link component={RouterLink} to={"/car-appointment"}>
                    {` tire service `}
                  </Link>

                  {`or shop by tire brand.`}
                </Typography>
              </Box>
            </Grid>

            <>
              {!isReady && (
                <Grid container style={{ justifyContent: "center" }}>
                  {Array.from({ length: 6 }).map((_, i) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ padding: 10 }}
                      key={i}
                    >
                      <Skeleton
                        variant="rect"
                        height={155}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" height={50} width={220} />
                  </Grid>
                </Grid>
              )}
            </>

            {isReady && (
              <Grid container style={{ justifyContent: "center" }}>
                {[...(!showAllBrands ? PREFERRED_BRANDS : ALL_BRANDS)].map(
                  ({ brandName, brandImage, brandSlug }) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        style={{ padding: 15 }}
                        key={brandName}
                        component={RouterLink}
                        to={brandSlug}
                      >
                        <Paper
                          component={Button}
                          fullWidth
                          elevation={5}
                          style={{
                            display: "block",
                            textAlign: "center",
                            backgroundColor: theme.palette.background.paper,
                            padding: 0,
                          }}
                        >
                          <Box
                            style={{
                              width: "100%",
                              paddingTop: 25,
                              paddingBottom: 25,
                            }}
                          >
                            <img src={brandImage} />
                          </Box>
                          <Box
                            style={{
                              width: "100%",
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.common.white,
                              height: 50,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{ fontWeight: 600 }}
                            >{`Shop ${brandName}`}</Typography>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  }
                )}
                {!showAllBrands && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: 15,
                      paddingBottom: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      component={Link}
                      onClick={() => setShowAllBrands(true)}
                      variant="contained"
                      color="primary"
                      style={{ height: 50 }}
                    >
                      Show All Tire Brands
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </DefaultContainer>
    </>
  );
};

export default TiresMain;
