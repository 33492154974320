import { Box, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const SmogCheckOfferedLocationWidget = ({
  isSmogCheckStore = true,
  endPoint = "",
}) => {
  return (
    <>
      {isSmogCheckStore && (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <CheckCircleRoundedIcon
            style={{
              marginRight: 8,
              color: theme.palette.error.dark,
              fontSize: 16,
            }}
          />
          <Link
            style={{
              fontSize: 14,
              display: "flex",
              alignItems: "center",
              color: theme.palette.error.dark,
            }}
            underline="always"
            component={RouterLink}
            to={endPoint}
          >
            Smog Check Certified Location
          </Link>
        </Box>
      )}
    </>
  );
};

export default SmogCheckOfferedLocationWidget;
