import { Grid, Box, Typography } from "@material-ui/core";
import ReviewStars from "./widgets/review-stars";
import DefaultContainer from "../generic/default-container";

const TireBrandModelHeader = ({ tireBrand, tireModel, children }) => {
  return (
    <DefaultContainer>
      <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
        <Grid item xs={12}>
          {Boolean(tireBrand?.brandImage) && <img src={tireBrand.brandImage} />}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
            height: 350,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {Boolean(tireModel?.itemModelImage) && (
            <img src={tireModel.itemModelImage} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            style={{
              width: "100%",
            }}
          >
            <Typography variant={"h3"}>{tireBrand?.brandName}</Typography>
          </Box>
          <Box
            style={{
              width: "100%",
              marginBottom: 10,
            }}
          >
            <Typography variant={"h2"}>{tireModel?.itemModelName}</Typography>
          </Box>
          <ReviewStars {...tireModel} fontSize={25} />
          {children}
        </Grid>
      </Grid>
    </DefaultContainer>
  );
};

export default TireBrandModelHeader;
