import create from "zustand";
import TireItemsStore from "./tire-items-store";
import ClientTrackingStore from "./client/client-tracking-store";
import TireSearchFilterStore from "./tire-search-results/tire-search-filter-store";
import { useLocationStore } from "./location-store";
import { addToCartTireOptions } from "../../services/venom-service";
import dayjs from "dayjs";

const TireOptionTrackingStore = create((set, get) => {
  const initState = {
    renderedTireItemIds: [],
  };

  const addRenderedTire = (tire, selectedQty) => {
    const { renderedTireItemIds } = get();

    if (!renderedTireItemIds.includes(tire.itemId)) {
      set((prev) => ({
        renderedTireItemIds: [...prev.renderedTireItemIds, tire.itemId],
      }));
    }

    const activeTireByQty = tire.qtyController(selectedQty);
    const [userStore] = useLocationStore((st) => [st.userStore]);
    const [getEdgeClient] = ClientTrackingStore((st) => [st.getEdgeClient]);

    const addToCart = async () => {
      await activeTireByQty.addToCart();

      (async () => {
        const trackingTireList = (
          await TireItemsStore.getState().getActiveTireItems({
            tireSizeEndPoint: activeTireByQty.tireSizeEndPoint,
          })
        )
          .map((_tire) => _tire.qtyController(activeTireByQty._cartQty))
          .map((_tire) => {
            let {
              _fulfillmentDetails,
              _mailInRebates,
              mailInRebates,
              ...rest
            } = _tire;
            _fulfillmentDetails = JSON.stringify(_fulfillmentDetails);
            _mailInRebates = JSON.stringify(_mailInRebates);
            mailInRebates = JSON.stringify(mailInRebates);
            return {
              ...rest,
              _fulfillmentDetails,
              _mailInRebates,
              mailInRebates,
              optionRendered: get().renderedTireItemIds.includes(_tire.itemId),
              optionSelected: _tire.itemId == activeTireByQty.itemId,
            };
          });

        const { sorts } = TireSearchFilterStore.getState();

        const payload = {
          client: getEdgeClient(),
          ClientDeviceDateTime: dayjs().toISOString(),
          addToCartPathname: new URL(document.location).pathname,
          trackingTireList,
          sortByLabel: sorts.options.find(({ isSelected }) => isSelected)?.label ?? "",
          storeNumber: userStore.storeNumber,
        };

        addToCartTireOptions(payload);
      })();
    };

    return {
      ...activeTireByQty,
      addToCart,
    };
  };

  return {
    ...initState,
    addRenderedTire,
  };
});

export default TireOptionTrackingStore;
