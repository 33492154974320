import { Badge, IconButton } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import APP_ENV from "../../appEnvironment";
import { useCartStore } from "../../hooks/store/cart-store";
import ModalStore from "../../hooks/store/modal-store";

const { theme } = APP_ENV;

const CartIcon = ({ onClick = () => null }) => {
  const [cartDrawer, closeAllModals] = ModalStore((st) => [
    st.CartDrawer,
    st.closeAllModals,
  ]);
  const [cartItems] = useCartStore((st) => [st.cartItems]);

  const itemCount = cartItems.length;

  return (
    <IconButton
      aria-label="cart"
      onClick={() => {
        if (!cartDrawer.isOpen) {
          closeAllModals();
        }
        cartDrawer.toggleModal();
        onClick();
      }}
    >
      <Badge
        badgeContent={`${itemCount}`}
        color="primary"
        overlap="rectangular"
      >
        <ShoppingCartIcon
          style={{ color: theme.palette.common.black, fontSize: 28 }}
        />
      </Badge>
    </IconButton>
  );
};

export default CartIcon;
