import { Typography } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";

const StillHaveAConcern = () => {
  return (
    <>
      <Typography variant="h4" style={{ marginTop: 40 }}>
        Still have a concern?
      </Typography>
      <Typography variant="h5" gutterBottom>
        <span>
          Please call us at{" "}
          <a href={`tel:${APP_ENV.appPhoneNumber}`}>{APP_ENV.appPhoneNumber}</a>
          .
        </span>
      </Typography>
    </>
  );
};

export default StillHaveAConcern;
