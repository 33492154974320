import { Box, Divider, Paper } from "@material-ui/core";
import { useCartStore, CART_ITEM_TYPE } from "../../hooks/store/cart-store";
import CartItemCard from "./widgets/cart-item-card";
import TirePackageDetails from "../tires/tire-package-details";
import BrandModelSize from "../tires/widgets/brand-model-size";
import APP_ENV from "../../appEnvironment";
import DetailsOrRemoveLinks from "./widgets/details-or-remove-links";
import StaticCartItemDescription from "./widgets/static-cart-item-description";
import QtySelector from "../tires/widgets/qty-selector";
import CartHeader from "./widgets/cart-header";
import TirePackageSubtotal from "../tires/widgets/tire-package-subtotal";
import InventoryStatusByQty from "../tires/widgets/inventory-status-by-qty";
import ServiceCouponCallOut from "../promotions/widgets/service-coupon-call-out";

const { theme } = APP_ENV;

const CartDetails = () => {
  const [cartItems] = useCartStore((st) => [st.cartItems]);

  return (
    <>
      <CartHeader cartItemQty={cartItems?.length} />
      <Paper>
        {cartItems.map((item, i) => {
          return (
            <Box key={i} style={{ padding: 8 }}>
              {item.cartItemTypeId === CART_ITEM_TYPE.tire && (
                <>
                  <CartItemCard
                    itemImage={item.itemModelImage}
                    itemPrice={item.price}
                    qtyComponent={
                      <QtySelector
                        selectedQty={item._cartQty}
                        qtySelectOptions={item.qtySelectOptions}
                        onQtyChange={item.updateQty}
                      />
                    }
                  >
                    <BrandModelSize {...item} />
                    <DetailsOrRemoveLinks
                      itemEndPoint={item.itemSlug}
                      removeFromCart={item.removeFromCart}
                    />
                  </CartItemCard>
                  <TirePackageDetails activeTireByQty={item} />
                  <TirePackageSubtotal
                    subtotal={item._subtotal}
                    packageSubtotal={item._packageSubtotal}
                  />
                  <InventoryStatusByQty {...item} />
                </>
              )}

              {item.cartItemTypeId !== CART_ITEM_TYPE.tire && (
                <>
                  <CartItemCard
                    itemImage={item.image}
                    itemPrice={item?.price ?? 0}
                    qtyComponent={<QtySelector disableSelection={true} />}
                  >
                    <StaticCartItemDescription {...item} />
                    <DetailsOrRemoveLinks
                      itemEndPoint={item.endPoint}
                      removeFromCart={item.removeFromCart}
                    />
                  </CartItemCard>
                  <Box style={{ marginBottom: 10 }}>
                    <ServiceCouponCallOut {...item} />
                  </Box>
                </>
              )}
              {i !== cartItems.length - 1 && <Divider style={{ height: 2 }} />}
            </Box>
          );
        })}
      </Paper>
    </>
  );
};

export default CartDetails;
