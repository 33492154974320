import { Paper, Typography, Grid, Link, Box } from "@material-ui/core";
import MetaTags from "../../components/reusable/ReactMetaTags";
import StandardPageHeader from "../../components/generic/standard-page-header";
import { Link as RouterLink } from "react-router-dom";
import APP_ENV from "../../appEnvironment";
import { StandardWidthContainer } from "../../components/generic/containers";
import { useLocationStore } from "../../hooks/store/location-store";
import BreadCrumb from "../../components/generic/bread-crumb";

const { theme } = APP_ENV;

const LocationsDirectory = () => {
  const [locations] = useLocationStore((st) => [st.locations]);

  const directoryLocations = [
    ...new Set(locations.map(({ stateName }) => stateName)),
  ]
    .sort()
    .map((stateName) => {
      const locationsByState = locations.filter(
        (loc) => loc.stateName == stateName
      );

      return {
        stateName,
        cities: [
          ...new Set(locationsByState.map(({ displayCity }) => displayCity)),
        ]
          .sort()
          .map((_displayCity) => {
            const storesByDisplayCity = locationsByState.filter(
              (loc) => loc.displayCity == _displayCity
            );
            const { displayCity, displayCityEndPoint } = storesByDisplayCity[0];

            return {
              name: displayCity,
              endPoint: `/tire-shop/${displayCityEndPoint}`,
              stores: storesByDisplayCity
                .map(({ streetAddress, publicWebEndPoint }) => ({
                  name: streetAddress,
                  endPoint: `/locations/${publicWebEndPoint}`,
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
            };
          }),
      };
    });

  return (
    <>
      <MetaTags
        description={`Find a ${APP_ENV.appBrandName} location near you. ${APP_ENV.appBrandName} is the one-stop shop for tires, oil change, brakes, wheel alignment, and more.`}
        canonical="/locations-directory"
        title={`Locations Directory | ${APP_ENV.appBrandName}`}
      />
      <BreadCrumb
        paths={[["Locations", "/locations"], ["Locations Directory"]]}
      />
      <StandardPageHeader
        title={`${APP_ENV.appBrandName} Locations Directory`}
        subtitle={`Find ${APP_ENV.appBrandName} store near you.`}
      />

      <StandardWidthContainer
        backgroundColor={theme.palette.background.default}
      >
        <Paper style={{ marginTop: 25, marginBottom: 50, padding: 10 }}>
          <Grid container>
            {directoryLocations.map(({ stateName, cities }) => {
              return (
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3} key={stateName}>
                  <Typography
                    variant="h4"
                    style={{ paddingBottom: 15, paddingTop: 15 }}
                  >
                    {stateName}
                  </Typography>
                  {cities.map((city) => {
                    return (
                      <Box key={city.name} style={{ paddingBottom: 8 }}>
                        <Link
                          component={RouterLink}
                          to={city.endPoint}
                          underline="always"
                          style={{
                            color: theme.palette.common.black,
                            fontWeight: 600,
                          }}
                        >
                          {city.name}
                        </Link>
                        {city.stores.map((store) => {
                          return (
                            <Box key={store.name} style={{ paddingTop: 8 }}>
                              <Link component={RouterLink} to={store.endPoint}>
                                {store.name}
                              </Link>
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </StandardWidthContainer>
    </>
  );
};

export default LocationsDirectory;
