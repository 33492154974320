import MetaTags from "../../reusable/ReactMetaTags";
import APP_ENV from "../../../appEnvironment";

const FlatRepair = () => {
  return (
    <>
      <MetaTags
        description={`At ${APP_ENV.appBrandName} we fix flat tires FREE.
                             Whether you bought your tires from us or not, we are happy to repair
                             your flat car, light truck or SUV tire at no charge.`}
        canonical="/car-services/flat-repair"
        title={`Flat Tire Repair | ${APP_ENV.appBrandName}`}
      />
      <div>
        <p>
          <span>
            <strong>{`${APP_ENV.appBrandName} Repair Policy`}</strong>
          </span>
        </p>
        <p>
          Whether you bought your tires from us or not, we are happy to repair
          your flat car, light truck or SUV tire at no charge. We proudly repair
          thousands of flat tires for free every month.
          <br />
          During the repair process, we will remove the tire from the rim and
          inspect it inside and out to make sure the tire can be safely
          repaired.
        </p>
        <p>
          <span>
            <strong>
              For a tire to be repaired it must meet the following safety
              requirements:
            </strong>
          </span>
        </p>
        <ol>
          <li>The puncture must be in the tread area of the tire</li>
          <li>The puncture must be less that 1/4 inch in diameter.</li>
          <li>
            {`There must be a least 2/32" of tread remaining on the tread surfaces.`}
          </li>
          <li>The tire must not be severely weather checked.</li>
          <li>The tire must not be older than 6 years old*.</li>
          <li>The overall condition of the tire must be good. </li>
        </ol>
        <p>
          *Many customers are surprised by our decision to not to repair old
          tires. Our decision is based on the following:
        </p>
        <ul>
          <li>
            {`The National Highway Traffic Safety Administration (NHTSA) issued a
                    consumer advisory on the effects of aging on tires. In its advisory,
                    the agency cited both vehicle and tire manufacturers recommendations
                    that tires should be replaced within six to ten years, regardless of
                    tread depth.`}
          </li>
          <br />
          <li>
            NHTSA also issued a “Research Report to Congress on Tire Aging”. In
            the report, NHTSA studied the risk of tire failure caused by tires
            degrading over time. Their research showed that 77% of tire claims
            made to insurance companies made between 2002 and 2006 were made due
            to the effects of hot climates on tire construction and 84% of tire
            claims were made for tires that were more than six years old.
            California is a hot climate so tire aging occurs more rapidly here.
          </li>
        </ul>
        <p>
          <span>
            <strong>Run Flat Tire Repair</strong>
          </span>
        </p>
        <p>
          {`Some manufacturers allow repairs on their run flat tires, so all run flat
                tire repairs are handled based on the tire manufacturer's repair
                recommendations. Please call us at ${APP_ENV.appPhoneNumber} if you would like us
                to tell you if your tire manufacturer allows run flat repairs.`}
        </p>
      </div>
    </>
  );
};

export default FlatRepair;
