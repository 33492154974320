import * as React from "react";
import dayjs from "dayjs";
import Modal from "react-bootstrap-modal";
import { SyncLoader } from "react-spinners";
import auth from "../components/auth.jsx";
import { withStyles } from "@material-ui/core/styles";
import s from "../styles/wholesaleStyles.css";
import LocationStore from "../hooks/store/location-store";

const FetchData = (url, external, opts = {}, onProgress, asyncS = true) => {
  var uurl = "";
  if (typeof window !== "undefined") {
    let u = external
      ? url
      : `${window.location.protocol}//${window.location.hostname}:${
          window.location.port ? window.location.port : ""
        }/${url}`;
    uurl = u;
  }
  let storeno = LocationStore.getState().userStoreNumber;

  return fetch(uurl, {
    method: opts.method || "GET",
    headers: Object.assign({ "X-Global-Storeno": storeno }, opts.headers),
    body: opts.body,
  });
};

class WholeSale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      rim: "",
      ratio: "",
      width: "",
      rimRear: "",
      ratioRear: "",
      widthRear: "",
      searchmethod: "bysize",
      size: "",
      isMountedData: false,
      cart: false,
      thankyou: false,
      showinput: true,
      cussName: "",
      historytab: false,
      history: [],
      user: auth.getAuthObject().username,
      logedin: false,
      cartCount: 0,
      NoData: false,
    };

    this.logout = this.logout.bind(this);
    this.populateData = this.populateData.bind(this);
    this.selectPopData = this.selectPopData.bind(this);
    this.addtostate = this.addtostate.bind(this);
    this.resetEveryThing = this.resetEveryThing.bind(this);
    this.onChange = this.onChange.bind(this);
    this.buyModal = this.buyModal.bind(this);
    this.bymanule = this.bymanule.bind(this);
    this.bysiz = this.bysiz.bind(this);
    this.showcart = this.showcart.bind(this);
    this.thankyou = this.thankyou.bind(this);
    this.orderHistory = this.orderHistory.bind(this);
    this.getcartdata = this.getcartdata.bind(this);
  }
  getcartdata() {
    let url = "api/wholesale/wholesalecart";
    fetch(
      url,
      false,
      {
        method: "GET",
        headers: Object.assign(
          { "Content-Type": "application/json; charset=utf-8" },
          auth.getAccessToken()
        ),
      },
      (progressEvent) => {}
    )
      .then((response) => response.json())
      .then(
        (res) => {
          let data = res;
          this.setState({ cartCount: data.count });
        },
        (err) => {
          console.log(err);
        }
      );
  }
  componentWillUnmount() {
    auth.signOut(this.props.history);
  }
  populateData() {
    this.setState({ loading: true });
    var params = {
      rim: this.state.rim,
      ratio: this.state.ratio,
      width: this.state.width,
      rimRear: this.state.rimrear,
      reatioRear: this.state.ratiorear,
      widthRear: this.state.widthRear,
      user: this.state.user,
    };
    let url = "api/wholesale/indexnew";
    FetchData(
      url,
      false,
      {
        method: "POST",
        headers: Object.assign(
          { "Content-Type": "application/json; charset=utf-8" },
          auth.getAccessToken()
        ),
        body: JSON.stringify(params),
      },
      (progressEvent) => {}
    )
      .then((response) => response.json())
      .then(
        (res) => {
          let data = res;
          if (data.length > 0) {
            this.setState({
              list: data,
              isMountedData: true,
              showinput: false,
              thankyou: false,
              historytab: false,
              loading: false,
            });
          } else {
            this.setState({
              list: data,
              isMountedData: true,
              showinput: false,
              thankyou: false,
              historytab: false,
              loading: false,
              NoData: true,
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  logout() {
    this.setState({ logedin: false });
    auth.signOut(this.props.history);
  }

  selectPopData(da, us) {
    this.setState({ cussName: da, custno: us, user: us });
  }
  componentDidMount() {
    this.setState(
      { loggingIn: true, logedin: true, open: false, showinput: true },
      this.selectPopData(auth.getAuthObject().firstName, this.state.user)
    );
    this.getcartdata();
  }

  addtostate(partial, evals) {
    if (evals == "1") {
      this.setState({ width: partial });
    } else if (evals == "2") {
      this.setState({ ratio: partial });
    } else if (evals == "3") {
      this.setState({ rim: partial });
      this.state.rim = partial;
      this.populateData();
    }
  }
  resetEveryThing() {
    if (this.refs.child) {
      this.refs.child.state.evals = "1";
      this.refs.child.state.select = "Select Your Width";
      this.refs.child.state.part = "width";
      this.refs.child.populateSize();
      this.setState({ cart: false });
    }
    this.setState({ width: "", ratio: "", rim: "", size: "" });
  }
  manualentry = (str) => {
    var arr = [];
    arr[0] = str.substring(0, 3);
    arr[1] = str.substring(3, 5);
    arr[2] = str.substring(5, str.length);
    return arr;
  };
  onChange(e) {
    if (e.target.value.length < 8) {
      const state = this.state;
      state[e.target.name] = e.target.value;
      this.setState(state);

      var tires = this.manualentry(e.target.value);
      this.setState({ width: tires[0], ratio: tires[1], rim: tires[2] });
    }
  }
  bysiz() {
    this.setState({
      searchmethod: "bysize",
      cart: false,
      showinput: true,
      historytab: false,
    });
    this.resetEveryThing();
  }
  bymanule() {
    this.setState({
      searchmethod: "manule",
      cart: false,
      showinput: true,
      historytab: false,
    });
    this.resetEveryThing();
  }
  buyModal(part) {
    this.refs.buymodal.closeModal(part);
  }
  thankyou() {
    this.setState({ thankyou: true });
  }
  showcart() {
    this.setState({
      cart: true,
      showinput: false,
      historytab: false,
      searchmethod: "",
    });
  }
  orderHistory() {
    this.setState({
      showinput: false,
      searchmethod: "history",
      historytab: true,
    });

    let url = `api/wholesale/gethistory?custno=${this.state.user}`;
    FetchData(
      url,
      false,
      {
        method: "GET",
        headers: Object.assign(
          { "Content-Type": "application/json; charset=utf-8" },
          auth.getAccessToken()
        ),
      },
      (progressEvent) => {}
    )
      .then((response) => response.json())
      .then(
        (res) => {
          let data = res;
          this.setState({ history: data });
        },
        (err) => {
          console.log(err);
        }
      );
  }
  render() {
    let style = {
      width: "100%",
      padding: "5px",
      height: "30px",
    };
    return (
      <div className="container">
        <div>
          {this.state.logedin && (
            <div className="col-lg-12">
              <h1 className="col-lg-12 col-md-12 col-sm-12">
                {this.state.cussName}
              </h1>
              <div className="WS-actions">
                <a
                  className={
                    this.state.searchmethod == "manule"
                      ? "btn btn-primary inverted"
                      : "btn btn-primary"
                  }
                  onClick={() => this.bymanule()}
                >
                  Manual Entry
                </a>
                <span> </span>
                <a
                  className={
                    this.state.searchmethod == "bysize"
                      ? "btn btn-primary inverted"
                      : "btn btn-primary"
                  }
                  onClick={() => this.bysiz()}
                >
                  By Size
                </a>
                <span> </span>
                <a
                  className={
                    this.state.searchmethod == "history" &&
                    this.state.historytab != false
                      ? "btn btn-primary inverted"
                      : "btn btn-primary"
                  }
                  onClick={() => this.orderHistory()}
                >
                  Order History
                </a>
                <span> </span>
                {/*<a className={this.state.cart == true ? "btn btn-continue-clicked" : "btn btn-continue"} onClick={() => this.refs.payments.closeModal()}>Make Payment</a><span> </span>*/}{" "}
                <a
                  className={
                    this.state.cart == true
                      ? "btn btn-continue-clicked"
                      : "btn btn-continue"
                  }
                  onClick={() => this.showcart()}
                >
                  Cart
                </a>
                <span> </span>
                <a className="btn btn-additional" onClick={() => this.logout()}>
                  Log Out
                </a>
                <a onClick={() => this.showcart()}>
                  {" "}
                  <div
                    style={{ display: "inline-block" }}
                    className="cart-summary-wholesale row"
                  >
                    <span id="count-cartw" className="count-cartw">
                      {this.state.cartCount}
                    </span>{" "}
                    <span className="cart-divider">|</span>{" "}
                    <img
                      className="cart-img"
                      width="16"
                      height="16"
                      src="https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/ico-cart-blue.svg"
                    />{" "}
                  </div>
                </a>
              </div>
              <hr />
            </div>
          )}
          {this.state.logedin && this.state.showinput ? (
            <div className="row input-row">
              <div className="col-lg-4 col-md-4 col-sm-4">
                {/*<Payments ref="payments" />*/}
                {this.state.searchmethod == "bysize" ? (
                  <SelectEdit
                    funcAddTosatate={this.addtostate}
                    name="width"
                    ref="child"
                    part="205"
                    evals="1"
                  />
                ) : (
                  <input
                    style={style}
                    className="form-control WS-input"
                    onChange={this.onChange}
                    name="size"
                    value={this.state.size}
                    ref="manuleinput"
                    placeholder="Tire Size"
                    type="text"
                  />
                )}
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 WS-size-label">
                <div className="col-lg-1">{this.state.width} /</div>
                <div className="col-lg-1">{this.state.ratio} R</div>
                <div className="col-lg-1">{this.state.rim}</div>
              </div>
              <a
                className="btn btn-continue"
                onClick={() => this.populateData()}
              >
                Search
              </a>
              <a className="btn btn-danger" onClick={this.resetEveryThing}>
                Clear
              </a>
              <span> </span>
            </div>
          ) : null}

          <ModalWholeSaleBuyTires
            getcartdata={this.getcartdata}
            custno={this.state.custno}
            ref="buymodal"
          />
          {this.state.historytab ? (
            <div>
              <History historys={this.state.history} />
            </div>
          ) : this.state.thankyou ? (
            <ThankYou />
          ) : this.state.cart ? (
            <Cart getcartdata={this.getcartdata} thankyou={this.thankyou} />
          ) : this.state.loading ? (
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100px",
                marginTop: "100px",
              }}
            >
              <SyncLoader />
            </div>
          ) : (
            <Table
              buyModal={this.buyModal}
              NoData={this.state.NoData}
              isMountedData={this.state.isMountedData}
              ref="childs"
              li={this.state.list}
            />
          )}
        </div>
      </div>
    );
  }
}

class History extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <h3>Your order history:</h3>
        <table className="_tablesorter">
          <thead style={{ cursor: "pointer" }}>
            <tr>
              <th className="col-lg-1 col-md-1 brandcontainer">Date</th>
              <th className="col-lg-1 col-md-1">Brand</th>
              <th className="col-lg-1 col-md-1">Model</th>
              <th className="col-lg-1 col-md-1">Qty</th>
              <th className="col-lg-1 col-md-1">Delivery Instructions</th>
              <th className="col-lg-2 col-md-2">Payment Type</th>
              <th className="col-lg-3 col-md-3">Description</th>
            </tr>
          </thead>

          <tbody>
            {this.props.historys.map((x) => (
              <tr>
                <td>{dayjs(x.ts).format("DD MMM YYYY")}</td>
                <td>{x.brand}</td>
                <td>{x.model}</td>
                <td>{x.qty}</td>
                <td>{x.delivery_Instructions}</td>
                <td>{x.payment}</td>
                <td>{x.descrip.replace("*", "").replace("@", "")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isMounted: false,
      remove: true,
      customeremail: "",
    };
    this.sub = true;
    this.getcartdata = this.getcartdata.bind(this);
    this.submitt = this.submitt.bind(this);
    this.carttotal = this.carttotal.bind(this);
    this.remove = this.remove.bind(this);
    this.onchange = this.onchange.bind(this);
  }
  componentDidMount() {
    this.getcartdata();
  }
  getcartdata() {
    let url = "api/wholesale/wholesalecart";
    FetchData(
      url,
      false,
      {
        method: "GET",
        headers: Object.assign(
          { "Content-Type": "application/json; charset=utf-8" },
          auth.getAccessToken()
        ),
      },
      (progressEvent) => {}
    )
      .then((response) => response.json())
      .then(
        (res) => {
          let data = res;
          this.setState({ list: data.items, isMounted: true });
          this.props.getcartdata();
        },
        (err) => {
          console.log(err);
        }
      );
  }
  submitt() {
    if (this.sub) {
      this.sub = false;
      let url = `api/wholesale/emailwholesalenew?customeremail=${this.state.customeremail}`;
      FetchData(
        url,
        false,
        {
          method: "GET",
          headers: { "Content-Type": "application/json; charset=utf-8" },
        },
        (progressEvent) => {}
      ).then((response) => {
        this.props.thankyou();
        this.sub = true;
      });
    }
  }
  remove(idd) {
    let url = `api/wholesale/itemremoval?id=${idd}`;
    FetchData(
      url,
      false,
      {
        method: "GET",
        headers: Object.assign(
          { "Content-Type": "application/json; charset=utf-8" },
          auth.getAccessToken()
        ),
      },
      (progressEvent) => {}
    ).then(
      (response) => {
        this.getcartdata();
        this.props.getcartdata();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  carttotal() {
    var li = this.state.list;
    var total = 0;

    if (li.length > 0) {
      for (var i = 0; i < li.length; i++) {
        total += parseFloat(li[i].price) * parseFloat(li[i].qty);
      }
    }

    return total.toFixed(2);
  }
  onchange(e) {
    let st = this.state;
    st[e.target.name] = e.target.value;
    this.setState(st);
  }
  render() {
    return (
      <div>
        <h3>Your order details:</h3>
        <table className="tablesorter">
          <thead>
            <tr>
              <th className="col-lg-1 col-md-1 brandcontainer">Brand</th>
              <th className="col-lg-1 col-md-1">PartNo</th>
              <th className="col-lg-1 col-md-1">Model</th>
              <th className="col-lg-3 col-md-3"></th>
              <th className="col-lg-2 col-md-2">Location</th>
              <th className="col-lg-1 col-md-1">Qty.</th>
              <th className="col-lg-1 col-md-1">Price</th>
              <th className="col-lg-1 col-md-1">Total Price</th>
              <th className="col-lg-1 col-md-1"></th>
            </tr>
          </thead>

          <tbody>
            {this.state.list.map((x) => (
              <tr>
                <td>
                  <div>
                    <div>
                      <a className="brand-image-container" href="#">
                        <div className="brandtext">
                          <img
                            className="newbrandLink"
                            alt={x.brand.trim()}
                            src={
                              "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images - tirebrands/" +
                              x.brand.trim().replace(" ", "-").toLowerCase() +
                              ".webp"
                            }
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </td>
                <td>{x.partno}</td>
                <td>{x.model}</td>
                <td>
                  <b>Description:</b> {x.descrip}
                  <br />
                  <b>Delivery:</b>
                  {x.delivery_Instructions}
                  <br />
                  <b>PO:</b> {x.pOno}
                </td>
                <td>{x.storename}</td>
                <td>{x.qty}</td>
                <td>${x.price}</td>
                <td>${(parseFloat(x.qty) * parseFloat(x.price)).toFixed(2)}</td>
                <td>
                  <a onClick={() => this.remove(x.id)}>Remove</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        Enter An Email Address To Recieve Order Confirmation<span> </span>
        <input
          placeholder="Email"
          style={{ border: "solid 1px black" }}
          name="customeremail"
          value={this.state.customeremail}
          onChange={(e) => this.onchange(e)}
        />
        <div className="row WS-total col-lg-2 col-md-2">
          <div className="col-lg-12 col-md-12">Total Price</div>
          <div className="col-lg-12 col-md-12" id="ordertotal">
            ${this.state.isMounted ? this.carttotal() : null}
          </div>
        </div>
        <br />
        <a
          onClick={() => this.submitt()}
          className="btn btn-continue pull-right WS-confirm-order"
        >
          Confirm Order
        </a>
        <br /> <br />
      </div>
    );
  }
}
class SelectEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "205",
      isMounted: false,
      width: "",
      ratio: "",
      rim: "",
      evals: this.props.evals,
      part: "width",
      Data: [],
      DataRatio: [],
      DataRim: [],
      select: "Select Your Width",
    };
    this.handleChange = this.handleChange.bind(this);
    this.populateSize = this.populateSize.bind(this);
  }

  handleChange(e) {
    //const state = this.state;
    //state[e.target.name] = e.target.value;
    //this.setState(state);

    if (this.state.part == "width") {
      this.props.funcAddTosatate(e.target.value, this.state.evals);
      this.setState({
        value: e.target.value,
        evals: "2",
        part: "ratio",
        width: e.target.value,
      });
      this.state.evals = "2";
      this.state.select = "Select Your Ratio";
      this.state.part = "ratio";
      this.state.width = e.target.value;
      this.populateSize();
    } else if (this.state.part == "ratio") {
      this.props.funcAddTosatate(e.target.value, this.state.evals);
      this.setState({
        value: e.target.value,
        evals: "3",
        part: "rim",
        ratio: e.target.value,
      });
      this.state.evals = "3";
      this.state.part = "rim";
      this.state.select = "Select Your Rim";
      this.state.ratio = e.target.value;
      this.populateSize();
    } else {
      this.props.funcAddTosatate(e.target.value, this.state.evals);
      this.setState({
        value: e.target.value ? e.target.value : "205",
        evals: "1",
        rim: e.target.value,
      });
      this.state.evals = "1";
      this.state.part = "width";
      this.state.select = "Another Width ?";
      this.state.rim = e.target.value;
      this.populateSize();
    }
  }
  componentDidMount() {
    this.state.isMounted = true;
    this.populateSize();
  }
  populateSize() {
    var perams = {
      eval: this.state.evals,
      width: this.state.width,
      ratio: this.state.ratio,
      rim: this.state.rim,
    };

    if (this.state.isMounted == true) {
      let url = `api/wholesale/getsizes?width=${this.state.width}&&ratio=${this.state.ratio}&&rim=${this.state.rim}&&eval=${this.state.evals}`;
      FetchData(
        url,
        false,
        {
          method: "GET",
          headers: Object.assign(
            { "Content-Type": "application/json; charset=utf-8" },
            auth.getAccessToken()
          ),
        },
        (progressEvent) => {}
      )
        .then((response) => response.json())
        .then(
          (res) => {
            let msg = res;
            if (this.state.evals == "1") {
              this.setState({ Data: msg });
            } else if (this.state.evals == "2") {
              this.setState({ DataRatio: msg });
            } else if (this.state.evals == "3") {
              this.setState({ DataRim: msg });
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }
  render() {
    var i = 0;
    var list = [];
    if (this.state.evals == "1") {
      list = this.state.Data.map((x) => (
        <option key={x} value={x}>
          {" "}
          {x}{" "}
        </option>
      ));
    } else if (this.state.evals == "2") {
      list = this.state.DataRatio.map((x) => (
        <option key={x} value={x}>
          {" "}
          {x}{" "}
        </option>
      ));
    } else if (this.state.evals == "3") {
      list = this.state.DataRim.map((x) => (
        <option key={x} value={x}>
          {" "}
          {x}{" "}
        </option>
      ));
    }
    return (
      <select
        name={this.props.name}
        onChange={this.handleChange}
        value={this.state.part}
        className="sizeselector form-control WS-select"
        id="qty"
      >
        <option value={this.state.select}> {this.state.select}</option>
        {list}
      </select>
    );
  }
}
class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      dealerView: true,
      whichwaysorted: false,
      sorted: "",
    };
    this.handleInsertButtonClick = this.handleInsertButtonClick.bind(this);
    this.filterFunction = this.filterFunction.bind(this);
    this.sortTable = this.sortTable.bind(this);
    this.compare = this.compare.bind(this);
    this.dealerview = this.dealerview.bind(this);
    this.price = true;
    this.stock1 = true;
    this.stock2 = true;
    this.store3 = true;
    this.corp = true;
  }
  handleInsertButtonClick(part) {
    this.props.buyModal(part);
  }

  componentDidMount() {
    this.setState({ isMounted: true });
    let that = this;
  }
  upDate() {
    //this.refs.table.forceUpdate();
  }
  filterFunction(e) {
    //$('tr').show();
    document.getElementsByName("tr").style.display = "unset";
    var find = e.target.value; //search value

    if (find != null && find.length > 0) {
      //search every span for this content
      //$("tbody  td:first-child").each(function () {
      //    if ($(this).attr("data-brand").indexOf(find.toUpperCase()) == -1) {
      //        $(this).closest('tr').hide();
      //    }
      //});
    }
  }
  compare(a, b) {
    if (parseInt(a.onhand1) < parseInt(b.onhand1)) return 1;
    if (parseInt(a.onhand1) > parseInt(b.onhand1)) return -1;
    return 0;
  }

  sortTable(index, price, context) {
    if (this.state.sorted != context) {
      this.setState({ sorted: context, whichwaysorted: false });
      this.state.sorted = context;
      this.state.whichwaysorted = false;
    }
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("tirestable1");
    switching = true;
    var cont = this.refs[context];
    this[context] = !this[context];

    if (this[context] == true) {
      while (switching) {
        switching = false;
        rows = table.getElementsByTagName("tr");
        for (i = 1; i < rows.length - 1; i++) {
          shouldSwitch = false;

          x = rows[i].getElementsByTagName("TD")[index];
          y = rows[i + 1].getElementsByTagName("TD")[index];

          if (price == "price") {
            if (parseFloat(x.dataset.price) > parseFloat(y.dataset.price)) {
              shouldSwitch = true;
              break;
            }
          } else {
            if (parseInt(x.innerHTML) > parseInt(y.innerHTML)) {
              shouldSwitch = true;
              break;
            }
          }
        }
        if (shouldSwitch) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
        }
      }

      //cont.find(".erro").empty();
      //cont.find(".erro").append("<span style='display:inline-block' className='glyphicon glyphicon-circle-arrow-up'></span>");
    } else {
      while (switching) {
        switching = false;
        rows = table.getElementsByTagName("tr");
        for (i = 1; i < rows.length - 1; i++) {
          shouldSwitch = false;

          x = rows[i].getElementsByTagName("TD")[index];
          y = rows[i + 1].getElementsByTagName("TD")[index];

          if (price == "price") {
            if (parseFloat(x.dataset.price) < parseFloat(y.dataset.price)) {
              shouldSwitch = true;
              break;
            }
          } else {
            if (parseInt(x.innerHTML) < parseInt(y.innerHTML)) {
              shouldSwitch = true;
              break;
            }
          }
        }
        if (shouldSwitch) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
        }
      }
      //cont.find(".erro").empty();
      //cont.find(".erro").append("<span style='display:inline-block' className='glyphicon glyphicon-circle-arrow-down'></span>");
    }
  }
  dealerview() {
    this.setState({ dealerView: !this.state.dealerView });
  }
  render() {
    const options = {
      onRowClick: this.handleInsertButtonClick,
    };
    var sty = {
      color: "green",
    };
    var grey = {
      color: "grey",
    };
    var store1 = "";
    var store2 = "";
    var store3 = "";
    if (!this.props.NoData && this.props.isMountedData == true) {
      var li = this.props.li[0];
      store1 = li.store1;
      store2 = li.store2;
      store3 = li.store3;
    }
    var poof = "poof";

    return (
      <div>
        {this.props.li.length > 0 ? (
          <div>
            <div className="row">
              <div className="col-lg-3">
                <input
                  className="form-control WS-select"
                  id="search"
                  data-table="tirestable"
                  placeholder="Filter By Brand"
                  onChange={this.filterFunction}
                />
              </div>
              <div className="col-lg-3 pull-right WS-result-actions">
                {this.state.dealerView ? (
                  <div className="WS-customer">Dealer View</div>
                ) : (
                  <div className="WS-dealer">Customer View</div>
                )}
                <label className="WSswitch">
                  <input
                    type="checkbox"
                    onChange={() => this.dealerview()}
                    className="btn btn-default"
                  />
                  <span className="WSslider round"></span>
                </label>
              </div>
            </div>
            <table id="tirestable1">
              <thead style={{ cursor: "pointer" }}>
                <tr style={{ fontSize: "10px" }}>
                  <th>Brand</th>
                  <th>PartNo</th>
                  <th>Description</th>
                  <th>WTY</th>
                  <th>Rating</th>
                  <th
                    className={this.state.dealerView ? null : poof}
                    onClick={() => this.sortTable(5, "price", "price")}
                    ref="price"
                  >
                    Price
                  </th>
                  <th
                    onClick={() => this.sortTable(6, "", "store1")}
                    style={{ color: this.state.sorted == "store1" && "blue" }}
                    ref="store1"
                  >
                    {store1}
                  </th>
                  <th
                    onClick={() => this.sortTable(7, "", "store2")}
                    style={{ color: this.state.sorted == "store2" && "blue" }}
                    ref="store2"
                  >
                    {store2}
                  </th>
                  <th
                    onClick={() => this.sortTable(8, "", "store3")}
                    style={{ color: this.state.sorted == "store3" && "blue" }}
                    ref="store3"
                  >
                    {store3}
                  </th>
                  <th
                    onClick={() => this.sortTable(9, "", "corp")}
                    style={{ color: this.state.sorted == "corp" && "blue" }}
                    data-sort={this.corp}
                    ref="corp"
                  >
                    Corp
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.li.sort(this.compare).map((x) => (
                  <tr key={x.partNo}>
                    <td data-brand={x.brand}>
                      <img
                        className={this.state.dealerView ? poof : "tire-img"}
                        width="100%"
                        height="100px"
                        src={
                          "https://venomstorage.blob.core.windows.net/bbtwebblob/images/WebInventory/" +
                          x.image
                        }
                      />
                      <img
                        className="newbrandLink"
                        width="100%"
                        height="100px"
                        alt={x.brand.trim()}
                        src={
                          "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images - tirebrands/" +
                          x.brand.trim().replace(" ", "-").toLowerCase() +
                          ".webp"
                        }
                      />
                    </td>

                    <td>{x.partNo}</td>
                    <td>
                      {x.description}
                      <br />
                      <span className={this.state.dealerView ? poof : null}>
                        <b>Model:</b>
                        <br />
                        {x.model}
                        <br />
                        <b>Part:</b>
                        <br /> {x.partNo}
                        <br />
                        <b>Rating:</b>
                        <br />
                        {x.rating}
                        <br />
                        <b>Warranty:</b>
                        <br />
                        {x.wty}
                        <br />
                      </span>
                    </td>
                    <td>{x.wty}</td>
                    <td>{x.rating}</td>
                    <td
                      data-price={x.price}
                      className={this.state.dealerView ? null : poof}
                    >
                      <span style={sty}>
                        <b>${x.price}</b>
                      </span>
                      <br />
                      <small style={grey}>SRP ${x.srprice}</small>
                    </td>
                    <td>{x.onhand1}</td>
                    <td>{x.onhand2}</td>
                    <td>{x.onhand3}</td>
                    <td>{x.stockLevelCorp}</td>
                    <td>
                      <a
                        onClick={() => this.handleInsertButtonClick(x.partNo)}
                        className="btn btn-default"
                      >
                        Add To Cart
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          this.props.NoData && (
            <div>
              We do not have the tire size you are looking for please try your
              search again...
            </div>
          )
        )}
      </div>
    );
  }
}
class ThankYou extends React.Component {
  render() {
    var sty = {
      testAlign: "center",
    };
    return (
      <div style={sty}>
        <h2>
          Thank you your order has been submitted we will be contacting you
          shortly.
        </h2>
      </div>
    );
  }
}
class ModalWholeSaleBuyTires extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      canClose: false,
      partNo: "",
      image: "",
      partno: "",
      brand: "",
      description: "",
      wty: "",
      rating: "",
      price: "",
      model: "",
      storeName1: "",
      storeName2: "",
      storeName3: "",
      stock1: "",
      stock2: "",
      stock3: "",
      custname: "",
      stockLevelCorp: "",
      storeno1: "",
      storeno2: "",
      storeno2: "",
      getfromstore: 1,
      qtywanted: "00",
      payment: "01",
      Delivery_Instructions: "",
      POno: "",
      storeNo: "",
      storename: "",
      qtytosend: "",
      error: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.getTireData = this.getTireData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectqty = this.selectqty.bind(this);
    this.getCount = this.getCount.bind(this);
    this.submittocart = this.submittocart.bind(this);
  }

  onChange(e) {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  closeModal(part) {
    this.setState({ open: !this.state.open, partNo: part });
    this.state.partNo = part;
    if (part) {
      this.getTireData();
    }
  }
  getTireData() {
    let url = `api/wholesale/tiremodalnew?id=${this.state.partNo}`;
    FetchData(
      url,
      false,
      {
        method: "GET",
        headers: Object.assign(
          { "Content-Type": "application/json; charset=utf-8" },
          auth.getAccessToken()
        ),
      },
      (progressEvent) => {}
    )
      .then((response) => response.json())
      .then(
        (res) => {
          let data = res;
          this.setState({
            image: data.image,
            partno: data.partno,
            brand: data.brand,
            description: data.description,
            wty: data.wty,
            rating: data.rating,
            price: data.price,
            model: data.model,
            storeName1: data.storeName1,
            storeName2: data.storeName2,
            storeName3: data.storeName3,
            stock1: data.stock1,
            stock2: data.stock2,
            stock3: data.stock3,
            custname: data.custname,
            stockLevelCorp: data.stockLevelCorp,
            storeno1: data.storeno1,
            storeno2: data.storeno2,
            storeno3: data.storeno3,
          });
        },
        (err) => {
          this.setState({ loggingIn: false });
          alert("Please Enter Valid Login Credentials");
        }
      );
  }
  selectqty(e) {
    const state = this.state;
    state[e.target.name] = e.target.value;
    if (e.target.name == "getfromstore") {
      state.storeNo =
        e.target[e.target.selectedIndex].getAttribute("data-stockatstore");
      state.storename =
        e.target[e.target.selectedIndex].getAttribute("data-storen");
      state.qtytosend =
        e.target[e.target.selectedIndex].getAttribute("data-value");
      if (parseInt(state.qtytosend) < 1) {
        state.error = true;
      } else {
        state.error = false;
      }
    }
    this.setState(state);
  }
  getCount() {
    var lis = [];
    for (var i = 1; i <= parseInt(this.state.qtytosend); i++) {
      lis.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return lis;
  }
  submittocart() {
    if (parseInt(this.state.qtywanted) > 0) {
      var peram = JSON.stringify({
        StoreNo: this.state.storeNo,
        Partno: this.state.partNo,
        Model: this.state.model,
        Brand: this.state.brand,
        Qty: this.state.qtywanted,
        Price: this.state.price,
        POno: this.state.POno,
        Delivery_Instructions: this.state.Delivery_Instructions,
        payment: this.state.payment,
        storename: this.state.storename,
        descrip: this.state.description,
        storeNo: this.state.storeNo,
        Custno: this.props.custno,
      });

      let url = `api/wholesale/modalnewpost?cartt=${peram}`;
      FetchData(
        url,
        false,
        {
          method: "GET",
          headers: Object.assign(
            { "Content-Type": "application/json; charset=utf-8" },
            auth.getAccessToken()
          ),
        },
        (progressEvent) => {}
      ).then(
        (response) => {
          this.closeModal();
          this.props.getcartdata();
        },
        (err) => {}
      );
    } else {
      alert("Please Select A Quantity");
    }
  }
  render() {
    var sty = {
      color: "red",
    };

    return (
      <div>
        <Modal
          style={{ width: "300px" }}
          dialogClassName="WS-cart-modal"
          show={this.state.open}
          onHide={() => this.closeModal()}
          aria-labelledby="ModalHeader"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">Wholesale Cart</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-2 col-md-2">
                  <b>Brand:</b>
                </div>
                <div className="col-lg-2 col-md-2">
                  <b>Details:</b>
                </div>
                <div className="col-lg-3 col-md-3">
                  <b>Description:</b>
                </div>
                <div className="col-lg-1 col-md-1">
                  <b>Price:</b>
                </div>
                <div className="col-lg-4 col-md-4">
                  <b>Store Stock Levels:</b>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-2 col-md-2">
                  <div>
                    <a className="brand-image-container" href="#">
                      <div className="brandtext">
                        <img
                          width="100%"
                          className="newbrandLink"
                          alt={this.state.brand.trim()}
                          src={
                            "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images - tirebrands/" +
                            this.state.brand
                              .trim()
                              .replace(" ", "-")
                              .toLowerCase() +
                            ".webp"
                          }
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2">
                  <span>Model:</span>
                  <br />
                  <span>{this.state.model}</span> <br />
                  <span>Part:</span>
                  <br />
                  <span>{this.state.partNo}</span> <br />
                  <span>Rating:</span>
                  <br />
                  <span>{this.state.rating}</span> <br />
                  <span>Warranty:</span>
                  <br />
                  <span>{this.state.wty}</span>
                </div>
                <div className="col-lg-3 col-md-3">
                  {this.state.description}
                </div>
                <div className="col-lg-2 col-md-2">
                  {"$ " + this.state.price}
                </div>
                <div className="col-lg-3 col-md-3">
                  {this.state.storeName1}: {this.state.stock1}/InStock
                  <br />
                  {this.state.storeName2}; {this.state.stock2}/InStock
                  <br />
                  {this.state.storeName3}: {this.state.stock3}/InStock
                  <br />
                  Corp: {this.state.stockLevelCorp}/InStock
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <label>Store:</label>
                  <br />
                  <select
                    className="col-lg-12 form-control WS-select"
                    value={this.state.getfromstore}
                    id="StoreNo"
                    name="getfromstore"
                    onChange={this.selectqty}
                    required="required"
                  >
                    <option value="1">Select...</option>
                    <option
                      value="2"
                      data-value={this.state.stock1}
                      data-stockatstore={this.state.storeno1}
                      data-storen={this.state.storeName1}
                    >
                      {this.state.storeName1}
                    </option>
                    <option
                      value="3"
                      data-value={this.state.stock2}
                      data-stockatstore={this.state.storeno2}
                      data-storen={this.state.storeName2}
                    >
                      {this.state.storeName2}
                    </option>
                    <option
                      value="4"
                      data-value={this.state.stock3}
                      data-stockatstore={this.state.storeno3}
                      data-storen={this.state.storeName3}
                    >
                      {this.state.storeName3}
                    </option>
                    <option
                      value="5"
                      data-value={this.state.stockLevelCorp}
                      data-storen="Corp"
                      data-stockatstore="98"
                    >
                      Corp
                    </option>
                  </select>
                </div>
                <div className="col-lg-4 col-md-4">
                  <label>Quantity:</label>
                  <br />
                  <select
                    className="col-lg-12 form-control WS-select"
                    id="Qty"
                    name="qtywanted"
                    required="required"
                    onChange={this.selectqty}
                  >
                    <option value="00">Select...</option>
                    {parseInt(this.state.qtytosend) > 0
                      ? this.getCount()
                      : null}
                  </select>
                </div>
                <div className="col-lg-4 col-md-4">
                  <label>Payment:</label>
                  <br />
                  <select
                    className="col-lg-12 form-control WS-select"
                    id="payment"
                    name="payment"
                    required="required"
                    onChange={this.selectqty}
                  >
                    <option value="01">Select...</option>
                    <option value="Charge to Account">Charge to Account</option>
                    <option value="COD">COD</option>
                    <option value="National Account Charge">
                      National Account Charge
                    </option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  {this.state.error ? (
                    <span style={sty} id="errormsg">
                      Error this store has none in stock
                    </span>
                  ) : null}
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <label>P.O. Number:</label>
                  <br />
                  <input
                    type="text"
                    maxLength="50"
                    name="POno"
                    className="form-control WS-input"
                    onChange={this.selectqty}
                  />
                </div>
                <div className="col-lg-6 col-md-6">
                  <label>Delivery Instructions:</label>
                  <br />
                  <input
                    type="text"
                    maxLength="50"
                    name="Delivery_Instructions"
                    className="form-control WS-select"
                    onChange={this.selectqty}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <a onClick={() => this.submittocart()} className="btn btn-primary">
              Submit
            </a>
            <a onClick={() => this.closeModal()} className="btn btn-primary">
              Cancel
            </a>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withStyles(s)(WholeSale);
