import VyperLandingPage from "./template/vyper-landing-page";
import APP_ENV from "../../../appEnvironment";
import { STATIC_CART_ITEMS } from "../../../static/cart-items";

export const VYPER_ESSENTIAL_PERSONNEL_DISCOUNT = {
  promotionDirectory: "/essential-personnel-discount",
  edgeItemId: 101616,
  activePromotions: [
    {
      endPoint: "nevada",
      stores: [
        "3200",
        "3201",
        "3202",
        "3203",
        "3204",
        "3205",
        "3206",
        "3207",
        "3208",
        "3209",
        "3210",
        "3211",
        "3212",
        "3213",
        "3214",
      ],
      story:
        "We are proud for the opportunity to say thank you for your service. A 10% discount is our present to you!",
    },
  ],
};

const VyperEssentialPersonnelDiscount = () => {
  const PROMO_IMAGES = {
    1: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/military-uber-union-coupon-code.png",
  };

  return (
    <VyperLandingPage
      lineOneText="Save 10% Instantly"
      lineTwoText="On Your Next Purchase"
      lineThreeText="*Military, Uber/Lyft, & Union Members Only"
      buttonText="Redeem Offer"
      promotionImage={PROMO_IMAGES[APP_ENV.appBrandId]}
      promotionCartItem={STATIC_CART_ITEMS.ESSENTIAL_PERSONNEL_DISCOUNT}
      activePromotions={VYPER_ESSENTIAL_PERSONNEL_DISCOUNT.activePromotions}
      cardPrimaryText="Confirm Service Center Location"
      cardSecondaryText="Must show proof at time of invoice"
    />
  );
};

export default VyperEssentialPersonnelDiscount;
