import { Box, Typography } from "@material-ui/core";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import APP_ENV from "../../../appEnvironment";
import AcUnitIcon from "@material-ui/icons/AcUnit";

const { theme } = APP_ENV;

const TireFeaturesCollection = ({
  warrantyMiles = 130,
  isRunFlat = false,
  isSnowRated = false,
  isSnowFlake = false,
}) => {
  return (
    <Box>
      {Boolean(warrantyMiles) && (
        <Box
          style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}
        >
          <ThumbUpIcon style={{ color: "green", marginRight: 8 }} />
          <Typography style={{ fontSize: 14 }}>
            <strong>{warrantyMiles.toLocaleString("en-US")}</strong>
            {` Mile Warranty`}
          </Typography>
        </Box>
      )}
      {isRunFlat && (
        <Box
          style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}
        >
          <VerifiedUserIcon
            style={{ color: theme.palette.gray[700], marginRight: 8 }}
          />
          <Typography style={{ fontSize: 14 }}>Run-Flat Rated</Typography>
        </Box>
      )}
      {isSnowRated && (
        <Box
          style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}
        >
          <AcUnitIcon style={{ color: "blue", marginRight: 8 }} />
          <Typography style={{ fontSize: 14 }}>
            {isSnowFlake && "3-Peak Mountain Snowflake Rated"}
            {!isSnowRated && "Snow Rated"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TireFeaturesCollection;
