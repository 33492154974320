import create from "zustand";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";

const TIME_OUT_MIN = 30;

let timeout_ref;

const SessionTimerStore = create((set, get) => {
  const createSessionState = () => ({
    sessionStart: dayjs(),
    sessionId: uuidv4(),
  });

  const setSessionTimer = () => {
    if (!document.hidden) {
      const minRemaining = Math.max(
        TIME_OUT_MIN - dayjs().diff(get().sessionStart, "minute"),
        0.01
      );

      timeout_ref = setTimeout(() => {
        set({ ...createSessionState() });
        setSessionTimer();
      }, minRemaining * 60000);
    } else {
      clearTimeout(timeout_ref);
      timeout_ref = null;
    }
  };

  return {
    ...createSessionState(),
    setSessionTimer,
  };
});

export const useSessionTimerStore = (selector = (store) => store) => {
  const [setSessionTimer] = SessionTimerStore((st) => [st.setSessionTimer]);

  useEffect(() => {
    setSessionTimer();
    document.addEventListener("visibilitychange", setSessionTimer);
  }, []);

  return SessionTimerStore(selector);
};

export default SessionTimerStore;
