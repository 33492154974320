import {
  Box,
  Typography,
  Paper,
  Collapse,
  Button,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useState } from "react";
import ExpandIcons from "../../generic/expand-icons";
import APP_ENV from "../../../appEnvironment";
import TireItemDiscountsCollection from "../../promotions/full-site-tire-sale/tire-item-discounts-collection";

const { theme } = APP_ENV;

const TireItemDiscountsCollapsible = ({ activeTireByQty }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { _totalDiscountsAvailable, _promotion } = activeTireByQty;

  return (
    <>
      {_totalDiscountsAvailable > 0 && (
        <>
          <ListItem
            component={Button}
            style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <ListItemText>
              <Typography
                style={{
                  fontSize: 14,
                  color: _promotion?.sitePromoColors?.tertiary,
                  fontWeight: "bold",
                }}
              >
                Total Savings
              </Typography>
            </ListItemText>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  marginRight: 5,
                  fontSize: 14,
                  color: _promotion?.sitePromoColors?.tertiary,
                  fontWeight: "bold",
                }}
              >{`-$${_totalDiscountsAvailable.toFixed(2)}`}</Typography>
              <ExpandIcons isExpanded={isOpen} />
            </Box>
          </ListItem>

          <Collapse in={isOpen}>
            <Box
              style={{
                backgroundColor: theme.palette.background.default,
                padding: 4,
                marginTop: 8,
                marginBottom: 8,
              }}
            >
              <Paper style={{ padding: 8 }}>
                <TireItemDiscountsCollection
                  activeTireByQty={activeTireByQty}
                />
              </Paper>
            </Box>
          </Collapse>
        </>
      )}
    </>
  );
};

export default TireItemDiscountsCollapsible;
