import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import ModalStore from "./store/modal-store";
import { createVIPMember } from "../services/venom-service";

const { validationSchema, schemaKeys, initialValues } = (() => {
  const schema = {
    userName: yup
      .string()
      .required("First & Last Name is required.")
      .min(3, "First & Last Name must be more than 3 characters")
      .max(60, "First & Last Name must be less than 60 characters"),
    email: yup
      .string("Enter your email")
      .email("Please enter a valid email")
      .required("A valid email is required."),
    storeNumber: yup
      .string()
      .required("Preferred location is required.")
      .min(2),
  };

  const schemaKeys = Object.keys(schema);

  return {
    validationSchema: yup.object(schema),
    schemaKeys,
    initialValues: Object.assign(
      {},
      ...schemaKeys.map((key) => ({ [key]: "" }))
    ),
  };
})();

const useVipMembership = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [GlobalLoading] = ModalStore((st) => [st.GlobalLoading]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!hasSubmitted && !GlobalLoading.isOpen) {
        GlobalLoading.openModal();
        const { userName, email, storeNumber } = values;
        const [firstName, lastName = ""] = userName.split(" ");

        await createVIPMember({
          storeNumber,
          email,
          firstName,
          lastName,
        });

        setHasSubmitted(true);
        GlobalLoading.closeModal();
      }
    },
  });

  const createHandleChange = (key) => {
    return (e) => {
      const value = e?.target?.value ?? e;
      formik.setTouched({}, false);
      formik.setFieldValue(key, value);
    };
  };

  const { handleSubmit, values, touched, errors } = formik;

  return {
    handleSubmit,
    hasSubmitted,
    ...Object.assign(
      {},
      ...schemaKeys.map((key) => {
        return {
          [key]: {
            key,
            value: values?.[key] ?? "",
            errorMessage: touched?.[key] && errors?.[key],
            handleChange: createHandleChange(key),
          },
        };
      })
    ),
  };
};

export default useVipMembership;
