import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import TireSearchResults from "./tire-search-results";
import TireSearchFilterStore from "../../hooks/store/tire-search-results/tire-search-filter-store";
import BreadCrumb from "../../components/generic/bread-crumb";
import StandardPageHeader from "../../components/generic/standard-page-header";
import AllTiresStore from "../../hooks/store/tire-search-results/all-tires-store";
import ModalStore from "../../hooks/store/modal-store";
import useOpenTireSizeSelector from "../../hooks/util/useOpenTireSizeSelector";
import { useLocationStore } from "../../hooks/store/location-store";
import { useSessionTimerStore } from "../../hooks/store/client/session-timer-store";
import useZState from "../../hooks/util/useZState";

export const REAR_TIRE_SIZE_KEY = "rear_tire_size";

const TireSearchResultsWrapper = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [getState, setState] = useZState({
    sessionId: null,
    userStoreNumber: null,
  });

  const { tireSetId, sorts, filters, tireSet, matchedTireSizes, totalTireCounts } =
    TireSearchFilterStore();

  const { getAllTiresStore, getAllTiresStoreAsync } = AllTiresStore();
  const [GlobalLoading] = ModalStore((st) => [st.GlobalLoading]);
  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: true });
  const [sessionId] = useSessionTimerStore((st) => [st.sessionId]);
  const [userStoreNumber, userStore] = useLocationStore((st) => [
    st.userStoreNumber,
    st.userStore,
  ]);

  const totalTireCount = totalTireCounts[activeTab] ?? totalTireCounts[0] ?? [];
  const activeTireData = tireSet?.[activeTab] ?? tireSet?.[0];
  const isStaggered = matchedTireSizes?.length > 1;
  const activeFilters = filters?.[activeTab]?.[0]?.options?.length > 0
    ? filters[activeTab]
    : [];

  useEffect(() => {
    const currentEndPoint = [location.pathname, location.search].join("");
    if (!currentEndPoint || !userStoreNumber || !sessionId) return;
    let fetchTires = false;
    let forceUpdate = false;
    switch (true) {
      case currentEndPoint !== getAllTiresStore().tireSearchEndPoint:
        fetchTires = true;
        break;
      case userStoreNumber !== getState().userStoreNumber:
        fetchTires = true;
        forceUpdate = true;
        break;
      case sessionId !== getState().sessionId:
        fetchTires = true;
        forceUpdate = true;
        break;
    }
    
    if (!fetchTires) return;
    setState({ userStoreNumber, sessionId });

    (async () => {
      GlobalLoading.openModal();
      const frontTireSizeEndPoint = params?.tireSizeEndPoint;
      const rearTireSizeEndPoint = new URLSearchParams(location.search).get(
        REAR_TIRE_SIZE_KEY
      );

      const allTiresState = await getAllTiresStoreAsync(
        [frontTireSizeEndPoint, rearTireSizeEndPoint],
        forceUpdate
      );
      GlobalLoading.closeModal();

      switch (true) {
        case !Boolean(allTiresState.tireSearchEndPoint):
          openTireSizeModal();
          break;
        case Boolean(allTiresState.tireSearchEndPoint !== currentEndPoint):
          history.push(allTiresState.tireSearchEndPoint);
          break;
        default:
          return;
      }
    })();
  }, [location, userStore]);

  useEffect(() => () => getAllTiresStore().clearAllTiresStore(), []);

  const getBreadCrumbPaths = () => {
    const basePath = ["Tires", "/tires"];
    if (!matchedTireSizes?.length) return [basePath, [""]];

    const displaySizes = matchedTireSizes.map((tire, i) =>
      matchedTireSizes.length > 1
        ? `${tire.tireSizeEndPoint} (${i === 0 ? "Front" : "Rear"})`
        : tire.tireSizeEndPoint
    );

    return [basePath, [displaySizes.join(" / ")]];
  };

  const getHeaderTitle = () => {
    if (!matchedTireSizes?.length) return "Shop Tires";

    const type = matchedTireSizes.length > 1 ? `${activeTab === 0 ? "Front" : "Rear"} ` : "";
    const size =
      matchedTireSizes[activeTab]?.tireDisplaySize ??
      matchedTireSizes[0].tireDisplaySize;

    return `Shop ${type}Tires - ${size}`;
  };

  const getHeaderSubtitle = () => {
    if (totalTireCount.length === 0) return "0 total items";
    return `${totalTireCount} total item${totalTireCount !== 1 ? "s" : ""}`;
  };

  const handleTabChange = (_, tabValue) => setActiveTab(tabValue);

  return (
    <>
      <BreadCrumb paths={getBreadCrumbPaths()} />
      <StandardPageHeader
        title={getHeaderTitle()}
        subtitle={getHeaderSubtitle()}
      />
      <TireSearchResults
        tireOptionTabs={
          isStaggered && (
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label={`${tireSet?.[0]?.length} ${tireSet?.[0]?.length === 1 ? "Front Tire" : "Front Tires"}`} value={0} />
              <Tab label={`${tireSet?.[1]?.length} ${tireSet?.[1]?.length === 1 ? "Rear Tire" : "Rear Tires"}`} value={1} />
            </Tabs>
          )
        }
        tireData={activeTireData}
        filters={activeFilters}
        sorts={sorts}
        tireSetId={tireSetId}
        isStaggered={isStaggered}
      />
    </>
  );
};

export default TireSearchResultsWrapper;