import {
  Box,
  Button,
  IconButton,
  Typography,
  Paper,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DrawerOffsetTop from "../../../modals/widgets/DrawerOffsetTop";
import APP_ENV from "../../../../appEnvironment";

const { theme } = APP_ENV;

const ConditionalSelectionMobileDrawer = ({
  selectionActive,
  setAllFieldsInactive,
  fields,
  isLoading,
}) => {
  const field = fields.find((field) => field.isActive);

  let gridItemWidth = 12;

  if (field && field?.options.length > 12) {
    const longestOption = Math.max(
      ...field.options?.map(({ label }) => label.toString().length)
    );

    if (longestOption < 15) {
      gridItemWidth = longestOption < 7 ? 4 : 6;
    }
  }

  return (
    <DrawerOffsetTop
      isOpen={selectionActive}
      closeModal={setAllFieldsInactive}
      anchor="right"
      PaperProps={{
        style: {
          width: "90%",
        },
      }}
    >
      {field && (
        <>
          <Paper
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              {field.showBackButton && (
                <IconButton onClick={field.goBack}>
                  <ArrowBackIosRoundedIcon
                    color={"primary"}
                    style={{ fontSize: 15 }}
                  />
                </IconButton>
              )}
              <Typography style={{ marginLeft: 10, fontWeight: "bold" }}>
                {field.label}
              </Typography>
            </Box>

            <IconButton onClick={setAllFieldsInactive}>
              <CloseRoundedIcon />
            </IconButton>
          </Paper>
          <Grid
            container
            style={{ padding: 8, paddingLeft: 16, paddingBottom: 100 }}
          >
            {isLoading && (
              <Box
                style={{
                  width: "100%",
                  marginTop: 50,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {field.options.map((option, i) => (
              <Grid item xs={gridItemWidth} key={i} style={{ marginTop: 5 }}>
                <Button
                  key={option.label}
                  onClick={option.setSelected}
                  variant={option.isSelected ? "contained" : "text"}
                  color={"primary"}
                  style={{ justifyContent: "flex-start", paddingBottom: 8 }}
                >
                  {option.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </DrawerOffsetTop>
  );
};

export default ConditionalSelectionMobileDrawer;
