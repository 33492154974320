import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  Link,
  Divider,
} from "@material-ui/core";
import LayoutStore from "../../hooks/store/client/layout-store";
import DefaultContainer from "../../components/generic/default-container";
import AboutBrandInfoGraphics from "../../components/reusable/about-brand-info-graphics";
import FiveReasonsToShopWithUs from "../../components/reusable/five-reasons-to-shop-with-us";
import { STATIC_CART_ITEMS } from "../../static/cart-items";
import MetaTags from "../../components/reusable/ReactMetaTags";
import APP_ENV from "../../appEnvironment";

const SaveHundredBrakes = () => {
  const { theme } = APP_ENV;

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  return (
    <>
      <MetaTags
        description={`Save $100 on brake service at ${APP_ENV.appBrandName}.`}
        canonical="/coupons/save-hundred-brakes"
        title={`Save $100 on Brakes`}
      />
      <Grid
        container
        style={{
          backgroundImage: `linear-gradient(${theme.palette.footer1.main}, ${theme.palette.background.default})`,
        }}
      >
        <Container style={{ paddingTop: 10, paddingBottom: 50 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Box style={{ width: "100%", maxWidth: 500 }}>
                <Box style={{ marginTop: 25 }}>
                  <Box>
                    <Typography
                      style={{ color: theme.palette.primary.contrastText }}
                    >{`Limited Time Offer`}</Typography>
                    <Divider
                      style={{
                        width: 40,
                        height: 2,
                        backgroundColor: theme.palette.primary.main,
                        marginBottom: 5,
                      }}
                    />
                  </Box>
                  <Typography
                    variant="h2"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    {"SAVE $100 ON BRAKES"}
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{
                      color: theme.palette.primary.contrastText,
                      fontWeight: 600,
                      paddingTop: 5,
                    }}
                  >
                    {"*Includes Free Brake Inspection"}
                  </Typography>

                  <Typography
                    style={{
                      color: theme.palette.primary.contrastText,
                      marginTop: 15,
                      marginBottom: 25,
                    }}
                  >
                    {`We will perform a free brake inspection and if your vehicle is in need of brakes, you will save $100 instantly with this offer. Same-day appointments available!`}
                  </Typography>
                </Box>
                <Paper
                  style={{
                    borderRadius: 15,
                    marginTop: 15,
                    marginBottom: 15,
                    padding: 25,
                    textAlign: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      STATIC_CART_ITEMS.SAVE_HUNDRED_ON_BRAKES.addToCart();
                    }}
                    style={{
                      width: 300,
                      height: 50,
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Get Offer Now
                  </Button>
                </Paper>
                <Box style={{ padding: 25, textAlign: "center" }}>
                  <Typography style={{ fontSize: 25, marginRight: 8 }}>
                    Need More Information? Call Us Now
                  </Typography>
                  <Link
                    href={`tel:${APP_ENV.appPhoneNumber}`}
                    style={{ fontWeight: "bold", fontSize: 25 }}
                    underline="always"
                  >
                    {APP_ENV.appPhoneNumber}
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: 500,
                  marginTop: 20,
                }}
              >
                <img
                  src={
                    "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/100-off-brakes-vyper.png"
                  }
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <DefaultContainer>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <AboutBrandInfoGraphics />
          </Grid>

          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <FiveReasonsToShopWithUs />
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default SaveHundredBrakes;
