import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import Rating from "@material-ui/lab/Rating";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const TireReviewCard = ({
  reviewerName,
  rating,
  reviewDate,
  vehicle,
  comment,
}) => {
  comment ??= "Reviewer did not leave a comment...";

  return (
    <Box style={{ width: "100%", marginBottom: 15 }}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <AccountCircleIcon
          style={{
            marginRight: 8,
            fontSize: 25,
            color: theme.palette.grey[500],
          }}
        />
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 600,
            color: theme.palette.grey[800],
          }}
        >
          {reviewerName}
        </Typography>
      </Box>
      <Rating
        value={rating}
        precision={1}
        style={{ fontSize: 20, marginTop: 5, marginBottom: 1 }}
        readOnly
      />

      <Typography
        style={{
          fontSize: 12,
          fontWeight: 600,
          color: theme.palette.grey[700],
          marginBottom: 5,
        }}
      >
        {`Reviewed on ${dayjs(reviewDate).format("MMMM D, YYYY")}`}
      </Typography>

      {vehicle && (
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          {vehicle}
        </Typography>
      )}

      <Typography style={{ marginTop: 3 }}>{comment}</Typography>
    </Box>
  );
};

export default TireReviewCard;
