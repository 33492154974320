import { useEffect, useState } from "react";
import { Box, Collapse, Typography } from "@material-ui/core";
import ExpandIcons from "../../generic/expand-icons";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import ErrorIcon from "@material-ui/icons/Error";
import { CART_ITEM_TYPE, useCartStore } from "../../../hooks/store/cart-store";
import React from "react";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const WebDepositRequired = ({ initialIsExpanded = false }) => {
  const [state, setState] = useState({
    show: false,
    isExpanded: initialIsExpanded,
  });

  const [isReady, cartItems] = useCartStore((st) => [st.isReady, st.cartItems]);

  useEffect(() => {
    if (isReady) {
      const show = cartItems.some((cartItem) =>
        [
          CART_ITEM_TYPE.tire,
          CART_ITEM_TYPE.consultation,
          CART_ITEM_TYPE.smogCheck,
        ].includes(cartItem.cartItemTypeId)
      );
      setState((prev) => ({ ...prev, show }));
    }
  }, [isReady, cartItems]);

  return (
    <>
      {state.show && (
        <Box
          style={{
            paddingBottom: 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box style={{ width: 250 }}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    isExpanded: !prev.isExpanded,
                  }))
                }
              >
                <ErrorIcon
                  style={{ color: theme.palette.error.main, marginRight: 8 }}
                />
                <Typography
                  style={{
                    fontWeight: 600,
                    color: theme.palette.grey[700],
                    fontSize: 13,
                    marginRight: 8,
                  }}
                >
                  Web Deposit is Required
                </Typography>
                <ExpandIcons isExpanded={state.isExpanded} />
              </Box>
            </Box>
            <Collapse in={state.isExpanded}>
              <Box
                style={{ textAlign: "center", paddingTop: 8, paddingBottom: 8 }}
              >
                <Typography style={{ fontSize: 12, color: "red" }}>
                  *Please note that one or more items in your order may require
                  a web deposit. A team member will reach out if a web deposit
                  is required.*
                </Typography>
              </Box>
            </Collapse>
          </Box>
        </Box>
      )}
    </>
  );
};

export default WebDepositRequired;
