import VehicleTireSelectionModal from "./widgets/VehicleTireSizeSelectionModal";
import useVehicleTireSizeSelectControl, {
  VehicleTireSizeSelectComponentControl,
} from "../../hooks/useVehicleTireSizeSelectControl";

const VehicleTireSizeSelectionModalDesktop = ({ isOpen, closeModal }) => {
  const { allSteps, tabs, currentStep, activeStep } =
    useVehicleTireSizeSelectControl({
      isOpen,
      closeModal,
    });

  return (
    <VehicleTireSelectionModal
      isOpen={isOpen}
      closeModal={closeModal}
      tabs={tabs}
      currentStep={currentStep + 1}
      sectionTitle={activeStep.sectionTitle}
      sectionDesc={activeStep.sectionDesc}
    >
      <VehicleTireSizeSelectComponentControl
        allSteps={allSteps}
        activeStep={activeStep}
      />
    </VehicleTireSelectionModal>
  );
};

export default VehicleTireSizeSelectionModalDesktop;
