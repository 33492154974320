import { Typography, Grid, Button, Box, Link } from "@material-ui/core";
import StandardPageHeader from "../../components/generic/standard-page-header";
import { Link as RouterLink } from "react-router-dom";
import DefaultContainer from "../../components/generic/default-container";
import APP_ENV from "../../appEnvironment";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { StandardWidthContainer } from "../../components/generic/containers";
import EvRecommendedTires from "../../components/ev-tires/ev-recommended-tires";
import useOpenTireSizeSelector from "../../hooks/util/useOpenTireSizeSelector";
import MetaTags from "../../components/reusable/ReactMetaTags";

const { theme } = APP_ENV;

const EvTiresMain = () => {
  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: false });

  return (
    <>
      <MetaTags
        description={`${APP_ENV.appBrandName} has a HUGE selection of tires for your Tesla, Volt, or other electric vehicle.`}
        canonical="/electric-vehicle-tires"
        title={`EV Tires | ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title="Premium Electric Vehicle Tires"
        subtitle="Tires for Your Tesla, Chevy Volt, Rivian and more!"
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {[
            {
              buttonText: "Quick Appointment",
              icon: EventAvailableIcon,
              to: "/car-appointment",
            },
            {
              buttonText: "Find My Store",
              icon: LocationOnIcon,
              to: "/locations",
            },
            {
              buttonText: "Save Today",
              to: "/coupons",
            },
          ].map(({ buttonText, icon: Icon = LocalOfferIcon, to = "" }, i) => (
            <Grid
              item
              key={i}
              xs={6}
              sm={6}
              md={4}
              component={RouterLink}
              to={to}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: 10,
              }}
            >
              <Box
                component={Button}
                style={{
                  display: "block",
                }}
              >
                <Box>
                  <Icon
                    color="primary"
                    style={{
                      fontSize: 32,
                    }}
                  />
                </Box>
                <Typography variant="h5" color="primary">
                  {buttonText}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </StandardPageHeader>
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            paddingTop: 40,
            paddingBottom: 40,
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                marginBottom: 16,
              }}
            >
              {`Welcome to Your Premier Destination for Electric Vehicle Tires and Wheels!`}
            </Typography>
            <Typography
              variant="body1"
              style={{
                marginBottom: 16,
              }}
            >
              {`Looking for the ideal tires and wheels for your EV? We're here to help! Specializing in a variety of electric vehicles like `}
              <Link onClick={openTireSizeModal}>
                Tesla, Chevy Volt, Hyundai Ioniq, Ford Mustang Mach-E,
              </Link>
              {` and more, we provide exceptional tire and wheel services. Our extensive selection of premium EV tires and wheels is available online and in our stores. Need an emergency repair or a new tire and wheel package? We've got your EV needs covered. Trust us for top-quality service and expertise for all your EV tire and wheel needs.`}
            </Typography>
            <Button
              variant="contained"
              color={"primary"}
              style={{
                padding: 10,
                width: "200px",
                height: "40px",
                marginTop: 20,
              }}
              onClick={openTireSizeModal}
            >
              Shop EV Tires
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "block",
              textAlign: "center",
              padding: 10,
            }}
          >
            <Box
              style={{
                borderRadius: 10,
                overflow: "hidden",
                backgroundColor: "transparent",
              }}
            >
              <img
                src="https://venomstorage.blob.core.windows.net/bbtwebblob/promos/electric-vehicle-landing-page-cartoon.png"
                style={{
                  height: "400px",
                }}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
              }}
            ></Box>
          </Grid>
        </Grid>
      </DefaultContainer>
      <StandardWidthContainer>
        <Grid
          item
          md={12}
          style={{
            paddingTop: 38,
          }}
        >
          <Typography variant="h4">
            Trending Electric Vehicle Tires
            <hr
              style={{
                marginTop: 5,
              }}
            />
          </Typography>
        </Grid>
        <EvRecommendedTires />
      </StandardWidthContainer>
    </>
  );
};
export default EvTiresMain;
