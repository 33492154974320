import create from "zustand";
import ClientTrackingStore from "./client-tracking-store";
import { addVyperEvent } from "../../../services/venom-service";

const useEventStore = create(() => ({
  vyperPageViewEvent: (props) => {
    const clientDeviceGuid = ClientTrackingStore.getState().clientDeviceGuid;
    addVyperEvent({
      clientDeviceGuid,
      ...props,
    });
  },
}));

export default useEventStore;
