import { useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PackageSubtotalWithInfo from "../../cart/widgets/package-subtotal-with-info";
import APP_ENV from "../../../appEnvironment";
import { roundMoney } from "../../../helpers/generic";
import ExpandIcons from "../../generic/expand-icons";
import LayoutStore from "../../../hooks/store/client/layout-store";
import { TireSearchResultsCardContext } from "../tire-search-results-card";

const { theme } = APP_ENV;

const TirePackageAddToCartCollection = (isp = true) => {
  const { activeTireByQty, contextState } = useContext(
    TireSearchResultsCardContext
  );
  const { selectedQty, setSelectedQty, showDetails, setShowDetails } =
    contextState;

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  return (
    <Grid container style={{ paddingLeft: 10, paddingRight: 10 }}>
      {isMobile && (
        <>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <PackageSubtotalWithInfo />
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Grid
              item
              xs={5}
              style={{ display: "flex", justifyContent: "right" }}
            >
              <FormControl
                variant="outlined"
                style={{ marginTop: 5, borderRadius: 8 }}
              >
                <InputLabel id="demo-multiple-checkbox-label">Qty</InputLabel>
                <Select
                  value={selectedQty}
                  onChange={(e) => setSelectedQty(e.target.value)}
                  renderValue={() => selectedQty}
                  label={"Qty"}
                  style={{
                    borderRadius: 3,
                    fontSize: 18,
                    fontWeight: 600,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  {activeTireByQty.deliveryOptionsByQty.map(
                    ({
                      qtyNeeded,
                      qtyIsInStock,
                      qtyIsAvailable,
                      availabilityLabel,
                    }) => {
                      let fabColor;
                      let message;

                      switch (true) {
                        case qtyIsInStock:
                          fabColor = theme.palette.success.main;
                          message = "In Stock";
                          break;
                        case qtyIsAvailable:
                          fabColor = theme.palette.success.main;
                          message = availabilityLabel;
                          break;
                        case !qtyIsInStock && !qtyIsAvailable:
                          fabColor = theme.palette.common.black;
                          message = availabilityLabel;
                          break;
                      }

                      return (
                        <MenuItem key={qtyNeeded} value={qtyNeeded}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FiberManualRecordIcon
                              style={{
                                color: fabColor,
                                fontSize: 13,
                              }}
                            />
                            <Typography
                              style={{
                                fontWeight: 600,
                                marginLeft: 8,
                                marginRight: 5,
                              }}
                            >{`${qtyNeeded} ${
                              qtyNeeded == 1 ? `Tire  -` : "Tires -"
                            }`}</Typography>

                            <Typography style={{ fontSize: 14 }}>
                              {message}
                            </Typography>
                          </Box>
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={7} style={{ paddingRight: "6px" }}>
              <Box style={{ display: "flex", justifyContent: "right" }}>
                <Typography style={{ fontSize: 25, fontWeight: "bold" }}>
                  {`$${roundMoney(activeTireByQty._packageSubtotal).toFixed(
                    2
                  )}`}
                </Typography>
              </Box>
              {activeTireByQty._totalDiscountsAvailable > 0 && (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    textAlign: "right",
                  }}
                >
                  <Paper
                    style={{
                      backgroundColor:
                        activeTireByQty?._promotion?.sitePromoColors?.tertiary,
                      paddingTop: 3,
                      paddingBottom: 3,
                      paddingLeft: 6,
                      paddingRight: 6,
                      width: "fit-content",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {`Save $${roundMoney(
                        activeTireByQty._totalDiscountsAvailable
                      ).toFixed(2)}`}
                    </Typography>
                  </Paper>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end", // Aligns the child (Box) to the right
            }}
          >
            <Box style={{ marginRight: 5, marginTop: 3 }}>
              <Typography
                style={{ fontSize: 11, textAlign: "right" }}
              >{`Was $${roundMoney(activeTireByQty._subtotal).toFixed(
                2
              )}. Includes Tire Install.`}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "right",
            }}
          >
            <Box
              component={Button}
              style={{ paddingTop: 0 }}
              onClick={() => setShowDetails(!showDetails)}
            >
              <Box
                style={{
                  display: "block",
                  textAlign: "left",
                  width: "100%",
                  paddingTop: 5,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Typography
                    color="primary"
                    style={{
                      fontSize: 12,
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      marginRight: 5,
                      marginLeft: 3,
                    }}
                  >
                    See Details
                  </Typography>
                  <ExpandIcons isExpanded={showDetails} fontSize={12} />
                </Box>
              </Box>
            </Box>
          </Grid>
        </>
      )}
      {!isMobile && (
        <>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <PackageSubtotalWithInfo />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                padding: 10,
              }}
            >
              <FormControl variant="outlined" style={{ borderRadius: 8 }}>
                <InputLabel id="demo-multiple-checkbox-label">Qty</InputLabel>
                <Select
                  value={selectedQty}
                  onChange={(e) => setSelectedQty(e.target.value)}
                  renderValue={() => selectedQty}
                  label={"Qty"}
                  style={{
                    borderRadius: 3,
                    fontSize: 18,
                    fontWeight: 600,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  {activeTireByQty.deliveryOptionsByQty.map(
                    ({
                      qtyNeeded,
                      qtyIsInStock,
                      qtyIsAvailable,
                      availabilityLabel,
                    }) => {
                      let fabColor;
                      let message;

                      switch (true) {
                        case qtyIsInStock:
                          fabColor = theme.palette.success.main;
                          message = "In Stock";
                          break;
                        case qtyIsAvailable:
                          fabColor = theme.palette.success.main;
                          message = availabilityLabel;
                          break;
                        case !qtyIsInStock && !qtyIsAvailable:
                          fabColor = theme.palette.common.black;
                          message = availabilityLabel;
                          break;
                      }

                      return (
                        <MenuItem key={qtyNeeded} value={qtyNeeded}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FiberManualRecordIcon
                              style={{
                                color: fabColor,
                                fontSize: 13,
                              }}
                            />
                            <Typography
                              style={{
                                fontWeight: 600,
                                marginLeft: 8,
                                marginRight: 5,
                              }}
                            >{`${qtyNeeded} ${
                              qtyNeeded == 1 ? `Tire  -` : "Tires -"
                            }`}</Typography>

                            <Typography style={{ fontSize: 14 }}>
                              {message}
                            </Typography>
                          </Box>
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box
              component={Button}
              style={{ paddingTop: 0 }}
              onClick={() => setShowDetails(!showDetails)}
            >
              <Box>
                <Box style={{ display: "flex" }}>
                  <Typography style={{ fontSize: 25, fontWeight: "bold" }}>
                    {`$${roundMoney(activeTireByQty._packageSubtotal).toFixed(
                      2
                    )}`}
                  </Typography>
                </Box>
                {activeTireByQty._totalDiscountsAvailable > 0 && (
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Paper
                      style={{
                        backgroundColor:
                          activeTireByQty?._promotion?.sitePromoColors
                            ?.tertiary,
                        paddingTop: 3,
                        paddingBottom: 3,
                        paddingLeft: 6,
                        paddingRight: 6,
                        width: "fit-content",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {`Save $${roundMoney(
                          activeTireByQty._totalDiscountsAvailable
                        ).toFixed(2)}`}
                      </Typography>
                    </Paper>
                    <Box style={{ marginLeft: 5 }}>
                      <Typography style={{ fontSize: 11 }}>{`Was $${roundMoney(
                        activeTireByQty._subtotal
                      ).toFixed(2)}`}</Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection={{ md: "column", lg: "row" }}
                  style={{
                    width: "100%",
                    paddingTop: 5,
                    marginTop: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 12,
                    }}
                  >
                    Includes Tire Install.{" "}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      color="primary"
                      style={{
                        fontSize: 12,
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                        marginRight: 5,
                        marginLeft: 3,
                      }}
                    >
                      See Details
                    </Typography>
                    <ExpandIcons isExpanded={showDetails} fontSize={12} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              width: "100%",
              marginRight: 11,
            }}
          ></Box>
        </>
      )}
    </Grid>
  );
};

export default TirePackageAddToCartCollection;
