import React from "react";
import { Helmet } from "react-helmet";
import APP_ENV from "../../appEnvironment";

export default class MetaTags extends React.Component {

    defualtKeywords = "tires, tire, buy tires online, truck tires, 4x4 tires, suv tires, Goodyear, Michelin, car maintenance, wheels, custom wheels, car wheels, Battery, Batteries, Oil Change, Oil Changes, Brake Job, Brake Jobs, discount tires, discount wheels";

    render() {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{this.props.title}</title>
                {this.props.canonical && <link rel="canonical" href={APP_ENV.appSlug + this.props.canonical} />}
                {this.props.description && <meta name="description" content={this.props.description} />}
                <meta name="keywords" content={`${APP_ENV.appBrandName}, ${this.props.keywords ? this.props.keywords : this.defualtKeywords}`} />
                {this.props.script && <script type='application/ld+json'>{`${this.props.script}`}</script>}
            </Helmet>
        );
    }
};