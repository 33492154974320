import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const ExpandIcons = ({
  isExpanded = false,
  fontSize = 13,
  color = theme.palette.primary.main,
}) => {
  return (
    <>
      {[isExpanded ? RemoveCircleOutlineIcon : AddCircleOutlineIcon].map(
        (Icon) => (
          <Icon key={1} style={{ fontSize, color }} />
        )
      )}
    </>
  );
};

export default ExpandIcons;
