import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import useVipMembership from "../hooks/use-vip-membership";
import DefaultContainer from "../components/generic/default-container";
import PromoStoreSelectDrawer from "../components/promotions/widgets/promo-store-select-drawer";
import AboutBrandInfoGraphics from "../components/reusable/about-brand-info-graphics";
import FiveReasonsToShopWithUs from "../components/reusable/five-reasons-to-shop-with-us";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import LayoutStore from "../hooks/store/client/layout-store";
import { useLocationStore } from "../hooks/store/location-store";
import APP_ENV from "../appEnvironment";

const { theme } = APP_ENV;

const VipMembership = () => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);
  const [locations, userStore] = useLocationStore((st) => [
    st.locations,
    st.userStore,
  ]);

  const _formState = useVipMembership();

  const [state, setState] = useState({
    drawerOpen: false,
    hasLocationSelected: false,
  });

  const [openDrawer, closeDrawer] = [true, false].map(
    (bool) => () => setState((prev) => ({ ...prev, drawerOpen: bool }))
  );

  const handleLocationSelection = (userStoreNumber) => {
    _formState.storeNumber.handleChange(userStoreNumber);
    setState((prev) => ({ ...prev, hasLocationSelected: true }));
    closeDrawer();
  };

  return (
    <>
      <Grid
        container
        style={{
          backgroundImage: `linear-gradient(${theme.palette.footer1.main}, ${theme.palette.background.default})`,
          paddingTop: 25,
        }}
      >
        <Container style={{ paddingTop: 10, paddingBottom: 50 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Box style={{ width: "100%", maxWidth: 450 }}>
                <Box style={{ marginTop: 25 }}>
                  <Typography
                    variant="h2"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    Activate Your VIP
                  </Typography>
                  <Typography
                    variant="h2"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    Membership Now
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{
                      color: theme.palette.primary.contrastText,
                      fontWeight: 600,
                      paddingTop: 5,
                    }}
                  >
                    *Limit one membership per valid email address
                  </Typography>
                  <Typography
                    style={{
                      color: theme.palette.primary.contrastText,
                      marginTop: 15,
                      marginBottom: 25,
                    }}
                  >
                    {`The ${APP_ENV.appBrandName} VIP Membership is an exclusive program for our most loyal guests. We look forward to helping you save on your vehicle repairs and maintenance.`}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Paper
                style={{
                  paddingTop: 25,
                  paddingBottom: 40,
                  paddingLeft: 15,
                  paddingRight: 15,
                  borderRadius: 10,
                  width: "100%",
                  maxWidth: 500,
                }}
              >
                {!_formState.hasSubmitted && (
                  <>
                    <Typography
                      variant="h4"
                      style={{
                        paddingBottom: 25,
                        textAlign: "center",
                      }}
                    >
                      VIP Member Information
                    </Typography>

                    {[
                      ["First & Last Name", _formState.userName],
                      ["Email", _formState.email],
                    ].map(([label, field], i) => {
                      const { key, value, errorMessage, handleChange } = field;
                      return (
                        <TextField
                          key={key}
                          id={key}
                          name={key}
                          label={label}
                          fullWidth
                          value={value}
                          onChange={handleChange}
                          error={Boolean(errorMessage)}
                          helperText={errorMessage}
                          color="primary"
                          variant="outlined"
                          style={{ paddingBottom: 20 }}
                          InputProps={{
                            style: {
                              borderRadius: 12,
                              backgroundColor: "white",
                            },
                          }}
                        />
                      );
                    })}
                    <Box
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginBottom: 20,
                      }}
                    >
                      {!state.hasLocationSelected && (
                        <>
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            style={{
                              padding: 16,
                              borderRadius: 12,
                              border:
                                _formState.storeNumber?.errorMessage &&
                                `1px solid red`,
                              color: theme.palette.common.black,
                            }}
                            startIcon={
                              <AddCircleOutlineIcon
                                style={{ color: theme.palette.primary.main }}
                              />
                            }
                            onClick={openDrawer}
                          >
                            Select Preferred Location
                          </Button>
                          {_formState.storeNumber?.errorMessage && (
                            <Typography
                              style={{
                                textAlign: "left",
                                marginLeft: 14,
                                marginRight: 14,
                                marginTop: 3,
                                color: theme.palette.error.main,
                              }}
                            >
                              {_formState.storeNumber.errorMessage}
                            </Typography>
                          )}
                        </>
                      )}
                      {state.hasLocationSelected && (
                        <Box
                          style={{
                            width: "100%",
                            padding: 15,
                            border: `1px solid ${theme.palette.gray[400]}`,
                            borderRadius: 12,
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h6">
                              Preferred Location
                            </Typography>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={openDrawer}
                            >
                              Change
                            </Button>
                          </Box>
                          <Box>
                            <Typography
                              variant="h5"
                              style={{ color: theme.palette.footer1.main }}
                            >
                              {userStore?.displayName}
                            </Typography>
                            <Typography>{userStore?.streetAddress}</Typography>
                            <Typography>{`${userStore?.city}, ${userStore?.stateAbbreviation} ${userStore?.zipCode}`}</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box style={{ width: "100%", textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: 250, height: 50 }}
                        onClick={_formState.handleSubmit}
                      >
                        Activate Membership
                      </Button>
                    </Box>
                  </>
                )}
                {_formState.hasSubmitted && (
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                      <Typography variant="h4">
                        VIP Membership Activated
                      </Typography>
                      <Box
                        style={{
                          width: "100%",
                          height: 150,
                          objectFit: "contain",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src="https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/easy-survey-thumbs-up.gif"
                          style={{ height: "100%", width: "auto" }}
                        />
                      </Box>
                      {[
                        ["Shop Online Now", "primary", "/"],
                        ["Quick Appointment", "secondary", "/car-appointment"],
                      ].map(([label, btnColor, link], i) => {
                        return (
                          <Box key={i}>
                            <Button
                              component={RouterLink}
                              to={link}
                              variant="contained"
                              color={btnColor}
                              style={{ width: 300, height: 50, marginTop: 15 }}
                            >
                              {label}
                            </Button>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <DefaultContainer>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <AboutBrandInfoGraphics />
          </Grid>

          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <FiveReasonsToShopWithUs />
          </Grid>
        </Grid>
      </DefaultContainer>
      <PromoStoreSelectDrawer
        isOpen={state.drawerOpen}
        closeModal={closeDrawer}
        drawerHeader={`Total locations offering VIP Membership: ${locations.length}`}
        promoStores={locations.map(({ storeNumber }) => storeNumber)}
        handleLocationSelection={handleLocationSelection}
        hasLocation={state.hasStoreSelected}
      />
    </>
  );
};

export default VipMembership;
