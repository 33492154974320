import create from "zustand";
import CLIENT_STORAGE from "./storage/client-storage";
import TagManager from "react-gtm-module";
import LocationStore from "../location-store";
import ClientTrackingStore from "./client-tracking-store";
import APP_ENV from "../../../appEnvironment";
import { CART_ITEM_TYPE } from "../cart-store";
import { roundMoney } from "../../../helpers/generic";
import { useEffect } from "react";

const GoogleEventsStore = create(() => {
  const initializeTagManager = () => {
    const original_location =
      CLIENT_STORAGE.originalLocation.getItem() ?? window.location.href;
    CLIENT_STORAGE.originalLocation.setItem(original_location);

    window.public_web_globals = {
      original_location,
      client_device_guid: ClientTrackingStore.getState().clientDeviceGuid,
    };

    TagManager.initialize({ gtmId: APP_ENV.appGTMId });
  };

  const _eventCreator = (eventName, func = () => {}) => {
    return async (...args) => {
      const payload = await func.apply(null, args);
      window.dataLayer ??= [];
      window.dataLayer.push({
        event: eventName,
        ...payload,
      });
    };
  };

  const mapCartItemsForGoogle = async (cartItems = []) => {
    const { userStore } =
      await LocationStore.getState().getLocationStoreAsync();

    return cartItems.map((item) => {
      const [cartItemTypeName, cartItemTypeId] = Object.entries(
        CART_ITEM_TYPE
      ).find(([key, val]) => val == item.cartItemTypeId);

      const isTire = cartItemTypeId == 1;

      return {
        item_id: isTire ? item?.itemId : item?.edgeItemId,
        item_name: isTire ? item?.itemModelEndPoint : item?.webUniqueItemId,
        item_brand: isTire ? item?.brandEndPoint : cartItemTypeName,
        item_category: cartItemTypeName,
        item_variant: isTire ? item?.tireSizeDisplay : item?.webUniqueItemId,
        price: roundMoney((item._subtotal + item._tax) / item._cartQty),
        discount: roundMoney(item._instantRebateDiscountsTotal / item._cartQty),
        quantity: item._cartQty,
        location_id: userStore?.googlePlaceId,
      };
    });
  };

  return {
    initializeTagManager,
    sendAddToCartEvent: _eventCreator(
      "add_to_cart",
      async (newItemValue = 0, newItem = {}) => ({
        value: newItemValue,
        items: await mapCartItemsForGoogle([newItem]),
      })
    ),
    sendPurchaseEvent: _eventCreator(
      "purchase",
      async (transaction_id = null, value = 0, cartItems = []) => ({
        transaction_id,
        value,
        items: await mapCartItemsForGoogle(cartItems),
      })
    ),
    sendPhoneCallEvent: _eventCreator("phone_call"),
  };
});

export const useGoogleEventsStore = () => {
  const [initializeTagManager, sendPhoneCallEvent] = GoogleEventsStore((st) => [
    st.initializeTagManager,
    st.sendPhoneCallEvent,
  ]);

  useEffect(() => {
    initializeTagManager();
    window.addEventListener("click", (e) => {
      const isPhoneCall = [
        e?.target?.protocol,
        e?.target?.parentElement?.protocol,
      ].includes("tel:");

      if (isPhoneCall) {
        sendPhoneCallEvent();
      }
    });
  }, []);
};

export default GoogleEventsStore;
