import { Grid, IconButton, Box } from "@material-ui/core";
import useLocationSearch from "../../hooks/useLocationSearch";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import APP_ENV from "../../appEnvironment";
import LocationSearchContainer from "../locations/LocationSearchContainer";
import DrawerOffsetTop from "./widgets/DrawerOffsetTop";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const { theme } = APP_ENV;

const LocationSelectDrawer = ({ isOpen, closeModal }) => {
  const locationSearchState = useLocationSearch();

  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpen) {
      closeModal();
    }
  }, [pathname]);

  return (
    <DrawerOffsetTop
      isOpen={isOpen}
      closeModal={closeModal}
      PaperProps={{
        style: {
          maxWidth: "100%",
          width: 500,
          paddingLeft: 8,
          paddingRight: 8,
          backgroundColor: theme.palette.background.default,
          paddingBottom: 350,
        },
      }}
    >
      <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={closeModal}>
          <CloseRoundedIcon />
        </IconButton>
      </Grid>

      <Grid container>
        <LocationSearchContainer
          locationSearchState={locationSearchState}
          onSelectedLocationClick={closeModal}
          onNewSelectionClick={closeModal}
        />
      </Grid>
    </DrawerOffsetTop>
  );
};

export default LocationSelectDrawer;
