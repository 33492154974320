import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const BulletPoint = ({ text, link = null }) => {
  let displayText = text;
  if (link) {
    displayText = (
      <Link component={RouterLink} to={link}>
        {text}
      </Link>
    );
  }

  return (
    <li
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: 16,
        marginBottom: 5,
      }}
    >
      <span>
        <FiberManualRecordIcon style={{ marginRight: 10, fontSize: 8 }} />
        {displayText}
      </span>
    </li>
  );
};

export default BulletPoint;
