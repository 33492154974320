import { Typography, Dialog, Box, Link, IconButton } from "@material-ui/core";
import CloseRounded from "@material-ui/icons/CloseRounded";
import APP_ENV from "../../appEnvironment";
import { useLocationStore } from "../../hooks/store/location-store";

const { theme } = APP_ENV;

const LifetimeTireMaintCertModal = ({ isOpen, closeModal }) => {
  const [userStore] = useLocationStore((st) => [st.userStore]);

  return (
    <Dialog fullWidth maxWidth={"sm"} open={isOpen} onClose={closeModal}>
      <Box style={{ width: "100%", padding: 15 }}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography style={{ fontSize: 22, fontWeight: 600 }}>
              Lifetime Tire Maintenance Certificate
            </Typography>
            <Typography style={{ fontSize: 16 }}>
              Our Road Hazard & Tire Maintenance Program
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={closeModal}>
              <CloseRounded />
            </IconButton>
          </Box>
        </Box>

        <Box style={{ marginTop: 20, marginBottom: 20 }}>
          {[
            [
              "Tire Replacement",
              "On Road Hazards, Workmanship, & Material Defects",
            ],
            ["Remote Refund", "Out of area 100% Refund Policy"],
            ["Nitrogen Inflation", "Lifetime Nitrogen Inflation"],
            ["Tire Re-Balancing", "For the Life of the Tire"],
            ["Tire Rotations", "Every 3,000 to 5,000 miles"],
            ["Air Pressure Checks", "Increase Gas Mileage & Tire Life"],
            ["Flat Tire Repairs", "$0 Tire Repair For Life of Tire"],
          ].map(([label, subLabel]) => {
            return (
              <Box style={{ width: "100%", paddingBottom: 15 }} key={label}>
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    marginRight: 5,
                    color: "red",
                  }}
                >
                  FREE
                </span>

                <span
                  style={{
                    fontSize: 18,
                    color: "black",
                    fontWeight: 600,
                    marginRight: 5,
                  }}
                >
                  {label}
                </span>
                <span style={{ fontSize: 16, color: "black" }}>{subLabel}</span>
              </Box>
            );
          })}
        </Box>
        <Box
          style={{
            width: "100%",
            backgroundColor: theme.palette.grey[100],
            borderRadius: 8,
            padding: 10,
          }}
        >
          <Typography
            style={{ fontSize: 16, fontWeight: 600, paddingBottom: 10 }}
          >
            Questions about the Lifetime Tire Maintenance Certificate?
          </Typography>
          {userStore?.phoneNumber && (
            <Typography style={{ fontSize: 16, paddingBottom: 10 }}>
              {`We are here to help. Please give us a call at`}{" "}
              <Link href={`tel:${userStore?.phoneNumber}`}>
                {userStore?.phoneNumber}
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default LifetimeTireMaintCertModal;
