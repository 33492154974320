import { useHistory } from "react-router";
import { Avatar, Box, Button, Typography } from "@material-ui/core";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { QUICK_APPOINTMENT_DIRECTORY } from "../../routes";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const EmptyCart = ({ closeModal = () => null }) => {
  const history = useHistory();

  return (
    <>
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Avatar
          style={{
            padding: 50,
            backgroundColor: theme.palette.grey[100],
            marginBottom: 20,
          }}
        >
          <ShoppingCartOutlinedIcon color="primary" style={{ fontSize: 60 }} />
        </Avatar>
      </Box>
      <Box style={{ textAlign: "center" }}>
        <Typography variant="h6" style={{ marginBottom: 10 }}>
          Your Cart Is Empty
        </Typography>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginBottom: 10, height: 50 }}
          onClick={closeModal}
        >
          Shop Now
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          style={{ marginBottom: 10, height: 50 }}
          onClick={() => {
            history.push(QUICK_APPOINTMENT_DIRECTORY);
            closeModal();
          }}
        >
          Quick Appointment
        </Button>
      </Box>
    </>
  );
};

export default EmptyCart;
