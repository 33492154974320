import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { getTireReviews } from "../services/venom-service";

const useTireReviews = () => {
  const { brandEndPoint, itemModelEndPoint } = useParams();

  const [state, setState] = useState({
    isReady: false,
    isLoading: false,
    hasReviews: false,
    reviewsSummary: {},
    allReviews: [],
  });

  useEffect(() => {
    if (brandEndPoint && itemModelEndPoint) {
      setState((prev) => ({ ...prev, isLoading: true }));
      (async () => {
        const allReviews =
          (await getTireReviews({
            brandEndPoint,
            itemModelEndPoint,
          })) ?? [];

        const { reviewCount = 0, reviewAvg = 0 } = allReviews?.[0] ?? {};
        const hasReviews = Boolean(reviewCount);

        const reviewsSummary = {
          reviewCount,
          reviewAvg,
          wouldRecommendPercentage: (
            (reviewCount
              ? allReviews.filter((i) => i.wouldRecommend).length / reviewCount
              : reviewCount) * 100
          ).toFixed(1),
          reviewDistribution: Object.assign(
            {},
            ...[1, 2, 3, 4, 5].map((key) => {
              const count = allReviews.filter((i) => i.rating == key).length;
              return {
                [key]: reviewCount ? (count / reviewCount) * 100 : reviewCount,
              };
            })
          ),
        };

        setState((prev) => ({
          ...prev,
          isReady: true,
          isLoading: false,
          hasReviews,
          reviewsSummary,
          allReviews,
        }));
      })();
    }
  }, [brandEndPoint, itemModelEndPoint]);

  return {
    ...state,
  };
};

export default useTireReviews;
