import { useState, useEffect } from "react";
import useDebounce from "../hooks/util/useDebounce";
import GoogleServicesStore from "./store/client/google-services-store";
import LocationStore from "./store/location-store";

const useLocationSearch = (storesToDisplay = 15) => {
  const [state, setState] = useState({
    isLoading: false,
    displayLocations: [],
    searchQuery: "",
    isActiveQuery: true,
    autoCompleteOptions: [],
  });

  const [userStoreNumber, getLocationStoreAsync, fetchLocations] =
    LocationStore((st) => [
      st.userStoreNumber,
      st.getLocationStoreAsync,
      st.fetchLocations,
    ]);

  useEffect(() => {
    if (userStoreNumber) {
      (async () => {
        setState((prev) => ({ ...prev, isLoading: true }));
        const { locations } = await getLocationStoreAsync();
        setState((prev) => ({
          ...prev,
          isLoading: false,
          displayLocations: locations,
        }));
      })();
    }
  }, [userStoreNumber]);

  const debouncedValue = useDebounce(state.searchQuery, 200);

  const googleServices = GoogleServicesStore();

  const setSearchQuery = (e) => {
    const searchQuery = e.target.value ?? "";
    setState((prev) => ({
      ...prev,
      searchQuery,
      isActiveQuery: prev.searchQuery.length < searchQuery.length,
    }));
  };

  useEffect(() => {
    if (googleServices.isReady && state.isActiveQuery) {
      googleServices.placesAutoComplete.getPlacePredictions(
        {
          input: debouncedValue,
          componentRestrictions: { country: "us" },
          types: ["geocode"],
          fields: ["address_components", "geometry"],
        },
        (res) => {
          const autoCompleteOptions = res.filter((item) =>
            item.types.every(
              (i) => !["premise", "street_address", "route"].includes(i)
            )
          );
          setState((prev) => ({
            ...prev,
            autoCompleteOptions,
          }));
        }
      );
    }
  }, [debouncedValue]);

  const handleLocationRequest = async ({ lat = 0, lng = 0 } = {}) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const displayLocations = await fetchLocations({ lat, lng });
    setState((prev) => {
      return {
        ...prev,
        isLoading: false,
        displayLocations,
      };
    });
  };

  const onAutoCompleteSelection = ({ place_id: placeId }) => {
    if (!googleServices.isReady) return;

    setState((prev) => ({ ...prev, isLoading: true }));
    googleServices.mapGeocoder.geocode(
      {
        placeId,
      },
      ([res]) => {
        const { lat, lng } = res.geometry.location;
        const [latitude, longitude] = [lat, lng].map((i) => i());

        handleLocationRequest({
          lat: latitude,
          lng: longitude,
        });
      }
    );
  };

  return {
    ...state,
    displayLocations: state.displayLocations.filter(
      (_, i) => i < storesToDisplay
    ),
    setSearchQuery,
    onAutoCompleteSelection,
    getStoresByUserLocation: handleLocationRequest,
  };
};

export default useLocationSearch;
