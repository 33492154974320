import { Grid, Box, Collapse, Button } from "@material-ui/core";
import useSelectCustomTireSize from "../../../hooks/useSelectCustomTireSize";
import SelectionFieldMobile from "./widgets/SelectionFieldMobile";
import ConditionalSelectionMobileDrawer from "./widgets/ConditionalSelectionMobileDrawer";
import SelectionFieldDesktop from "./widgets/SelectionFieldDesktop";
import LayoutStore from "../../../hooks/store/client/layout-store";

const SelectCustomTireSize = (
  onComplete = (tireSize) =>
    console.warn(
      "You need to pass an onComplete function to SelectCustomTireSize",
      tireSize
    )
) => {
  const state = useSelectCustomTireSize(onComplete);

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  return {
    component: (
      <>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: 20,
          }}
        >
          <img
            style={{ maxHeight: 175, width: "auto" }}
            src="https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/shopfortire-size.png"
          />
        </Box>
        <Grid container>
          {state.front.fields.map((field) => (
            <Grid item xs={4} key={field.key} style={{ padding: 8 }}>
              {isMobile ? (
                <SelectionFieldMobile {...field} />
              ) : (
                <SelectionFieldDesktop
                  {...field}
                  setAllFieldsInactive={state.front.setAllFieldsInactive}
                />
              )}
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button
              onClick={state.toggleStaggered}
              size="small"
              color="primary"
            >
              Different Rear Size?
            </Button>
          </Grid>

          {Boolean(state.isReady && state.rear) && (
            <>
              {state.rear?.fields.map((field) => (
                <Grid item xs={4} key={field.key} style={{ padding: 8 }}>
                  {isMobile ? (
                    <SelectionFieldMobile {...field} />
                  ) : (
                    <SelectionFieldDesktop
                      {...field}
                      setAllFieldsInactive={state.rear.setAllFieldsInactive}
                    />
                  )}
                </Grid>
              ))}
            </>
          )}
        </Grid>
        {isMobile && (
          <ConditionalSelectionMobileDrawer {...state[state.position]} />
        )}
      </>
    ),
    handleSubmit: state.handleSubmit,
  };
};

export default SelectCustomTireSize;
