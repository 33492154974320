import { Box, Button } from "@material-ui/core";
import TireReviewCard from "./tire-review-card";
import { useState, useEffect } from "react";

const TireReviewCustomerReviews = ({ allReviews = [] }) => {
  const [state, setState] = useState({
    index: 0,
    reviews: [],
  });

  useEffect(() => {
    if (allReviews.length) {
      const CHUNK_LEN = 3;

      const reviews = [
        ...Array(Math.ceil(allReviews.length / CHUNK_LEN)),
      ].reduce(
        (prev, _, i) => [
          ...prev,
          [allReviews.slice(i * CHUNK_LEN, i * CHUNK_LEN + CHUNK_LEN)],
        ],
        []
      );

      setState({ index: 0, reviews });
    }
  }, [allReviews]);

  const showMoreButton =
    state.reviews?.[state.index + 1] &&
    (() => setState((prev) => ({ ...prev, index: prev.index + 1 })));

  return (
    <>
      {state.reviews
        .filter((_, i) => i <= state.index)
        .flat(Infinity)
        .map((review, i) => (
          <TireReviewCard key={i} {...review} />
        ))}
      {showMoreButton && (
        <Box
          style={{
            width: "100%",
            textAlign: "center",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            style={{ height: 50 }}
            onClick={showMoreButton}
          >
            View More
          </Button>
        </Box>
      )}
    </>
  );
};

export default TireReviewCustomerReviews;
