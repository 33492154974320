import { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Link,
  Paper,
  Divider,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  Tooltip,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import StackedDisplayName from "../../../../locations/widgets/StackedDisplayName";
import AddressWithLink from "../../../../locations/widgets/AddressWithLink";
import { QUICK_APPOINTMENT_DIRECTORY } from "../../../../../routes";
import { useLocationStore } from "../../../../../hooks/store/location-store";
import { Link as RouterLink } from "react-router-dom";
import APP_ENV from "../../../../../appEnvironment";

const { theme } = APP_ENV;

const VyperAfterStoreSelectCard = ({
  storeCount = 0,
  showSharingLink = true,
  openLocationDrawer = () => null,
}) => {
  const [userStore] = useLocationStore((st) => [st.userStore]);

  const [state, setState] = useState({
    tipOpen: false,
  });

  const sharingUrl = window.location.href;

  return (
    <Paper elevation={6} style={{ borderRadius: 6, padding: 8 }}>
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: 10,
          paddingBottom: 12,
        }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          <CheckCircleOutlineIcon
            style={{
              marginRight: 10,
              color: theme.palette.success.main,
            }}
          />
          <Typography variant="h4">It's In Your Cart!</Typography>
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: theme.palette.background.default,
          padding: 10,
          borderRadius: 12,
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <Typography
            variant="h4"
            style={{ color: theme.palette.footer2.main, padding: 5 }}
            gutterBottom
          >
            Your Store
          </Typography>
          {storeCount > 1 && (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={openLocationDrawer}
              style={{
                backgroundColor: theme.palette.background.paper,
              }}
            >
              Change
            </Button>
          )}
        </Box>
        <Paper
          style={{
            padding: 10,
            borderRadius: 12,
            border: `1px solid ${theme.palette.info.main}`,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box>
            <StackedDisplayName {...userStore} />
            <Link
              style={{ fontSize: 14 }}
              href={`tel:${userStore.phoneNumber}`}
            >
              {userStore.phoneNumber}
            </Link>
            <AddressWithLink {...userStore} />
          </Box>
        </Paper>
      </Box>

      <Box
        style={{
          width: "100%",
          paddingTop: 15,
          paddingBottom: 10,
        }}
      >
        <Divider />
        {showSharingLink && (
          <>
            <Box
              style={{
                width: "100%",
                backgroundColor: theme.palette.background.default,
              }}
            >
              <Typography
                variant="h5"
                style={{
                  color: theme.palette.footer2.main,
                  textAlign: "center",
                  paddingTop: 8,
                }}
              >
                {`SEND TO A FRIEND (OPTIONAL)`}
              </Typography>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: 8,
                }}
              >
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    fullWidth
                    style={{
                      maxWidth: 500,
                      backgroundColor: theme.palette.background.paper,
                      border: `2px solid ${theme.palette.primary.light}`,
                    }}
                    type={"text"}
                    value={sharingUrl}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={() =>
                            setState((prev) => ({
                              ...prev,
                              tipOpen: false,
                            }))
                          }
                          open={state.tipOpen}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          onBlur={() =>
                            setState((prev) => ({
                              ...prev,
                              tipOpen: false,
                            }))
                          }
                          title="Copied to Clipboard!"
                        >
                          <IconButton
                            edge="end"
                            onClick={() => {
                              setState((prev) => ({
                                ...prev,
                                tipOpen: true,
                              }));
                              navigator.clipboard.writeText(sharingUrl);
                            }}
                          >
                            <AssignmentReturnedIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </Box>
            <Divider />
          </>
        )}
      </Box>
      <Box
        style={{
          width: "100%",
          paddingTop: 20,
          paddingBottom: 20,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          elevation={5}
          variant="contained"
          color="primary"
          fullWidth
          style={{
            maxWidth: 300,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 15,
            paddingRight: 15,
          }}
          component={RouterLink}
          to={QUICK_APPOINTMENT_DIRECTORY}
        >
          SCHEDULE APPOINTMENT
        </Button>
      </Box>
    </Paper>
  );
};

export default VyperAfterStoreSelectCard;
