import { useState } from "react";
import { Grid, IconButton, Paper } from "@material-ui/core";
import CartIcon from "../cart-icon";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import APP_ENV from "../../../appEnvironment";
import { Link as RouterLink } from "react-router-dom";
import ModalStore from "../../../hooks/store/modal-store";
import { FullWidthContainer } from "../../generic/containers";
import MenuDrawerMobile from "./menu-drawer-mobile";
import LocationSectionMobile from "./location-selection-mobile";

const { theme } = APP_ENV;

const MAIN_SECTION_H = 60;
const LOCATION_SECTION_H = 60;

export const MOBILE_HEADER_HEIGHT = MAIN_SECTION_H + LOCATION_SECTION_H;
export const MOBILE_MAIN_SECTION_HEIGHT = MAIN_SECTION_H;

const MainHeaderMobile = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const { closeAllModals } = ModalStore();

  return (
    <>
      <Paper elevation={3}>
        <FullWidthContainer>
          <Grid container style={{ height: MAIN_SECTION_H }}>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  setDrawerIsOpen((prev) => !prev);
                  closeAllModals();
                }}
              >
                <MenuRoundedIcon
                  style={{ color: theme.palette.common.black, fontSize: 30 }}
                />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RouterLink
                to="/"
                onClick={() => {
                  setDrawerIsOpen(false);
                  closeAllModals();
                }}
              >
                <img height={50} src={APP_ENV.appBrandLogoImg} />
              </RouterLink>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CartIcon onClick={() => setDrawerIsOpen(false)} />
            </Grid>
          </Grid>
        </FullWidthContainer>
        <FullWidthContainer backgroundColor={theme.palette.background.default}>
          <LocationSectionMobile height={LOCATION_SECTION_H} />
        </FullWidthContainer>
      </Paper>

      <MenuDrawerMobile
        mobileHeaderHeight={MAIN_SECTION_H}
        isOpen={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
      />
    </>
  );
};

export default MainHeaderMobile;
