import { Container } from "@material-ui/core";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

export const FullWidthContainer = ({
  backgroundColor = theme.palette.background.paper,
  children,
}) => {
  return (
    <Container
      style={{
        minWidth: "100%",
        padding: 0,
        margin: 0,
        backgroundColor,
      }}
    >
      {children}
    </Container>
  );
};

export const StandardWidthContainer = ({
  backgroundColor = theme.palette.background.paper,
  children,
}) => {
  return (
    <FullWidthContainer backgroundColor={backgroundColor}>
      <Container
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Container>{children}</Container>
      </Container>
    </FullWidthContainer>
  );
};
