import { Grid, Paper, Button, Box, Link } from "@material-ui/core";
import APP_ENV from "../../appEnvironment";
import useOpenTireSizeSelector from "../../hooks/util/useOpenTireSizeSelector";
import dayjs from "dayjs";

const { theme } = APP_ENV;

const CouponTireMailInRebates = ({ rebates = [] }) => {
  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: false });

  const isLoading = !Boolean(rebates.length);

  return (
    <Grid container>
      {[...(isLoading ? [1, 2, 3] : rebates)].map((rebate, i) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            key={i}
            style={{ padding: 10, textAlign: "center" }}
          >
            <Paper elevation={5}>
              {isLoading && (
                <Box
                  style={{
                    width: "100%",
                    height: 350,
                    backgroundColor: theme.palette.background.default,
                  }}
                />
              )}
              {!isLoading && (
                <>
                  <img src={rebate.image} width={"100%"} height="auto" />
                  {!rebate.isExpired && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={openTireSizeModal}
                      style={{
                        width: 250,
                        height: 50,
                        marginTop: 15,
                        color: theme.palette.primary.contrastText,
                      }}
                    >
                      Shop Tires Now
                    </Button>
                  )}

                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      component={Link}
                      target="_blank"
                      href={rebate.pdf}
                      variant="contained"
                      color="secondary"
                      style={{
                        width: 200,
                        height: 50,
                        margin: 15,
                        color: theme.palette.secondary.contrastText,
                      }}
                    >
                      Print Form
                    </Button>
                    {rebate.hrefTo && (
                      <Button
                        component={Link}
                        target="_blank"
                        href={rebate.hrefTo}
                        variant="contained"
                        color="primary"
                        style={{
                          width: 200,
                          height: 50,
                          margin: 15,
                          color: theme.palette.primary.contrastText,
                        }}
                      >
                        Redeem Online
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </Paper>
          </Grid>
        );
      })}

      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={4}
        xl={4}
        style={{ padding: 10, textAlign: "center" }}
      >
        <Paper elevation={5}>
          {isLoading && (
            <Box
              style={{
                width: "100%",
                height: 350,
                backgroundColor: theme.palette.background.default,
              }}
            />
          )}
          {Boolean(!isLoading && dayjs().isBefore(dayjs("04/1/2024"))) && (
            <>
              <img
                src={
                  "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/valvoline-rebate-march-2024.jpg"
                }
                width={"100%"}
                height="auto"
              />

              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  component={Link}
                  target="_blank"
                  href={
                    "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/valvoline-rebate-form-march-2024.pdf"
                  }
                  variant="contained"
                  color="secondary"
                  style={{
                    width: 200,
                    height: 50,
                    margin: 15,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  Print Form
                </Button>
                <Button
                  component={Link}
                  target="_blank"
                  href={"https://vperks.valvolineglobal.com/brands"}
                  variant="contained"
                  color="primary"
                  style={{
                    width: 200,
                    height: 50,
                    margin: 15,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  Redeem Online
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CouponTireMailInRebates;
