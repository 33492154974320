import React from "react";
import { Paper, Box, LinearProgress, Grid, Button } from "@material-ui/core";
import LocationSearchCard from "./LocationSearchCard";
import LocationSearchAutoComplete from "./LocationSearchAutoComplete";
import { Link as RouterLink } from "react-router-dom";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const LocationSearchContainer = ({
  locationSearchState,
  maxHeight = null,
  onSelectedLocationClick,
  onNewSelectionClick,
}) => {
  const { displayLocations, isLoading } = locationSearchState;

  const maxHeightStyle = maxHeight
    ? {
        height: maxHeight,
        overflow: "auto",
      }
    : {};

  return (
    <>
      <Paper elevation={5} style={{ padding: 15, height: 185, width: "100%" }}>
        <LocationSearchAutoComplete {...locationSearchState} />
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <Button
            component={RouterLink}
            style={{ fontSize: 14 }}
            to={"/locations-directory"}
          >
            View All Locations
          </Button>
        </Box>
      </Paper>
      <Box
        style={{
          width: "100%",
          height: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        {isLoading && (
          <LinearProgress color="primary" style={{ width: "100%" }} />
        )}
      </Box>
      <Paper
        className="NoScrollbar"
        style={{
          height: "fit-content",
          minHeight: 500,
          width: "100%",
          backgroundColor: theme.palette.background.default,
          padding: 8,
          ...maxHeightStyle,
        }}
      >
        {!isLoading && (
          <>
            {displayLocations.map((item, i) => {
              return (
                <LocationSearchCard
                  index={i + 1}
                  {...item}
                  isSelected={item.isSelected()}
                  onSelectedLocationClick={() =>
                    onSelectedLocationClick(item.storeNumber)
                  }
                  onNewSelectionClick={() =>
                    onSelectedLocationClick(item.storeNumber)
                  }
                  key={i}
                />
              );
            })}
          </>
        )}
      </Paper>
    </>
  );
};

export default LocationSearchContainer;
