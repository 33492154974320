import { Grid, Box, Typography } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const SelectSection = ({ title = "", subtitle = "", children }) => {
  return (
    <>
      <Grid
        container
        component={Box}
        style={{ paddingTop: 20, paddingBottom: 20 }}
      >
        <Typography variant="h6">
          {`${title}. `}
          <span style={{ color: theme.palette.grey[500] }}>{subtitle}</span>
        </Typography>
      </Grid>

      <Grid container>{children}</Grid>
    </>
  );
};

export default SelectSection;
