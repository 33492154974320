import { Grid, Typography, Box } from "@material-ui/core";
import DefaultContainer from "../../generic/default-container";
import OpenClosedDisplayMessage from "../widgets/OpenClosedDisplayMessage";
import APP_ENV from "../../../appEnvironment";
import SmogCheckOfferedLocationWidget from "../../smog-check/smog-check-offered-location-widget";

const { theme } = APP_ENV;

export const SingleLocationHeaderDesktop = ({
  displayName,
  streetAddress,
  city,
  stateAbbreviation,
  zipCode,
  currentStatusDisplayMessage,
  isOpenBusinessHours,
  smogCheck = {},
}) => {
  return (
    <DefaultContainer>
      <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
        <Grid container style={{ padding: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {APP_ENV.appBrandName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h2"
              style={{
                fontWeight: "bold",
                color: theme.palette.primary.dark,
                marginBottom: 10,
              }}
            >
              {displayName}
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
              {`${streetAddress} ${city}, ${stateAbbreviation} ${zipCode}`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} style={{ padding: 5 }}>
            <OpenClosedDisplayMessage
              currentStatusDisplayMessage={currentStatusDisplayMessage}
              fontSize={17}
              isOpenBusinessHours={isOpenBusinessHours}
            />
            {smogCheck?.isSmogCheckStore && (
              <Box
                style={{
                  paddingTop: 10,
                }}
              >
                <SmogCheckOfferedLocationWidget {...smogCheck} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DefaultContainer>
  );
};

export const SingleLocationHeaderMobile = ({
  displayCity,
  webAlias,
  streetAddress,
  city,
  stateAbbreviation,
  zipCode,
  currentStatusDisplayMessage,
  isOpenBusinessHours,
  smogCheck = {},
}) => {
  return (
    <DefaultContainer>
      <Grid
        container
        style={{
          marginBottom: 20,
          marginTop: 20,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box style={{ padding: 20, textAlign: "center" }}>
          <Typography variant="h4" gutterBottom>
            {APP_ENV.appBrandName}
          </Typography>
          <Typography
            variant="h2"
            style={{ fontWeight: "bold", color: theme.palette.primary.dark }}
          >
            {displayCity}
          </Typography>
          <Typography
            variant="h2"
            style={{ fontWeight: "bold", color: theme.palette.primary.dark }}
            gutterBottom
          >
            {webAlias}
          </Typography>
          <Typography
            style={{ fontWeight: 600 }}
            gutterBottom
          >{`${streetAddress} ${city}, ${stateAbbreviation} ${zipCode}`}</Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 10,
            }}
          >
            <OpenClosedDisplayMessage
              currentStatusDisplayMessage={currentStatusDisplayMessage}
              fontSize={16}
              isOpenBusinessHours={isOpenBusinessHours}
            />
          </Box>
          {smogCheck?.isSmogCheckStore && (
            <Box
              style={{
                paddingTop: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SmogCheckOfferedLocationWidget {...smogCheck} />
            </Box>
          )}
        </Box>
      </Grid>
    </DefaultContainer>
  );
};
