import jsCookie from "js-cookie";
import dayjs from "dayjs";

const SAFE_ACCESS_STORAGE = {
  parse: (value = null) => {
    if (Boolean(value) && value !== "undefined") {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    }

    return null;
  },
  stringify: (value = "") => {
    value ??= "";
    return typeof value == "object" ? JSON.stringify(value) : value;
  },
};

const STORAGE_CREATORS = {
  COOKIE: (key, { expires = 30, isSessionOnly = false }) => {
    const cookieProps = {
      sameSite: "Strict",
      secure: true,
    };

    if (!isSessionOnly) {
      cookieProps.expires = expires;
    }

    return {
      get: () => SAFE_ACCESS_STORAGE.parse(jsCookie.get(key)),
      set: (value) =>
        jsCookie.set(key, SAFE_ACCESS_STORAGE.stringify(value), cookieProps),
      remove: () => jsCookie.remove(key, cookieProps),
    };
  },
  LOCAL_STORAGE: (key, expires = 30) => {
    const currentDateTime = dayjs();

    const actions = {
      set: (value) => {
        const ttl = currentDateTime.add(expires, "day");
        localStorage.setItem(
          key,
          SAFE_ACCESS_STORAGE.stringify({ value, ttl })
        );
      },
      remove: () => localStorage.removeItem(key),
    };

    const get = () => {
      let { value = null, ttl = null } =
        SAFE_ACCESS_STORAGE.parse(localStorage.getItem(key)) ?? {};

      if (value) {
        let isExpired = false;
        if (dayjs(ttl).isValid()) {
          isExpired = currentDateTime.isAfter(dayjs(ttl));
        } else {
          actions.set(value);
        }

        if (isExpired) {
          actions.remove();
          value = null;
        }
      }

      return value;
    };

    return {
      get,
      ...actions,
    };
  },
  SESSION_STORAGE: (key) => ({
    get: () => SAFE_ACCESS_STORAGE.parse(sessionStorage.getItem(key)),
    set: (value) =>
      sessionStorage.setItem(key, SAFE_ACCESS_STORAGE.stringify(value)),
    remove: () => sessionStorage.removeItem(key),
  }),
};

const createClientStorage = (storageOptions = []) => ({
  getItem: () => storageOptions.find(({ get }) => get())?.get(),
  setItem: (value) => storageOptions.forEach(({ set }) => set(value)),
  removeItem: () => storageOptions.forEach(({ remove }) => remove()),
});

const clientStorageBuilders = {
  createSessionStorage: (key) =>
    createClientStorage([
      STORAGE_CREATORS.SESSION_STORAGE(key),
      STORAGE_CREATORS.COOKIE(key, { isSessionOnly: true }),
    ]),
  createPersistStorage: (key, expires = 30) =>
    createClientStorage([
      STORAGE_CREATORS.LOCAL_STORAGE(key, expires),
      STORAGE_CREATORS.COOKIE(key, { expires, isSessionOnly: false }),
    ]),
};

export default clientStorageBuilders;
