import { useEffect, useState } from "react";
import { useVehicleStore } from "./store/vehicle-store";

const useSelectTireSizeByVehicle = () => {
  const [userVehicle] = useVehicleStore((st) => [st.userVehicle]);

  const [state, setState] = useState({
    isLoading: false,
    currentIndex: 0,
    fitmentLabels: [],
    fitmentOptions: [],
  });

  const FITMENT_LABEL = {
    factory: {
      label: "Factory Sizes",
      message: "These are the tire sizes for your exact vehicle submodel",
    },
    optional: {
      label: "Optional Sizes",
      message: "These are all the tire sizes that fit your vehicle",
    },
    all: {
      label: "Vehicle Tire Sizes",
      message: "These are all the tire sizes that fit your vehicle",
    },
    custom: {
      label: "Custom Tire Size",
      message: "",
    },
  };

  useEffect(() => {
    if (!userVehicle) return;

    setState((prev) => ({ ...prev, isLoading: true }));

    (async () => {
      const { fitmentsBySubModelId = [], allDistinctFitments = [] } =
        await userVehicle.getTireFitments();

      let fitmentLabels = [];
      let fitmentOptions = [];
      if (allDistinctFitments.length) {
        if (
          fitmentsBySubModelId.length &&
          fitmentsBySubModelId.length !== allDistinctFitments.length
        ) {
          fitmentLabels = [FITMENT_LABEL.factory, FITMENT_LABEL.optional];
          fitmentOptions = [fitmentsBySubModelId, allDistinctFitments];
        } else {
          fitmentLabels = [FITMENT_LABEL.all];
          fitmentOptions = [allDistinctFitments];
        }
      }

      fitmentLabels = [...fitmentLabels, FITMENT_LABEL.custom];

      setState((prev) => ({
        ...prev,
        isLoading: false,
        currentIndex: 0,
        fitmentLabels,
        fitmentOptions,
      }));
    })();
  }, [userVehicle]);

  return {
    ...state,
    showCustomTireSizeComp:
      state.currentIndex == state.fitmentLabels.length - 1,
    setCurrentIndex: (val) =>
      setState((prev) => ({ ...prev, currentIndex: val })),
  };
};

export default useSelectTireSizeByVehicle;
