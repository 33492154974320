import MetaTags from "../../reusable/ReactMetaTags";
import APP_ENV from "../../../appEnvironment";

const WheelAlignment = () => {
  return (
    <>
      <MetaTags
        description={`At ${APP_ENV.appBrandName} we specialize in vehicle wheel alignments. Wheel
                alignment is quick service that can significantly improve the life of your tires. Same day appointments
                available.`}
        canonical="/car-services/wheel-alignment-check"
        title={`Alignment Check | ${APP_ENV.appBrandName}`}
      />
      <div>
        <p>
          <span>
            <strong>What is a wheel alignment?</strong>
          </span>
        </p>
        <p>
          Wheel alignment is a service in which a mechanic first checks, or
          measures the positioning of a vehicles wheels against the vehicle
          manufactures recommended specifications, and then adjusts various
          suspension components as needed.
        </p>
        <p>
          <span>
            <strong>Why is wheel alignment important?</strong>
          </span>
        </p>
        <p>
          Wheel alignment is important because it effects the life of your tires
          and the handling of your vehicle. A vehicle that is out of alignment
          may rapidly wear tires unevenly and uneven tire wear is not covered by
          the tire manufacture&rsquo;s mileage warranty. Wheel alignment may
          also affect the handling of your vehicle.
        </p>
        <p>
          <span>
            <strong>What cause a car to go out of alignment?</strong>
          </span>
        </p>
        <p>
          Some of the most common causes of your car going out of alignment are
          uneven roads or potholes, small collisions (curbs), suspension related
          repairs to your vehicle, and normal wear and tear.
        </p>
        <p>
          <span>
            <strong>Common symptoms of a bad alignment include&hellip;</strong>
          </span>
        </p>
        <ul>
          <li>Tire inner or outer edge wear</li>
          <li>Cupping wear pattern</li>
          <li>Car pulling or wandering</li>
          <li>Steering wheel not centered</li>{" "}
        </ul>
        <p>
          <span>
            <strong>How often should I get my wheels aligned?</strong>
          </span>
        </p>
        <p>
          The general rule of thumb for scheduling a wheel alignment is every
          6,000 miles. If you wait too long, your tires can start to wear
          unevenly. Tire alignment can be affected if the tires shift even a
          tenth of a degree or hundredth of an inch. As time goes on, this
          misalignment can cause premature and uneven wear.
        </p>
        <p>
          <span>
            <strong>How can I tell if my tires need alignment?</strong>
          </span>
        </p>{" "}
        <p>
          To the naked eye, it is nearly impossible to tell if your tires need
          alignment. Simply put, even a fraction of a degree can affect your
          tires alignment and technology is needed to diagnose your tire&rsquo;s
          alignment situation. It&rsquo;s better not to wait until your tires
          are prematurely worn down to get your alignment fixed, but tackle the
          problem early and secure a longer tire life and save yourself some
          money down the road.
        </p>
        <p>
          <span>
            <strong>Camber, Toe, and Caster</strong>
          </span>
        </p>{" "}
        <p>
          Wheel alignment can be simplified into three main elements: Camber,
          Toe, and Caster. Camber angle can be described as the angle in degrees
          that the tire is tilted along a vertical axis. A camber angle of zero
          degrees would be the wheel aligned vertically perpendicular to the
          surface, meaning the tire is aligned perfectly straight up and down.
          This is ideal for even tire wear and longer tire life.
        </p>{" "}
        <p>
          Toe angle is the angle that the tires are pointing inward or outward
          when you look top-down at your vehicle. It is easy to visualize toe
          angle if you picture yourself walking with your toes pointing out or
          pointing inward (pigeon toed). Maintaining correct toe angle is
          important to reduce wear on tire edges, due to scrubbing against the
          surface of the road.
        </p>{" "}
        <p>
          Caster is the hardest to visualize, and is the angle created by the{" "}
          <span>steerings</span> pivot point from the front to the back of the
          vehicle. Car racers will often adjust their caster angle to optimize
          performance in certain driving situations.
        </p>{" "}
      </div>
    </>
  );
};

export default WheelAlignment;
