import { Card, Button, Box, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const StyledSelectCard = ({
  isSelected = false,
  onSelect = () => null,
  isDisabled = false,
  isLoading = false,
  isLoadingHeight = 50,
  styleProps = {},
  children,
}) => {
  let isSelectedProps = {};
  if (isSelected) {
    isSelectedProps = {
      border: `3px solid ${theme.palette.primary.dark}`,
    };
  }

  return (
    <Card
      variant="outlined"
      component={Button}
      disabled={isDisabled}
      onClick={onSelect}
      style={{
        backgroundColor: theme.palette.background.paper,
        justifyContent: "flex-start",
        textAlign: "left",
        padding: 14,
        borderRadius: 12,
        border: `2px solid ${theme.palette.divider}`,
        width: "100%",
        ...isSelectedProps,
        ...styleProps,
      }}
    >
      {isLoading && (
        <Skeleton variant="rect" width={"100%"} height={isLoadingHeight} />
      )}
      {!isLoading && <Box>{children}</Box>}
    </Card>
  );
};

export default StyledSelectCard;
