import create from "zustand";
import * as yup from "yup";
import { useFormik } from "formik";
import { useVehicleStore } from "./vehicle-store";
import { useEffect } from "react";
import APP_ENV from "../../appEnvironment";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const userInfoSchema = {
  userName: yup
    .string()
    .required("First & Last Name is required.")
    .min(3, "First & Last Name must be more than 3 characters")
    .max(60, "First & Last Name must be less than 60 characters"),
  userPhoneNumber: yup
    .string()
    .required("A valid phone number is required.")
    .matches(phoneRegExp, "A valid phone number is required")
    .min(10, "Too Short. Please enter a valid phone number")
    .max(10, "Too Long. Please enter a valid phone number"),
  userEmail: yup
    .string("Enter your email")
    .email("Please enter a valid email")
    .required("A valid email is required."),
  vehicleDisplayName: yup.string().required("Vehicle is required").min(2),
  userApptNotes: yup
    .string()
    .max(
      300,
      `Character limit is 300. For Assistance please call ${APP_ENV.appPhoneNumber}`
    ),
};

const userInfoKeys = Object.keys(userInfoSchema);

const UserInfoStore = create((set, get) => {
  const initialState = {
    ...Object.assign({}, ...userInfoKeys.map((key) => ({ [key]: "" }))),
    isTextMessageOptedIn: false,
  };

  const setFieldValue = (key, value) => set({ [key]: value });

  const getEdgeUser = () => {
    const {
      userName,
      userPhoneNumber,
      userEmail,
      userApptNotes,
      isTextMessageOptedIn,
    } = get();

    const [userFirstName = "", userLastName = ""] = userName.split(" ");

    return {
      CustomerFirstName: userFirstName,
      CustomerLastName: userLastName,
      CustomerAddress: "",
      CustomerCity: "",
      CustomerState: "",
      CustomerZipCode: "",
      CustomerPhoneNumber: userPhoneNumber,
      CustomerEmail: userEmail,
      CustomerApptNotes: userApptNotes,
      CustomerTextOptIn: isTextMessageOptedIn,
    };
  };

  return {
    ...initialState,
    setFieldValue,
    getEdgeUser,
    clearUserInfoStore: () => set(initialState),
  };
});

export const useCollectUserInfo = ({ onComplete = () => null }) => {
  const _userInfoStore = UserInfoStore();

  const formik = useFormik({
    initialValues: Object.assign(
      {},
      ...userInfoKeys.map((key) => ({ [key]: _userInfoStore[key] }))
    ),
    validationSchema: yup.object({ ...userInfoSchema }),
    onSubmit: (values) => {
      onComplete({
        ...values,
        isTextMessageOptedIn: _userInfoStore.isTextMessageOptedIn,
      });
    },
  });

  const setFieldValue = _userInfoStore.setFieldValue;

  const handleOptInChange = (isOptedIn) => {
    setFieldValue("isTextMessageOptedIn", isOptedIn);
  };

  const [userVehicle] = useVehicleStore((st) => [st.userVehicle]);

  useEffect(() => {
    if (userVehicle) {
      const { year, makeName, modelName, subModelId, subModelName } =
        userVehicle;
      const vehicleDisplayName = [
        year,
        makeName,
        modelName,
        subModelId ? subModelName : "",
      ]
        .filter((i) => i)
        .join(" ");
      createHandleChange("vehicleDisplayName")(vehicleDisplayName);
    }
  }, [userVehicle]);

  const createHandleChange = (key) => {
    return (e) => {
      const value = e?.target?.value ?? e;
      formik.setTouched({}, false);
      formik.setFieldValue(key, value);
      const isValid = userInfoSchema?.[key]?.isValidSync(value);

      if (isValid) {
        _userInfoStore.setFieldValue(key, value);
      }
    };
  };

  return {
    formik,
    handleOptInChange,
    ...Object.assign(
      {},
      ...userInfoKeys.map((key) => {
        const { values, touched, errors } = formik;
        return {
          [key]: {
            key,
            value: values?.[key],
            errorMessage: touched?.[key] && errors?.[key],
            handleChange: createHandleChange(key),
          },
        };
      })
    ),
    userOptIn: _userInfoStore.isTextMessageOptedIn,
  };
};

export default UserInfoStore;
