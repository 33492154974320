import { Typography, Grid, Button, Box, Link } from "@material-ui/core";
import ContactSupportRoundedIcon from "@material-ui/icons/ContactSupportRounded";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const OilChangeNeedHelpBox = () => {
  return (
    <Grid
      container
      style={{
        justifyContent: "center",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <Grid
        item
        xs={12}
        sm={10}
        md={10}
        lg={7}
        xl={7}
        style={{ border: "1px solid black", padding: 15 }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Grid container>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ContactSupportRoundedIcon
                  style={{
                    color: theme.palette.secondary.main,
                    fontSize: 50,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={10}
                style={{
                  paddingBottom: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box style={{ display: "block" }}>
                  <Typography style={{ fontWeight: "bold" }} gutterBottom>
                    Need help selecting the right oil for your vehicle?
                  </Typography>
                  <Typography>
                    Vehicle specific quotes are available at{" "}
                    <Link href={`tel:${APP_ENV.appPhoneNumber}`}>
                      {APP_ENV.appPhoneNumber}
                    </Link>
                    .
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              href={`tel:${APP_ENV.appPhoneNumber}`}
              style={{ height: 50, width: 250 }}
            >
              Call Now
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OilChangeNeedHelpBox;
