import { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Checkbox,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import ExpandIcons from "../../generic/expand-icons";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const TireSearchFilterSection = ({ label: sectionLabel, options = [] }) => {
  const [state, setState] = useState({
    isExpanded: false,
  });

  return (
    <>
      <Paper
        onClick={() => setState((prev) => ({ ...prev, isExpanded: !prev.isExpanded }))}
        style={{
          color: state.isExpanded
            ? theme.palette.primary.main
            : theme.palette.common.black,
        }}
      >
        <MenuItem divider style={{ height: 50 }}>
          <ListItemText>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              {sectionLabel}
            </Typography>
          </ListItemText>
          <ListItemIcon style={{ display: "flex", justifyContent: "end" }}>
            <ExpandIcons isExpanded={state.isExpanded} fontSize={18} />
          </ListItemIcon>
        </MenuItem>
      </Paper>
      <Collapse in={state.isExpanded}>
        <Paper>
          <MenuList style={{ padding: 0, width: "100%" }}>
            {options.map(({ label, onSelect, isSelected, isDisabled }) => {
              return (
                <MenuItem
                  key={label}
                  onClick={onSelect}
                  disabled={isDisabled && !isSelected}
                  divider
                >
                  <ListItemIcon>
                    <Checkbox edge={"start"} checked={isSelected} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="h6" style={{ fontWeight: 600 }}>
                      {label}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              );
            })}
          </MenuList>
        </Paper>
      </Collapse>
    </>
  );
};

export default TireSearchFilterSection;
