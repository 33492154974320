import { Grid, IconButton, Paper, Button, Typography } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DrawerOffsetTop from "../../modals/widgets/DrawerOffsetTop";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const TireSearchFilterDrawer = ({ isOpen, closeModal, children }) => {
  return (
    <DrawerOffsetTop
      anchor="left"
      isOpen={isOpen}
      closeModal={closeModal}
      PaperProps={{
        style: {
          maxWidth: "100%",
          width: 500,
          backgroundColor: theme.palette.background.default,
          padding: 8,
          paddingBottom: 350,
        },
      }}
    >
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Typography variant="h6">FILTER OPTIONS</Typography>
        <IconButton onClick={closeModal}>
          <CloseRoundedIcon />
        </IconButton>
      </Grid>
      <Paper>{children}</Paper>
      <Paper
        elevation={8}
        style={{
          position: "fixed",
          bottom: 35,
          width: "calc(100% - 16px)",
          maxWidth: "calc(500px - 16px)",
          display: "flex",
          justifyContent: "center",
          padding: 15,
        }}
      >
        <Button
          variant="contained"
          fullWidth
          color="primary"
          style={{ height: 50 }}
          onClick={closeModal}
        >
          View Results
        </Button>
      </Paper>
    </DrawerOffsetTop>
  );
};

export default TireSearchFilterDrawer;
