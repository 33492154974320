import VyperLandingPage from "./template/vyper-landing-page";
import { STATIC_CART_ITEMS } from "../../../static/cart-items";

export const VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE_KROQ = {
  promotionDirectory: "/50-percent-off-oil-change-kroq",
  edgeItemId: 105431,
  activePromotions: [
    {
      stores: [
        1101, 1106, 1107, 1113, 1114, 1116, 1126, 1128, 1131, 1136, 1137, 1144,
        1145, 1149, 1151, 1152, 1156, 1158, 1166, 1172, 1174, 1180, 1188,
      ],
    },
  ].map(({ stores, story = "" }) => ({
    stores: stores.map((val) => String(val)),
    story: story
      ? story
      : `We are excited to celebrate our communities. A 50% off oil change is our present to you!`,
  })),
};
const VyperFiftyPercentOffOilChangeKroq = () => {
  const PROMO_IMAGE =
    "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/atd_50-percent-off-oil-change-kroq.png";

  return (
    <VyperLandingPage
      lineOneText="Save 50% on your"
      lineTwoText="Oil Change Now"
      lineThreeText="*Includes Tire Rotation & Vehicle Inspection"
      buttonText="Get 50% Off NOW"
      promotionImage={PROMO_IMAGE}
      promotionCartItem={STATIC_CART_ITEMS.FIFTY_PERCENT_OFF_OIL_CHANGE_KROQ}
      activePromotions={
        VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE_KROQ.activePromotions
      }
    />
  );
};

export default VyperFiftyPercentOffOilChangeKroq;
