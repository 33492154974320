import { useState } from "react";
import {
  Button,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TextField,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ModalStore from "../../hooks/store/modal-store";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const EasySurveyForm = ({ onFormSubmit, ...props }) => {
  const { customerFirstName = "", thumbsUp, easySurveyCategories } = props;

  const [globalLoading] = ModalStore((st) => [st.GlobalLoading]);

  const [state, setState] = useState({
    starRatingSelected: false,
    starRatingError: false,
    starRating: 0,
    comment: "",
    categories: easySurveyCategories.map((item) => ({
      ...item,
      isSelected: false,
      setSelected: () =>
        setState((prev) => {
          return {
            ...prev,
            categories: prev.categories.map((category) => ({
              ...category,
              isSelected:
                category.id == item.id
                  ? !category.isSelected
                  : category.isSelected,
            })),
          };
        }),
    })),
  });

  const handleFormSubmit = async () => {
    if (!state.starRatingSelected) {
      setState((prev) => ({ ...prev, starRatingError: true }));
      return;
    }

    globalLoading.openModal();

    const { starRating, categories, comment } = state;

    const { thumbsUp } = await props.handleFormSubmit({
      starRating,
      categories: categories.filter(({ isSelected }) => isSelected),
      comment,
    });

    globalLoading.closeModal();

    onFormSubmit(thumbsUp);
  };

  const StarRatingRequired = (
    <Typography
      style={{ color: state.starRatingError && theme.palette.error.main }}
    >
      <ErrorOutlineIcon style={{ marginTop: 5, marginRight: 8 }} />
      Star Rating Required.
    </Typography>
  );

  return (
    <Box
      style={{
        width: "100%",
        textAlign: "center",
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 100,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {customerFirstName
          ? `${customerFirstName}, please let us know about your experience.`
          : `Please let us know about your experience.`}
      </Typography>
      {!thumbsUp && (
        <Typography variant="h6" gutterBottom>
          <span>
            We are here to help. Please call us at{" "}
            <a href={`tel:${APP_ENV.appPhoneNumber}`}>
              {APP_ENV.appPhoneNumber}
            </a>
            .
          </span>
        </Typography>
      )}
      <Divider style={{ marginBottom: 25, marginTop: 25 }} />
      <Box style={{ marginBottom: 35 }}>
        <Typography variant="h3" style={{ marginBottom: 15 }}>
          {`Rate your experience`}
        </Typography>
        <Rating
          name="rating"
          style={{ fontSize: 50 }}
          precision={1}
          value={state.starRating}
          onChange={(e, newValue) => {
            setState((prev) => ({
              ...prev,
              starRatingSelected: true,
              starRatingError: false,
              starRating: newValue ?? prev.starRating,
            }));
          }}
        />

        {state.starRatingSelected && (
          <Typography variant="h6">{`Selected Rating: ${state.starRating} Star`}</Typography>
        )}

        {!state.starRatingSelected && StarRatingRequired}
      </Box>
      {state.starRatingSelected && state.starRating < 4 && (
        <>
          <Typography variant="h3" style={{ marginBottom: 8 }}>
            Select all that apply
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 35,
            }}
          >
            <List>
              {state.categories.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    dense
                    button
                    onClick={() => {
                      item.setSelected();
                      setState((prev) => ({ ...prev }));
                    }}
                    style={{ fontSize: 12 }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={item.isSelected}
                        disableRipple
                      />
                    </ListItemIcon>

                    <ListItemText>
                      <Typography>{item.categoryName}</Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      )}

      <Box style={{ marginBottom: 35 }}>
        <Typography variant="h3" style={{ marginBottom: 15 }}>
          {`Your feedback is very important.`}
        </Typography>

        <TextField
          label="Leave a comment"
          multiline
          fullWidth
          minRows={5}
          value={state.comment}
          onChange={({ target }) =>
            setState((prev) => ({ ...prev, comment: target.value }))
          }
          style={{ maxWidth: 600 }}
          variant="outlined"
        />
      </Box>
      <Box style={{ width: "100%", marginBottom: 50 }}>
        <Button
          style={{
            width: 300,
            height: 50,
            color: theme.palette.primary.contrastText,
            marginBottom: 15,
          }}
          variant="contained"
          color="primary"
          onClick={handleFormSubmit}
        >
          Submit
        </Button>
        {state.starRatingError && StarRatingRequired}
      </Box>
    </Box>
  );
};

export default EasySurveyForm;
