import {
  Paper,
  IconButton,
  Box,
  Button,
  Avatar,
  Typography,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DrawerOffsetTop from "./widgets/DrawerOffsetTop";
import SelectVehicle from "../reusable/conditional-selection/SelectVehicle";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const SelectVehicleOnlyDrawerMobile = ({ isOpen, closeModal }) => {
  const SelectVehicleComp = () => {
    const { component, handleSubmit } = SelectVehicle(closeModal);
    return (
      <Box>
        {component}
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            style={{ width: "100%", height: 50 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <DrawerOffsetTop
      isOpen={isOpen}
      closeModal={closeModal}
      anchor={"right"}
      PaperProps={{ style: { width: "100%" } }}
    >
      <Paper
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" style={{ marginLeft: 15 }}>
          Select Vehicle
        </Typography>
        <IconButton onClick={closeModal}>
          <CloseRoundedIcon />
        </IconButton>
      </Paper>
      <Box style={{ padding: 15 }}>
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{
              marginRight: 15,
              width: 25,
              height: 25,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            1
          </Avatar>
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            Select your Vehicle
          </Typography>
        </Box>

        <Typography style={{ fontSize: 12, marginTop: 15, marginBottom: 15 }}>
          All we need is your vehicle year, make, and model to find the best
          deals.
        </Typography>
        {isOpen && <SelectVehicleComp />}
      </Box>
    </DrawerOffsetTop>
  );
};

export default SelectVehicleOnlyDrawerMobile;
