import MetaTags from "../../reusable/ReactMetaTags";
import APP_ENV from "../../../appEnvironment";

const BatteryCheck = () => {
  return (
    <>
      <MetaTags
        description={`At ${APP_ENV.appBrandName} we test car batteries for free and
                       we have a large selection of batteries in stock. Same day appointments available.`}
        canonical="/car-services/battery-check"
        title={`Battery Check | ${APP_ENV.appBrandName}`}
      />
      <div>
        <span>
          <strong>Free Battery Check</strong>
        </span>
        <p>
          {`An unexpected dead battery is a stressful situation. At ${APP_ENV.appBrandName}
                     we try to help you avoid this situation by offering free
                    battery checks at all of our locations.`}
        </p>
        <span>
          <strong>Signs of a failing battery:</strong>
        </span>
        <ol>
          <li>Slow starts in the morning</li>
          <li>Not starting sometimes</li>
          <li>Weird electrical problems</li>
        </ol>
        <p>
          {`Often times your vehicle will not have any symptoms of a failing
                    battery until it is too late. Let ${APP_ENV.appBrandName} test your battery for
                    free to avoid the hastle of a dead battery.`}
        </p>
        <p>
          <span>
            <strong>
              {`How often should I have my vehicle's battery checked?`}
            </strong>
          </span>
        </p>
        <p>
          {`At ${APP_ENV.appBrandName} we recommend having your battery
                    tested with each oil change or mechanical repair. The expected life of
                    a battery is between 2-5 years, so it is even more vital to have your
                    battery tested if it is older than 2 years.`}
        </p>
      </div>
    </>
  );
};

export default BatteryCheck;
