import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Button, Grid, Box, Paper, Link } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { StandardWidthContainer } from "../../components/generic/containers";
import { useLocationStore } from "../../hooks/store/location-store";
import { useWheelBrandStore } from "../../hooks/store/wheel-store";
import LayoutStore from "../../hooks/store/client/layout-store";
import DefaultContainer from "../../components/generic/default-container";
import WheelVisualizerButton from "./wheel-visualizer-button";
import WheelUnderConstruction from "./wheel-under-construction";

import { WHEELS_ROUTE_DIRECTORY } from "../../routes";

import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const WheelsMain = () => {

  if (APP_ENV.appBrandId !== 4) {
    return <WheelUnderConstruction />;
  }
  
  const [showAllBrands, setShowAllBrands] = useState(false);
  const [isMobile] = LayoutStore((st) => [st.isMobile]);
  const [promotion] = useLocationStore((st) => [st.userStore?.promotion]);
  const [wheelBrands, isReady] = useWheelBrandStore((st) => [
    st.wheelBrands,
    st.isReady,
  ]);
  
  const PREFERRED_BRANDS = wheelBrands.filter(({ Brand }) =>
    [
      "Touren",
      "Dirty Life Race Wheels",
      "Method",
      "Mayhem",
      "American Racing",
      "Fuel",
    ].includes(Brand)
  );

  let image = "";
  if (promotion) {
    image = isMobile ? promotion.images.main : promotion.images.secondary;
  }

  return (
    <>
      <StandardWidthContainer>
        <Grid
          container
          style={{
            marginTop: 10,
            marginBottom: 10,
            direction: "row",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            sm={12}
            md={6}
            style={{
              paddingTop: 25,
            }}
          >
            <img
              src={image}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "auto",
              }}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <Typography variant="h3">{`Shop Wheels`}</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <div>
                <div
                  style={{
                    padding: 10,
                  }}
                >
                  <WheelVisualizerButton/>
                </div>
                {[
                  {
                    buttontext: "Quick Appointment",
                    action: {
                      component: RouterLink,
                      to: "/car-appointment",
                    },
                  },
                  {
                    buttontext: "Call Now",
                    action: {
                      href: `tel:${APP_ENV.appPhoneNumber}`,
                    },
                  },
                ].map(({ buttontext, action }, i) => (
                  <div
                    key={i}
                    style={{
                      padding: 10,
                    }}
                  >
                    <Button
                      {...action}
                      variant="contained"
                      color="secondary"
                      style={{
                        height: 50,
                        width: 300,
                      }}
                    >
                      {buttontext}
                    </Button>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </StandardWidthContainer>

      <DefaultContainer backgroundColor={theme.palette.background.paper}>
        <Box style={{ paddingTop: 10 }}>
          <Grid
            container
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              marginBottom: 20,
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box style={{ maxWidth: 450, textAlign: "center" }}>
                <Typography
                  variant="h3"
                  style={{
                    marginBottom: 20,
                  }}
                >
                  Shop By Wheel Brand
                </Typography>

                <Typography variant="body1">
                  {`${APP_ENV.appBrandName} offers a wide variety of wheel brands for
                  sale. We provide quick and quality wheel installation.`}
                </Typography>
              </Box>
            </Grid>

            <>
              {!isReady && (
                <Grid container style={{ justifyContent: "center" }}>
                  {Array.from({ length: 6 }).map((_, i) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ padding: 10 }}
                      key={i}
                    >
                      <Skeleton
                        variant="rect"
                        height={155}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" height={50} width={220} />
                  </Grid>
                </Grid>
              )}
            </>

            {isReady && (
              <Grid container style={{ justifyContent: "center" }}>
                {[
                  ...(!showAllBrands ? PREFERRED_BRANDS : wheelBrands),
                ].map(({ Brand, Logo, brandEndPoint }) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ padding: 15 }}
                      key={Brand}
                      component={RouterLink}
                      to={`${WHEELS_ROUTE_DIRECTORY}/${brandEndPoint}`}
                    >
                      <Paper
                        component={Button}
                        fullWidth
                        elevation={5}
                        style={{
                          display: "block",
                          textAlign: "center",
                          backgroundColor: theme.palette.background.paper,
                          padding: 0,
                        }}
                      >
                        <Box
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            paddingTop: 25,
                            paddingBottom: 25,
                            height: 150,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={Logo}
                            style={{
                              maxWidth: "80%",
                              maxHeight: 100,
                              width: "auto",
                              height: "auto",
                            }}
                          />
                        </Box>
                        <Box
                          style={{
                            width: "100%",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                            height: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{ fontWeight: 600 }}
                          >{`Shop ${Brand}`}</Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  );
                })}
                {!showAllBrands && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: 15,
                      paddingBottom: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      component={Link}
                      onClick={() => setShowAllBrands(true)}
                      variant="contained"
                      color="primary"
                      style={{ height: 50 }}
                    >
                      Show All Wheel Brands
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </DefaultContainer>
    </>
  );
};

export default WheelsMain;
