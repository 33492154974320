import { Paper, Box, Typography, IconButton } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { useLocationStore } from "../../../hooks/store/location-store";
import ModalStore from "../../../hooks/store/modal-store";
import OpenClosedDisplayMessage from "../../locations/widgets/OpenClosedDisplayMessage";
import Skeleton from "@material-ui/lab/Skeleton";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const LocationSectionMobile = ({ height }) => {
  const [userStore] = useLocationStore((st) => [st.userStore]);

  const locationSelectDrawer = ModalStore((st) => ({
    ...st.LocationSelectDrawer,
  }));

  return (
    <Paper
      elevation={0}
      style={{
        width: "100%",
        height,
        backgroundColor: theme.palette.background.default,
        padding: 2,
      }}
    >
      <Box
        style={{
          backgroundColor: theme.palette.background.paper,
          paddingLeft: 10,
        }}
      >
        <Typography style={{ fontSize: 12 }}>Your Store:</Typography>
        <Box
          style={{ display: "flex", alignItems: "center" }}
          onClick={locationSelectDrawer.openModal}
        >
          <Box>
            {userStore && (
              <>
                <Typography style={{ fontSize: 13, fontWeight: "bold" }}>
                  {userStore.displayName}
                </Typography>
                <OpenClosedDisplayMessage {...userStore} fontSize={12} />
              </>
            )}
            {!userStore && (
              <>
                <Skeleton
                  variant="rect"
                  width={250}
                  height={15}
                  style={{ marginBottom: 8 }}
                />
                <Skeleton variant="rect" width={200} height={15} />
              </>
            )}
          </Box>
          {userStore && (
            <IconButton
              style={{ padding: 5, marginLeft: 8 }}
              onClick={locationSelectDrawer.openModal}
            >
              <ExpandMoreRoundedIcon />
            </IconButton>
          )}
          {!userStore && (
            <IconButton style={{ padding: 5, marginLeft: 8 }}>
              <Skeleton variant="circle" width={20} height={20} />
            </IconButton>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default LocationSectionMobile;
