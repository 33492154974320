import MetaTags from "../../reusable/ReactMetaTags";
import APP_ENV from "../../../appEnvironment";

const VehicleFluidCheck = () => {
  return (
    <>
      <MetaTags
        description={`At ${APP_ENV.appBrandName} we always other free vehicle fluid checks.
                Whether you are preparing for a road trip or just want a professional opinion, we have
                mechanics available to help. Same day appointments available.`}
        canonical="/car-services/vehicle-fluid-check"
        title={`Vehicle Fluid Check | ${APP_ENV.appBrandName}`}
      />
      <div>
        <p>
          <span>
            <strong>What is a vehicle fluid check?</strong>
          </span>
        </p>
        <p>
          {`A vehicle fluid check is a basic service in 
                  quick a qualified technician inspects the 
                  levels and condition of your vehicle's fluids. During vehicle fluid 
                  check the technician will also look for any signs of fluid leaks or 
                  problems.`}
        </p>
        <p>
          <span>
            <strong>Common fluids checked during an inspection include:</strong>
          </span>
        </p>
        <ol>
          {" "}
          <li>Windshield washer fluid</li>
          <li>Coolant/Anti-freeze</li> <li>Transmission Fluid</li>
          <li>Power Steering Fluid</li> <li>Brake Fluid</li>
          <li>Engine oil level</li>
        </ol>
        <p>
          <span>
            <strong>{`How often should I have my vehicle's fluids checked?`}</strong>
          </span>
        </p>
        <p>
          {`Your vehicle's fluids should be checked with every oil change or mechanical repair.
                         It is also advised to have your vehicle's fluids checked prior to any long drives 
                         to help reduce the chance of a problem. ${APP_ENV.appBrandName} always offers 
                         fast and free vehicle fluid checks.`}
        </p>
      </div>
    </>
  );
};

export default VehicleFluidCheck;
