import { Typography } from "@material-ui/core";

const UserDistance = ({ userDistance }) => {
  return (
    <>
      {userDistance > 0 && (
        <Typography
          variant="caption"
          style={{ fontSize: 13 }}
        >{`${userDistance.toFixed(1)} miles away`}</Typography>
      )}
    </>
  );
};

export default UserDistance;
