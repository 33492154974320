import { useHistory } from "react-router";
import { Grid, Paper } from "@material-ui/core";
import useLocationSearch from "../../hooks/useLocationSearch";
import LayoutStore from "../../hooks/store/client/layout-store";
import LocationMap from "../../components/locations/LocationMap";
import MetaTags from "../../components/reusable/ReactMetaTags";
import DefaultContainer from "../../components/generic/default-container";
import StandardPageHeader from "../../components/generic/standard-page-header";
import NeedHelpHeader from "../../components/locations/main-locations-page/NeedHelpHeader";
import LocationSearchContainer from "../../components/locations/LocationSearchContainer";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const LocationsMain = () => {
  const locationSearchState = useLocationSearch();

  const { displayLocations } = locationSearchState;

  const history = useHistory();

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const desktopMapHeight = 700;

  return (
    <>
      <MetaTags
        description={`Find a ${APP_ENV.appBrandName} location near you. ${APP_ENV.appBrandName} is the one-stop shop for tires, oil change, brakes, wheel alignment, and more.`}
        canonical="/locations"
        title={`Locations | ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title="Location Finder"
        subtitle={`Find ${APP_ENV.appBrandName} store near you.`}
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid container style={{ paddingTop: 25, paddingBottom: 25 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={4}
            xl={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} sm={9} md={12} lg={12} xl={12}>
              <LocationSearchContainer
                locationSearchState={locationSearchState}
                maxHeight={!isMobile ? desktopMapHeight : null}
                onSelectedLocationClick={() => history.push("/")}
                onNewSelectionClick={() => {}}
              />
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item xs={8} md={7} lg={8} xl={8} style={{ paddingLeft: 20 }}>
              <Paper
                style={{
                  padding: 15,
                  marginBottom: 20,
                  height: 150,
                }}
              >
                <NeedHelpHeader />
              </Paper>
              <Paper style={{ minHeight: desktopMapHeight }}>
                <Grid container>
                  <LocationMap
                    height={desktopMapHeight}
                    markers={displayLocations}
                  />
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default LocationsMain;
