import fetchData from "./fetchData";

export const getWheelBrands = async () => {
  const { BrandLogosUrlBase, Brands } = await fetchData("getWheelBrands");
  return Brands.map((brand) => ({
    ...brand,
    Logo: `${BrandLogosUrlBase}${brand.Logo}`,
    brandEndPoint: `${brand.Brand.toLowerCase().split(" ").join("-")}`,
    VisualizerSlug: `visualizer?wheels&brand=${brand.Brand.split(" ").join(
      "%20"
    )}`,
  }));
};

export const validateVehicle = async ({ make, model, year }) => {
  const data = await fetchData("validateAutosyncVehicle", {
    make,
    model,
    year,
  });

  if (!data.isValid) {
    return data;
  }

  return {
    ...data,
    vehicle: {
      ...data.vehicle,
      year
    },
    entryEndPoint: `/vehicle/${year}/${data.vehicle.Make}/${data.vehicle.Model}`,
  };
};
