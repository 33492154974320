import { Paper, Typography, Box, Button } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import APP_ENV from "../../../../../appEnvironment";

const { theme } = APP_ENV;

const VyperBeforeStoreSelectCard = ({
  openLocationDrawer,
  primaryText = ``,
  secondaryText = ``,
  buttonText,
}) => {
  return (
    <Paper
      elevation={6}
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 6,
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        style={{ color: theme.palette.footer2.main, marginBottom: 15 }}
      >
        {primaryText}
      </Typography>
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: 15,
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.error.main,
          }}
        >
          <ErrorOutlineIcon style={{ marginRight: 8 }} />
          <Typography variant="h6">{secondaryText}</Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        style={{ width: 250, height: 50 }}
        onClick={openLocationDrawer}
      >
        {buttonText}
      </Button>
    </Paper>
  );
};

export default VyperBeforeStoreSelectCard;
