import { TextField, Paper, Box, Button, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import APP_ENV from "../../appEnvironment";
import GpsFixedRoundedIcon from "@material-ui/icons/GpsFixedRounded";

const { theme } = APP_ENV;

const LocationSearchAutoComplete = ({
  searchQuery,
  setSearchQuery,
  autoCompleteOptions,
  onAutoCompleteSelection,
  getStoresByUserLocation,
}) => {
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <Typography variant="h5" style={{ paddingLeft: 8, paddingBottom: 12 }}>
        Find Your Store
      </Typography>
      <Autocomplete
        id="location-autocomplete"
        fullWidth
        onInputChange={setSearchQuery}
        options={autoCompleteOptions}
        onChange={(e, value) => {
          if (value) {
            onAutoCompleteSelection(value);
          }
        }}
        popupIcon={null}
        open={Boolean(searchQuery.length)}
        noOptionsText={"Loading..."}
        getOptionLabel={(option) => option.description}
        renderInput={(params) => (
          <form noValidate>
            <Paper style={{ display: "flex" }}>
              <TextField
                {...params}
                size="small"
                variant="outlined"
                value={searchQuery}
                label="Search by City or Zip"
              />

              <Box
                component={Button}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 0,
                }}
              >
                <SearchIcon
                  style={{ color: theme.palette.primary.contrastText }}
                />
              </Box>
            </Paper>
          </form>
        )}
      />

      <Button
        onClick={getStoresByUserLocation}
        startIcon={<GpsFixedRoundedIcon />}
        color="primary"
        style={{ marginTop: 10 }}
      >
        Use Current Location
      </Button>
    </Box>
  );
};

export default LocationSearchAutoComplete;
