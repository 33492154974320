const createUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const CheckExistingStorage = (type, value) => {
  if (GetUserAppointmentInfo("AppointmentInfo")) {
    let val = GetUserAppointmentInfo("AppointmentInfo")[type];
    if (type != "CustomerIdentification") {
      if (value != null) {
        if (value != val) {
          return value;
        } else {
          return val;
        }
      } else {
        return val ? val : null;
      }
    } else {
      if (val != null && val != "") {
        return val;
      } else {
        return value;
      }
    }
  }
};

export const setUserAppointmentCookie = (apptPayloadObj = {}) => {
  const payload = JSON.stringify({
    Appointment: null,
    AppointmentAddress: null,
    AppointmentNotes: null,
    AppointmentTime: null,
    CartItems: null,
    CustomerCity: null,
    CustomerEmail: null,
    CustomerIdentification: createUUID(),
    CustomerName: null,
    CustomerState: null,
    CustomerZip: null,
    EmailBody: null,
    IsDropof: null,
    PhoneNumber: null,
    PickUpPreference: null,
    ShyftDropOff: null,
    ShyftPickUp: null,
    TimeDesg: null,
    WaitType: null,
    ...apptPayloadObj,
  });

  localStorage.setItem("AppointmentInfo", payload);
  var targetDate = new Date();
  targetDate.setDate(targetDate.getDate() + 100);
  var expires = "expires=" + targetDate;
  document.cookie = "AppointmentInfo=" + payload + ";" + expires + ";path=/";
};

export const SetUserAppointmentInfo = (
  appTime,
  shyftpickUp,
  shyftdropoff,
  phonenumber,
  address,
  city,
  state,
  zip,
  timedesg,
  isdropof,
  firstAndLast,
  email,
  waitTime,
  appointment,
  cartitems,
  appnotes,
  Indentification,
  EmailBody,
  PickUpPreference
) => {
  if (typeof window !== "undefined") {
    let obj = {
      AppointmentTime: CheckExistingStorage("AppointmentTime", appTime),
      ShyftPickUp: CheckExistingStorage("ShyftPickUp", shyftpickUp),
      ShyftDropOff: CheckExistingStorage("ShyftDropOff", shyftdropoff),
      PhoneNumber: CheckExistingStorage("PhoneNumber", phonenumber),
      AppointmentAddress: CheckExistingStorage("AppointmentAddress", address),
      CustomerCity: CheckExistingStorage("CustomerCity", city),
      CustomerState: CheckExistingStorage("CustomerState", state),
      CustomerZip: CheckExistingStorage("CustomerZip", zip),
      TimeDesg: CheckExistingStorage("TimeDesg", timedesg),
      IsDropof: CheckExistingStorage("IsDropof", isdropof),
      CustomerName: CheckExistingStorage("CustomerName", firstAndLast),
      CustomerEmail: CheckExistingStorage("CustomerEmail", email),
      WaitType: CheckExistingStorage("WaitType", waitTime),
      Appointment: JSON.parse(appointment) ? JSON.parse(appointment) : null,
      CartItems: JSON.parse(cartitems) ? JSON.parse(cartitems) : null,
      AppointmentNotes: CheckExistingStorage("AppointmentNotes", appnotes),
      CustomerIdentification: CheckExistingStorage(
        "CustomerIdentification",
        Indentification
      ),
      EmailBody: EmailBody,
      PickUpPreference: CheckExistingStorage(
        "PickUpPreference",
        PickUpPreference
      ),
    };

    const stringObj = JSON.stringify(obj);

    localStorage.setItem("AppointmentInfo", stringObj);
    var targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + 100);
    var expires = "expires=" + targetDate;
    document.cookie =
      "AppointmentInfo=" + stringObj + ";" + expires + ";path=/";

    return obj;
  }
};

export const GetUserAppointmentInfo = (cname) => {
  if (typeof window !== "undefined") {
    var cookies = null;
    var decodedCookie = null;
    try {
      var name = cname + "=";
      decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          cookies = c.substring(name.length, c.length);
          return JSON.parse(cookies);
        }
      }
    } catch (e) {
      throw new Error(
        `JSON parse Error Cookies Line: 70, Function: GetUserAppointmentInfo, Object: ${cname}, Error: ${e}, Cookie: ${cookies}`
      );
    }
  }
  return null;
};

export const SetLocalStorage = (Name, Object) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(Name, JSON.stringify(Object));

    if (window.addtocart && Name == "cart") {
      window.addtocart();
    }
    if (window.updateVhehicle && Name == "Vehicle") {
      window.updateVhehicle();
    }

    if (window.updateLocation && Name == "perferedStore") {
      window.updateLocation();
    }

    ///cart tally
    var co = document.getElementsByClassName("count-cart");
    if (localStorage.getItem("cart")) {
      for (var i = 0; i < co.length; i++) {
        co[i].innerText = JSON.parse(localStorage.getItem("cart")).length;
      }
    }
  }
};
export const GetLocalStorage = (Name) => {
  let loc = { isDefined: null };
  if (typeof window !== "undefined") {
    try {
      loc = {
        isDefined:
          localStorage.getItem(Name) && localStorage.getItem(Name).length > 0,
        object:
          localStorage.getItem(Name) && localStorage.getItem(Name).length > 0
            ? JSON.parse(localStorage.getItem(Name))
            : null,
      };
    } catch (e) {
      throw new Error(
        `JSON parse Error Local Storage Line: 91, Function: GetLocalStorage, Object: ${Name}, Error: ${e}`
      );
    }
  }
  return loc;
};

export const RemoveLocalStorageItem = (Name) => {
  if (typeof window !== "undefined") {
    if (Name == "all") {
      localStorage.clear();
    } else {
      localStorage.removeItem(Name);
    }
  }
};
