import { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router";
import TireItemsStore from "./store/tire-items-store";
import { useLocationStore } from "./store/location-store";
import { useTireBrandStore } from "./store/tire-brand-store";
import { TIRES_ROUTE_DIRECTORY } from "../routes";
import { useTireSizeStore } from "./store/tire-size-store";

const defaultState = {
  isLoading: true,
  tireBrand: {},
  tireModel: {},
  tires: [],
  tiresByRimSize: {},
  breadCrumbPaths: [],
};

const useTireItemsByBrandModel = () => {
  useTireSizeStore();

  const [state, setState] = useState(defaultState);

  const [userStore] = useLocationStore((st) => [st.userStore]);

  const [getActiveTireItems] = TireItemsStore((st) => [st.getActiveTireItems]);

  const [getActiveTireBrand] = useTireBrandStore((st) => [
    st.getActiveTireBrand,
  ]);

  const {
    brandEndPoint = null,
    itemModelEndPoint = null,
    tireSizeEndPoint = null,
    partNumberEndPoint = null,
  } = useParams();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (userStore) {
      (async () => {
        setState(defaultState);

        const payload = Object.assign(
          {},
          ...Object.entries({
            brandEndPoint,
            itemModelEndPoint,
            tireSizeEndPoint,
            partNumberEndPoint,
          })
            .filter(([key, val]) => val)
            .map(([key, val]) => ({ [key]: val }))
        );

        const initParamsCount = Object.keys(payload).length;

        const [tireBrand, tires] = await Promise.all(
          [getActiveTireBrand, getActiveTireItems].map((func) => func(payload))
        );

        if (!Boolean(tires.length)) {
          const paths = [
            tireBrand?.brandEndPoint,
            payload?.itemModelEndPoint,
            payload?.tireSizeEndPoint,
            payload?.partNumberEndPoint,
          ]
            .filter((_, i) => i < initParamsCount - 1)
            .filter((i) => i);

          const redirectUrl = [TIRES_ROUTE_DIRECTORY, ...paths].join("/");

          console.log({redirectUrl})

          history.push(redirectUrl);
          return;
        }

        const tiresByRimSize = Object.assign(
          {},
          ...[...new Set(tires.map(({ rim }) => rim))].map((rim) => {
            return {
              [rim]: tires.filter((tire) => tire.rim == rim),
            };
          })
        );

        const maximumSavings = Math.max(
          0,
          ...tires.map(({ totalDiscountOnFour = 0 }) => totalDiscountOnFour)
        );

        const targetItem = tires[0];

        const tireModel = Object.assign(
          { maximumSavings },
          ...Object.entries(targetItem)
            .filter(
              ([key]) => key.includes("itemModel") || key.includes("review")
            )
            .map(([key, value]) => ({ [key]: value }))
        );

        const breadCrumbPaths = [
          ["Tires", TIRES_ROUTE_DIRECTORY],
          [targetItem.brandName, targetItem.brandSlug],
          [targetItem.itemModelName, targetItem.itemModelSlug],
          [targetItem.tireSizeEndPoint, targetItem.tireSizeSlug],
          [targetItem.partNumberEndPoint, null],
        ].filter((_, i) => i <= initParamsCount);

        setState((prev) => ({
          ...prev,
          isLoading: false,
          tireBrand,
          tireModel,
          tires,
          breadCrumbPaths,
          tiresByRimSize,
        }));
      })();
    }
  }, [userStore, location]);

  return state;
};

export default useTireItemsByBrandModel;
