import create from "zustand";
import { useEffect } from "react";
import { getTireModelsByBrand } from "../../services/venom-service";
import TireBrandStore from "./tire-brand-store";
import LocationStore from "./location-store";
import { useParams, useHistory } from "react-router";
import { TIRES_ROUTE_DIRECTORY } from "../../routes";

let _storeNumber;
let brandModelCache = [];

const TireBrandModelsStore = create((set, get) => {
  const initialState = {
    isReady: false,
    tireBrand: null,
    tireModels: [],
    maximumSavings: 0,
  };

  const getActiveModelsByBrand = async ({ brandEndPoint = null }) => {
    set(initialState);

    const { userStoreNumber } =
      await LocationStore.getState().getLocationStoreAsync();

    if (_storeNumber !== userStoreNumber) {
      _storeNumber = userStoreNumber;
      brandModelCache = [];
    }

    const tireBrand = await TireBrandStore.getState().getActiveTireBrand({
      brandEndPoint,
    });

    let tireModels = [];
    let maximumSavings = 0;

    if (tireBrand) {
      const cacheItem = brandModelCache.find(
        ({ brandEndPoint }) => brandEndPoint == tireBrand.brandEndPoint
      );

      if (cacheItem) {
        tireModels = cacheItem.tireModels;
        maximumSavings = cacheItem.maximumSavings;
      } else {
        tireModels = await getTireModelsByBrand({
          storeNumber: userStoreNumber,
          brandEndPoint,
        });

        maximumSavings = Math.max(
          0,
          ...tireModels.map(
            ({ totalDiscountOnFour = 0 }) => totalDiscountOnFour
          )
        );
        brandModelCache = [
          { brandEndPoint, tireModels, maximumSavings },
          ...brandModelCache,
        ];
      }
    }

    set({
      isReady: true,
      tireBrand,
      tireModels,
      maximumSavings,
    });
  };

  return {
    ...initialState,
    getActiveModelsByBrand,
    clearState: () => set(initialState),
  };
});

export const useTireBrandModelsStore = (selector = (store) => store) => {
  const { getActiveModelsByBrand, clearState, isReady, tireBrand, tireModels } =
    TireBrandModelsStore();

  const { brandEndPoint = null } = useParams("$brandEndPoint");
  const history = useHistory();

  useEffect(() => clearState, []);

  useEffect(() => {
    if (isReady && (!tireBrand || !tireModels.length)) {
      history.push(TIRES_ROUTE_DIRECTORY);
    }
  }, [isReady]);

  const [userStore] = LocationStore((st) => [st.userStore]);

  useEffect(() => {
    if (userStore) {
      getActiveModelsByBrand({ brandEndPoint });
    }
  }, [userStore]);

  return TireBrandModelsStore(selector);
};

export default TireBrandModelsStore;
