import { Typography, Box, Popover, Paper, Button } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import useAnchorEl from "../../../hooks/util/useAnchorEl";

const PackageSubtotalWithInfo = ({ fontSize = 16 }) => {
  const { anchorEl, isOpen, setClosed, toggle } = useAnchorEl();

  return (
    <>
      <Box
        component={Button}
        style={{ display: "flex", alignItems: "center" }}
        onClick={toggle}
      >
        <Typography style={{ fontWeight: 600, marginRight: 3, fontSize }}>
          Package Subtotal
        </Typography>

        <InfoIcon style={{ margin: 5 }} />
      </Box>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={setClosed}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper style={{ padding: 10, width: 250 }}>
          Package Subtotal includes all parts and labor, minus the total of all
          available discounts. This price does not include state sales tax and
          mandatory state/federal fees.
        </Paper>
      </Popover>
    </>
  );
};

export default PackageSubtotalWithInfo;
