import { Box } from "@material-ui/core";
import Footer from "./footer/index.js";
import LayoutStore from "../../hooks/store/client/layout-store";
import MainHeaderMobile from "./header/main-header-mobile";
import MainHeaderDesktop from "./header/main-header-desktop";
import MainAlertBar from "../promotions/full-site-tire-sale/main-alert-bar.js";
import TiresToYouTextUsBar from "./tire-to-you-text-us-bar.js";
import APP_ENV from "../../appEnvironment.js";

const MainLayout = ({ children }) => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  return (
    <>
      <Box style={{ position: "sticky", top: 0, zIndex: 2 }}>
        {isMobile ? <MainHeaderMobile /> : <MainHeaderDesktop />}
        {APP_ENV.appBrandId !== 4 ? <MainAlertBar /> : <TiresToYouTextUsBar />}
      </Box>

      <section>{children}</section>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default MainLayout;
