import { useState } from "react";
import {
  Typography,
  ListItem,
  Button,
  ListItemText,
  Box,
  Collapse,
} from "@material-ui/core";
import ExpandIcons from "../../generic/expand-icons";
import APP_ENV from "../../../appEnvironment";
import { useLocationStore } from "../../../hooks/store/location-store";

const { theme } = APP_ENV;

const TaxesAndFeesCollapsible = ({
  taxAndFeesTotal = 0,
  tax = 0,
  stfTotal = 0,
  fetTotal = 0,
}) => {
  const [tireSTF] = useLocationStore((st) => [st.userStore?.tireSTF]);

  const [isOpen, setIsOpen] = useState(false);

  const hasFees = stfTotal || fetTotal;

  return (
    <>
      <ListItem
        component={Button}
        style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
        onClick={() => hasFees && setIsOpen(!isOpen)}
      >
        <ListItemText>
          <Typography
            style={{
              fontSize: 14,
            }}
          >
            {hasFees ? `Taxes & Fees` : `Taxes`}
          </Typography>
        </ListItemText>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              marginRight: 5,
              fontSize: 16,
              fontWeight: 600,
            }}
          >{`$${taxAndFeesTotal.toFixed(2)}`}</Typography>
          {hasFees ? <ExpandIcons isExpanded={isOpen} /> : null}
        </Box>
      </ListItem>

      <Collapse in={isOpen}>
        <Box
          style={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            padding: 10,
          }}
        >
          {[
            ["Sales Tax", tax],
            [tireSTF?.description ?? "State Tire Fee", stfTotal],
            ["Federal Excise Tax", fetTotal],
          ]
            .filter(([label, value]) => value)
            .map(([label, value]) => (
              <Box
                key={label}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: 8,
                }}
              >
                <Typography style={{ fontSize: 13 }}>{label}</Typography>
                <Typography style={{ fontWeight: 600, fontSize: 13 }}>
                  {`$${value.toFixed(2)}`}
                </Typography>
              </Box>
            ))}
        </Box>
      </Collapse>
    </>
  );
};

export default TaxesAndFeesCollapsible;
