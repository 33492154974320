import {
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

const TireSearchSortOptions = ({ sorts = {}, label = "" }) => {
  const { options } = sorts;

  return (
    <Paper style={{ marginBottom: 10 }}>
      <FormControl variant="outlined" fullWidth>
        {label && (
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        )}

        <Select
          style={{ fontWeight: 600 }}
          value={options.find(({ isSelected }) => isSelected)?.value}
          onChange={(e) =>
            options.find(({ value }) => value == e.target.value).onSelect()
          }
          {...(label ? { label } : {})}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value} style={{ fontWeight: 600 }}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
};

export default TireSearchSortOptions;
