import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Box,
  Paper,
  ListItemAvatar,
  Avatar,
  ListItemText,
  MenuList,
  MenuItem,
  Button,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useLocationStore } from "../../hooks/store/location-store";
import useOpenTireSizeSelector from "../../hooks/util/useOpenTireSizeSelector";
import MetaTags from "../../components/reusable/ReactMetaTags";
import APP_ENV from "../../appEnvironment";
import BreadCrumb from "../../components/generic/bread-crumb";
import DefaultContainer from "../../components/generic/default-container";
import LayoutStore from "../../hooks/store/client/layout-store";
import LocationMap from "../../components/locations/LocationMap";
import OpenClosedDisplayMessage from "../../components/locations/widgets/OpenClosedDisplayMessage";
import ShopNowCardCollection from "../../components/reusable/shop-now-card-collection";
import SingleLocationOfferings from "../../components/locations/single-location-page/SingleLocationOfferings";
import AboutOurCompany from "../../components/locations/widgets/about-our-company";

const REGIONAL_PHONE_NUMBERS = [
  ["phoenix-az", "(623) 900-6919"],
  ["reno-nv", "(775) 322-8107"],
  ["denver-co", "(303) 691-9161"],
  ["tucson-az", "(520) 744-6437"],
  ["las-vegas-nv", "(702) 906-2072"],
].map(([displayCityEndPoint, phoneNumber]) => ({
  displayCityEndPoint,
  phoneNumber,
}));

const { theme } = APP_ENV;

const theMonth = dayjs().format("MMMM");

const LocationsNearby = () => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);
  const [locations] = useLocationStore((st) => [st.locations]);
  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: true });

  const { displayCityEndPoint } = useParams();
  const history = useHistory();
  const [state, setState] = useState({
    isReady: false,
    heroImage: null,
    nearbyMiles: 0,
    totalStoreCount: 0,
    city: "",
    cityState: "",
    displayPhoneNumber: "",
    displayStores: [],
  });

  useEffect(() => {
    if (locations.length && displayCityEndPoint) {
      const targetStore = locations.find(
        (loc) => loc.displayCityEndPoint == displayCityEndPoint
      );

      if (!targetStore) {
        history.push("/locations");
        return;
      }

      const { storesNearby, displayCity, stateAbbreviation, promotion } =
        targetStore;

      if (!Boolean(storesNearby?.storeList?.length > 1)) {
        history.push(`/locations/${targetStore.publicWebEndPoint}`);
        return;
      }

      setState((prev) => ({
        ...prev,
        heroImage: promotion?.images?.main ?? null,
        nearbyMiles: storesNearby.nearbyMiles,
        totalStoreCount: storesNearby.storeList.length,
        city: displayCity,
        cityState: `${displayCity}, ${stateAbbreviation}`,
        displayPhoneNumber:
          REGIONAL_PHONE_NUMBERS.find(
            (item) => item.displayCityEndPoint == displayCityEndPoint
          )?.phoneNumber ?? APP_ENV.appPhoneNumber,
        displayStores: storesNearby.storeList.reduce((stores, nearbyStore) => {
          const store = locations.find(
            ({ storeNumber }) => nearbyStore.storeNumber == storeNumber
          );
          if (store && stores.length < 3) {
            stores = [
              ...stores,
              {
                ...store,
                relativeDistance: nearbyStore.relativeDistance,
              },
            ];
          }
          return stores;
        }, []),
      }));
    }
  }, [locations, displayCityEndPoint]);

  return (
    <>
      <MetaTags
        description={`We have ${state.totalStoreCount} ${APP_ENV.appBrandName} locations in ${state.cityState} for new tires, oil change, wheel alignment, & more. Your one stop automotive shops in ${state.cityState}.`}
        keywords={["tires in", "tire shop in", "oil change", "wheel alignment"]
          .map((label) => `${label} ${state.city}`)
          .join(", ")}
        canonical={`/tire-shop/${displayCityEndPoint}`}
        title={`${APP_ENV.appBrandName} | Tire Shops & Oil Change | ${state.cityState}`}
        script={null}
      />
      <BreadCrumb paths={[["Locations", "/locations"], [state.cityState]]} />
      <DefaultContainer>
        <Grid container style={{ paddingBottom: 25 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{ paddingTop: 15, display: "flex", alignItems: "center" }}
          >
            <Box
              style={{ width: "100%", textAlign: isMobile ? "center" : "left" }}
            >
              <Typography variant="h4">{APP_ENV.appBrandName}</Typography>
              <Typography
                variant="h2"
                style={{
                  fontWeight: "bold",
                  color: theme.palette.primary.dark,
                }}
              >
                {state.cityState}
              </Typography>
              <Typography variant="caption" style={{ fontWeight: 600 }}>
                {`We have ${state.totalStoreCount} Locations near ${state.cityState}`}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{
              paddingTop: 15,
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-end",
            }}
          >
            <Box style={{ textAlign: "center" }}>
              <Link variant="h4" href={`tel:${state.displayPhoneNumber}`}>
                {state.displayPhoneNumber}
              </Link>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: 50, width: 300, marginTop: 15 }}
                  href={`tel:${state.displayPhoneNumber}`}
                >
                  Call Now
                </Button>
              </Box>
              <Button
                component={RouterLink}
                to={"/car-appointment"}
                variant="contained"
                color="secondary"
                style={{ height: 50, width: 300, marginTop: 15 }}
              >
                Quick Appointment
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DefaultContainer>

      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid container style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{
              padding: 10,
            }}
          >
            <img
              onClick={openTireSizeModal}
              src={state.heroImage}
              style={{
                width: "100%",
                height: "auto",
                cursor: "pointer",
              }}
            />
            <Paper style={{ marginTop: 20, padding: 20, textAlign: "center" }}>
              <Typography variant="h4" style={{ paddingBottom: 20 }}>
                {`${theMonth} Hot Deals & Savings`}
              </Typography>
              <Button
                component={RouterLink}
                to="/coupons"
                color="primary"
                variant="contained"
                style={{ height: 50, width: 250 }}
              >
                View All Deals
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} style={{ padding: 10 }}>
            <Paper>
              <Box style={{ padding: 10 }}>
                <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                  {`Tire shops near ${state.cityState}`}
                </Typography>
                <Typography
                  style={{ fontSize: 14 }}
                >{`We have ${state.totalStoreCount} locations within ${state.nearbyMiles} miles`}</Typography>
              </Box>
              <LocationMap markers={state.displayStores} height={115} />
              <MenuList>
                {state.displayStores.map((store, i) => {
                  return (
                    <MenuItem
                      component={RouterLink}
                      to={`/locations/${store.publicWebEndPoint}`}
                      onClick={() => {
                        store.setSelected();
                      }}
                      key={i}
                      style={{
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <ListItemText>
                        <Typography style={{ fontWeight: 600 }}>
                          {APP_ENV.appBrandName}
                        </Typography>
                        <Typography
                          style={{ fontSize: 12 }}
                          display="block"
                          gutterBottom
                        >
                          {`${store.relativeDistance} miles from ${state.cityState}`}
                        </Typography>
                        <Typography display="block" style={{ fontSize: 13 }}>
                          {store.streetAddress}
                        </Typography>
                        <Typography
                          display="block"
                          style={{ fontSize: 13 }}
                          gutterBottom
                        >
                          {`${store.city}, ${store.stateAbbreviation} ${store.zipCode}`}
                        </Typography>
                        <OpenClosedDisplayMessage fontSize={12} {...store} />
                      </ListItemText>

                      <ListItemAvatar
                        style={{
                          border: `1px solid ${theme.palette.divider}`,
                          display: "flex",
                          justifyContent: "center",
                          padding: 8,
                          borderRadius: 10,
                          objectFit: "contain",
                        }}
                      >
                        <img
                          src={APP_ENV.appBrandLogoMarkImg}
                          style={{ width: 50, height: "auto" }}
                        />
                      </ListItemAvatar>
                    </MenuItem>
                  );
                })}
              </MenuList>
              <Box style={{ padding: 10, textAlign: "center" }}>
                <Box>
                  <Button
                    component={RouterLink}
                    to={"/locations"}
                    fullWidth
                    color="primary"
                    variant="contained"
                    style={{ height: 50, width: 300, marginBottom: 20 }}
                  >
                    Use Location Search
                  </Button>
                </Box>
                <Button
                  style={{ marginBottom: 20, fontSize: 14 }}
                  component={RouterLink}
                  to="/locations-directory"
                >
                  View All Locations
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DefaultContainer>
      <DefaultContainer>
        <Box style={{ paddingTop: 20, paddingBottom: 20 }}>
          <ShopNowCardCollection />
        </Box>
      </DefaultContainer>
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Box style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Paper style={{ display: "flex", justifyContent: "center" }}>
            <SingleLocationOfferings />
          </Paper>
          <Box style={{ paddingTop: 20 }}>
            <AboutOurCompany cityState={state.cityState} />
          </Box>
        </Box>
      </DefaultContainer>
    </>
  );
};

export default LocationsNearby;
