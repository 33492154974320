import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  Fab,
} from "@material-ui/core";
import dayjs from "dayjs";
import APP_ENV from "../../../appEnvironment";
import LayoutStore from "../../../hooks/store/client/layout-store";
import MobileAccordion from "./footer-mobile-menu.js";
import DesktopGrid from "./footer-desktop-menu.js";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import PinterestIcon from "@material-ui/icons/Pinterest";

const SOCIAL_ICONS = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  youtube: YouTubeIcon,
  linkedin: LinkedInIcon,
  twitter: TwitterIcon,
  pinterest: PinterestIcon,
};

const { theme } = APP_ENV;

const Footer = () => {
  const phone = APP_ENV.appPhoneNumber;
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  return (
    <>
      <Box
        style={{
          backgroundColor: theme.palette.footer2.main,
          color: "white",
          padding: "6rem 4rem 0 4rem",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Grid container spacing={8}>
            <Grid item xs={12} md={12} lg={8}>
              {isMobile ? <MobileAccordion /> : <DesktopGrid />}
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <Box
                padding="2rem"
                display="flex"
                justifyContent="center"
                width="100%"
              >
                <Card
                  elevation={3}
                  style={{ color: "white", maxWidth: "440px", width: "100%" }}
                >
                  <CardContent
                    style={{ backgroundColor: theme.palette.footer1.main }}
                  >
                    <Box textAlign="center">
                      <Typography variant="h3" gutterBottom>
                        Contact Information
                      </Typography>
                      <Box my="1rem">
                        <Typography variant="h5">
                          Toll Free Sales &amp; Support
                        </Typography>
                      </Box>
                      <Box mt="1rem">
                        <a
                          href={"tel:" + phone}
                          style={{ color: theme.palette.primary.contrastText }}
                        >
                          {phone}
                        </a>
                      </Box>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="h3"
                          style={{ marginTop: 20, marginBottom: 20 }}
                        >
                          Stay Connected
                        </Typography>
                        <Grid
                          container
                          style={{
                            marginBottom: 20,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {Object.entries(APP_ENV.appBrandLinks)
                            .filter(([_, _link]) => _link)
                            .map(([linkName, link], i) => {
                              const Icon = SOCIAL_ICONS[linkName];

                              return (
                                <Grid
                                  item
                                  xs={4}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: 10,
                                  }}
                                  key={i}
                                >
                                  <Fab
                                    aria-label={linkName}
                                    component={Link}
                                    href={link}
                                    target={"_blank"}
                                  >
                                    <Icon style={{ fontSize: 25 }} />
                                  </Fab>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box textAlign="center" mt="3rem">
          <Typography variant="overline">
            &copy;{" "}
            {`Copyright ${dayjs(new Date()).format("YYYY")} ${
              APP_ENV.appBrandName
            }. 14401 Princeton Ave, Moorpark, CA 93021`}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
