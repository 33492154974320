import { useState } from "react";
import { Grid, Typography, Box, Button } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CartDetails from "../cart/cart-details";
import CartSummary from "../cart/cart-summary";
import StyledInfoCard from "./widgets/styled-info-card";
import StyledSelectCard from "./widgets/styled-select-card";
import SelectSectionContainer from "./widgets/selection-section-container";
import APP_ENV from "../../appEnvironment";
import SelectSection from "./widgets/select-section";
import PrimaryActionSelectButton from "./widgets/primary-action-select-button";
import { useCartStore } from "../../hooks/store/cart-store";
import QuickSelectOptionDrawer from "./widgets/quick-select-option-drawer";
import QuickSelectServicesCollection from "./cart-review/quick-select-services-collection";

const { theme } = APP_ENV;

const QuickAppointmentCartReview = ({ onComplete = () => null }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [cartItems] = useCartStore((st) => [st.cartItems]);

  const isLoading = !Boolean(cartItems.length);

  return (
    <>
      <Grid container>
        <SelectSectionContainer>
          <SelectSection
            title="Review"
            subtitle="Confirm or edit the items in your cart."
          >
            <StyledInfoCard isLoading={isLoading} isLoadingHeight={300}>
              <CartDetails />
            </StyledInfoCard>
          </SelectSection>
        </SelectSectionContainer>
        <SelectSectionContainer>
          <SelectSection
            title="Services"
            subtitle="We offer many free services."
          >
            <StyledSelectCard
              styleProps={{ justifyContent: "center" }}
              onSelect={() => setIsOpen(true)}
              isSelected={isOpen}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <AddCircleOutlineIcon
                  style={{ color: theme.palette.primary.main }}
                />
                <Typography style={{ marginLeft: 8, fontWeight: 600 }}>
                  Add Additional Services
                </Typography>
              </Box>
            </StyledSelectCard>
          </SelectSection>
          <SelectSection
            title="Totals"
            subtitle="Review your package subtotal."
          >
            <StyledInfoCard isGrey={false}>
              <CartSummary showNextAvailableApptTime={false} />
            </StyledInfoCard>
            <PrimaryActionSelectButton
              buttonTitle={"Schedule Appointment"}
              onClick={onComplete}
            />
          </SelectSection>
        </SelectSectionContainer>
      </Grid>
      <QuickSelectOptionDrawer
        drawerTitle={"Additional Services"}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      >
        <QuickSelectServicesCollection onSelect={() => setIsOpen(false)} />
      </QuickSelectOptionDrawer>
    </>
  );
};

export default QuickAppointmentCartReview;
