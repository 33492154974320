import { Drawer, Grid, IconButton, Typography } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import LayoutStore from "../../../hooks/store/client/layout-store";
import useLocationSearch from "../../../hooks/useLocationSearch";
import LocationSearchContainer from "../../locations/LocationSearchContainer";
import { MOBILE_MAIN_SECTION_HEIGHT } from "../../layout/header/main-header-mobile";
import { DESKTOP_HEADER_HEIGHT } from "../../layout/header/main-header-desktop";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const PromoStoreSelectDrawer = ({
  isOpen,
  closeModal,
  promoStores = [],
  drawerHeader = "",
  handleLocationSelection,
  hasLocation,
}) => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const locationSearchState = useLocationSearch(999);

  const state = {
    ...locationSearchState,
    displayLocations: locationSearchState.displayLocations
      .filter(({ storeNumber }) => promoStores.includes(storeNumber))
      .filter((s, idx) => idx < 15)
      .map((i) => ({
        ...i,
        isSelected: hasLocation ? i.isSelected : () => false,
      })),
  };

  const offsetModalTop = {
    style: {
      top: isMobile ? MOBILE_MAIN_SECTION_HEIGHT : DESKTOP_HEADER_HEIGHT,
    },
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={closeModal}
      ModalProps={offsetModalTop}
      BackdropProps={offsetModalTop}
      PaperProps={{
        id: "locationDrawer",
        style: {
          ...offsetModalTop.style,
          width: isMobile ? "100vw" : "500px",
          maxWidth: 500,
          paddingLeft: 8,
          paddingRight: 8,
          backgroundColor: theme.palette.background.default,
          borderTop: `1px solid ${theme.palette.gray[700]}`,
        },
      }}
    >
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 5,
        }}
      >
        <Typography variant="h4">Select Your Store</Typography>
        <IconButton onClick={closeModal}>
          <CloseRoundedIcon />
        </IconButton>
      </Grid>

      <Typography style={{ margin: 8, fontWeight: 600 }}>
        {drawerHeader}
      </Typography>

      <Grid container style={{ paddingBottom: 250 }}>
        <LocationSearchContainer
          locationSearchState={state}
          onSelectedLocationClick={handleLocationSelection}
          onNewSelectionClick={handleLocationSelection}
        />
      </Grid>
    </Drawer>
  );
};

export default PromoStoreSelectDrawer;
