import fetchData from "./fetchData";
import TireSizeStore from "../hooks/store/tire-size-store";

export const getVehicleYears = async () => {
  let mostRecentYear = await fetchData("getMostRecentVehicleYear");
  if (typeof mostRecentYear !== "number") {
    mostRecentYear = new Date().getFullYear();
  }

  return [...new Array(50)].map((_, i) => mostRecentYear - i);
};

export const getVehicleMakes = async (year) => {
  const res = await fetchData("getVehicleMakes", { year });

  const makes = res.map(({ Id, WHIId, Desc }) => ({
    makeId: Id,
    whiMakeId: WHIId,
    makeName: Desc,
  }));

  return makes;
};

export const getVehicleModels = async (year, makeId) => {
  const res = await fetchData("getVehicleModels", { year, makeId });

  const models = res.map(({ Id, Desc }) => ({ modelId: Id, modelName: Desc }));

  return models;
};

const createIdkOption = (idKey, nameKey) => ({
  [idKey]: 0,
  [nameKey]: `I don't know`,
});

const IDK_OPTIONS = {
  subModel: createIdkOption("subModelId", "subModelName"),
  engine: createIdkOption("engineId", "engineName"),
};

export const getVehicleSubModelsAndEngines = async (year, makeId, modelId) => {
  const res = await fetchData("getVehicleSubModelsAndEngines", {
    year,
    makeId,
    modelId,
  });

  let data = res.map(({ Id, Desc, SubModelId, SubModelDesc }) => ({
    engineId: Id,
    engineName: Desc.split(" VIN")[0],
    subModelId: SubModelId,
    subModelName: SubModelDesc,
  }));

  const getDistinct = (key, data) => {
    return [...new Set(data.map((i) => i[key]))].map((id) =>
      data.find((i) => i[key] == id)
    );
  };

  if (getDistinct("subModelId", data).length > 1) {
    data = [
      ...data,
      ...getDistinct("engineId", data).map((item) => ({
        ...item,
        ...IDK_OPTIONS.subModel,
      })),
    ];
  }

  if (getDistinct("engineId", data).length > 1) {
    data = [
      ...data,
      ...getDistinct("subModelId", data)
        .filter(
          (sub) =>
            getDistinct(
              "engineId",
              data.filter((i) => i.subModelId == sub.subModelId)
            ).length > 1
        )
        .map((subModel) => ({
          ...subModel,
          ...IDK_OPTIONS.engine,
        })),
    ];
  }

  const getSubModelEngine = (subModelId = null, engineId = null) => {
    const { subModel, engine } = IDK_OPTIONS;

    const cleanedData = data.map((i) => ({
      ...i,
      subModelName: i.subModelId !== subModel.subModelId ? i.subModelName : "",
      engineName: i.engineId !== engine.engineId ? i.engineName : "",
    }));

    subModelId = subModelId ?? subModel.subModelId;
    engineId = engineId ?? engine.engineId;

    if (subModelId == subModel.subModelId && engineId !== engine.engineId) {
      const subs = cleanedData.filter(
        (i) => i.engineId == engineId && i.subModelId !== subModel.subModelId
      );
      if (subs.length == 1) {
        subModelId = subs[0].subModelId;
      }
    }

    if (subModelId !== subModel.subModelId && engineId == engine.engineId) {
      const engs = cleanedData.filter(
        (i) => i.subModelId == subModelId && i.engineId !== engine.engineId
      );
      if (engs.length == 1) {
        engineId = engs[0].engineId;
      }
    }

    return {
      ...subModel,
      ...engine,
      ...(cleanedData.find(
        (i) => i.subModelId == subModelId && i.engineId == engineId
      ) ?? {}),
    };
  };

  const getVehicleByYMME = async (
    year,
    makeId,
    modelId,
    subModelId,
    engineId
  ) => {
    const subModelEngine = getSubModelEngine(subModelId, engineId);

    let [_subModelId, _engineId] = [
      subModelEngine.subModelId,
      subModelEngine.engineId,
    ];

    if (!_subModelId || !_engineId) {
      const fallBack = data[0];
      let target = fallBack;

      switch (true) {
        case !_subModelId && !_engineId:
          break;
        case !_subModelId:
          target = data.find((i) => i.engineId == _engineId) ?? {};
          break;
        case !_engineId:
          target = data.find((i) => i.subModelId == _subModelId) ?? {};
        default:
          break;
      }

      target = { ...fallBack, ...target };

      const item =
        data.find(
          (i) =>
            i.subModelId == target.subModelId && i.engineId == target.engineId
        ) ?? fallBack;

      _subModelId = item.subModelId;
      _engineId = item.engineId;
    }

    const payLoad = {
      year,
      makeId,
      modelId,
      subModelId: _subModelId,
      engineId: _engineId,
    };

    const whiResponse = await fetchData("getVehicleByYMME", payLoad);

    return handleWhiVehicleResponse(whiResponse, subModelEngine);
  };

  return {
    distinctSubModels: getDistinct("subModelId", data),
    getEnginesBySubModelId: (subModelId) =>
      data.filter((i) => i.subModelId == subModelId),
    getSubModelEngine,
    getVehicleByYMME,
  };
};

export const getVehicleByVehicleId = async (
  vehicleId,
  subModelId = null,
  engineId = null
) => {
  const whiResponse = await fetchData("getVehicleById", { vehicleId });

  let [_subModelId, _engineId] = [subModelId, engineId];

  const { BaseVehicle, EngineConfigId, Attrib } = whiResponse;

  if (_subModelId == null || _engineId == null) {
    _subModelId = Attrib.find((i) => i.Name == "SUB_MODEL")?.Id;
    _engineId = EngineConfigId;
  }

  const { Year, MakeId, ModelId } = BaseVehicle;

  const { getSubModelEngine } = await getVehicleSubModelsAndEngines(
    Year,
    MakeId,
    ModelId
  );

  const subModelEngine = getSubModelEngine(_subModelId, _engineId);

  return handleWhiVehicleResponse(whiResponse, subModelEngine);
};

const handleWhiVehicleResponse = (whiResponse, subModelEngine) => {
  const whiData = (({
    Attrib,
    BaseVehicle: bv,
    VehicleId,
    EngineConfigId,
    VehicleToEngineConfigId,
  } = whiResponse) => {
    const { Id: subModelId = 0, value: subModelName = "" } =
      Attrib.find((i) => i?.Name == "SUB_MODEL") ?? {};

    const engineName = Attrib.find((i) => i.Name == "ENGINE_BASE")?.value ?? "";

    return Object.assign(
      {
        vehicleToEngineConfigId:
          EngineConfigId == subModelEngine.engineId
            ? VehicleToEngineConfigId
            : 0,
      },
      ...Object.entries({
        vehicleId: VehicleId ?? 0,
        baseVehicleId: bv?.BaseVehicleId ?? 0,
        engineId: EngineConfigId ?? 0,
        year: bv?.Year ?? 0,
        makeId: bv?.MakeId ?? 0,
        makeName: bv?.MakeName ?? "",
        modelId: bv?.ModelId ?? 0,
        modelName: bv?.ModelName ?? "",
        subModelId,
        subModelName,
        engineName,
      })
        .filter(([key, value]) => value)
        .map(([key, value]) => ({ [key]: value }))
    );
  })();

  const userVehicle = {
    ...subModelEngine,
    ...whiData,
  };

  return userVehicle;
};

export const getVehicleTireFitments = async ({
  baseVehicleId,
  subModelId = 0,
}) => {
  const [whiRes, tireSizeStore] = await Promise.all([
    fetchData("getVehicleTireFitments", { baseVehicleId }),
    TireSizeStore.getState().getTireSizeStoreAsync(),
  ]);

  const tireSizeRef = whiRes
    .map((ts) => {
      const { Id: _subModelId } = ts.Attrib.find((i) => i.Name == "SUB_MODEL");
      const tireSizeEndPoints = [
        ...new Set(
          [ts.TireSize, ts.TireSizeR].map(
            (t) => `${t.Width}-${t.AspectRatio}-${t.RimDiameter}`
          )
        ),
      ];
      return { subModelId: _subModelId, tireSizeEndPoints };
    })
    .filter(({ tireSizeEndPoints }) =>
      tireSizeEndPoints.every((endPoint) =>
        tireSizeStore.getTireSizeByTireSizeEndPoint(endPoint)
      )
    )
    .map((item) => ({
      ...item,
      tireSizeKey: [item.subModelId, ...item.tireSizeEndPoints].join("_"),
    }));

  const data = [
    ...new Set(tireSizeRef.map(({ tireSizeKey }) => tireSizeKey)),
  ].map((tireSizeKey) => {
    const tireSize = tireSizeRef.find((i) => i.tireSizeKey == tireSizeKey);
    let [front, rear = null] = tireSize.tireSizeEndPoints.map((endPoint) => {
      return tireSizeStore.tireSizes.find(
        (i) => i.tireSizeEndPoint == endPoint
      );
    });

    rear ??= front;
    const isStaggered = front.tireSizeEndPoint !== rear.tireSizeEndPoint;

    return {
      subModelId: tireSize.subModelId,
      tireFitmentKey: [front, rear]
        .map(({ tireSizeEndPoint }) => tireSizeEndPoint)
        .join("_"),
      isStaggered,
      front,
      rear,
      setSelected: () =>
        tireSizeStore.setUserTireSize({
          frontTireSizeEndPoint: front.tireSizeEndPoint,
          rearTireSizeEndPoint: rear.tireSizeEndPoint,
        }),
    };
  });

  const allDistinctFitments = [
    ...new Set(data.map(({ tireFitmentKey }) => tireFitmentKey)),
  ].map((tireFitmentKey) =>
    data.find((i) => i.tireFitmentKey == tireFitmentKey)
  );

  const fitmentsBySubModelId = data.filter((i) => i.subModelId == subModelId);

  return {
    allDistinctFitments,
    fitmentsBySubModelId,
  };
};
