import * as React from "react";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const SameDayAppointmentEmail = ({ alternativeTires }) => {
  return (
    <>
      <div style={{ margin: 0, padding: 0 }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: 800,
              boxSizing: "border-box",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                boxSizing: "border-box",
                backgroundColor: theme.palette.grey[100],
                padding: 10,
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: 28,
                    fontWeight: 600,
                    marginTop: 10,
                    marginBottom: 8,
                    textAlign: "center",
                  }}
                >
                  You have a NEW Same-Day Tire Appt!
                </p>
                <p
                  style={{
                    fontSize: 16,
                    marginBottom: 20,
                    marginTop: 0,
                    color: theme.palette.error.main,
                    textAlign: "center",
                  }}
                >
                  The tires below are <strong>in stock</strong> at your store.
                </p>
              </div>
              <div
                style={{
                  boxSizing: "border-box",
                  backgroundColor: theme.palette.background.paper,
                  paddingTop: 20,
                  paddingBottom: 20,
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
              >
                {alternativeTires.map(
                  ({ tireSizeDisplay, alternativeTireOptions }, i) => (
                    <React.Fragment key={i}>
                      <div
                        style={{
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          marginBottom: 20,
                          marginTop: 20,
                        }}
                      >
                        <p
                          style={{
                            paddingRight: 8,
                            fontWeight: 600,
                          }}
                        >
                          {alternativeTireOptions.length > 0
                            ? `Tire Size: ${tireSizeDisplay}`
                            : `No alternative tires for ${tireSizeDisplay}`}
                        </p>
                      </div>
                      <table
                        style={{
                          width: "100%",
                          boxSizing: "border-box",
                          paddingLeft: 8,
                          paddingRight: 8,
                        }}
                      >
                        {alternativeTireOptions.length > 0 && (
                          <React.Fragment>
                            <thead>
                              <tr>
                                {[
                                  "Brand",
                                  "Model",
                                  "Part Number",
                                  "Qty",
                                  "Price",
                                ].map((label, j) => (
                                  <th
                                    key={j}
                                    style={{
                                      paddingBottom: 15,
                                      paddingRight: 8,
                                      textAlign: "left",
                                    }}
                                  >
                                    {label}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            {alternativeTireOptions.map(
                              (
                                {
                                  brandAbbreviation,
                                  itemModelName,
                                  partNumber,
                                  storeAvailableQty,
                                  price,
                                },
                                k
                              ) => (
                                <tbody key={k}>
                                  <tr>
                                    <td>{brandAbbreviation}</td>
                                    <td>{itemModelName}</td>
                                    <td>{partNumber}</td>
                                    <td>{storeAvailableQty}</td>
                                    <td>{price}</td>
                                  </tr>
                                </tbody>
                              )
                            )}
                          </React.Fragment>
                        )}
                      </table>
                    </React.Fragment>
                  )
                )}
              </div>
              <div
                style={{
                  boxSizing: "border-box",
                  backgroundColor: theme.palette.grey[100],
                  padding: 10,
                }}
              >
                <p
                  style={{
                    fontSize: 28,
                    fontWeight: 600,
                    marginTop: 10,
                    marginBottom: 8,
                    textAlign: "center",
                  }}
                >
                  Below is the Customer's order comfirmation email.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SameDayAppointmentEmail;
