import { Box, Grid, Link, Typography } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";
import useFooterLinks from "../../../hooks/use-footer-links";
import { Link as RouterLink } from "react-router-dom";

const { theme } = APP_ENV;

const DesktopGrid = () => {
  const {
    wholesale,
    automotiveServices,
    commonLinksSection,
    tireBrands,
    careers,
    partnerships,
    about,
  } = useFooterLinks();

  const [
    WHOLESALE,
    AUTO_SERVICES,
    COMMON_LINKS_SECTION,
    TIRE_BRANDS,
    CAREERS,
    PARTNERSHIPS,
    ABOUT,
  ] = [
    wholesale,
    automotiveServices,
    commonLinksSection,
    tireBrands,
    careers,
    partnerships,
    about,
  ].map(({ display, title, links }) => {
    return (
      display && (
        <>
          <Typography variant="h3">{title}</Typography>
          <Box display="flex" flexDirection="column" my="2rem">
            {links.map(({ href = "", linkText = "" }, i) => (
              <Link
                key={i}
                component={RouterLink}
                to={href}
                style={{
                  color: "white",
                  textDecoration: "none",
                  margin: "0.5rem",
                  "&:hover": {
                    textDecoration: "none",
                    color: theme.palette.info.dark,
                    cursor: "pointer",
                  },
                }}
              >
                {linkText}
              </Link>
            ))}
          </Box>
        </>
      )
    );
  });

  return (
    <Grid container>
      <Grid item sm={12} md={4}>
        {COMMON_LINKS_SECTION}
      </Grid>
      <Grid item sm={12} md={4}>
        {AUTO_SERVICES}
        {TIRE_BRANDS}
      </Grid>
      <Grid item sm={12} md={4}>
        {CAREERS}
        {WHOLESALE}
        {PARTNERSHIPS}
        {ABOUT}
      </Grid>
    </Grid>
  );
};

export default DesktopGrid;
