import {
  Box,
  Typography,
  Paper,
  Collapse,
  Button,
  ListItem,
  ListItemText,
  Link,
} from "@material-ui/core";
import { useState } from "react";
import EcoIcon from "@material-ui/icons/Eco";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import ModalStore from "../../../hooks/store/modal-store";
import ExpandIcons from "../../generic/expand-icons";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const TireInstallCollapsible = ({
  _installSubtotal = 0,
  _installPackage = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { TireRecyclingModal, LifetimeTireMaintCertModal } = ModalStore();

  return (
    <>
      <ListItem
        component={Button}
        style={{
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: theme.palette.gray[100],
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ListItemText>
          <Typography style={{ fontSize: 14 }}>Installation</Typography>
        </ListItemText>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{ marginRight: 5, fontSize: 14 }}
          >{`$${_installSubtotal.toFixed(2)}`}</Typography>
          <ExpandIcons isExpanded={isOpen} />
        </Box>
      </ListItem>

      <Collapse in={isOpen}>
        <Box
          style={{
            backgroundColor: theme.palette.background.default,
            padding: 4,
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          <Paper style={{ padding: 8 }}>
            {_installPackage.map((item, i) => {
              const isSpecial = ["EW", "CDF"].includes(item.partNumber);

              return (
                <ListItem key={i} style={{ padding: 0 }}>
                  <ListItemText>
                    {!isSpecial && (
                      <Typography style={{ fontSize: 14 }}>
                        {item.description}
                      </Typography>
                    )}

                    {item.partNumber == "CDF" && (
                      <Box
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={TireRecyclingModal.openModal}
                      >
                        <EcoIcon style={{ color: "green", marginRight: 5 }} />

                        <Link style={{ fontSize: 14, color: "green" }}>
                          {item.description}
                        </Link>
                      </Box>
                    )}
                    {item.partNumber == "EW" && (
                      <Box
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={LifetimeTireMaintCertModal.openModal}
                      >
                        <ListAltRoundedIcon style={{ marginRight: 5 }} />

                        <Link
                          style={{
                            fontSize: 14,
                            color: theme.palette.common.black,
                          }}
                        >
                          {item.description}
                        </Link>
                      </Box>
                    )}
                  </ListItemText>

                  <Typography
                    style={{ fontSize: 14 }}
                  >{`$${item.itemSubtotal.toFixed(2)}`}</Typography>
                </ListItem>
              );
            })}
          </Paper>
        </Box>
      </Collapse>
    </>
  );
};

export default TireInstallCollapsible;
