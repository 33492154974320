import { Box, Typography } from "@material-ui/core";

const SubTotalWithStrikeThrough = ({
  subtotal = 0,
  displayPrice = 1,
  fontSize = 21,
}) => {
  const showStrikeThroughPrice = Boolean(subtotal && subtotal > displayPrice);
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {showStrikeThroughPrice && (
        <Typography
          style={{
            fontSize: Math.floor(fontSize * 0.7),
            textDecoration: "line-through",
          }}
        >{`$${subtotal.toFixed(2)}`}</Typography>
      )}
      <Typography
        style={{
          marginLeft: showStrikeThroughPrice ? 10 : 0,
          fontWeight: "bold",
          fontSize,
        }}
      >
        {`$${displayPrice.toFixed(2)}`}
      </Typography>
    </Box>
  );
};

export default SubTotalWithStrikeThrough;
