import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  Link,
} from "@material-ui/core";
import LayoutStore from "../../hooks/store/client/layout-store";
import DefaultContainer from "../../components/generic/default-container";
import AboutBrandInfoGraphics from "../../components/reusable/about-brand-info-graphics";
import FiveReasonsToShopWithUs from "../../components/reusable/five-reasons-to-shop-with-us";
import { STATIC_CART_ITEMS } from "../../static/cart-items";
import MetaTags from "../../components/reusable/ReactMetaTags";
import APP_ENV from "../../appEnvironment";
import LocationInfoCard from "../../components/locations/LocationInfoCard";
import { useLocationStore } from "../../hooks/store/location-store";
import StyledInfoCard from "../../components/quick-appointment/widgets/styled-info-card";
import ModalStore from "../../hooks/store/modal-store";

const SILVER_ROCK_PHONE_NUMBER = {
  cleanPhoneNumber: "8558773337",
  phoneNumber: "(855) 877-3337",
};

const MAIN_IMAGES = {
  1: "https://venomstorage.blob.core.windows.net/bbtwebblob/images/hero/silverrock-partnership_bbt.png",
  2: "https://venomstorage.blob.core.windows.net/bbtwebblob/images/hero/silverrock-partnership_tw.png",
  3: "https://venomstorage.blob.core.windows.net/bbtwebblob/images/hero/silverrock-partnership_atd.png",
  4: "https://venomstorage.blob.core.windows.net/bbtwebblob/images/hero/silverrock-partnership_bbt.png",
};

const { theme } = APP_ENV;

const { SILVER_ROCK_WARRANTY_CONSULTATION } = STATIC_CART_ITEMS;

const SilverRockServicePartnership = () => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const [LocationSelectDrawer] = ModalStore((st) => [st.LocationSelectDrawer]);

  const userStore = useLocationStore((st) => ({
    ...(st?.userStore ?? {}),
    ...SILVER_ROCK_PHONE_NUMBER,
  }));

  const isLoading = !Boolean(userStore?.storeNumber);

  return (
    <>
      <MetaTags
        description={`${APP_ENV.appBrandName} is an authorized warranty repair facility for SilverRock Warranty Repairs.`}
        canonical="/service-partnerships/silver-rock"
        title={`SilverRock Warranty Auto Repair`}
      />
      <Grid
        container
        style={{
          backgroundImage: `linear-gradient(${theme.palette.footer1.main}, ${theme.palette.background.default})`,
        }}
      >
        <Container style={{ paddingBottom: 50 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Box style={{ width: "100%", maxWidth: 475 }}>
                <Box style={{ marginTop: 25 }}>
                  <Typography
                    variant="h2"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    {"SilverRock Warranty"}
                  </Typography>
                  <Typography
                    variant="h3"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    {"Authorized Service Provider"}
                  </Typography>

                  <Typography
                    style={{
                      marginTop: 15,
                      marginBottom: 25,
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    {`Whether you just purchased your vehicle from Carvana®, DriveTime®, or another dealer, 
                     ${APP_ENV.appBrandName} is authorized to inspect and repair your vehicle.`}
                  </Typography>
                </Box>
                <Paper
                  style={{
                    borderRadius: 15,
                    marginTop: 15,
                    marginBottom: 15,
                    padding: 15,
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h4" style={{ marginBottom: 10 }}>
                    Schedule Appointment At
                  </Typography>

                  <StyledInfoCard>
                    <StyledInfoCard
                      isGrey={false}
                      isLoading={isLoading}
                      isLoadingHeight={200}
                    >
                      {!isLoading && <LocationInfoCard {...userStore} />}
                    </StyledInfoCard>
                    <Grid
                      container
                      style={{ justifyContent: "center", padding: 15 }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={LocationSelectDrawer.openModal}
                      >
                        Change Store
                      </Button>
                    </Grid>
                  </StyledInfoCard>

                  <Button
                    onClick={SILVER_ROCK_WARRANTY_CONSULTATION.addToCart}
                    style={{
                      width: 300,
                      height: 50,
                      marginTop: 15,
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Schedule Appointment
                  </Button>
                </Paper>
                <Box style={{ padding: 25 }}>
                  <Typography>Questions or need more information?</Typography>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 25,
                      width: "100%",
                    }}
                  >
                    <Typography style={{ fontSize: 25, marginRight: 8 }}>
                      Call Us Now at
                    </Typography>
                    <Link
                      href={`tel:${SILVER_ROCK_PHONE_NUMBER.cleanPhoneNumber}`}
                      style={{ fontWeight: "bold", color: "black" }}
                      underline="always"
                    >
                      {`${SILVER_ROCK_PHONE_NUMBER.phoneNumber}`}
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: 500,
                  marginTop: isMobile ? 20 : 75,
                }}
              >
                <img
                  src={MAIN_IMAGES[APP_ENV.appBrandId]}
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <DefaultContainer>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <AboutBrandInfoGraphics />
          </Grid>

          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <FiveReasonsToShopWithUs />
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default SilverRockServicePartnership;
