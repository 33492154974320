import APP_ENV from "../../appEnvironment";
import EcoIcon from "@material-ui/icons/Eco";
/*

 */

export const PROMO_THEME = {
  sitePromoName: "Thanksgiving Sale",
  sitePromoColors: {
    primary: "#D51F26",
    secondary: "#EA7A42",
    tertiary: "#DD0000",
  },
  SitePromoIcon: EcoIcon,
};

const PROMO_OPTIONS = {
  cali_austin: {
    promoType: "b3g1_nex-sum_ew",
    brandEndPoints: ["nexen", "summit"],
    edgeItemId: 103000,
    displayText: {
      alertBar: `${PROMO_THEME.sitePromoName} - Buy 3, Get 1 FREE!`,
      tireSaleName: `${PROMO_THEME.sitePromoName}!`,
      tirePromoBox: "Buy 3, Get 1 FREE!*",
    },
    images:
      APP_ENV.appBrandId !== 4
        ? {
            main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_thanksgiving-sale_hero.jpg",
            secondary:
              "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_thanksgiving-sale_tire-look-up.jpg",
            serviceTile:
              "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/every-day-tires.jpg",
          }
        : {
            main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_thanksgiving-sale_hero.jpg",
            secondary:
              "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_thanksgiving-sale_tire-look-up.jpg",
            serviceTile:
              "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/every-day-tires.jpg",
          },
  },

  focus: {
    promoType: "b2g2_nex-sum_ew-align",
    brandEndPoints: ["summit", "nexen"],
    edgeItemId: 103820,
    displayText: {
      alertBar: `${PROMO_THEME.sitePromoName} - Buy 2, Get 2 FREE!`,
      tireSaleName: `${PROMO_THEME.sitePromoName}!`,
      tirePromoBox: "Buy 2, Get 2 FREE!*",
    },
    maximumDiscount: 300,
    images: {
      main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g1_thanksgiving-sale_hero.jpg",
      secondary:
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g2_thanksgiving-sale_tire-look-up.jpg",
      serviceTile:
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/every-day-tires.jpg",
    },
  },
  lasVegas: {
    promoType: "b2g2_sum_ew-align",
    brandEndPoints: ["summit"],
    edgeItemId: 107773,
    displayText: {
      alertBar: `${PROMO_THEME.sitePromoName} - Buy 2, Get 2 FREE!`,
      tireSaleName: `${PROMO_THEME.sitePromoName}!`,
      tirePromoBox: "Buy 2, Get 2 FREE!*",
    },
    maximumDiscount: 300,
    images: {
      main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/LV_B2G2_thanksgiving-sale_hero.jpg",
      secondary:
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g2_LV_thanksgiving-sale_tire-look-up.jpg",
      serviceTile:
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_secondary-offer_LV.jpg",
    },
    secondaryOffer: {
      promoType: "b3g1_nex_ew",
      brandEndPoints: ["nexen"],
      edgeItemId: 104104,
      displayText: {
        alertBar: `${PROMO_THEME.sitePromoName} - Buy 3, Get 1 FREE!`,
        tireSaleName: `${PROMO_THEME.sitePromoName}!`,
        tirePromoBox: "Buy 3, Get 1 FREE!*",
      },
      maximumDiscount: null,
    },
  },
};

const PROMO_STORE_GROUPS = {
  tucson: [
    "3020",
    "3025",
    "3026",
    "3027",
    "3028",
    "3029",
    "3030",
    "3031",
    "3032",
    "3033",
    "3034",
    "3035",
  ],
  otherAz: [
    "3001",
    "3002",
    "3003",
    "3004",
    "3005",
    "3006",
    "3007",
    "3008",
    "3009",
    "3010",
    "3011",
    "3012",
    "3013",
    "3014",
    "3015",
    "3016",
    "3017",
    "3018",
    "3019",
    "3021",
    "3022",
    "3023",
    "3024",
    "3036",
    "3037",
  ],
  vegas: [
    "3203",
    "3204",
    "3205",
    "3206",
    "3207",
    "3208",
    "3209",
    "3210",
    "3211",
    "3212",
    "3213",
    "3214",
  ],
  denver: ["2007", "2008", "2009", "2010", "2011", "2012", "2013"],
  reno: ["3200", "3201", "3202", "1051"],
  texas: ["4001", "4002", "4003", "4004", "4005", "4006", "4007"],
  d18_nv: ["3200", "3201", "3202"],
  d18_ca: ["1047", "1048", "1049", "1050", "1051", "1054"],
};

export const getPromotionByLocation = ({ storeNumber = null }) => {
  let promotion;

  if ([...PROMO_STORE_GROUPS.vegas].includes(storeNumber)) {
    promotion = PROMO_OPTIONS.lasVegas;
    promotion.secondaryOffer = {
      ...promotion.secondaryOffer,
      ...PROMO_THEME,
    };
  }

  if (
    [
      ...PROMO_STORE_GROUPS.tucson,
      ...PROMO_STORE_GROUPS.otherAz,
      ...PROMO_STORE_GROUPS.denver,
      ...PROMO_STORE_GROUPS.d18_ca,
      ...PROMO_STORE_GROUPS.d18_nv,
    ].includes(storeNumber)
  ) {
    promotion = PROMO_OPTIONS.focus;
  }

  return {
    ...PROMO_THEME,
    ...(promotion ?? PROMO_OPTIONS.cali_austin),
  };
};
