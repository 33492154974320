import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Button,
  Hidden,
  Typography,
  Divider,
} from "@material-ui/core";
import DefaultContainer from "../../components/generic/default-container";
import APP_ENV from "../../appEnvironment";
import VehicleTireSizeSelectButtonWrapper from "../../components/modals/widgets/VehicleTireSizeSelectButtonWrapper";
import InfiniteScroll from "react-infinite-scroller";
import LayoutStore from "../../hooks/store/client/layout-store";
import TireSearchFilterDrawer from "../../components/tires/tire-search-sort-filter/tire-search-filter-drawer";
import TireSearchSortOptions from "../../components/tires/tire-search-sort-filter/tire-search-sort-options";
import TireSearchFilterSection from "../../components/tires/tire-search-sort-filter/tire-search-filter-section";
import TuneIcon from "@material-ui/icons/Tune";
import useOpenTireSizeSelector from "../../hooks/util/useOpenTireSizeSelector";
import { useLocationStore } from "../../hooks/store/location-store";
import TireSearchResultsCard from "../../components/tires/tire-search-results-card";
import { getPromotionByLocation } from "../../hooks/store/promotion-store";

const { theme } = APP_ENV;

const [Mobile, Desktop] = ["lgUp", "mdDown"].map((prop) => ({ children }) => (
  <Hidden {...{ [prop]: true }}>{children}</Hidden>
));

const RESULTS_PER_PAGE = 3;

const TireSearchResults = ({
  tireData = [],
  filters = [],
  sorts,
  tireSetId = "",
  isStaggered = false,
  tireOptionTabs = false,
}) => {
  const [state, setState] = useState({
    drawerOpen: false,
    currentPage: 0,
  });

  const [isMobile] = LayoutStore((st) => [st.isMobile]);
  const [userStore] = useLocationStore((st) => [st.userStore]);

  useEffect(() => {
    if (!isMobile && state.drawerOpen) {
      setState((prev) => ({ ...prev, drawerOpen: false }));
    }
  }, [isMobile]);

  useEffect(() => {
    setState((prev) => ({ ...prev, currentPage: 0 }));
  }, [tireSetId]);

  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: false });

  const getNextPage = () =>
    setState((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));

  const defaultPromoImage =
    !Boolean(userStore) && getPromotionByLocation({})?.images?.secondary;

  const PROMOTION = (
    <Paper
      style={{ marginBottom: 10, cursor: "pointer" }}
      elevation={8}
      onClick={openTireSizeModal}
    >
      <img
        src={userStore?.promotion?.images?.secondary ?? defaultPromoImage ?? ""}
        style={{
          width: "100%",
          height: "auto",
          filter: !Boolean(userStore) && "blur(15px)",
        }}
      />
    </Paper>
  );

  const SORT_DROP_DOWN = (
    <>
      {!isMobile && (
        <Typography variant="h6" style={{ paddingLeft: 10, paddingBottom: 5 }}>
          SORT BY
        </Typography>
      )}

      <TireSearchSortOptions sorts={sorts} label={isMobile && "SORT BY"} />
    </>
  );

  const TIRE_COLLECTION = (
    <InfiniteScroll
      loadMore={getNextPage}
      hasMore={Boolean(state.currentPage * RESULTS_PER_PAGE < tireData.length)}
    >
      {tireData
        .slice(0, state.currentPage * RESULTS_PER_PAGE)
        .map((tire, i) => (
          <TireSearchResultsCard
            key={i}
            tire={tire}
            isStaggered={isStaggered}
          />
        ))}
    </InfiniteScroll>
  );

  const FILTERS = (
    <>
      {filters.map((filter) => (
        <TireSearchFilterSection
          {...filter}
          key={filter.label}
          tireSetId={tireSetId}
        />
      ))}
    </>
  );

  const CHANGE_TIRE_SIZE = (
    <VehicleTireSizeSelectButtonWrapper>
      <Button
        color={"primary"}
        style={{ marginLeft: 10 }}
        size={"small"}
        variant="contained"
      >
        Change Tire Size
      </Button>
    </VehicleTireSizeSelectButtonWrapper>
  );

  return (
    <>
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            paddingTop: 10,
            display: "flex",
            justifyContent: "center",
            maxWidth: 1500,
          }}
        >
          <Mobile>
            <Box
              style={{
                width: "100%",
                maxWidth: 750,
              }}
            >
              <Grid container style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Grid item xs={6}>
                  <Box style={{ maxWidth: 250 }}>{SORT_DROP_DOWN}</Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    paddingBottom: 13,
                    paddingLeft: 15,
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    style={{ maxWidth: 350, height: 50 }}
                    startIcon={<TuneIcon />}
                    onClick={() =>
                      setState((prev) => ({ ...prev, drawerOpen: true }))
                    }
                  >
                    Filter
                  </Button>
                </Grid>
              </Grid>

              <Box
                style={{
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                {PROMOTION}
              </Box>
              <Box
                style={{
                  width: "100%",
                  paddingBottom: 10,
                }}
              >
                {CHANGE_TIRE_SIZE}
              </Box>

              <Divider />

              {tireOptionTabs && (
                <Box
                  style={{
                    paddingBottom: 10,
                  }}
                >
                  {tireOptionTabs}
                </Box>
              )}

              <Box>{TIRE_COLLECTION}</Box>
              <TireSearchFilterDrawer
                isOpen={state.drawerOpen}
                closeModal={() =>
                  setState((prev) => ({ ...prev, drawerOpen: false }))
                }
              >
                {FILTERS}
              </TireSearchFilterDrawer>
            </Box>
          </Mobile>
          <Desktop>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                style={{
                  width: "100%",
                }}
              >
                {SORT_DROP_DOWN}
                <Typography
                  variant="h6"
                  style={{ paddingLeft: 10, paddingBottom: 5 }}
                >
                  FILTER BY
                </Typography>
                {FILTERS}
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              style={{ padding: 10, display: "flex", justifyContent: "center" }}
            >
              <Box
                style={{
                  width: "100%",
                }}
              >
                {PROMOTION}
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: tireOptionTabs ? "space-between" : "end",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  {tireOptionTabs}
                  {CHANGE_TIRE_SIZE}
                </Box>

                {TIRE_COLLECTION}
              </Box>
            </Grid>
          </Desktop>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default TireSearchResults;
