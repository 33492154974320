import { Drawer } from "@material-ui/core";
import LayoutStore from "../../../hooks/store/client/layout-store";
import { DESKTOP_HEADER_HEIGHT } from "../../../components/layout/header/main-header-desktop";
import { MOBILE_MAIN_SECTION_HEIGHT } from "../../../components/layout/header/main-header-mobile";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const DrawerOffsetTop = ({
  isOpen,
  closeModal,
  anchor = "left",
  PaperProps,
  children,
}) => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const offsetModalTop = {
    style: {
      top: isMobile ? MOBILE_MAIN_SECTION_HEIGHT : DESKTOP_HEADER_HEIGHT,
    },
  };

  const { style = {}, ...otherProps } = PaperProps ?? {};

  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={closeModal}
      ModalProps={offsetModalTop}
      BackdropProps={offsetModalTop}
      PaperProps={{
        className: "NoScrollbar",
        style: {
          borderTop: `1px solid ${theme.palette.gray[700]}`,
          ...offsetModalTop.style,
          ...style,
        },
        ...otherProps,
      }}
    >
      {children}
    </Drawer>
  );
};

export default DrawerOffsetTop;
