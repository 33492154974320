import { Box, Typography } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const CouponPageSectionContainer = ({ title, children }) => {
  return (
    <Box
      style={{
        backgroundColor: theme.palette.background.paper,
        marginBottom: 25,
      }}
    >
      <Typography variant="h2" style={{ padding: 20, textAlign: "center" }}>
        {title}
      </Typography>
      <Box style={{ paddingBottom: 20 }}>{children}</Box>
    </Box>
  );
};

export default CouponPageSectionContainer;
