import { Grid, Box } from "@material-ui/core";
import LayoutStore from "../../../hooks/store/client/layout-store";

const SelectSectionContainer = ({ children }) => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const padding = isMobile ? {} : { paddingLeft: 15, paddingRight: 15 };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      style={{ display: "flex", justifyContent: "center", ...padding }}
    >
      <Box style={{ width: "100%", maxWidth: 600 }}>{children}</Box>
    </Grid>
  );
};

export default SelectSectionContainer;
