import { Box, Link, Button, Grid } from "@material-ui/core";
import { StandardWidthContainer } from "../generic/containers";
import { Link as RouterLink, useHistory } from "react-router-dom";
import APP_ENV from "../../appEnvironment";

/*

 */

const SECONDARY_COUPONS = {
  1: {
    images: [
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/15-off-oil-change-BBT.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/coupon-wheel-alignment-q1-2024-bbt.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/coupon-brake-service-q1-2024-bbt.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shell-oil-coupon-2024-bbt_tw.png",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/bbt-tw-air-filter-coupon.jpg",
    ],
    couponSheet:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/coupon-summary-100724-BBT.pdf",
  },
  2: {
    images: [
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/15-off-oil-change-BBT.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/coupon-wheel-alignment-q1-2024-bbt.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/coupon-brake-service-q1-2024-bbt.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shell-oil-coupon-2024-bbt_tw.png",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/bbt-tw-air-filter-coupon.jpg",
    ],
    couponSheet:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/coupon-summary-100724-TW.pdf",
  },
  3: {
    images: [
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/coupon-wheel-alignment-q1-2024-atd.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/coupon-brake-service-q1-2024-atd.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/15-off-oil-change-ATD.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shell-oil-coupon-2024-atd.png",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/ATD_air-filter-coupon.jpg",
    ],
    couponSheet:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/coupon-summary-100724-ATD.pdf",
  },
  4: {
    images: [
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/Wheel-package-coupon.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/coupon-wheel-alignment-q1-2024-tty.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/free-flat-repair-coupon.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/alignment-check_web-coupon.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/tty-tire-rotation-coupon.jpg",
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/warranty-plan_tty.jpg",
    ],
    couponSheet:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/tire%20to%20you%20coupon%20page.pdf",
  },
};

const ACTIVE_COUPONS = SECONDARY_COUPONS[APP_ENV.appBrandId];

const { theme } = APP_ENV;

export const CouponSecondaryOfferSlider = () => {
  const history = useHistory();
  const handleImageClick = (image) => {
    if (
      image ===
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shell-oil-coupon-2024-bbt_tw.png" ||
      image ===
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shell-oil-coupon-2024-atd.png"
    ) {
      window.open(
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shell-gift-card-promo.pdf",
        "_blank"
      );
    } else {
      history.push("/coupons");
    }
  };

  return (
    <StandardWidthContainer>
      <Box
        style={{
          marginTop: 10,
          overflow: "auto",
          whiteSpace: "nowrap",
          display: "flex",
        }}
      >
        {ACTIVE_COUPONS.images.map((image, i) => (
          <Box
            key={i}
            style={{ padding: 10, minWidth: 300, cursor: "pointer" }}
            onClick={() => handleImageClick(image)}
          >
            <img src={image} style={{ width: "100%" }} />
          </Box>
        ))}
      </Box>
    </StandardWidthContainer>
  );
};

export const CouponSecondaryOffersGrid = () => {
  const handleImageClick = (image) => {
    if (
      image ===
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shell-oil-coupon-2024-bbt_tw.png" ||
      image ===
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shell-oil-coupon-2024-atd.png"
    ) {
      window.open(
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shell-gift-card-promo.pdf",
        "_blank"
      );
    } else {
      if (!ACTIVE_COUPONS.couponSheet === 4) {
        window.open(ACTIVE_COUPONS.couponSheet, "_blank");
      }
    }
  };

  return (
    <StandardWidthContainer>
      <Grid container>
        {ACTIVE_COUPONS.images.map((image, i) => (
          <Grid
            key={i}
            item
            xs={12}
            md={4}
            style={{ padding: 10, minWidth: 300, cursor: "pointer" }}
            onClick={() => handleImageClick(image)}
          >
            <img src={image} style={{ width: "100%" }} />
          </Grid>
        ))}
      </Grid>
      <Box
        style={{
          width: "100%",
          textAlign: "center",
          paddingTop: 25,
          paddingBottom: 25,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            width: 300,
            height: 50,
            color: theme.palette.primary.contrastText,
          }}
          onClick={() => window.open(ACTIVE_COUPONS.couponSheet, "_blank")}
        >
          Print Exclusive Coupons
        </Button>
      </Box>
    </StandardWidthContainer>
  );
};
