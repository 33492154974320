import { Box, Typography } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";
import BulletPoint from "../../generic/bullet-point";

const styleProps = {
  title: {
    variant: "h5",
    style: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  subtitle: {
    style: {
      marginBottom: 10,
    },
  },
  contentNoTitle: {
    style: {
      marginTop: 5,
    },
  },
};

const ListItems = ({ items = [] }) => {
  return (
    <Box style={{ width: "100%" }}>
      <ul>
        {items.map((text, key) => (
          <BulletPoint key={key} text={text} />
        ))}
      </ul>
    </Box>
  );
};

const AboutOurCompany = ({ cityState = "", storeNumber = "" }) => {
  switch (true) {
    case storeNumber == "4001":
      return (
        <>
          <Typography
            {...styleProps.contentNoTitle}
          >{`Tires To You is America's wheel and tire superstore. We carry new and used tires in Austin, TX by the most trusted brands in the industry, including Goodyear, Michelin, Pirelli, Continental, BF Goodrich, Yokohama, Bridgestone, Dunlop, Toyo Tires, and more. We are your one-stop shop for wheels and tires.`}</Typography>
          <Typography
            {...styleProps.contentNoTitle}
          >{`The pros at Tires To You know their stuff—we strive to be the best at what we do, and it shows in our customer satisfaction ratings. When you visit us for new and used tires in Austin, TX you can expect:`}</Typography>
          <ListItems
            items={[
              "The lowest prices on new tires—guaranteed",
              "Variety—we carry an exceptional range of products",
              "Excellent customer service",
              "Efficiency—we respect your time",
              "Knowledgeable staff",
              "Quality work",
            ]}
          />
          <Typography
            {...styleProps.title}
          >{`New Tire Services & Our Austin Location`}</Typography>
          <Typography>{`Tires To You provides San Antonio residents with a variety of valuable tire services that allows each vehicle to stay at its peak condition for years down the road. Our list of products and services includes:`}</Typography>
          <ListItems
            items={[
              "Quality new and used tires (with warranty)",
              "Used tires",
              "Aftermarket wheels & rims",
              "OEM wheels & rims",
              "Tire & wheel repair or replacement",
              "Free tire rotations",
              "Free wheel alignment checks",
              "Free wheel and tire washing",
              "Free 10-point tire and wheel inspection",
            ]}
          />
          <Typography
            {...styleProps.contentNoTitle}
          >{`Located in Austin, TX, Tires To You serves all of the following neighborhoods and surrounding communities:`}</Typography>

          <ListItems
            items={[
              "All of Austin and surrounding cities",
              "Crestview",
              "Allandale",
              "North Shoal Creek",
              "Rosedale",
              "North Loop",
              "Central Austin",
              "North Austin",
            ]}
          />
        </>
      );
    case storeNumber == "4002":
      return (
        <>
          <Typography {...styleProps.title}>
            {`Excellent Service Staff To Meet Your Needs`}
          </Typography>
          <Typography>
            {`We want every customer to leave our Woodway tire shop happy, so we focus on having the best staff you could ask for! All our employees are well-educated in our products, and always have time to help you find the best tires, wheels, and accessories for your vehicle. If you need service work or installation, our service bays are second to none. You'll always get smiling friendly service at Tires To You, no matter why you're visiting.`}
          </Typography>
          <Typography {...styleProps.title}>
            {`Keep Your Tires and Vehicle in Great Shape`}
          </Typography>
          <Typography>
            {`We believe in outreach and education! We always try to educate our customers on the choices they're making, and how those choices may affect their vehicles and performance. We want you to have great wheels and tires that will last for years, while also preventing wear and tear on your vehicle!`}
          </Typography>
          <Typography {...styleProps.title}>
            {`We're Texas' Fastest-Growing Tire Shop For A Reason`}
          </Typography>
          <Typography>
            {`In just a few years, Tires To You has expanded across the state - including here in Woodway. Our combination of vast selection, great prices, friendly staff, and wide variety of free services simply can't be beat.`}
          </Typography>
          <Typography {...styleProps.contentNoTitle}>
            {`Whether you need new wheels, tires, or repair work, Tires To You is here for you. Click here to contact us or schedule an appointment!`}
          </Typography>
        </>
      );
    case storeNumber == "4003":
      return (
        <>
          <Typography {...styleProps.contentNoTitle}>
            {`For the lowest prices on new and used tires in the state of Texas, please visit our Tires To You location in Temple, TX. We have an ample selection of today's top brands, including Pirelli, Michelin, BFGoodrich, Continental, Accelera, and more. We provide warranties on both new and used tires sold from our Temple, TX shop, so you can buy with confidence. Whether you're searching for top-quality tires, rims, or wheels, you will find what you need in our inventory.`}
          </Typography>
          <Typography {...styleProps.contentNoTitle}>
            {`Tires To You is more than just a vendor of tires. A family-owned business, we go the extra mile to help our customers get the most out of their cars and trucks. We have a free five-minute alignment check that can detect whether your vehicle needs adjustment and offer six-month same-as-cash financing. A HUB-certified business, we provide discounts to local and state government agencies, as well as generous group rates for organizations that buy in bulk (e.g., school districts, used car dealerships). Come visit us at Tires To You—we look forward to helping you.`}
          </Typography>
          <Typography {...styleProps.title}>
            {`Tire Services & Our Temple TX Location`}
          </Typography>
          <Typography>
            {`We offer Temple Texas area residents a whole range of valuable New and used tire services to ensure that they'll enjoy the optimal use of their vehicles for years to come. Our list of products and services includes the following:`}
          </Typography>
          <ListItems
            items={[
              "New tires",
              "Used tires",
              "Aftermarket wheels & rims",
              "OEM wheels & rims",
              "Wheel balancing",
              "Tire rotation",
              "Wheel alignment",
              "Financing",
            ]}
          />
          <Typography {...styleProps.contentNoTitle}>
            {`Located in Temple, Texas, Tires To You serves these nearby communities:`}
          </Typography>
          <ListItems
            items={[
              "Temple, TX",
              "Belton, TX",
              "Copperas Cove, TX",
              "Killeen, TX",
              "Waco, TX",
              "Harker Heights, TX",
            ]}
          />
        </>
      );
    case storeNumber == "4004":
      return (
        <>
          <Typography {...styleProps.title}>
            {`Why Trust Us with Your Tires?`}
          </Typography>
          <Typography>
            {`Finding a reliable tire shop in San Antonio is crucial for your vehicle's performance and safety. Tires to You stands out as a trusted name in tire services. We offer expertise, quality tires, wheel alignment in San Antonio, and exceptional customer service, ensuring your vehicle is equipped with the best tires for your specific needs.`}
          </Typography>
          <Typography {...styleProps.title}>
            {`Tire Service and Wheel Alignment`}
          </Typography>
          <Typography>
            {`Regular tire service and wheel alignment are key to maintaining your vehicle's health and safety. At Tires to You, we provide comprehensive tire services, including inspections, rotations, and balancing. Our wheel alignment in San Antonio is top-notch, ensuring your vehicle drives smoothly and efficiently.`}
          </Typography>
          <Typography {...styleProps.title}>
            {`Your Tires Unlock Your Car's Potential`}
          </Typography>
          <Typography>
            {`The right tires can significantly enhance your car's performance and fuel efficiency. At Tires to You, we understand this and help you buy tires in San Antonio that are perfect for your vehicle. Our extensive selection caters to various driving conditions and preferences, unlocking your car's true potential.`}
          </Typography>
          <Typography {...styleProps.title}>
            {`Wheel Alignment Services`}
          </Typography>
          <Typography>
            {`Proper wheel alignment is essential for even tire wear, optimal handling, and safe driving. In San Antonio, our wheel alignment services are conducted by skilled technicians using advanced technology. We ensure your alignment is perfect, improving your driving experience and extending your tire's lifespan.`}
          </Typography>
          <Typography {...styleProps.title}>
            {`Visit Tires to You for the Best Tire Shop in San Antonio`}
          </Typography>
          <Typography>
            {`For all your tire needs in San Antonio, visit Tires to You. Our commitment to quality service, expert advice, and a wide selection of tires makes us the go-to tire shop in San Antonio. Whether you need to buy tires, require wheel alignment, or seek general tire advice, our friendly team is ready to assist you.`}
          </Typography>
        </>
      );
    case storeNumber == "4005":
      return (
        <>
          <Typography
            {...styleProps.contentNoTitle}
          >{`Tires To You tire shop and wheel store is a leader in offering name brand tires, wheels, and alignments for customers located in and around the Killeen, Texas and Forth Hood area. Killeen's Tires To You has well-trained staff specializing in the sale and installation of wheels and tires. Special wheels and rims compliment any tire or customer vehicle. When you want your vehicle to look and perform at its best, Tires To You in Killeen can help you. We are your one-stop tire store or wheel store for everything in tires and wheels! We bring you the most extensive array of tires and wheels to boost the power and style of your automobile. Tires To You serves as your most convenient source for custom wheels, tires and alignments, at the lowest prices, and with the most hassle-free customer assistance provided.`}</Typography>
        </>
      );
    case storeNumber == "4006":
      return (
        <>
          <Typography
            {...styleProps.title}
          >{`Visit our tire shop in Hutto, Austin!`}</Typography>
          <Box style={{width: "100%"}}>
            <Typography {...styleProps.subtitle}>{`Your Trusted Tire Shop`}</Typography>
          </Box>
          <Typography>{`At Tires To You in Austin, we know all about equipping your vehicle with the right tires and wheels. We pride ourselves in helping to keep our friends and neighbors of the greater Austin area safe on the road. Our purpose is providing high-quality tires, wheels, and tire services to our community, helping motorists stay safe. Let our tire experts help you find the best tires for your vehicle and how you drive. Trust us to remove the stress of tire problems and get your car back on the road safely.`}</Typography>
        </>
      );
    case storeNumber == "4007":
      return (
        <>
          <Typography {...styleProps.title}>
            {`Visit our tire shop in Research, Austin!`}
          </Typography>
            <Box style={{width: "100%"}}>
              <Typography {...styleProps.subtitle}>{`Your Trusted Tire Shop`}</Typography>
            </Box>
          <Typography>
            {`At Tires To You in Austin, we know all about equipping your vehicle
            with the right tires and wheels. We pride ourselves in helping to
            keep our friends and neighbors of the greater Austin area safe on
            the road. Our purpose is providing high-quality tires, wheels, and
            tire services to our community, helping motorists stay safe. Let our
            tire experts help you find the best tires for your vehicle and how
            you drive. Trust us to remove the stress of tire problems and get
            your car back on the road safely.`}
          </Typography>
        </>
      );
    default:
      return (
        <>
          <Typography {...styleProps.title}>
            {`About ${APP_ENV.appBrandName}`}
          </Typography>
          <Typography>
            {`${
              APP_ENV.appBrandName
            } is a full service auto repair facility where 
              you will find honest, ethical, and female friendly auto service 
            ${cityState ? cityState : "near you"}. ${
              APP_ENV.appBrandName
            } locations have 
            been keeping communities moving for years with quality automotive inspections, 
            repairs, and regularly scheduled maintenance services. Now our company is growing 
            across the nation to provide you with facilities that are equipped with state 
            of the art computer technology in order to diagnose and service your vehicle.`}
          </Typography>
        </>
      );
  }
};

export default AboutOurCompany;
