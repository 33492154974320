import create from "zustand";
import { getTireBrands } from "../../services/venom-service";
import { useEffect } from "react";

let promise;

const TireBrandStore = create((set, get) => {
  const initialState = {
    isReady: false,
    tireBrands: [],
  };

  const getTireBrandStoreAsync = async () => {
    const _state = get();
    if (!_state.isReady && !promise) {
      promise = (async () => {
        const tireBrands = await getTireBrands();
        set({ isReady: true, tireBrands });
        promise = null;
        return;
      })();
    }

    if (!_state.isReady) {
      await promise;
    }

    return get();
  };

  const getActiveTireBrand = async ({ brandEndPoint = null }) => {
    const { tireBrands } = await getTireBrandStoreAsync();
    return tireBrands.find((brand) => brand.brandEndPoint == brandEndPoint);
  };

  return {
    ...initialState,
    getTireBrandStoreAsync,
    getActiveTireBrand,
  };
});

export const useTireBrandStore = (selector = (store) => store) => {
  const [isReady, getTireBrandStoreAsync] = TireBrandStore((st) => [
    st.isReady,
    st.getTireBrandStoreAsync,
  ]);

  useEffect(() => {
    if (!isReady) {
      getTireBrandStoreAsync();
    }
  }, []);

  return TireBrandStore(selector);
};

export default TireBrandStore;
