import { Grid } from "@material-ui/core";
import useVehicleSelection from "../../../hooks/useVehicleSelection";
import ConditionalSelectionMobileDrawer from "./widgets/ConditionalSelectionMobileDrawer";
import SelectionFieldMobile from "./widgets/SelectionFieldMobile";
import SelectionFieldDesktop from "./widgets/SelectionFieldDesktop";
import LayoutStore from "../../../hooks/store/client/layout-store";

const SelectVehicle = (
  onComplete = (tireSize) =>
    console.warn(
      "You need to pass an onComplete function to SelectVehicleDesktop",
      tireSize
    )
) => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);
  const state = useVehicleSelection(onComplete);

  return {
    component: (
      <>
        <Grid container>
          {state.fields.map((field, i) => {
            const width = i < 2 ? 6 : 12;
            return (
              <Grid item xs={width} key={field.key} style={{ padding: 8 }}>
                {isMobile ? (
                  <SelectionFieldMobile {...field} />
                ) : (
                  <SelectionFieldDesktop
                    {...field}
                    setAllFieldsInactive={state.setAllFieldsInactive}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
        {isMobile && <ConditionalSelectionMobileDrawer {...state} />}
      </>
    ),
    handleSubmit: state.handleSubmit,
  };
};

export default SelectVehicle;
