import { Box, Divider, Paper, Button } from "@material-ui/core";
import { useCartStore, CART_ITEM_TYPE } from "../../hooks/store/cart-store";
import CartItemCard from "./widgets/cart-item-card";
import TirePackageDetails from "../tires/tire-package-details";
import BrandModelSize from "../tires/widgets/brand-model-size";
import StaticCartItemDescription from "./widgets/static-cart-item-description";
import QtySelector from "../tires/widgets/qty-selector";
import CartHeader from "./widgets/cart-header";
import ServiceCouponCallOut from "../promotions/widgets/service-coupon-call-out";

const OrderDetails = ({ onEditCartClick = () => null }) => {
  const [cartItems] = useCartStore((st) => [st.cartItems]);

  return (
    <>
      <CartHeader cartItemQty={cartItems?.length}>
        <Button onClick={onEditCartClick} color="primary">
          Edit Cart
        </Button>
      </CartHeader>
      <Paper elevation={4}>
        {cartItems.map((item, i) => {
          return (
            <Box key={i} style={{ padding: 8 }}>
              {item.cartItemTypeId === CART_ITEM_TYPE.tire && (
                <>
                  <CartItemCard
                    itemImage={item.itemModelImage}
                    itemPrice={item.price}
                    qtyComponent={
                      <QtySelector
                        disableSelection={true}
                        selectedQty={item._cartQty}
                      />
                    }
                  >
                    <BrandModelSize {...item} />
                  </CartItemCard>
                  <TirePackageDetails activeTireByQty={item} />
                </>
              )}

              {item.cartItemTypeId !== CART_ITEM_TYPE.tire && (
                <>
                  <CartItemCard
                    itemImage={item.image}
                    itemPrice={item?.price ?? 0}
                    qtyComponent={<QtySelector disableSelection={true} />}
                  >
                    <StaticCartItemDescription {...item} />
                  </CartItemCard>
                  <Box style={{ marginBottom: 10 }}>
                    <ServiceCouponCallOut {...item} />
                  </Box>
                </>
              )}
              {i !== cartItems.length - 1 && (
                <Divider style={{ height: 2, marginTop: 15 }} />
              )}
            </Box>
          );
        })}
      </Paper>
    </>
  );
};

export default OrderDetails;
