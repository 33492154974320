import { Paper, Button, Box, Typography } from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import useOpenTireSizeSelector from "../../../hooks/util/useOpenTireSizeSelector";
import APP_ENV from "../../../appEnvironment";
import { useLocationStore } from "../../../hooks/store/location-store";

const { theme } = APP_ENV;

const MainAlertBar = () => {
  const openTireModal = useOpenTireSizeSelector({ pushToTiresPage: true });

  const [userStore] = useLocationStore((st) => [st.userStore]);

  const { sitePromoColors, displayText, SitePromoIcon } =
    userStore?.promotion ?? {};

  return (
    <>
      {userStore && (
        <Paper
          component={Button}
          onClick={openTireModal}
          style={{
            height: 60,
            backgroundColor: sitePromoColors?.primary,
            width: "100%",
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box style={{ marginRight: 12, marginTop: 3 }}>
              <SitePromoIcon style={{ color: "white", fontSize: 20 }} />
            </Box>
            <Box>
              <Typography
                style={{ fontWeight: "bold", fontSize: 15, color: "white" }}
              >
                {`${displayText?.alertBar ?? ""}`}
              </Typography>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  style={{
                    color: theme.palette.common.white,
                    marginRight: 5,
                    fontSize: 13,
                  }}
                >
                  Most Tire Sizes - Shop Now
                </Typography>
                <ChevronRightRoundedIcon
                  style={{ color: theme.palette.common.white }}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default MainAlertBar;
