import { Box, IconButton, Typography, Link } from "@material-ui/core";
import DirectionsRoundedIcon from "@material-ui/icons/DirectionsRounded";

const AddressWithLink = ({
  streetAddress,
  city,
  stateAbbreviation,
  zipCode,
  googleDirectionsUrl = "",
}) => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box style={{ marginRight: 10 }}>
        <Typography style={{ fontSize: 15 }}>{streetAddress}</Typography>
        <Typography
          style={{ fontSize: 15 }}
        >{`${city}, ${stateAbbreviation} ${zipCode}`}</Typography>
      </Box>
      <IconButton
        href={googleDirectionsUrl}
        target={"_blank"}
        component={Link}
        color={"primary"}
      >
        <Box style={{ textAlign: "center", display: "block" }}>
          <DirectionsRoundedIcon />
          <Typography style={{ fontSize: 13 }}>Directions</Typography>
        </Box>
      </IconButton>
    </Box>
  );
};

export default AddressWithLink;
