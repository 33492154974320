import * as React from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import FiberManualRecordTwoToneIcon from "@material-ui/icons/FiberManualRecordTwoTone";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const OpenClosedDisplayMessage = ({
  fontSize,
  currentStatusDisplayMessage,
  isOpenBusinessHours,
}) => {
  const iconProps = {
    style: {
      marginRight: 5,
      color: isOpenBusinessHours
        ? theme.palette.success.main
        : theme.palette.error.main,
      fontSize: fontSize + 3,
    },
  };
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      {isOpenBusinessHours ? (
        <>
          <CheckCircleRoundedIcon {...iconProps} />
          <Typography
            style={{
              color: theme.palette.success.main,
              fontSize,
              fontWeight: "bold",
            }}
          >
            Open
          </Typography>
        </>
      ) : (
        <>
          <FiberManualRecordTwoToneIcon {...iconProps} />
          <Typography
            style={{
              color: theme.palette.error.main,
              fontSize,
              fontWeight: "bold",
            }}
          >
            Closed
          </Typography>
        </>
      )}
      <Box
        style={{
          height: 15,
          width: 15,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Divider orientation="vertical" />
      </Box>
      <Typography style={{ fontSize }}>
        {currentStatusDisplayMessage}
      </Typography>
    </Box>
  );
};

export default OpenClosedDisplayMessage;
