import { Grid, Typography, Box } from "@material-ui/core";
import LayoutStore from "../../../hooks/store/client/layout-store";
import BulletPoint from "../../generic/bullet-point";
import BulletIcon from "../../generic/bullet-icon";
import BuildIcon from "@material-ui/icons/Build";
import WifiIcon from "@material-ui/icons/Wifi";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import WeekendIcon from "@material-ui/icons/Weekend";
import TodayIcon from "@material-ui/icons/Today";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { CREDIT_PAGE_ENDPOINT } from "../../../hooks/store/credit-store";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const SingleLocationOfferings = ({ businessDescriptionLong = "" }) => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const serviceBulletPoints = [
    {
      text: "Tire & Service Repair",
      link: "/car-services",
    },
    {
      text: "Oil Change",
      link: "/oilrecommendations",
    },
    {
      text: "Wheel Alignment",
      link: "/car-services/wheel-alignment-check",
    },
    {
      text: "Brake Repair",
      link: "/car-services/brake-check",
    },
    {
      text: "Battery Replacement",
      link: "/car-services/battery-check",
    },
    {
      text: "Suspension and Steering Repair",
    },
    {
      text: "TPMS Repair",
    },
    {
      text: "Factory Scheduled Maintenance",
    },
    {
      text: "Custom Wheels",
      link: "/wheels",
    },
    {
      text: "Air Conditioning Diagnostics & Repair",
      link: "/car-services/cooling-system-check",
    },
  ].filter(
    (bulletpoint) =>
      !Boolean(
        APP_ENV.appBrandId === 4 &&
          [
            "Oil Change",
            "Brake Repair",
            "Battery Replacement",
            "Suspension and Steering Repair",
            "TPMS Repair",
            "Factory Scheduled Maintenance",
            "Air Conditioning Diagnostics & Repair",
          ].includes(bulletpoint.text)
      )
  );

  return (
    <Grid
      container
      style={{ maxWidth: theme.breakpoints.values.md, padding: 20 }}
    >
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography variant="h3" style={{ marginBottom: 25 }}>
          {`${APP_ENV.appBrandName} - Our Services`}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={!isMobile ? { display: "flex", justifyContent: "center" } : {}}
      >
        <Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <BuildIcon
              style={{
                marginRight: 15,
                color: theme.palette.primary.main,
                fontSize: 25,
              }}
            />
            <Typography variant="h6">Services Offered</Typography>
          </div>
          <ul style={{ marginRight: 30 }}>
            {serviceBulletPoints.map((item, index) => (
              <BulletPoint key={index} text={item.text} link={item.link} />
            ))}
          </ul>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={!isMobile ? { display: "flex", justifyContent: "center" } : {}}
      >
        <Box>
          <Typography variant="h6" style={{ marginTop: 20, marginBottom: 20 }}>
            Store Amenities
          </Typography>

          <BulletIcon text="Free Wifi" Icon={WifiIcon} />
          <BulletIcon text="Free Hot Coffee" Icon={FreeBreakfastIcon} />
          <BulletIcon text="Comfortable Waiting Area" Icon={WeekendIcon} />
          <BulletIcon text="Same-Day Appointments" Icon={TodayIcon} />
          <BulletIcon
            text="Special Financing Available"
            Icon={CreditCardIcon}
            link={CREDIT_PAGE_ENDPOINT}
          />
        </Box>
      </Grid>
      {businessDescriptionLong && (
        <Grid container style={{ marginTop: 10 }}>
          <Typography variant="body1" component={"p"}>
            {businessDescriptionLong}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SingleLocationOfferings;
