import { Box, Typography, ListItem, ListItemText } from "@material-ui/core";
import TireInstallCollapsible from "./widgets/tire-install-collapsible";
import TireItemDiscountsCollapsible from "../promotions/full-site-tire-sale/tire-item-discounts-collapsible";
import { roundMoney } from "../../helpers/generic";

const TirePackageDetails = ({ activeTireByQty }) => {
  const { _tiresSubtotal = 0, _cartQty, price } = activeTireByQty;

  return (
    <Box style={{ width: "100%" }}>
      <ListItem
        style={{
          display: "flex",
          alignItems: "center",
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <ListItemText>
          <Typography style={{ fontSize: 14 }}>Tires</Typography>
        </ListItemText>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: 8,
          }}
        >
          <Typography
            style={{
              fontSize: 10,
              fontWeight: 100,
              textAlign: "right",
              marginRight: 3,
            }}
          >
            {`Qty ${_cartQty} @`}
          </Typography>
          <Typography
            variant="h4"
            style={{
              textAlign: "right",
              fontWeight: 500,
              fontSize: 10,
            }}
          >
            {`$${roundMoney(price).toFixed(2)}`}/ea.
          </Typography>
        </Box>

        <Typography
          style={{
            minWidth: 75,
            marginRight: 5,
            fontSize: 14,
            textAlign: "right",
          }}
        >{`$${_tiresSubtotal.toFixed(2)}`}</Typography>
      </ListItem>

      <TireInstallCollapsible {...activeTireByQty} />
      <TireItemDiscountsCollapsible activeTireByQty={activeTireByQty} />
    </Box>
  );
};

export default TirePackageDetails;
