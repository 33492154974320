import { Grid, Typography } from "@material-ui/core";
import DefaultContainer from "./default-container";

const StandardPageHeader = ({
  title,
  subtitle = "",
  children,
  subtitleSx = {},
}) => {
  return (
    <DefaultContainer>
      <Grid container style={{ marginBottom: 10, marginTop: 15 }}>
        <Grid container style={{ padding: 5 }}>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography style={subtitleSx}>{subtitle}</Typography>
          </Grid>
        </Grid>
        {children}
      </Grid>
    </DefaultContainer>
  );
};

export default StandardPageHeader;
