import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Link,
  Divider,
  ListItemIcon,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import APP_ENV from "../../../appEnvironment";
import { useLocationStore } from "../../../hooks/store/location-store";

const { theme } = APP_ENV;

const InventoryStatusByQty = ({
  _cartQty = 0,
  _qtyIsAvailable = false,
  _qtyIsInStock = false,
  _availabilityLabel = "",
  _orderBeforeDateTimeDisplay = "",
  _arrivesDateTimeDisplay = "",
}) => {
  const [userStore] = useLocationStore((st) => [st.userStore]);

  const { phoneNumber = "", webAlias = "" } = userStore ?? {};

  const isDeliveryOnly = Boolean(!_qtyIsInStock && _qtyIsAvailable);

  const isInStock = _qtyIsInStock;
  const isAvail = !isInStock && _qtyIsAvailable;
  const notAvail = !isInStock && !isAvail;

  return (
    <>
      <List
        style={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 8,
          marginBottom: 5,
        }}
      >
        <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItemText>
            <Typography style={{ fontSize: 12 }}>Inventory Status</Typography>
          </ListItemText>
        </ListItem>
        <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItemText>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <FiberManualRecordIcon
                style={{
                  marginRight: 10,
                  color: notAvail
                    ? theme.palette.common.black
                    : theme.palette.success.main,
                }}
              />
              <Box>
                {isInStock && (
                  <>
                    <strong
                      style={{ fontSize: 18 }}
                    >{`${_cartQty} In Stock`}</strong>
                    <span style={{ fontSize: 18 }}>{` at ${webAlias}`}</span>
                  </>
                )}
                {isAvail && (
                  <>
                    <strong
                      style={{ fontSize: 18 }}
                    >{`${_cartQty} In Stock`}</strong>
                    <span style={{ fontSize: 18 }}>{` Nearby`}</span>
                  </>
                )}
                {notAvail && (
                  <>
                    <span>{`${_cartQty} Tires - `}</span>
                    <Link
                      href={`tel:${phoneNumber}`}
                      style={{ fontWeight: 600 }}
                    >
                      Call for Availability
                    </Link>
                  </>
                )}
              </Box>
            </Box>
          </ListItemText>
        </ListItem>
        {isAvail && (
          <>
            {[
              ["Arrives", _arrivesDateTimeDisplay],
              ["Order By", _orderBeforeDateTimeDisplay],
            ].map(([key, value]) => (
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }} key={key}>
                <ListItemText>
                  <span style={{ fontSize: 13 }}>{`${key}: `}</span>
                  <span style={{ fontSize: 14, fontWeight: 600 }}>{value}</span>
                </ListItemText>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </>
  );
};

export default InventoryStatusByQty;
