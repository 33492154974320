import { Box } from "@material-ui/core";
import TireBrandModelHeader from "../../components/tires/tire-brand-model-header";
import SemiCollapseableDescription from "../../components/generic/semi-collapseable-description";
import APP_ENV from "../../appEnvironment";
import DefaultContainer from "../../components/generic/default-container";
import TireReviewController from "../../components/tire-reviews/tire-review-controller";
import useTireItemsByBrandModel from "../../hooks/useTireItemsByBrandModel";

const { theme } = APP_ENV;

const TireItemReview = () => {
  const { tireBrand, tireModel } = useTireItemsByBrandModel();

  return (
    <>
      {tireBrand && (
        <>
          <TireBrandModelHeader tireBrand={tireBrand} tireModel={tireModel} />
          <DefaultContainer>
            <SemiCollapseableDescription
              description={tireModel?.itemModelDescription}
            />
          </DefaultContainer>
          <DefaultContainer backgroundColor={theme.palette.background.default}>
            <Box style={{ paddingTop: 20, paddingBottom: 20 }}>
              <TireReviewController isWriteReview={true} />
            </Box>
          </DefaultContainer>
        </>
      )}
    </>
  );
};

export default TireItemReview;
