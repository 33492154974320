import {
  TextField,
  InputAdornment,
  Popover,
  Chip,
  Box,
  Button,
  ClickAwayListener,
} from "@material-ui/core";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { useEffect, useState } from "react";
import APP_ENV from "../../../../appEnvironment";

const { theme } = APP_ENV;

const SelectionFieldDesktop = ({
  isDisabled,
  label,
  selection,
  setActive,
  hasError,
  isActive,
  setAllFieldsInactive,
  options,
}) => {
  const [state, setState] = useState({
    elementId: Math.random().toString(),
    anchorEl: null,
    buttonWidth: 0,
    containerWidth: 0,
  });

  useEffect(() => {
    let anchorEl = null;
    let buttonWidth = 0;
    let containerWidth = 0;
    if (isActive && options.length) {
      anchorEl = document.getElementById(state.elementId);
      buttonWidth = Math.max(
        ...[100, ...options.map(({ label }) => label.toString().length * 10)]
      );

      containerWidth = buttonWidth;

      const { length } = options;
      if (length > 15) {
        if (buttonWidth <= 100) {
          containerWidth = buttonWidth * 5;
        } else {
          containerWidth = buttonWidth * 3;
        }
      }
    }

    setState((prev) => ({ ...prev, anchorEl, buttonWidth, containerWidth }));
  }, [isActive, options.length]);

  return (
    <>
      <TextField
        id={state.elementId}
        fullWidth
        disabled={isDisabled}
        error={hasError}
        focused={false}
        label={label}
        type="button"
        size={"medium"}
        value={selection?.label ?? label}
        onClick={setActive}
        variant={"outlined"}
        style={{ backgroundColor: theme.palette.background.paper }}
        InputProps={{
          style: {
            cursor: "pointer",
          },
          endAdornment: (
            <InputAdornment position="end">
              {isActive ? (
                <ExpandLessRoundedIcon style={{ fontSize: 20 }} />
              ) : (
                <ExpandMoreRoundedIcon style={{ fontSize: 20 }} />
              )}
            </InputAdornment>
          ),
        }}
      />
      {isActive && (
        <ClickAwayListener onClickAway={setAllFieldsInactive}>
          <Popover
            open={Boolean(state.anchorEl)}
            anchorEl={state.anchorEl}
            onClose={setAllFieldsInactive}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: options.length > 15 ? "left" : "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: options.length > 15 ? "left" : "center",
            }}
          >
            <Box
              style={{
                width: state.containerWidth + 50,
                objectFit: "contain",
                padding: 10,
                paddingBottom: 20,
              }}
            >
              {options.map((option) => (
                <Button
                  key={option.label}
                  onClick={option.setSelected}
                  variant={option.isSelected ? "contained" : "text"}
                  color={option.isSelected ? "primary" : "inherit"}
                  style={{
                    fontSize: 14,
                    width: state.buttonWidth,
                    minWidth: "max-content",
                    marginTop: 5,
                    justifyContent: "flex-start",
                    whiteSpace: "nowrap",
                  }}
                >
                  {option.label}
                </Button>
              ))}
            </Box>
          </Popover>
        </ClickAwayListener>
      )}
    </>
  );
};

export default SelectionFieldDesktop;
