import MetaTags from "../../reusable/ReactMetaTags";
import APP_ENV from "../../../appEnvironment";

const EngineCheck = () => {
  return (
    <>
      <MetaTags
        description={`At ${APP_ENV.appBrandName} we can diagnose and fix most check engine lights
                within one day. Same day appointments available.`}
        canonical="/car-services/engine-check"
        title={`Engine Check | ${APP_ENV.appBrandName}`}
      />
      <div>
        <p>
          <span>
            <strong>General Engine Check Services</strong>
          </span>
        </p>
        <p>
          {`If you have noticed a check engine light on, your car is running
                    rough, or something just does not feel right, you may need to have
                    your engine checked. At ${APP_ENV.appBrandName} we have highly
                    trained mechanics with industry leading tools availble to help
                    diagnose your problem.`}
        </p>
        <span>
          <strong>Common engine problems include:</strong>
        </span>
        <ol>
          <li>Worn spark plugs</li>
          <li>Bad fuel pump</li>
          <li>Vacuum leaks</li>
          <li>Engine oil leaks</li>
          <li>Failed Oxygen Sensors</li>
          <li>Exhaust leaks</li>
          <li>Failed Mass Air Flow sensor</li>
          <li>Failed Catalytic Converters</li>
        </ol>
        <p>
          <span>
            <strong>How much will it cost to inspect my engine?</strong>
          </span>
        </p>
        <p>
          {`At ${APP_ENV.appBrandName} we do many inspections for free. Some
                    engine problems take longer than others to diagnose, and we do
                    sometimes need to charge an inspection fee for more difficult
                    inspections. If you would like to discuss your vehicle's engine
                    problems, please call us at ${APP_ENV.appPhoneNumber} and one of our trained staff
                    members may be able to determine over the phone the difficulty of your
                    inspection.`}
        </p>
      </div>
    </>
  );
};

export default EngineCheck;
