import { Box, Typography, Link } from "@material-ui/core";

const TireItemMailInRebateCollection = ({
  _mailInRebateTotal = 0,
  _mailInRebates = [],
}) => {
  return (
    <>
      {_mailInRebateTotal > 0 && (
        <>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 600,
              width: "100%",
              marginTop: 5,
            }}
          >
            Mail-In Rebates
          </Typography>

          {_mailInRebates.map((rebate, i) => (
            <Box key={i} style={{ marginBottom: 10 }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ fontSize: 11, marginRight: 8 }}>
                  {rebate.rebateDescription}
                </Typography>
                <Typography
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >{`-$${rebate.rebateAmount.toFixed(2)}`}</Typography>
              </Box>
              <Box>
                <Link
                  underline="always"
                  style={{ fontSize: 12 }}
                  href={rebate?.pdf ?? rebate?.image}
                  target={"_blank"}
                >
                  View Details
                </Link>
              </Box>
            </Box>
          ))}
        </>
      )}
    </>
  );
};

export default TireItemMailInRebateCollection;
