import { Box, Typography, Divider } from "@material-ui/core";
import TireItemMailInRebateCollection from "./tire-item-mail-in-rebate-collection";

const TireItemDiscountsCollection = ({ activeTireByQty }) => {
  const {
    _promotion = {},
    _totalDiscountsAvailable = 0,
    _instantRebateDiscountsTotal = 0,
    instantRebateAmount = 0,
  } = activeTireByQty;

  const hasPromotion = _promotion?.promoType;

  return (
    <>
      {_totalDiscountsAvailable > 0 && (
        <Box style={{ width: "100%" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
              {_promotion?.displayText?.tireSaleName}
            </Typography>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: _promotion?.sitePromoColors?.tertiary,
              }}
            >{`-$${_totalDiscountsAvailable.toFixed(2)}`}</Typography>
          </Box>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />

          {_instantRebateDiscountsTotal > 0 && (
            <>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography style={{ fontSize: 12, fontWeight: 600 }}>
                  {hasPromotion
                    ? _promotion.displayText.tirePromoBox
                    : "Instant Savings"}
                </Typography>
                <Typography style={{ fontSize: 11, fontWeight: 600 }}>
                  {`-$${_instantRebateDiscountsTotal.toFixed(2)}`}
                </Typography>
              </Box>
              {hasPromotion && (
                <>
                  {["gen_b3g1", "col_b3g1"].includes(_promotion.promoType) && (
                    <Typography style={{ fontSize: 10 }}>
                      {`Offer valid with purchase of in-store installation and Tire Maintenance Certificates.*`}
                    </Typography>
                  )}

                  {_promotion.promoType == "focus_b2g2" && (
                    <Typography style={{ fontSize: 10 }}>
                      {`Offer valid with purchase of in-store installation, Tire Maintenance Certificates & wheel alignment. $300 maximum discount.*`}
                    </Typography>
                  )}

                  {_promotion.promoType == "d18_b2g2" && (
                    <Typography style={{ fontSize: 10 }}>
                      {`Offer valid with purchase of in-store installation & Tire Maintenance Certificates. $300 maximum discount.*`}
                    </Typography>
                  )}
                  {Boolean(instantRebateAmount > 0) && (
                    <Typography
                      style={{ fontSize: 10, marginTop: 5, fontWeight: 600 }}
                    >
                      {`Don't need 4 tires? Save $${instantRebateAmount.toFixed(
                        2
                      )} per tire. In-store installation required. No minimum purchase.`}
                    </Typography>
                  )}
                </>
              )}
              {!hasPromotion && (
                <Typography style={{ fontSize: 11 }}>
                  {`Save $${instantRebateAmount.toFixed(
                    2
                  )} per tire. In-store installation required. No minimum purchase.`}
                </Typography>
              )}
            </>
          )}

          <TireItemMailInRebateCollection {...activeTireByQty} />
        </Box>
      )}
    </>
  );
};

export default TireItemDiscountsCollection;
