import { Typography, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import APP_ENV from "../../appEnvironment";
import { useAppointmentStore } from "../../hooks/store/appointment-store";
import CartStore from "../../hooks/store/cart-store";
import PackageSubtotalWithInfo from "./widgets/package-subtotal-with-info";
import SubTotalWithStrikeThrough from "./widgets/subtotal-with-strike-through";
import PaymentNotRequired from "./widgets/payment-not-required";
import MonthlyFinanceAmount from "./widgets/monthy-finance-amount";
import WebDepositRequired from "./widgets/web-deposit-required";

const { theme } = APP_ENV;

const CartSummary = ({ children, showNextAvailableApptTime = true }) => {
  const {
    subtotal,
    packageSubtotal,
    totalDiscountsAvailable,
    invoicePrice,
    requiresSpecialOrder,
    tax,
    stfTotal,
    fetTotal,
  } = CartStore();

  const cart = CartStore();

  const [soonestApptDateTimeDisplay] = useAppointmentStore((st) => [
    st.soonestApptDateTimeDisplay,
  ]);

  return (
    <>
      <Box
        style={{
          textAlign: "center",
          margin: 10,
          marginBottom: 20,
        }}
      >
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PackageSubtotalWithInfo />
        </Box>
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: 8,
          }}
        >
          <SubTotalWithStrikeThrough
            subtotal={subtotal}
            displayPrice={packageSubtotal}
          />
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <MonthlyFinanceAmount
            amount={invoicePrice - (tax + stfTotal + fetTotal)}
          />
        </Box>

        {/* {!requiresSpecialOrder ? (
          <PaymentNotRequired />
          ) : (
            <WebDepositRequired />
            )} */}

        <PaymentNotRequired />

        {Boolean(totalDiscountsAvailable) && (
          <Box
            style={{
              width: "100%",
              marginBottom: 8,
            }}
          >
            <Typography style={{ fontWeight: 600 }}>Total Savings</Typography>
            <Typography
              gutterBottom
              style={{
                fontWeight: "bold",
                color: theme.palette.error.main,
                fontSize: 18,
              }}
            >
              {`-$${totalDiscountsAvailable.toFixed(2)}`}
            </Typography>
          </Box>
        )}

        {showNextAvailableApptTime && (
          <>
            <Typography style={{ fontWeight: 600, paddingBottom: 6 }}>
              Next Available Appointment
            </Typography>
            <Typography style={{ fontSize: 12, paddingBottom: 8 }}>
              As early as
            </Typography>
            {!Boolean(soonestApptDateTimeDisplay) && (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  variant="rect"
                  width={200}
                  height={25}
                  style={{ marginBottom: 8 }}
                />
              </Box>
            )}
            {Boolean(soonestApptDateTimeDisplay) && (
              <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                {soonestApptDateTimeDisplay}
              </Typography>
            )}
          </>
        )}
      </Box>
      {children}
    </>
  );
};

export default CartSummary;
