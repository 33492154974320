import { Typography, MenuItem, Box, TextField } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const QtySelector = ({
  selectedQty = 1,
  qtySelectOptions = [1],
  onQtyChange = () => null,
  disableSelection = false,
}) => {
  return (
    <Box
      style={{
        width: 50,
        height: 40,
        border: `1px solid ${theme.palette.divider}`,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          marginTop: -10,
          cursor: "default",
        }}
      >
        <Box
          style={{
            paddingLeft: 4,
            paddingRight: 4,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography
            style={{ fontSize: 12, color: theme.palette.primary.dark }}
          >
            Qty
          </Typography>
        </Box>

        {disableSelection && (
          <Typography style={{ fontWeight: 600 }}>{selectedQty}</Typography>
        )}
        {!disableSelection && (
          <TextField
            select
            value={selectedQty}
            onChange={(e) => onQtyChange(e.target.value)}
            style={{ fontWeight: 600 }}
          >
            {qtySelectOptions.map((val) => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
    </Box>
  );
};

export default QtySelector;
