import create from "zustand";
import { LoadScript } from "@react-google-maps/api";
import APP_ENV from "../../../appEnvironment";

const GoogleServicesStore = create((set, get) => {
  const initialState = {
    isReady: false,
    placesAutoComplete: null,
    mapGeoCoder: null,
  };

  return {
    ...initialState,
    onGoogleScriptsLoad: () =>
      set({
        isReady: true,
        placesAutoComplete: new window.google.maps.places.AutocompleteService(),
        mapGeocoder: new window.google.maps.Geocoder(),
      }),
  };
});

const GOOGLE_LIBRARIES = ["places", "geometry", "drawing"];

export const GoogleLibrariesLoadAsync = () => {
  const [onGoogleScriptsLoad] = GoogleServicesStore((st) => [
    st.onGoogleScriptsLoad,
  ]);

  return (
    <LoadScript
      googleMapsApiKey={APP_ENV.googleMapsApiKey}
      libraries={GOOGLE_LIBRARIES}
      onLoad={onGoogleScriptsLoad}
      loadingElement={<></>}
    />
  );
};

export default GoogleServicesStore;
