import { useEffect } from "react";
import create from "zustand";
import {
  getWheelBrands,
  validateVehicle,
} from "../../services/autosync-service";

let promise;

const WheelBrandStore = create((set, get) => {
  const initialState = {
    isReady: false,
    wheelBrands: [],
  };

  const getWheelBrandStoreAsync = async () => {
    const _state = get();
    if (!_state.isReady && !promise) {
      promise = (async () => {
        const wheelBrands = await getWheelBrands();
        set({ isReady: true, wheelBrands });
        promise = null;
        return;
      })();
    }

    if (!_state.isReady) {
      await promise;
    }

    return get();
  };

  const getValidVehicle = async (vehicle) => {
    const validVehicle = validateVehicle(vehicle);
    return validVehicle;
  };

  return {
    ...initialState,
    getWheelBrandStoreAsync,
    getValidVehicle,
  };
});

export const useWheelBrandStore = (selector = (store) => store) => {
  const [isReady, getWheelBrandStoreAsync] = WheelBrandStore((st) => [
    st.isReady,
    st.getWheelBrandStoreAsync,
  ]);

  useEffect(() => {
    if (!isReady) {
      getWheelBrandStoreAsync();
    }
  }, []);

  return WheelBrandStore(selector);
};

export default WheelBrandStore;
