import LayoutStore from "../store/client/layout-store";
import ModalStore from "../store/modal-store";

const useOpenVehicleSelector = () => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const [openMobile, openDesktop] = ModalStore((st) => [
    st.SelectVehicleOnlyDrawerMobile.openModal,
    st.SelectVehicleOnlyModalDesktop.openModal,
  ]);

  const openModal = () => {
    isMobile ? openMobile() : openDesktop();
  };

  return openModal;
};

export default useOpenVehicleSelector;
