import * as React from "react";
import MetaTags from "../components/reusable/ReactMetaTags.jsx";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import APP_ENV from "../appEnvironment.js";
import { Grid, Paper, Typography } from "@material-ui/core";
import StandardPageHeader from "../components/generic/standard-page-header.js";
import DefaultContainer from "../components/generic/default-container.js";

const theme = APP_ENV.theme;

const FleetServices = () => {
  return (
    <>
      <MetaTags
        description={`${APP_ENV.appBrandName} offers a wide array of maintenance and repair services to help keep your fleet vehicles running smoothly. Contact us today!`}
        canonical="/fleet-services"
        title={`Commercial Fleet Services | ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title="Commercial Fleet Services"
        subtitle={`${APP_ENV.appBrandName} offers a wide array of maintenance and repair services to help keep your fleet vehicles running smoothly. Contact us today!`}
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 25,
            paddingBottom: 40,
          }}
        >
          <Grid item xs={11} sm={10} md={10} lg={10}>
            <Paper elevation={8} style={{ padding: "1.7em" }}>
              <Grid container>
                <Grid item xs={12}>
                  <img
                    style={{ width: "100%" }}
                    className="img-responsive"
                    src="https://venomstorage.blob.core.windows.net/bbtwebblob/images/hero-banner-fleet.jpg"
                  />
                  <Typography style={{ marginTop: "20px" }} gutterBottom>
                    One-call, one stop option for maintenance.
                  </Typography>
                  <Typography>
                    {`${APP_ENV.appBrandName} has built on of the most comprehensive fleet management systems available today.
                                    At every stage of the fleet maintenance management process we have addressed the service, data, and payment
                                    needs of the fleet manager and built controls that help offer real savings in both time and money. Combined
                                    fleet management capabilities, universal credit products and a premier network of service unlike any other.
                                    Complete Vehicle and Repair Services At ${APP_ENV.appBrandName}, we offer an array of maintenance and repair
                                    services to help keep your fleet vehicles running smoothly.`}
                  </Typography>
                </Grid>
              </Grid>
              <Box my="4rem">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <img
                      style={{ width: "100%" }}
                      className="img-responsive"
                      src="https://venomstorage.blob.core.windows.net/bbtwebblob/images/fleet-generic.jpg"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box padding="2rem">
                      <Typography variant="h4" gutterBottom>
                        Fleet Advantage
                      </Typography>
                      <Typography>
                        A full range of services created specifically for
                        businesses like yours:
                      </Typography>
                      <ul>
                        <li>Competitive Fleet Service Pricing – Every Day</li>
                        <li>
                          The Fleet Manager’s Ability to set Card and System
                          Level Purchasing Controls
                        </li>
                        <li>Centralized and Consolidated Billing</li>
                        <li>
                          A Comprehensive View of Purchase History and Invoices
                        </li>
                        <li>The Convenience of Making an Online Appointment</li>
                      </ul>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box my="4rem">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Box padding="2rem">
                      <Typography gutterBottom variant="h4">
                        Fleet Services
                      </Typography>
                      <Typography>
                        A full range of services created specifically for
                        businesses like yours:
                      </Typography>
                      <ul>
                        <li>
                          A One-stop-shop with Expert Knowledge and Experience
                          Ranging from Routine Car and
                          <br />
                          Truck Fleet Maintenance to Major Repairs{" "}
                        </li>
                        <li>
                          Comprehensive Estimates before Performing Fleet Repair
                          Work
                        </li>
                        <li>State-of-the-art Equipment and Tools</li>
                        <li>Honoring of Warranties on Parts and Labor</li>
                        <li>
                          On-site Detailed Weekly Fleet Inspections Available
                        </li>
                        <li>
                          A Free Multi-point Inspection with every Service
                        </li>
                      </ul>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <img
                      style={{ width: "100%" }}
                      className="img-responsive"
                      src="https://venomstorage.blob.core.windows.net/bbtwebblob/images/fleet-van.jpg"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box my="4rem">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <img
                      style={{ width: "100%" }}
                      className="img-responsive"
                      src="https://venomstorage.blob.core.windows.net/bbtwebblob/images/fleet-van.jpg"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box padding="2rem">
                      <Typography variant="h4" gutterBottom>
                        Location, Location, Location
                      </Typography>
                      <Typography>
                        {`We know downtime is a concern and understand how unscheduled repairs negatively
                                    impact your company’s productivity. Your drivers can drive in anytime and we will be happy to get their vehicle
                                    serviced and back on the road, or if you prefer to schedule an appointment, we will work with you to bring your
                                    vehicle to ${APP_ENV.appBrandName} when it’s most convenient for you.`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* <Grid container>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography gutterBottom variant="h4">
                      Set up Your Fleet Account
                    </Typography>
                    <Box my="2rem" display="flex" justifyContent="center">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          window.open(
                            "http://edge.bigbrandtire.com/Administration/Accounting/CreditApplication",
                            "Credit App",
                            "width=1200,height=700,left=100,top=50,resizable=yes,scrollbars=yes"
                          );
                        }}
                      >
                        Apply Now
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid> */}
            </Paper>
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default FleetServices;
