import { Box, Typography, Link, Button } from "@material-ui/core";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import InfoIcon from "@material-ui/icons/Info";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const AppointmentInventoryArrivalInfo = ({
  isInventoryInStock = false,
  isInventoryAvailable = false,
  inventoryArrivesDateTimeDisplay = "",
  userStore = {},
}) => {
  const inventoryNotAvailable = !isInventoryInStock && !isInventoryAvailable;
  const inventoryByDelivery = !inventoryNotAvailable && !isInventoryInStock;

  const { webAlias, phoneNumber, cleanPhoneNumber } = userStore;

  return (
    <>
      <Box style={{ display: "flex", alignItems: "center" }}>
        {isInventoryAvailable ? (
          <ThumbUpIcon style={{ color: theme.palette.success.main }} />
        ) : (
          <InfoIcon />
        )}

        {!inventoryNotAvailable && (
          <Typography
            style={{
              marginLeft: 8,
              fontSize: 14,
              fontWeight: isInventoryInStock ? 600 : 400,
            }}
          >
            {isInventoryInStock && `Your items are In Stock at ${webAlias}`}
            {inventoryByDelivery && `Your items arrive at ${webAlias}:`}
          </Typography>
        )}
        {inventoryNotAvailable && (
          <Button
            component={Link}
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: theme.palette.error.main,
            }}
            href={`tel:${phoneNumber}`}
          >
            Call For Inventory Availability
          </Button>
        )}
      </Box>
      {!isInventoryInStock && (
        <Box style={{ paddingLeft: 25, paddingTop: 5 }}>
          {inventoryNotAvailable && (
            <Typography style={{ fontSize: 12, paddingBottom: 10 }}>
              {`*Arrival date unknown for item(s) in your cart. Most inventory is available within 3 days, so only showing appointment times after:`}
            </Typography>
          )}

          <Typography style={{ fontWeight: 600, fontSize: 18 }}>
            {inventoryArrivesDateTimeDisplay}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AppointmentInventoryArrivalInfo;
