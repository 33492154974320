import { Grid, Button } from "@material-ui/core";

const PrimaryActionSelectButton = ({
  buttonTitle,
  onClick = () => null,
  isDisabled = false,
}) => {
  return (
    <Grid
      container
      style={{ paddingTop: 30, paddingBottom: 30, justifyContent: "center" }}
    >
      <Button
        disabled={isDisabled}
        variant="contained"
        color="primary"
        style={{ width: 300, height: 50 }}
        onClick={onClick}
      >
        {buttonTitle}
      </Button>
    </Grid>
  );
};

export default PrimaryActionSelectButton;
