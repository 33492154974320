import MetaTags from "../components/reusable/ReactMetaTags.jsx";
import APP_ENV from "../appEnvironment";
import DefaultContainer from "../components/generic/default-container.js";
import StandardPageHeader from "../components/generic/standard-page-header.js";
import { Grid, Paper, Typography } from "@material-ui/core";
const theme = APP_ENV.theme;

const TireWarranty = () => {
  return (
    <>
      <MetaTags
        description={`Learn about our warranties and conditions. ${APP_ENV.appBrandName} offers a limited 6 month or 6,000 mile warranty on all repairs. Learn more.`}
        canonical="/warranty-information"
        title={`Warranty Information | ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title="Warranty Information"
        subtitle={`Learn about our warranties and conditions. ${APP_ENV.appBrandName} offers a limited 6 month or 6,000 mile warranty on all repairs. Learn more.`}
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 25,
            paddingBottom: 40,
          }}
        >
          <Grid item xs={11} sm={8} md={8} lg={10}>
            <Paper elevation={8} style={{ padding: "1.7em" }}>
              <Typography>
                {`${APP_ENV.appBrandName.toUpperCase()} WARRANTY (LIMITED)
                                    Parts & Labor warranty period – 6 months or 6,000 miles (whichever occurs first).`}
              </Typography>
              <Typography>
                MANUFACTURER’S STANDARD TIRE WARRANTY (LIMITED) If your
                passenger or truck tires used in normal service become unsafe or
                unserviceable due to defects in workmanship or materials, the
                tire will be replaced on a pro-rata basis charging for only the
                portion of tread warn off, down to the last 1/16 of an inch of
                tread. Out of round tires are adjustable during the first 10% of
                the tire’s life only. Road hazards are not covered by this
                warranty.
              </Typography>
              <Typography>
                MANUFACTURER’S STANDARD WHEEL WARRANTY (LIMITED) Custom wheels
                are warranted for the first year for workmanship and materials
                defects from time of purchase.
              </Typography>
              <Typography>CONDITIONS NOT COVERED BY WARRANTIES</Typography>
              <ol>
                <li>Premature tread wear out</li>
                <li>Repairable punctures</li>
                <li>{`Improper mounting or dismounting, causing tire failure or damage if not mounted at a ${APP_ENV.appBrandName} facility.`}</li>
                <li>Run flat or tire run in under-inflated condition</li>
                <li>Defective rims</li>
                <li>4WD and AWD replacements other than the warranted tire</li>
                <li>Vehicle Misalignment</li>
                <li>Snow Chain damage</li>
                <li>Brake failure or other type mechanical problem</li>
                <li>Abusive driving</li>
                <li>Accident damage, wreck or collision damage</li>
                <li>Vandalism, willful or malicious damage</li>
              </ol>
              <Typography>
                These warranties are in lieu of all other warranties expressed
                or implied.
              </Typography>
              <Typography>
                {`These warranties do not cover and ${APP_ENV.appBrandName} shall not be liable for incidental, or
                                    consequential damages, including loss of time, road service charges, inconveniences, loss of use of
                                    vehicle, loss of revenues, and loss or damage to personal property.`}
              </Typography>
              <Typography>
                Warranty is provided to original consumer purchaser only.
              </Typography>
              <Typography>
                {`Some states do not allow limitation on how long an implied warranty lasts, or limitation, or exclusions,
                                    or incidental, or consequential damage, so the above limitations or exclusions may not apply to you.
                                    These warranties apply to truck tires and passenger car tires purchased at a ${APP_ENV.appBrandName}
                                    facility. Warranties will be handled promptly with a replacement tire out of stock. If tire is not in stock
                                    a replacement will be ordered and will be available usually within 10 days. If tire is discounted we will
                                    substitute a tire of equal quality.`}
              </Typography>
              <Typography>
                Your purchase invoice must be presented at time of warranty
                adjustment for warranty to apply. These warranties give you
                specific legal rights, and you may also have other rights which
                vary from state to state.
              </Typography>
              <Typography>
                These warranties do not cover any undertaking, representation,
                or warranty made by dealers or third parties selling our
                products beyond the provisions of this warranty.
              </Typography>
              <Typography>
                If your trailer tire used in normal service becomes
                unserviceable due to defects in workmanship or materials within
                2 years from date of purchase, it will be replaced on a pro-rate
                basis charging for only the portion of the portion of the tread
                worn off, down to the last 1/16 of an inch of tread.
              </Typography>
              <Typography>
                {`ALL TIRES MUST BE RETURNED TO A ${APP_ENV.appBrandName.toUpperCase()} FACILITY AND ACCOMPANIED BY THE ORIGINAL
                                    RECEIPT FOR ANY WARRANTY ADJUSTMENT CONSIDERATION.`}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default TireWarranty;
