import * as React from "react";
import MetaTags from "../components/reusable/ReactMetaTags.jsx";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import APP_ENV from "../appEnvironment";
import StandardPageHeader from "../components/generic/standard-page-header.js";
import DefaultContainer from "../components/generic/default-container.js";

const theme = APP_ENV.theme;

const PrivacyPolicy = () => {
  const { appBrandName, appPhoneNumber } = APP_ENV;

  return (
    <>
      <MetaTags
        description="We are committed to protecting and safeguarding your privacy on the Internet."
        canonical="/privacy-policy"
        title={`Privacy Policy | ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title="Privacy Policy"
        subtitle="We are committed to protecting and safeguarding your privacy on the Internet."
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 25,
            paddingBottom: 40,
          }}
        >
          <Grid item xs={11} sm={8} md={8} lg={10}>
            <Paper elevation={8} style={{ padding: "1.7em" }}>
              <Typography gutterBottom variant="h2">
                Internet Privacy Policy
              </Typography>
              <Box my="4rem">
                <Typography gutterBottom variant="h4">
                  Online Privacy Statement
                </Typography>
                <Typography>
                  {`Thank you for visiting our website. ${appBrandName} is committed to protecting
                                          and safeguarding your privacy on the Internet. We appreciate you placing your trust
                                          in ${appBrandName}.`}
                </Typography>
              </Box>
              <Box my="4rem">
                <Typography variant="h4" gutterBottom>
                  INFORMATION WE COLLECT AND HOW WE USE IT
                </Typography>
                <Typography>
                  We collect two types of information about visitors: personal
                  information, which you have voluntarily submitted to us
                  (things like your name, address, telephone number, e-mail
                  address, etc.) and aggregate information (such as information
                  about how many visitors log on to our sites, the pages they
                  access, etc.).
                </Typography>
                <Typography>
                  In general, you can visit this website without telling us who
                  you are or revealing any personal information about yourself.
                  However, you may need to provide some personal information in
                  order to participate in certain promotions, offers and
                  surveys, to register with this site or to receive information
                  about our products and services. We collect, maintain, and use
                  the personal information you have voluntarily submitted to
                  contact you or to respond to your comments and requests for
                  information. In addition, we may use the personal information
                  you provide to meet your needs and respond to your inquiries.
                </Typography>
                <Typography>
                  To better meet your needs, we may supplement the information
                  we collect with data obtained from third parties for the same
                  purposes.
                </Typography>
              </Box>
              <Box my="4rem">
                <Typography gutterBottom variant="h4">
                  DATA SHARING AND TRANSFER
                </Typography>
                <Typography>
                  {`${appBrandName} will not trade, sell, or share the personal information
                            you have voluntarily submitted with any third party without your consent, unless
                            required by law or as disclosed to you when the information is collected, except
                            that personal information you provide may be used by ${appBrandName}, its
                            franchisee dealers, or third-party business partners who may need to use such information
                            to help ${appBrandName} administer certain promotions, provide technical
                            services for this website, analyze your opinion of ${appBrandName}, address
                            a question or concern about our products and services, etc.`}
                </Typography>
              </Box>
              <Box my="4rem">
                <Typography gutterBottom variant="h4">
                  SMS
                </Typography>
                {[
                  `1. By subscribing, you will receive texts about about the work ${`you've`} scheduled, appointment
                                        reminders, approval for changes to the work being done on your car, and you will be able to pay via text.`,
                  `2. You can cancel the SMS service at any time. Just text to the short code. After you send the
                                        SMS message ${`"STOP"`} to us, we will send you an SMS message to confirm that you have been
                                        unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just
                                        sign up as you did the first time and we will start sending SMS messages to you again.`,
                  `3. If you are experiencing issues with the messaging program you can reply with the keyword HELP for
                                        more assistance, or you can get help directly at ${APP_ENV.appPhoneNumber}.`,
                  `4. Carriers are not liable for delayed or undelivered messages.`,
                  `5. As always, message and data rates may apply for any messages sent to you from us and to us from
                                        you. You will receive variable messages based on the work required on your vehicle. If you have any
                                        questions about your text plan or data plan, it is best to contact your wireless provider.`,
                  `6. If you have any questions regarding privacy, please read the rest of our privacy policy at ${APP_ENV.appSlug}/privacy-policy.`,
                  `7. Your mobile telephone number, opt-in data and SMS messaging will not be shared with any unaffiliated third parties or otherwise sold, transferred, disclosed, or disseminated to any third party.`,
                ].map((item, i) => (
                  <Typography key={i} style={{ marginTop: 10 }}>
                    {item}
                  </Typography>
                ))}
              </Box>
              <Box my="4rem">
                <Typography variant="h4" gutterBottom>
                  UPDATE YOUR PERSONAL INFORMATION
                </Typography>
                <Typography>
                  {`If you want to update any personal information you previously provided to ${appBrandName}, 
                            or if you want to start receiving or stop receiving email promotions,
                            please do any one of the following:`}
                </Typography>
                <ol>
                  <li>
                    Send an email to webmaster@bigbrandtire.com with an
                    explaination of your specific request.
                  </li>
                  <li>
                    {`Call the ${appBrandName} customer support team at`}{" "}
                    <a href={"tel:" + appPhoneNumber}>{appPhoneNumber}.</a>
                  </li>
                </ol>
              </Box>
              <Box my="4rem">
                <Typography variant="h4" gutterBottom>
                  COOKIES
                </Typography>
                <Typography>
                  Cookies help us make your visit to our website more enjoyable
                  and meaningful to you. A cookie is a small text information
                  file that your web browser places on your computer when you
                  visit a website. This helps us to recognize you when you
                  return to our site. We may use such technology to obtain
                  non-personal information from you as an online visitor.
                </Typography>
              </Box>
              <Box my="4rem">
                <Typography variant="h4" gutterBottom>
                  CHILDREN'S PRIVACY
                </Typography>
                <Typography>
                  We do not seek to collect any information or market to
                  children under the age of 16.
                </Typography>
              </Box>
              <Box my="4rem">
                <Typography variant="h4" gutterBottom>
                  LINKS TO OTHER SITES
                </Typography>
                <Typography>
                  {`This website may contain links to other websites. ${appBrandName} does
                            not control the privacy practices or the content of such websites. We recommend
                            you carefully read the privacy policies of each site you visit.`}
                </Typography>
              </Box>
              <Box my="4rem">
                <Typography variant="h4" gutterBottom>
                  YOUR ACCEPTANCE OF OUR PRIVACY PRACTICES
                </Typography>
                <Typography>
                  {`By using this website or any other ${appBrandName} website or interactive
                            banner ads, you signify your acceptance of our privacy statement and conditions
                            posted on the website. We reserve the right to update, change, modify or remove
                            portions of this policy at any time. Please check back for updates to our Privacy
                            Statement.`}
                </Typography>
              </Box>
              <Box my="4rem">
                <Typography variant="h4" gutterBottom>
                  CONTACT US
                </Typography>
                <Typography>
                  {`If you have any questions about this website or our On-line Privacy Policy, you
                            can contact ${appBrandName} the following ways:`}
                </Typography>
                <ol>
                  <li>
                    Send an email to webmaster@bigbrandtire.com with an
                    explaination of your specific request.
                  </li>
                  <li>
                    {`Call the ${appBrandName} customer support team at`}{" "}
                    <a href={"tel:" + appPhoneNumber}>{appPhoneNumber}.</a>
                  </li>
                </ol>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default PrivacyPolicy;
