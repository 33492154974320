import { Box, Button, LinearProgress, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import ThumbUpAltRoundedIcon from "@material-ui/icons/ThumbUpAltRounded";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const TireReviewSummary = ({
  reviewCount = 0,
  reviewAvg = 0,
  wouldRecommendPercentage = 0,
  reviewDistribution,
}) => {
  return (
    <Box>
      <Box style={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Rating
          readOnly
          value={reviewAvg}
          precision={0.1}
          style={{ fontSize: 30, marginRight: 10 }}
        />
        <Typography style={{ fontWeight: "bold" }}>
          {reviewAvg} out of 5
        </Typography>
      </Box>
      <Box
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Typography
          style={{ color: theme.palette.gray[700] }}
        >{`${reviewCount} Customer Ratings`}</Typography>
      </Box>
      <Box
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <ThumbUpAltRoundedIcon
          style={{ marginRight: 10, color: theme.palette.success.main }}
        />
        <Typography>
          {`${wouldRecommendPercentage}% Would Recommend`}
        </Typography>
      </Box>
      <Box style={{ width: "100%", marginTop: 10, marginBottom: 10 }}>
        {Object.entries(reviewDistribution)
          .reverse()
          .map(([key, value]) => {
            return (
              <Box
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{ marginRight: 10, fontSize: 14 }}
                >{`${key} star`}</Typography>
                <LinearProgress
                  variant="determinate"
                  value={value}
                  style={{ width: 150, height: 8, marginRight: 10 }}
                />
                <Typography style={{ fontSize: 14 }}>{`${value.toFixed(
                  0
                )}%`}</Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default TireReviewSummary;
