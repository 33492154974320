import { Box } from "@material-ui/core";

const TireImage = ({ itemModelImage = "", style = {} }) => {
  return (
    <Box
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 18,
        height: 250,
        ...style,
      }}
    >
      <img
        src={itemModelImage}
        style={{ height: "100%", width: "100%", objectFit: "contain" }}
      />
    </Box>
  );
};

export default TireImage;
