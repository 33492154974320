import { Paper, Typography, Avatar, Button, Box } from "@material-ui/core";
import APP_ENV from "../../appEnvironment";
import LocationInfoCard from "./LocationInfoCard";

const { theme } = APP_ENV;

const LocationSearchCard = (props) => {
  const { isSelected, index, onSelectedLocationClick, onNewSelectionClick } =
    props;

  const handleSelection = () => {
    props.setSelected();

    isSelected ? onSelectedLocationClick() : onNewSelectionClick();
  };

  return (
    <Paper
      style={{
        marginBottom: 10,
        border: isSelected && `2px solid ${theme.palette.secondary.main}`,
      }}
      elevation={isSelected ? 8 : 6}
    >
      {isSelected && (
        <Paper
          style={{
            backgroundColor: theme.palette.secondary.main,
            paddingLeft: 8,
            paddingRight: 8,
            width: "fit-content",
          }}
        >
          <Typography style={{ color: theme.palette.primary.contrastText }}>
            Your Store
          </Typography>
        </Paper>
      )}
      <Box style={{ padding: 10, paddingLeft: 0 }}>
        <Box style={{ display: "flex" }}>
          <Box
            style={{
              height: "100%",
              width: 50,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Avatar
              style={{
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
                width: 24,
                height: 24,
                fontSize: 12,
              }}
            >
              {index}
            </Avatar>
          </Box>
          <LocationInfoCard {...props} />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingLeft: 10,
            paddingTop: 15,
            paddingBottom: 15,
          }}
        >
          <Button
            variant={isSelected ? "contained" : "outlined"}
            color="primary"
            onClick={handleSelection}
            style={{ height: 50, width: "80%" }}
          >
            {isSelected ? "Shop Now" : "Make This Your Store"}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default LocationSearchCard;
