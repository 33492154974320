import { useEffect, useState } from "react";
import { Grid, Tabs, Tab, Paper } from "@material-ui/core";
import useTireReviews from "../../hooks/useTireReviews";
import TireReviewSummary from "./widgets/tire-review-summary";
import APP_ENV from "../../appEnvironment";
import DefaultContainer from "../generic/default-container";
import TireReviewSubmitForm from "./widgets/tire-review-submit-form";
import TireReviewCustomerReviews from "./widgets/tire-review-customer-reviews";
import { useHistory, useLocation } from "react-router";

const { theme } = APP_ENV;

export const TIRE_REVIEW_PARAM = "show-tire-reviews";

const TireReviewController = ({ isWriteReview = false }) => {
  const [state, setState] = useState({
    tabValue: !isWriteReview ? 0 : 1,
  });

  const tireReviewstate = useTireReviews();

  const { isReady, isLoading, hasReviews, reviewsSummary, allReviews } =
    tireReviewstate;

  const location = useLocation();
  const history = useHistory();

  const elementId = "tire-review-container";

  useEffect(() => {
    if (location?.search?.includes(TIRE_REVIEW_PARAM)) {
      (async () => {
        await new Promise((res) => setTimeout(res, 200));
        const el = document.getElementById(elementId);
        el.scrollIntoView({ behavior: "smooth", block: "nearest" });
      })();
    }
  }, [location?.search]);

  return (
    <DefaultContainer>
      <Grid
        id={elementId}
        container
        style={{ marginBottom: 25, paddingTop: 20, paddingBottom: 20 }}
      >
        {isReady && !isLoading && (
          <>
            {hasReviews && (
              <>
                <Grid
                  item
                  xs={12}
                  md={5}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 20,
                  }}
                >
                  <TireReviewSummary {...reviewsSummary} />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Tabs
                    value={state.tabValue}
                    onChange={(_, newVal) =>
                      setState((prev) => ({ ...prev, tabValue: newVal }))
                    }
                  >
                    <Tab value={0} label={"Customer Reviews"} />
                    <Tab value={1} label={"Write A Review"} />
                  </Tabs>
                  <Paper
                    style={{
                      width: "100%",

                      padding: 10,
                      border: `2px solid ${theme.palette.background.default}`,
                    }}
                  >
                    {state.tabValue == 0 && (
                      <TireReviewCustomerReviews allReviews={allReviews} />
                    )}
                    {state.tabValue == 1 && <TireReviewSubmitForm />}
                  </Paper>
                </Grid>
              </>
            )}

            {!hasReviews && (
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid
                  item
                  sm={10}
                  md={8}
                  lg={7}
                  xl={6}
                  component={Paper}
                  elevation={6}
                  style={{ padding: 15 }}
                >
                  <TireReviewSubmitForm hasReviews={hasReviews} />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </DefaultContainer>
  );
};

export default TireReviewController;
