import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  Link,
} from "@material-ui/core";
import LayoutStore from "../../hooks/store/client/layout-store";
import DefaultContainer from "../../components/generic/default-container";
import AboutBrandInfoGraphics from "../../components/reusable/about-brand-info-graphics";
import FiveReasonsToShopWithUs from "../../components/reusable/five-reasons-to-shop-with-us";
import { STATIC_CART_ITEMS } from "../../static/cart-items";
import MetaTags from "../../components/reusable/ReactMetaTags";
import APP_ENV from "../../appEnvironment";

const MAIN_IMAGES = {
  1: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shield-repair-network-bbt.png",
  2: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shield-repair-network-tw.png",
  3: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shield-repair-network-atd.png",
  4: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shield-repair-network-bbt.png",
};

const { theme } = APP_ENV;

const { SHIELD_REPAIR_NETWORK_WARRANTY_CONSULTATION } = STATIC_CART_ITEMS;

const ShieldRepairNetworkServicePartnership = () => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  return (
    <>
      <MetaTags
        description={`${APP_ENV.appBrandName} is an authorized warranty repair facility for Shield Repair Network Warranty Repairs.`}
        canonical="/service-partnerships/shield-repair-network"
        title={`Shield Repair Network Warranty Auto Repair`}
      />
      <Grid
        container
        style={{
          backgroundImage: `linear-gradient(${theme.palette.footer1.main}, ${theme.palette.background.default})`,
        }}
      >
        <Container style={{ paddingTop: 10, paddingBottom: 50 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Box style={{ width: "100%", maxWidth: 475 }}>
                <Box style={{ marginTop: 25 }}>
                  <Typography
                    variant="h2"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    {"Shield Repair Network Warranty"}
                  </Typography>
                  <Typography
                    variant="h3"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    {"Authorized Service Provider"}
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{
                      color: theme.palette.primary.contrastText,
                      fontWeight: 600,
                      paddingTop: 5,
                    }}
                  >
                    {"Same-Day Appointments Available!"}
                  </Typography>

                  <Typography
                    style={{
                      color: theme.palette.primary.contrastText,
                      marginTop: 15,
                      marginBottom: 25,
                    }}
                  >
                    {`Whether you have coverage from CarShield® or American Auto Shield®, 
                      ${APP_ENV.appBrandName} is authorized to inspect and repair your vehicle.`}
                  </Typography>
                </Box>
                <Paper
                  style={{
                    borderRadius: 15,
                    marginTop: 15,
                    marginBottom: 15,
                    padding: 25,
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h4">Schedule Appointment</Typography>
                  <Button
                    onClick={
                      SHIELD_REPAIR_NETWORK_WARRANTY_CONSULTATION.addToCart
                    }
                    style={{
                      width: 300,
                      height: 50,
                      marginTop: 20,
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Add To Cart
                  </Button>
                </Paper>
                <Box style={{ padding: 25 }}>
                  <Typography>Questions or need more information?</Typography>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 25,
                      width: "100%",
                    }}
                  >
                    <Typography style={{ fontSize: 25, marginRight: 8 }}>
                      Call Us Now at
                    </Typography>
                    <Link
                      href={`tel:8558773337`}
                      style={{ fontWeight: "bold", color: "black" }}
                      underline="always"
                    >
                      {`855-877-3337`}
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: 500,
                  marginTop: 20,
                }}
              >
                <img
                  src={MAIN_IMAGES[APP_ENV.appBrandId]}
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <DefaultContainer>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <AboutBrandInfoGraphics />
          </Grid>

          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <FiveReasonsToShopWithUs />
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default ShieldRepairNetworkServicePartnership;
