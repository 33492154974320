import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { Typography, Grid, Button, Box } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";
import OlarkChat from "../../../hooks/store/client/olark-chat";

const NeedHelpHeader = () => {
  return (
    <Grid container>
      <Grid
        item
        sm={12}
        md={12}
        lg={7}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <WhatsAppIcon
          style={{ fontSize: 50, marginLeft: 15, marginRight: 25 }}
          color="secondary"
        />
        <Box>
          <Typography variant="h5" noWrap>
            Shop with an expert, right from home.
          </Typography>
          <Typography>Call or chat online now.</Typography>
        </Box>
      </Grid>
      <Grid
        item
        sm={12}
        md={12}
        lg={5}
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{
            minWidth: 200,
            maxWidth: 225,
            width: "100%",
            marginBottom: 10,
            marginRight: 10,
            height: 50,
          }}
          onClick={OlarkChat.expand}
        >
          Live Chat
        </Button>
        <Button
          variant="contained"
          color="secondary"
          href={`tel:${APP_ENV.appPhoneNumber}`}
          fullWidth
          style={{
            minWidth: 200,
            maxWidth: 225,
            width: "100%",
            marginBottom: 10,
            marginRight: 10,
            height: 50,
          }}
        >
          Call Now
        </Button>
      </Grid>
    </Grid>
  );
};

export default NeedHelpHeader;
