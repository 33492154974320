import { Box, Button } from "@material-ui/core";
import VehicleTireSelectionModal from "./widgets/VehicleTireSizeSelectionModal";
import SelectVehicle from "../reusable/conditional-selection/SelectVehicle";

const SelectVehicleOnlyModalDesktop = ({ isOpen, closeModal }) => {
  const SelectVehicleComp = () => {
    const { component, handleSubmit } = SelectVehicle(closeModal);
    return (
      <Box>
        {component}
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            style={{ width: 300, height: 50 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <VehicleTireSelectionModal
      isOpen={isOpen}
      closeModal={closeModal}
      tabs={[{ tabLabel: "Select Vehicle", isActive: true }]}
      currentStep={1}
      sectionTitle={"Select your Vehicle"}
      sectionDesc={
        "All we need is your vehicle year, make, and model to find the best deals."
      }
    >
      {isOpen && <SelectVehicleComp />}
    </VehicleTireSelectionModal>
  );
};

export default SelectVehicleOnlyModalDesktop;
