import { Box, Link, Divider } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ModalStore from "../../../hooks/store/modal-store";

const DetailsOrRemoveLinks = ({
  itemEndPoint = "",
  removeFromCart = () => null,
}) => {
  const { closeAllModals } = ModalStore();

  return (
    <Box
      style={{
        display: "flex",
        height: 15,
        marginTop: 5,
        marginBottom: 5,
      }}
    >
      <Link
        style={{ fontSize: 12 }}
        color="primary"
        component={RouterLink}
        to={itemEndPoint}
        onClick={closeAllModals}
      >
        Details
      </Link>
      <Divider
        orientation="vertical"
        style={{ marginRight: 8, marginLeft: 8 }}
      />
      <Link color="error" style={{ fontSize: 12 }} onClick={removeFromCart}>
        Remove
      </Link>
    </Box>
  );
};

export default DetailsOrRemoveLinks;
