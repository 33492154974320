import { Typography, Box, Divider } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

const ServiceCouponCallOut = ({ promotion = null }) => {
  return (
    <>
      {promotion?.itemName && (
        <>
          <Divider
            style={{
              marginBottom: 8,
              marginTop: 8,
              backgroundColor: "#ed0000",
              height: 2,
            }}
          />
          <Box style={{ width: "100%", textAlign: "center" }}>
            <span style={{ display: "flex" }}>
              <MonetizationOnIcon
                style={{
                  color: "#ed0000",
                  fontSize: 20,
                }}
              />
              <Typography
                style={{ fontSize: 14, fontWeight: 600, marginLeft: 5 }}
              >
                {promotion?.itemName}
              </Typography>
            </span>
          </Box>
        </>
      )}
    </>
  );
};

export default ServiceCouponCallOut;
