import MetaTags from "../components/reusable/ReactMetaTags.jsx";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Grid, Typography, Paper } from "@material-ui/core";
import APP_ENV from "../appEnvironment";
import DefaultContainer from "../components/generic/default-container.js";
import StandardPageHeader from "../components/generic/standard-page-header.js";
const theme = APP_ENV.theme;

const ContactUs = () => {
  const phone = APP_ENV.appPhoneNumber;
  return (
    <>
      <MetaTags
        description="Contact us with questions about your tire order, customer service, warranties or price match. Reach us by phone or e-mail and we'll be happy to help."
        canonical="/contact"
        title={`Contact Us | ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title="Contact Us"
        subtitle="Contact us with questions about your tire order, customer service, warranties or price match. Reach us by phone or e-mail and we'll be happy to help."
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 25,
            paddingBottom: 40,
          }}
        >
          <Grid item xs={11} sm={8} md={8} lg={8}>
            <Paper elevation={8} style={{ padding: "2rem" }}>
              <Typography variant="h2">{APP_ENV.appBrandName}</Typography>
              <Box my="3rem">
                <Typography variant="h3" gutterBottom>
                  Address:
                </Typography>
                <Typography>14401 Princeton</Typography>
                <Typography>Moorpark CA 93021</Typography>
              </Box>
              <Box my="3rem">
                <Typography variant="h3" gutterBottom>
                  Phone:
                </Typography>
                <Typography gutterBottom>
                  <a href={"tel:" + phone}>{phone}</a>
                </Typography>
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    href={"tel:" + phone}
                    style={{ height: 50, width: 250 }}
                  >
                    Call Now!
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};
export default ContactUs;
