import { Grid, Paper, Button } from "@material-ui/core";
import { STATIC_CART_ITEMS } from "../../static/cart-items";
import APP_ENV from "../../appEnvironment";

const FREE_ITEMS = [
  {
    image:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/coupons/Tire-rotation-coupon.png",
    cartItem: STATIC_CART_ITEMS.TIRE_ROTATION,
  },
  {
    image:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/coupons/Flat-repair-coupon.png",
    cartItem: STATIC_CART_ITEMS.FLAT_TIRE_REPAIR,
  },
  {
    image:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/coupons/Alignment-check-coupon.png",
    cartItem: STATIC_CART_ITEMS.WHEEL_ALIGNMENT_CHECK,
  },
  {
    image:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/coupons/brake-inspection-coupon.png",
    cartItem: STATIC_CART_ITEMS.BRAKE_CHECK,
  },
];

const CouponFreeServices = () => {
  const filteredFreeItems = FREE_ITEMS.filter((item) => {
    if (
      APP_ENV.appBrandId === 4 &&
      item.cartItem === STATIC_CART_ITEMS.BRAKE_CHECK
    ) {
      return false;
    }
    return true;
  });

  return (
    <Grid container>
      {filteredFreeItems.map((freeItem, i) => {
        return (
          <Grid
            key={i}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            style={{ padding: 10, textAlign: "center" }}
          >
            <Paper elevation={5}>
              <img src={freeItem.image} width={"100%"} height="auto" />

              <Button
                variant="contained"
                color="primary"
                style={{
                  width: 200,
                  height: 50,
                  marginTop: 15,
                  marginBottom: 15,
                }}
                onClick={freeItem?.cartItem?.addToCart}
              >
                Add to Cart
              </Button>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CouponFreeServices;
