import { Paper, Button, Box, Typography } from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import APP_ENV from "../../appEnvironment";
import { useLocationStore } from "../../hooks/store/location-store";
import OlarkChat from "../../hooks/store/client/olark-chat";

const { theme } = APP_ENV;

const TiresToYouTextUsBar = () => {
  const [userStore] = useLocationStore((st) => [st.userStore]);

  const { sitePromoColors, displayText, SitePromoIcon } =
    userStore?.promotion ?? {};

  return (
    <>
      {userStore && (
        <Paper
          component={Button}
          onClick={OlarkChat.expand}
          style={{
            height: 60,
            backgroundColor: sitePromoColors?.primary,
            width: "100%",
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box>
              <Typography style={{ fontSize: 15, color: "white" }}>
                {`Same Day Installation - Zero Wait Time`}
              </Typography>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    style={{
                      color: theme.palette.common.white,
                      marginRight: 5,
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    Text Us Now!
                  </Typography>
                  <ChevronRightRoundedIcon
                    style={{ color: theme.palette.common.white }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default TiresToYouTextUsBar;
