import { Card, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const StyledInfoCard = ({
  isGrey = true,
  elevation = 0,
  isLoading = false,
  isLoadingHeight = 50,
  children,
}) => {
  return (
    <Card
      elevation={elevation}
      style={{
        justifyContent: "flex-start",
        textAlign: "left",
        padding: 14,
        borderRadius: 12,
        width: "100%",
        border: !isGrey && `2px solid ${theme.palette.divider}`,
        backgroundColor: isGrey
          ? theme.palette.grey[100]
          : theme.palette.background.paper,
      }}
    >
      {isLoading && (
        <Skeleton variant="rect" width={"100%"} height={isLoadingHeight} />
      )}
      {!isLoading && <Box>{children}</Box>}
    </Card>
  );
};

export default StyledInfoCard;
