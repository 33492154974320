import fetchData from "./fetchData";
import { TIRES_ROUTE_DIRECTORY } from "../routes";
import APP_ENV from "../appEnvironment";
import SMOG_CHECK_CONFIG from "../static/smog-check-config";
import { renderToString } from "react-dom/server";
import EasySurveyTemplate from "../components/easy-survey/easy-survey-template";
import LocationStore from "../hooks/store/location-store";

export const getLocations = async ({
  storeNumber = 0,
  lat = 0,
  lng = 0,
} = {}) => {
  const data = await fetchData("getLocations", {
    storeNumber,
    lat,
    lng,
  });

  let locations = data.map((store) => {
    return {
      ...store,
      storesNearby: JSON.parse(store.storesNearby),
      storeSchedule: JSON.parse(store.storeSchedule),
      tireInstallationPackage: JSON.parse(store.tireInstallationPackage),
      tireSTF: JSON.parse(store.tireSTF)[0],
      yelpReviewUrl: `https://www.yelp.com/writeareview/biz/${store.yelpBizId}`,
      yelpPageUrl: `https://www.yelp.com/biz/${store.yelpBizId}`,
      googleDirectionsUrl: `https://www.google.com/maps/search/?api=1&query=${[
        APP_ENV.appBrandName,
        store.streetAddress,
        store.city,
        store.stateAbbreviation,
        store.zipCode,
      ]
        .join(" ")
        .replaceAll(" ", "+")}&query_place_id=${store.googlePlaceId}`,
      facebookPageUrl: [
        "bigbrandtireandservice",
        "tireworldautorepair",
        "americantiredepot",
      ]
        .filter((_, i) => i + 1 == APP_ENV.appBrandId)
        .map(
          (item, i) => `https://www.facebook.com/${item}${store.storeNumber}`
        )[0],
    };
  });

  locations = SMOG_CHECK_CONFIG.mapSmogCheckLocations(locations);

  return locations;
};

export const getHotDealsTireRebates = async () => {
  return await fetchData("getHotDealsTireRebates");
};

export const getTireSizes = async () => {
  const data = await fetchData("getTireSizes");
  return data;
};

export const getTireBrands = async () => {
  const data = await fetchData("getTireBrands");
  const brands = data.map((brand) => {
    return {
      ...brand,
      brandSlug: `${TIRES_ROUTE_DIRECTORY}/${brand.brandEndPoint}`,
    };
  });
  return brands;
};

export const getTireModelsByBrand = async ({ storeNumber, brandEndPoint }) => {
  const data = await fetchData("getTireModelsByBrand", {
    storeNumber,
    brandEndPoint,
  });
  const models = data.map((model) => {
    return {
      ...model,
      itemModelSlug: `${TIRES_ROUTE_DIRECTORY}/${brandEndPoint}/${model.itemModelEndPoint}`,
    };
  });
  return models;
};

export const getTireItems = async ({
  storeNumber,
  priceTierId = 1,
  brandEndPoint = null,
  itemModelEndPoint = null,
  tireSizeEndPoint = null,
  partNumberEndPoint = null,
  ispOnly = true,
}) => {
  let tires = [];

  if (brandEndPoint || tireSizeEndPoint) {
    const data = await fetchData("getTireItems", {
      storeNumber,
      priceTierId,
      brandEndPoint,
      itemModelEndPoint,
      tireSizeEndPoint,
      partNumberEndPoint,
      ispOnly,
    });

    tires = data.map((tire) => {
      const {
        brandEndPoint,
        itemModelEndPoint,
        tireSizeEndPoint,
        partNumberEndPoint,
        deliveryAvailability,
        mailInRebates,
      } = tire;

      const brandSlug = `${TIRES_ROUTE_DIRECTORY}/${brandEndPoint}`;
      const itemModelSlug = `${brandSlug}/${itemModelEndPoint}`;
      const itemSlug = `${itemModelSlug}/${tireSizeEndPoint}/${partNumberEndPoint}`;
      const tireSizeSlug = `/search/1/${tireSizeEndPoint}`;

      const item = {
        ...tire,
        brandSlug,
        itemModelSlug,
        tireSizeSlug,
        itemSlug,
        deliveryAvailability: JSON.parse(deliveryAvailability),
        mailInRebates: JSON.parse(mailInRebates),
      };

      return item;
    });
  }

  return tires;
};

export const getTireReviews = async ({ brandEndPoint, itemModelEndPoint }) => {
  const data = await fetchData("getTireReviews", {
    brandEndPoint,
    itemModelEndPoint,
  });
  return data;
};

export const postTireReview = async ({
  brandEndPoint,
  itemModelEndPoint,
  firstName,
  lastInitial,
  vehicle,
  milesDriven,
  wouldRecommend,
  rating,
  comment,
}) => {
  const data = await fetchData("postTireReview", {
    brandEndPoint,
    itemModelEndPoint,
    firstName,
    lastInitial,
    vehicle,
    milesDriven,
    wouldRecommend,
    rating,
    comment,
  });

  return data;
};

export const getAppointmentAvailability = async ({
  storeNumber,
  venomApptServiceTypeId,
  inventoryArrivesDateTime,
}) => {
  const data = await fetchData("getAppointmentAvailability", {
    storeNumber,
    venomApptServiceTypeId,
    inventoryArrivesDateTime,
  });

  return data.map((item) => ({
    ...item,
    appointmentOptions: JSON.parse(item.appointmentOptions),
  }));
};

export const createOrderRetail = async ({
  storeNumber,
  client,
  retailCustomer,
  vehicle,
  appointmentDetails,
  cartSummary,
  cartDetails,
}) => {
  const data = await fetchData("createOrderRetail", {
    storeNumber,
    client,
    retailCustomer,
    vehicle,
    appointmentDetails,
    cartSummary,
    cartDetails,
  });

  return data?.orderGUID;
};

export const getOrderRetail = async ({ orderGUID }) => {
  let [order = null] = await fetchData("getOrderRetail", { orderGUID });

  if (!order) return null;

  order = {
    ...order,
    cartItems: JSON.parse(order.cartItems),
  };

  const { locations = [] } =
    await LocationStore.getState().getLocationStoreAsync();

  const orderStore = locations.find(
    ({ storeNumber }) => storeNumber == order.storeNumber
  );

  if (!orderStore) return null;

  return { orderStore, ...order };
};

export const sendMailChimpEmail = async ({
  html,
  subject,
  fromEmail = "sales@bigbrandtire.com",
  fromName,
  to = [
    {
      email: "xxx@null.com",
      name: "none",
    },
  ],
}) => {
  const message = {
    html,
    subject,
    from_email: fromEmail,
    from_name: fromName,
    track_opens: true,
    track_clicks: true,
    auto_html: true,
    to,
  };

  return await fetchData("sendMailChimpEmail", { message });
};

export const upsertClientDevice = async ({ storeNumber, clientDeviceGuid }) => {
  const res = await fetchData("upsertClientDevice", {
    storeNumber,
    clientDeviceGuid,
  });

  return res?.[0];
};

export const getEasySurveyDetails = async ({ invoiceHashKey }) => {
  const {
    easySurveyId,
    googleReviewUrl,
    yelpReviewUrl,
    easySurveyCategories,
    ...props
  } =
    (
      await fetchData("getEasySurveyDetails", {
        invoiceHashKey,
      })
    )?.[0] ?? {};

  return {
    ...props,
    easySurveyCategories: JSON.parse(easySurveyCategories),
    externalReviewOptions: [
      {
        name: "Google",
        url: googleReviewUrl,
      },
      {
        name: "Yelp",
        url: yelpReviewUrl,
      },
    ]
      .filter(({ url }) => url)
      .map((item) => ({
        ...item,
        onClick: () =>
          fetchData("recordEasySurveyLinkClick", {
            easySurveyId,
            linkName: item.name,
          }),
      })),
    handleFormSubmit: async ({ starRating, categories, comment }) => {
      const [res] = await fetchData("recordEasySurveyAnswers", {
        easySurveyId,
        starRating,
        comment: comment.slice(0, 800),
        categoriesString: categories.map(({ id }) => id).join(","),
      });

      const data = {
        ...res,
        ...Object.assign(
          {},
          ...["categories", "emails"].map((key) => ({
            [key]: JSON.parse(res[key]),
          }))
        ),
      };

      if (!data.wouldRecommend) {
        sendMailChimpEmail({
          html: renderToString(<EasySurveyTemplate {...data} />),
          subject: `Store ${data.storeNumber} - Thumbs Down Review`,
          fromEmail: "support@bigbrandtire.com",
          fromName: "Support Center",
          to: data.emails,
        });
      }

      return {
        thumbsUp: data.wouldRecommend,
      };
    },
  };
};

export const createVIPMember = async ({
  storeNumber,
  email,
  firstName,
  lastName,
}) => {
  const res = await fetchData("createVIPMember", {
    storeNumber,
    email,
    firstName,
    lastName,
  });

  return res?.[0];
};

export const getQuickLinkRecord = async ({
  quickLinkGuid,
  clientDeviceGuid,
}) => {
  const res = (
    await fetchData("getQuickLinkRecord", {
      quickLinkGuid,
      clientDeviceGuid,
    })
  ).map((item) => ({
    ...item,
    tireItemDetails: JSON.parse(item.tireItemDetails),
  }));

  return res?.[0];
};

export const getInStockAlternativeTireOptions = async (orderGUID) => {
  const res = await fetchData("getInStockAlternativeTireOptions", {
    orderGUID,
  });
  return res.map((opt) => ({
    ...opt,
    alternativeTireOptions: JSON.parse(opt.alternativeTireOptions),
  }));
};

export const verifySpecialOrder = async (orderGUID, requiresSpecialOrder) => {
  const payload = {
    RetailOrderGUID: orderGUID,
    RequiresSpecialOrder: requiresSpecialOrder,
  };

  const response = await fetchData("verifySpecialOrder", payload);
  return response;
};

export const addToCartTireOptions = async ({
  client,
  ClientDeviceDateTime,
  addToCartPathname,
  trackingTireList,
  sortByLabel,
  storeNumber,
}) => {
  const response = await fetchData("addToCartTireOptions", {
    client,
    ClientDeviceDateTime,
    addToCartPathname,
    trackingTireList,
    sortByLabel,
    storeNumber,
  });
  return response;
};

export const addVyperEvent = async ({
  clientDeviceGuid,
  campaign,
  edgeItemId,
  fullUrl,
  promotionDirectory,
  promotionEndpoint,
  source,
  medium,
}) => {
  const response = await fetchData("addVyperEvent", {
    clientDeviceGuid,
    campaign,
    edgeItemId,
    fullUrl,
    promotionDirectory,
    promotionEndpoint,
    source,
    medium,
  });
  return response;
};

export const sendCustomerTextOptIn = async (orderGUID, CustomerTextOptIn) => {
  const payload = {
    RetailOrderGUID: orderGUID,
    CustomerTextOptIn: CustomerTextOptIn,
  };

  const response = await fetchData("sendCustomerTextOptIn", payload);
  return response;
};
