import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const BulletIcon = ({ Icon, text, link = null }) => {
  let displayText = <span style={{ fontSize: 16 }}>{text}</span>;
  if (link) {
    displayText = (
      <Link component={RouterLink} to={link} style={{ fontSize: 16 }}>
        {text}
      </Link>
    );
  }

  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 15 }}>
      <Icon
        style={{
          marginRight: 15,
          fontSize: 25,
          color: theme.palette.primary.main,
        }}
      />
      {displayText}
    </div>
  );
};

export default BulletIcon;
