import StandardPageHeader from "../components/generic/standard-page-header.js";
import MetaTags from "../components/reusable/ReactMetaTags.jsx";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import DefaultContainer from "../components/generic/default-container.js";
import APP_ENV from "../appEnvironment";

const theme = APP_ENV.theme;

const ShippingPolicy = () => {
  const { appBrandName, appPhoneNumber } = APP_ENV;
  return (
    <>
      <MetaTags
        description="Order Online, Pick Up In Store."
        keywords="tires, tire, buy tires online, truck tires, 4x4 tires, suv tires, Goodyear, Michelin, car maintenance, tires in california, california tires, wheels, custom wheels, car wheels, Battery, Batteries, Oil Change, Oil Changes, Brake Job, Brake Jobs, big brand tire & service, big brand tire, big brand, bigbrandtire, bigbrand, discount tires, discount wheels"
        canonical="https://www.bigbrandtire.com/shipping-policy"
        title={`Shipping Policy | ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title="Shipping Policy"
        subtitle="Order Online, Pick Up In Store."
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 25,
            paddingBottom: 40,
          }}
        >
          <Grid item xs={11} sm={8} md={8} lg={10}>
            <Paper elevation={8} style={{ padding: "1.7em" }}>
              <Typography variant="h4">
                International and Domestic Shipping
              </Typography>
              <Typography>
                {`${APP_ENV.appBrandName} does not ship products internationally or domestically at this time.`}
              </Typography>
              <Typography variant="h4">Online Purchases</Typography>
              <Typography>
                Online purchases should be intalled in-store. When completing
                checkout, you will be prompted to choose a location to pick up
                products.
              </Typography>

              <Typography variant="h4">CONTACT US</Typography>
              <Typography>
                {`If you have any questions about this website or our On-line Shipping Policy, you
                                    can contact ${APP_ENV.appBrandName} by telephone
                                    at`}{" "}
                <a href={"tel:" + appPhoneNumber}>{appPhoneNumber}.</a>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default ShippingPolicy;
