import clientStorageBuilders from "./client-storage-builders";

const { createSessionStorage, createPersistStorage } = clientStorageBuilders;

const CLIENT_STORAGE = {
  storeNumber: createPersistStorage("__store__", 90),
  geo: createPersistStorage("__geo__", 30),
  cart: createPersistStorage("__cart__", 3),
  tireSize: createPersistStorage("__tireSize__", 90),
  vehicle: createPersistStorage("__vehicle__", 90),
  clientDeviceGuid: createPersistStorage("__clidev__", 365),
  originalLocation: createSessionStorage("__ogloc__"),
};

export default CLIENT_STORAGE;
