import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const StyledListItem = ({ children }) => {
  return (
    <ListItem style={{ paddingTop: 0 }}>
      <ListItemIcon style={{ minWidth: 30 }}>
        <FiberManualRecordIcon
          style={{ fontSize: 16, color: theme.palette.common.black }}
        />
      </ListItemIcon>
      {children}
    </ListItem>
  );
};

export const NormalListItem = ({ text }) => {
  return (
    <StyledListItem>
      <ListItemText primary={text} />
    </StyledListItem>
  );
};

export const FreeListItem = ({ text }) => {
  return (
    <StyledListItem>
      <ListItemText>
        <span style={{ color: "red", fontWeight: 600 }}>Free</span> {text}
      </ListItemText>
    </StyledListItem>
  );
};
