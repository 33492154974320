import { useContext } from "react";
import { Paper, Grid, Box, Divider } from "@material-ui/core";
import TireImage from "./widgets/tire-image";
import BrandModelSize from "./widgets/brand-model-size";
import TireSearchResultsExpandible from "./widgets/tire-search-results-expandible";
import ReviewStars from "./widgets/review-stars";
import TireFeaturesCollection from "./widgets/tire-features-collection";
import TireSpecsCollection from "./widgets/tire-specs-collection";
import APP_ENV from "../../appEnvironment";
import TirePackageAddToCartCollection from "./widgets/tire-package-add-to-cart-collection";
import TireItemChipMain from "../promotions/full-site-tire-sale/tire-item-chip-main";
import TireItemChipSecondary from "../promotions/full-site-tire-sale/tire-item-chip-secondary";
import TireItemSaveOn4Button from "../promotions/full-site-tire-sale/tire-item-save-on-4-button";
import { TireSearchResultsCardContext } from "./tire-search-results-card";

const { theme } = APP_ENV;

const TireSearchResultsCardDesktop = () => {
  const { tire, isStaggered } = useContext(TireSearchResultsCardContext);

  const defaultPromoQty = isStaggered ? 2 : 4;

  const promoTireItem = tire.qtyController(defaultPromoQty);

  return (
    <Paper style={{ marginBottom: 15, width: "100%" }} elevation={1}>
      <Grid container>
        <Grid item xs={3}>
          <TireItemChipMain {...tire} />
          <TireItemChipSecondary {...tire} />
          <TireImage {...tire} />
        </Grid>
        <Grid item xs={9}>
          <Grid container style={{ padding: 10 }}>
            <Grid item xs={7}>
              <Box style={{ paddingTop: 10 }}>
                <BrandModelSize {...tire} />
              </Box>
              <Box style={{ marginBottom: 8 }}>
                <ReviewStars {...tire} linkHref={tire.itemSlug} />
              </Box>
              <TireFeaturesCollection {...tire} />
            </Grid>
            <Grid item xs={5}>
              <TireItemSaveOn4Button promoTireItem={promoTireItem} />
            </Grid>
            <Grid item xs={5}>
              <TireSpecsCollection {...tire} />
            </Grid>
            <Grid item xs={7}>
              <Divider style={{ marginBottom: 8 }} />
              <TirePackageAddToCartCollection {...tire} />
              <TireSearchResultsExpandible />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TireSearchResultsCardDesktop;
