import { useState, useRef, useEffect } from "react";
import { Typography, Box, Button, Collapse } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";

const SemiCollapseableDescription = ({ description = "" }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const contentHeight = textRef.current.clientHeight;
      setShouldShowButton(contentHeight > 50);
    }
  }, [description]);

  return (
    <>
      {Boolean(description) && (
        <>
          <Collapse in={isExpanded} collapsedSize={50}>
            <Typography ref={textRef}>{description}</Typography>
          </Collapse>
          <Box
            style={{
              display: "flex",
              width: "100%",
              paddingRight: 20,
              justifyContent: "flex-end",
              marginBottom: 20,
            }}
          >
            {shouldShowButton && (
              <Button
                size={"small"}
                onClick={() => setIsExpanded((prev) => !prev)}
                endIcon={
                  isExpanded ? (
                    <ExpandLessRoundedIcon />
                  ) : (
                    <ExpandMoreRoundedIcon />
                  )
                }
              >
                {isExpanded ? "Show less" : "Show more"}
              </Button>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default SemiCollapseableDescription;
