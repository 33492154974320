import { useState, useEffect } from "react";
import { Box, Typography, Link, Button } from "@material-ui/core";
import { useLocation, useHistory, Link as RouterLink } from "react-router-dom";
import { useLocationStore } from "../hooks/store/location-store";
import { getQuickLinkRecord } from "../services/venom-service";
import ClientTrackingStore from "../hooks/store/client/client-tracking-store";
import TireItemsStore from "../hooks/store/tire-items-store";
import DefaultContainer from "../components/generic/default-container";
import StandardPageHeader from "../components/generic/standard-page-header";
import APP_ENV from "../appEnvironment";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import LayoutStore from "../hooks/store/client/layout-store";
import TireSearchResultsCard from "../components/tires/tire-search-results-card";

const { theme } = APP_ENV;

const initialState = {
  isLoading: false,
  employeeName: "",
  tires: [],
};

const QuickLink = () => {
  const [state, setState] = useState(initialState);

  const { search } = useLocation();
  const history = useHistory();

  const handleError = () => history.push("/");

  const [getLocationStoreAsync] = useLocationStore((st) => [
    st.getLocationStoreAsync,
  ]);

  const [getActiveTireItems] = TireItemsStore((st) => [st.getActiveTireItems]);

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  useEffect(() => {
    const GUID_PARAM = "guid";
    const quickLinkGuid =
      search.includes(GUID_PARAM) &&
      [...search.split(`?${GUID_PARAM}=`)?.[1]?.split("&")][0];

    if (!quickLinkGuid) {
      handleError();
      return;
    }

    setState({ ...initialState, isLoading: true });

    (async () => {
      const [{ storeNumber, employeeName, tireItemDetails }, { locations }] = (
        await Promise.allSettled([
          getQuickLinkRecord({
            quickLinkGuid,
            clientDeviceGuid: ClientTrackingStore.getState().clientDeviceGuid,
          }),
          getLocationStoreAsync(),
        ])
      ).map(({ value }) => value);

      const quickLinkLocation = locations.find(
        (loc) => loc.storeNumber == storeNumber
      );

      if (!quickLinkLocation) {
        handleError();
        return;
      }

      quickLinkLocation.setSelected();

      const tires = (
        await Promise.allSettled(
          tireItemDetails.map(({ brandEndPoint, partNumberEndPoint }) =>
            getActiveTireItems({ brandEndPoint, partNumberEndPoint })
          )
        )
      )
        .map(({ value }) => value)
        .flat(Infinity);

      setState({
        ...initialState,
        employeeName,
        tires,
      });
    })();
  }, []);

  return (
    <>
      <StandardPageHeader
        title={"Review Tire Options"}
        subtitle={
          state.employeeName && `Your service advisor: ${state.employeeName}`
        }
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Box
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box style={{ maxWidth: 850 }}>
            {state.tires.map((tire, i) => {
              return (
                <Box key={i}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{ fontWeight: 600, fontSize: 18 }}
                    >{`Option ${i + 1}`}</Typography>

                    <Button
                      component={RouterLink}
                      to={tire.tireSizeSlug}
                      size="small"
                      color="primary"
                      style={{ fontSize: 15 }}
                      endIcon={<KeyboardArrowRightIcon />}
                    >
                      {`Shop ${tire.tireSizeDisplay}`}
                    </Button>
                  </Box>
                  <TireSearchResultsCard tire={tire} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </DefaultContainer>
    </>
  );
};

export default QuickLink;
