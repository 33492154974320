import { Breadcrumbs, Link, Typography, Box } from "@material-ui/core";
import DefaultContainer from "./default-container";
import { Link as RouterLink } from "react-router-dom";

const BreadCrumb = ({ paths = [] }) => {
  const fontSize = 14;

  const lastItemIndex = Math.max(0, paths.length - 1);
  const current = paths[lastItemIndex]?.[0] ?? "";
  const linkPaths = paths.filter((_, i) => i < lastItemIndex);

  return (
    <DefaultContainer>
      <Box style={{ paddingTop: 15 }}>
        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize }}>
          {[["Home", "/"], ...linkPaths].map(([text, href], i) => {
            return (
              <Link key={i} component={RouterLink} to={href} color="inherit">
                {text}
              </Link>
            );
          })}
          <Typography color="textPrimary" style={{ fontSize }}>
            {current}
          </Typography>
        </Breadcrumbs>
      </Box>
    </DefaultContainer>
  );
};

export default BreadCrumb;
