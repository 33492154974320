import create from "zustand";
import { useEffect } from "react";
import { getHotDealsTireRebates } from "../../services/venom-service";
import SessionTimerStore from "./client/session-timer-store";

let _sessionId;
let _promise;

const HotDealsStore = create((set, get) => {
  const initialState = {
    isReady: false,
    activeTireRebates: [],
    expiredTireRebates: [],
  };

  const getHotDealsStoreAsync = async () => {
    const sessionId = SessionTimerStore.getState().sessionId;
    if (_sessionId !== sessionId) {
      _sessionId = sessionId;
      set({ isReady: false });
    }

    const _state = get();

    if (!_state.isReady && !_promise) {
      _promise = (async () => {
        const data = await getHotDealsTireRebates();
        set({
          isReady: true,
          activeTireRebates: data.filter((i) => !i.isExpired),
          expiredTireRebates: data.filter((i) => i.isExpired),
        });
        _promise = null;
      })();
    }

    if (!_state.isReady) {
      await _promise;
    }

    return get();
  };

  return {
    ...initialState,
    getHotDealsStoreAsync,
  };
});

const useHotDealsStore = (selector = (store) => store) => {
  const [getHotDealsStoreAsync] = HotDealsStore((st) => [
    st.getHotDealsStoreAsync,
  ]);

  const [sessionId] = SessionTimerStore((st) => [st.sessionId]);

  useEffect(() => {
    if (_sessionId !== sessionId) {
      getHotDealsStoreAsync();
    }
  }, []);

  return HotDealsStore(selector);
};

export default useHotDealsStore;
