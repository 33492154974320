import create from "zustand";
import { useEffect } from "react";
import CartStore, { useCartStore } from "./cart-store";
import { getAppointmentAvailability } from "../../services/venom-service";
import LocationStore from "./location-store";
import dayjs from "dayjs";
import { VYPER_FREE_OIL_CHANGE } from "../../components/promotions/vyper/vyper-free-oil-change";
import SessionTimerStore from "./client/session-timer-store";

const minMax = require("dayjs/plugin/minMax");

dayjs.extend(minMax);

export const VENOM_APPT_SERVICE_TYPE = {
  tireRotationFlats: 1,
  tiresMoreThanTwo: 2,
  oilChange: 3,
  mechanicalAlignment: 4,
};

export const WEB_APPOINTMENT_TYPES = {
  dropOff: 1,
  waiting: 2,
};

let _promise;

const AppointmentStore = create((set, get) => {
  const initialState = {
    sessionId: null,
    storeNumber: 0,
    venomApptServiceTypeId: null,
    inventoryArrivesDateTime: null,
    inventoryArrivesDateTimeDisplay: null,
    isInventoryInStock: true,
    isInventoryAvailable: true,
    soonestApptDateTimeDisplay: null,
    appointmentSchedule: [],
    userAppointment: null,
  };

  const getAppointmentStoreAsync = async () => {
    const {
      cartItems,
      hasTiresinCart,
      isInventoryInStock,
      isInventoryAvailable,
      inventoryArrivesDateTime,
      inventoryArrivesDateTimeDisplay,
    } = await CartStore.getState().getCartStoreAsync();

    if (!cartItems.length) {
      set(initialState);
      return get();
    }

    const sessionId = SessionTimerStore.getState().sessionId;

    const { userStore } =
      await LocationStore.getState().getLocationStoreAsync();

    const { storeNumber } = userStore;

    const webApptServiceTypes = cartItems.map(
      ({ venomApptServiceTypeId }) => venomApptServiceTypeId
    );

    let venomApptServiceTypeId;
    switch (true) {
      case webApptServiceTypes.includes(
        VENOM_APPT_SERVICE_TYPE.tiresMoreThanTwo
      ):
        venomApptServiceTypeId = VENOM_APPT_SERVICE_TYPE.tiresMoreThanTwo;
        break;
      case hasTiresinCart:
        venomApptServiceTypeId = VENOM_APPT_SERVICE_TYPE.tireRotationFlats;
        break;
      case webApptServiceTypes.includes(VENOM_APPT_SERVICE_TYPE.oilChange):
        venomApptServiceTypeId = VENOM_APPT_SERVICE_TYPE.oilChange;
        break;
      case webApptServiceTypes.includes(
        VENOM_APPT_SERVICE_TYPE.mechanicalAlignment
      ):
        venomApptServiceTypeId = VENOM_APPT_SERVICE_TYPE.mechanicalAlignment;
        break;
      default:
        venomApptServiceTypeId = VENOM_APPT_SERVICE_TYPE.tireRotationFlats;
    }

    const _state = get();

    const isReady =
      _state.sessionId == sessionId &&
      _state.storeNumber == storeNumber &&
      _state.venomApptServiceTypeId == venomApptServiceTypeId &&
      _state.inventoryArrivesDateTime == inventoryArrivesDateTime &&
      _state.isInventoryInStock == isInventoryInStock &&
      _state.isInventoryAvailable == isInventoryAvailable;

    if (!isReady && !_promise) {
      set(initialState);
      _promise = (async () => {
        const getApptDisplayDate = (dateTime) =>
          userStore.useCalendarDisplay(dateTime, {
            sameDay: "[Today -] dddd, MMM D",
            nextDay: "[Tomorrow -] dddd, MMM D",
            nextWeek: "dddd, MMMM D",
            sameElse: "dddd, MMMM D",
          });

        const getApptDisplayTime = (dateTime) =>
          dayjs(dateTime).format("h:mm A");

        const getApptDisplayDateTime = (dateTime) =>
          `${getApptDisplayDate(dateTime)} at ${getApptDisplayTime(dateTime)}`;

        const params = {
          storeNumber,
          venomApptServiceTypeId,
          inventoryArrivesDateTime,
        };

        const data = await getAppointmentAvailability(params);

        let apptOptions = data;

        if (
          cartItems.some(
            ({ edgeItemId }) => edgeItemId == VYPER_FREE_OIL_CHANGE.edgeItemId
          )
        ) {
          apptOptions = data.map((appt, i) => {
            if (i < 2) {
              appt.dropOffAvailable = false;
            }
            return appt;
          });
        }

        const appointmentSchedule = apptOptions
          .filter(
            ({ dropOffAvailable, appointmentOptions }) =>
              dropOffAvailable ||
              appointmentOptions.some(({ isAvailable }) => isAvailable)
          )
          .map(
            ({
              theDate,
              dropOffAvailable,
              dropOffDateTime,
              dropOffCutOff,
              minimumDropOffHours,
              appointmentOptions,
            }) => {
              const createAppointmentPayload = (
                apptTypeId,
                apptDateTime = dropOffDateTime
              ) => {
                const isWaiting = apptTypeId == WEB_APPOINTMENT_TYPES.waiting;
                const apptPayLoad = {
                  apptTypeId,
                  isWaiting,
                  apptDateTime,
                  apptDateTimeDisplay: isWaiting
                    ? getApptDisplayDateTime(apptDateTime)
                    : getApptDisplayDate(apptDateTime),
                  apptMessage: isWaiting
                    ? "Please arrive 15 minutes prior to your appointment time for check in."
                    : `Please drop your vehicle off before ${getApptDisplayTime(
                        dropOffCutOff
                      )} if you need your services performed same day. We need at least ${minimumDropOffHours} hours for service.`,
                };

                return {
                  ...apptPayLoad,
                  setAppointment: () => set({ userAppointment: apptPayLoad }),
                };
              };

              const dropOffAppointment = dropOffAvailable
                ? createAppointmentPayload(WEB_APPOINTMENT_TYPES.dropOff)
                : null;

              const waitingApptOptions = appointmentOptions
                .map((item) => ({
                  ...item,
                  displayTime: getApptDisplayTime(item.apptDateTime),
                  ...createAppointmentPayload(
                    WEB_APPOINTMENT_TYPES.waiting,
                    item.apptDateTime
                  ),
                }))
                .filter(
                  ({ isAvailable, apptDateTime }, i) =>
                    (isAvailable &&
                      dayjs(apptDateTime).isAfter(userStore.currentDateTime)) ||
                    i % 2 == 0
                );

              return {
                theDateISO: dayjs(theDate).toISOString(),
                displayDate: getApptDisplayDate(theDate),
                dropOffAppointment,
                waitingApptOptions,
              };
            }
          );

        let soonestApptDateTimeDisplay = "";

        appointmentSchedule.forEach(
          ({ dropOffAppointment, waitingApptOptions }) => {
            if (!soonestApptDateTimeDisplay) {
              soonestApptDateTimeDisplay = waitingApptOptions.find(
                ({ isAvailable }) => isAvailable
              )?.apptDateTimeDisplay;
            }
            if (!soonestApptDateTimeDisplay && dropOffAppointment) {
              soonestApptDateTimeDisplay =
                dropOffAppointment.apptDateTimeDisplay;
            }
          }
        );

        set({
          sessionId,
          ...params,
          inventoryArrivesDateTime,
          inventoryArrivesDateTimeDisplay,
          isInventoryInStock,
          isInventoryAvailable,
          soonestApptDateTimeDisplay,
          appointmentSchedule,
        });
        _promise = null;
      })();
    }

    if (!isReady) {
      await _promise;
    }

    return get();
  };

  const getEdgeAppointmentDetails = () => {
    const {
      userAppointment: ua = {},
      venomApptServiceTypeId = VENOM_APPT_SERVICE_TYPE.tireRotationFlats,
    } = get();

    return {
      AppointmentDropOffTypeId: ua.apptTypeId,
      AppointmentServiceTypeId: venomApptServiceTypeId,
      AppointmentDateTime: ua.apptDateTime,
      AppointmentDateTimeDisplay: ua.apptDateTimeDisplay,
      AppointmentArrivalMessage: ua.apptMessage,
    };
  };

  return {
    ...initialState,
    getAppointmentStoreAsync,
    clearUserAppointment: () => set({ userAppointment: null }),
    clearAppointmentStore: () => set(initialState),
    getEdgeAppointmentDetails,
  };
});

export const useAppointmentStore = (selector = (store) => store) => {
  const [getAppointmentStoreAsync] = AppointmentStore((st) => [
    st.getAppointmentStoreAsync,
  ]);
  const cart = useCartStore();

  useEffect(() => {
    getAppointmentStoreAsync();
  }, [cart]);

  return AppointmentStore(selector);
};

export default AppointmentStore;
