import create from "zustand";
import { getTireSizes } from "../../services/venom-service";
import CLIENT_STORAGE from "./client/storage/client-storage";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { REAR_TIRE_SIZE_KEY } from "../../views/tires/tire-search-results-wrapper";

let isReady;

const TireSizeStore = create((set, get) => {
  const initialState = {
    tireSizes: [],
    userTireSize: null,
  };

  let promise;

  const getTireSizeStoreAsync = async () => {
    if (!isReady && !promise) {
      promise = (async () => {
        const tireSizes = await getTireSizes();

        set({ tireSizes });

        const val = CLIENT_STORAGE.tireSize.getItem();

        let frontTireSizeEndPoint = "";
        let rearTireSizeEndPoint = "";

        const localStorageSize = CLIENT_STORAGE.tireSize.getItem();

        if (Array.isArray(localStorageSize)) {
          const [_front = "", _rear = ""] = localStorageSize;
          frontTireSizeEndPoint = _front;
          rearTireSizeEndPoint = _rear;
        }

        setUserTireSize({ frontTireSizeEndPoint, rearTireSizeEndPoint });

        isReady = true;
        promise = null;
        return;
      })();
    }

    if (!isReady) {
      await promise;
    }

    return get();
  };

  const getTireSizeByTireSizeEndPoint = (tireSizeEndPoint = "") => {
    const { tireSizes } = get();

    return tireSizes.find((ts) => ts.tireSizeEndPoint == tireSizeEndPoint);
  };

  const createTireSearchEndPoint = (tireSizeEndPoints = []) => {
    let tireSearchEndPoint = "";
    const [frontTireSizeEndPoint = "", rearTireSizeEndPoint = ""] =
      tireSizeEndPoints;
    if (frontTireSizeEndPoint) {
      tireSearchEndPoint = `/search/1/${frontTireSizeEndPoint}`;
      if (
        rearTireSizeEndPoint &&
        frontTireSizeEndPoint !== rearTireSizeEndPoint
      ) {
        tireSearchEndPoint += `?${REAR_TIRE_SIZE_KEY}=${rearTireSizeEndPoint}`;
      }
    }

    return tireSearchEndPoint;
  };

  const setUserTireSize = ({
    frontTireSizeEndPoint = "",
    rearTireSizeEndPoint = "",
  } = {}) => {
    const DEFUALT_TIRE_SIZE =
      getTireSizeByTireSizeEndPoint("225-45-17") ?? get().tireSizes[0];

    const frontTireSize = getTireSizeByTireSizeEndPoint(frontTireSizeEndPoint);
    const rearTireSize = getTireSizeByTireSizeEndPoint(rearTireSizeEndPoint);

    const front = frontTireSize ?? rearTireSize ?? DEFUALT_TIRE_SIZE;
    const rear = rearTireSize ?? frontTireSize ?? DEFUALT_TIRE_SIZE;
    const isStaggered = front.tireSizeEndPoint !== rear.tireSizeEndPoint;
    const tireSearchEndPoint = createTireSearchEndPoint([
      front.tireSizeEndPoint,
      rear.tireSizeEndPoint,
    ]);

    const userTireSize = {
      front,
      rear,
      isStaggered,
      tireSearchEndPoint,
    };

    CLIENT_STORAGE.tireSize.setItem([
      userTireSize.front.tireSizeEndPoint,
      userTireSize.rear.tireSizeEndPoint,
    ]);

    set({ userTireSize });

    return userTireSize;
  };

  const tireSizeController = async ({
    width = null,
    ratio = null,
    rim = null,
    tireSizeEndPoint = null,
  } = {}) => {
    const { tireSizes } = await getTireSizeStoreAsync();

    let response;
    switch (true) {
      case tireSizeEndPoint:
        response = getTireSizeByTireSizeEndPoint(tireSizeEndPoint);
        break;
      case !width:
        response = [...new Set(tireSizes.map((ts) => ts.width))];
        break;
      case !ratio:
        response = [
          ...new Set(
            tireSizes.filter((ts) => ts.width == width).map((ts) => ts.ratio)
          ),
        ];
        break;
      case !rim:
        response = [
          ...new Set(
            tireSizes
              .filter((ts) => ts.width == width && ts.ratio == ratio)
              .map((ts) => ts.rim)
          ),
        ];
        break;
      default:
        response = tireSizes.find((ts) =>
          Boolean(ts.width == width && ts.ratio == ratio && ts.rim == rim)
        );
    }

    return response;
  };

  return {
    ...initialState,
    getTireSizeStoreAsync,
    tireSizeController,
    getTireSizeByTireSizeEndPoint,
    setUserTireSize,
    createTireSearchEndPoint,
  };
});

export const useTireSizeStore = (selectorFunc = (store) => store) => {
  const [getTireSizeStoreAsync] = TireSizeStore((st) => [
    st.getTireSizeStoreAsync,
  ]);

  useEffect(() => {
    if (!isReady) {
      getTireSizeStoreAsync();
    }
  }, []);

  return TireSizeStore(selectorFunc);
};

export default TireSizeStore;
