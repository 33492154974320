import dayjs from "dayjs";
import { roundMoney } from "../../helpers/generic";

const EasySurveyTemplate = ({
  storeNumber,
  invoiceHeaderId,
  customerName,
  invoicedDate,
  invoiceTotal,
  edgeInvoiceLink,
  surveyDate,
  wouldRecommend,
  starRating,
  categories,
  comment,
}) => {
  const headerStyle = {
    padding: 8,
    backgroundColor: "#c7c7c7",
  };

  const TableRow = ({ values, index }) => {
    return (
      <tr
        style={{
          backgroundColor: index % 2 == 0 ? "#fff" : "#f2f2f2",
        }}
      >
        <td
          style={{
            padding: 8,
          }}
        >
          {values.map((value, x) => (
            <span
              key={x}
              style={
                x == 0 ? { fontSize: 14, fontWeight: 600 } : { float: "right" }
              }
            >
              {value}
            </span>
          ))}
        </td>
      </tr>
    );
  };

  return (
    <div style={{ margin: 0, padding: 0, width: "100%" }}>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h4>Your service center just received a thumbs down review.</h4>
      </div>

      <div
        style={{
          width: "100%",
          padding: 10,
          marginBottom: 50,
        }}
      >
        <table
          style={{
            width: "100%",
            maxWidth: 500,
            marginLeft: "auto",
            marginRight: "auto",
            borderCollapse: "collapse",
            borderLeft: "1px solid grey",
            borderRight: "1px solid grey",
            borderTop: "1px solid grey",
          }}
        >
          <thead>
            <tr>
              <th style={headerStyle}>Ticket Infomation</th>
            </tr>
          </thead>
          <tbody>
            {[
              ["Store Number", storeNumber],
              ["Invoice Number", invoiceHeaderId],
              ["Customer Name", customerName],
              ["Invoice Date", dayjs(invoicedDate).format("MM-DD-YYYY")],
              ["Invoice Total", `$${roundMoney(invoiceTotal).toFixed(2)}`],
              [
                "Edge Invoice Link",
                <a target={"_blank"} href={edgeInvoiceLink}>
                  Open
                </a>,
              ],
            ].map((values, i) => {
              return <TableRow key={i} values={values} index={i} />;
            })}
          </tbody>
        </table>

        <table
          style={{
            width: "100%",
            maxWidth: 500,
            marginLeft: "auto",
            marginRight: "auto",
            borderCollapse: "collapse",
            border: "1px solid grey",
          }}
        >
          <thead>
            <tr>
              <th style={headerStyle}>Customer Review</th>
            </tr>
          </thead>
          <tbody>
            {[
              ["Review Submitted", dayjs(surveyDate).format("MM-DD-YYYY")],
              ["Would Recommend", wouldRecommend ? "Yes" : "No"],
              ["Star Rating", starRating],
              ["Complaints", categories.map(({ name }) => name).join(", ")],
              ["Comment", comment],
            ].map((values, i) => {
              return <TableRow key={i} values={values} index={i} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EasySurveyTemplate;
