import create from "zustand";
import { v4 as uuidv4 } from "uuid";

const TireSearchFilterStore = create((set, get) => {
  const setState = (func = () => {}) =>
    set((prev) => {
      const _state = func(prev);
      const { filteredTires, sorts } = _state;
      const tireSet = sorts.options
        .find(({ isSelected }) => isSelected)
        .sortFunc(filteredTires);

      return {
        ..._state,
        tireSetId: uuidv4(),
        tireSet,
      };
    });

  const FILTERS = [
    [
      "Brand",
      "brandEndPoint",
      (value, tires) =>
        tires.find((item) => item.brandEndPoint == value)?.brandName,
      (values) => values.sort(),
    ],
    [
      "Speed Rating",
      "speedRatingId",
      (value, tires) => {
        const { speedRating, milesPerHour } = tires.find(
          (item) => item.speedRatingId == value
        );
        return `${speedRating} Rated: ${milesPerHour}mph`;
      },
      (values) => values.sort((a, b) => a - b),
    ],
    [
      "Load Range",
      "loadRangeId",
      (value, tires) =>
        tires.find((item) => item.loadRangeId == value)?.loadRange,
      (values) => values.filter((i) => i && i !== 14).sort((a, b) => a - b),
    ],
    [
      "Run-Flat",
      "isRunFlat",
      (value) => (Boolean(value) ? "Run-Flat Tires" : "Non Run-Flat Tires"),
      (values) => values.sort().reverse(),
    ],
    [
      "Winter",
      "isSnowRated",
      () => "Winter Tires Only",
      (values) => values.filter((i) => i),
    ],
  ].map(
    ([
        label,
        tireItemKey,
        mapValuesToLabels,
        sortLabels = (labels) => labels,
      ]) =>
      (tires = [], groupIndex = 0) => ({
        label,
        key: tireItemKey,
        groupIndex,
        options: sortLabels([
          ...new Set(tires.map((item) => item[tireItemKey])),
        ]).map((value) => ({
          value,
          label: mapValuesToLabels(value, tires),
          isSelected: false,
          isDisabled: false,
          onSelect: () => {
            const { filters: groupFilters = [], nestedTires = [] } = get();

            const activeGroupIndex = groupIndex;
            const syncGroupIndex =
              nestedTires.length > 1 ? (activeGroupIndex === 0 ? 1 : 0) : null;

            let _filters = groupFilters.map((group) =>
              group.map((filter) => ({
                ...filter,
                options: filter.options.map((opt) => ({ ...opt })),
              }))
            );

            _filters = _filters.map((groupFilter, idx) =>
              idx === activeGroupIndex
                ? groupFilter.map((_filter) =>
                    _filter.key !== tireItemKey
                      ? _filter
                      : {
                          ..._filter,
                          options: _filter.options.map((opt) => ({
                            ...opt,
                            isSelected:
                              opt.value == value
                                ? !opt.isSelected
                                : opt.isSelected,
                          })),
                        }
                  )
                : groupFilter
            );

            if (nestedTires.length > 1 && syncGroupIndex !== null) {
              const activeFilter = _filters[activeGroupIndex].find(
                (f) => f.key === tireItemKey
              );
              const activeOption = activeFilter.options.find(
                (o) => o.value === value
              );

              _filters[syncGroupIndex] = _filters[syncGroupIndex].map(
                (_filter) => {
                  if (_filter.key !== tireItemKey) return _filter;

                  return {
                    ..._filter,
                    options: _filter.options.map((opt) => ({
                      ...opt,
                      isSelected:
                        opt.value === value && !opt.isDisabled
                          ? activeOption.isSelected
                          : opt.isSelected,
                    })),
                  };
                }
              );
            }

            const getFilteredTires = (filterOptSelector) => {
              return nestedTires.map((tireGroup, idx) => {
                const filtersForGroup = _filters[idx];
                const activeFilters = filtersForGroup.map((_filter) => {
                  const selectedOptions = _filter.options.filter((opt) =>
                    filterOptSelector(_filter, opt)
                  );

                  const validValues = {};
                  for (const opt of selectedOptions) {
                    validValues[opt.value] = true;
                  }

                  return {
                    key: _filter.key,
                    validValues,
                    valuesCount: Object.keys(validValues).length,
                  };
                });

                return tireGroup.filter((tireItem) =>
                  activeFilters.every(
                    ({ key, validValues, valuesCount }) =>
                      valuesCount === 0 || validValues[tireItem[key]]
                  )
                );
              });
            };

            const filters = _filters.map((groupFilter, idx) => {
              const groupTires = nestedTires[idx];

              const selectedFilters = {};
              for (const filter of groupFilter) {
                const selectedValues = [];
                for (const option of filter.options) {
                  if (option.isSelected) {
                    selectedValues.push(option.value);
                  }
                }
                if (selectedValues.length > 0) {
                  selectedFilters[filter.key] = selectedValues;
                }
              }

              return groupFilter.map((filter) => ({
                ...filter,
                options: filter.options.map((option) => {
                  const matchingTires = groupTires.filter((tire) => {
                    for (const [key, values] of Object.entries(
                      selectedFilters
                    )) {
                      if (key === filter.key) continue;
                      if (!values.includes(tire[key])) return false;
                    }
                    return true;
                  });

                  const hasMatchingTires = matchingTires.some(
                    (tire) => tire[filter.key] === option.value
                  );

                  return {
                    ...option,
                    isSelected:
                      option.isSelected &&
                      (hasMatchingTires || option.value === value),
                    isDisabled: !hasMatchingTires && !option.isSelected,
                  };
                }),
              }));
            });

            const isOptionSelected = (_filter, _option) => _option.isSelected;
            const filteredTires = getFilteredTires(isOptionSelected);

            setState((prev) => ({ ...prev, filters, filteredTires }));
          },
        })),
      })
  );

  const SORTS = {
    label: "Sort By",
    options: [
      [
        "Top Recommended",
        "top-recommended",
        (tires) =>
          tires.map((group) =>
            [...group].sort((a, b) => a.originalSortIndex - b.originalSortIndex)
          ),
      ],
      [
        "Price High to Low",
        "price-high-to-low",
        (tires) =>
          tires.map((group) => [...group].sort((a, b) => b.price - a.price)),
      ],
      [
        "Price Low to High",
        "price-low-to-high",
        (tires) =>
          tires.map((group) => [...group].sort((a, b) => a.price - b.price)),
      ],
    ].map(([label, value, sortFunc], i) => ({
      label,
      value,
      sortFunc,
      isSelected: Boolean(i == 0),
      onSelect: () =>
        setState((prev) => ({
          ...prev,
          sorts: {
            ...prev.sorts,
            options: prev.sorts.options.map((_option) => ({
              ..._option,
              isSelected: _option.value == value,
            })),
          },
        })),
    })),
  };

  return {
    totalTireCounts: [],
    filters: null,
    sorts: SORTS,
    tireSetId: null,
    tireSet: [],
    filteredTires: [],
    matchedTires: [],
    initializeTireFilters: (nestedTires = [], matchedTireSizes = []) => {
      const groupFilters = nestedTires.map((tireGroup, groupIndex) => {
        const tiresWithIndex = tireGroup.map((tire, i) => ({
          ...tire,
          originalSortIndex: i,
        }));
        return FILTERS.map((init) => init(tiresWithIndex, groupIndex));
      });

      setState((prev) => ({
        ...prev,
        totalTireCounts: nestedTires.map((group) => group.length),
        filters: groupFilters,
        filteredTires: nestedTires,
        sorts: SORTS,
        matchedTireSizes,
        nestedTires,
      }));
    },
  };
});

export default TireSearchFilterStore;
