import * as React from "react";
import { roundMoney } from "../../helpers/generic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const LOGO_IMAGE = {
  1: "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/bbt_stroke_logo.png",
  2: "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/tw/tw_stroke_logo.png",
  3: "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/atd/atd_stroke_logo.png",
};

const RetailOrderConfirmationTemplate = ({
  isForEmail = false,
  invoiceHeaderId = null,
  cartItems,
  subtotal,
  tax,
  totalPrice,
  appointmentArrivalMessage,
  customerFirstName,
  customerLastName,
  customerPhoneNumber,
  customerEmail,
  appointmentDropOffTypeId,
  appointmentDateTimeDisplay,
  orderStore,
}) => {
  return (
    <>
      <div style={{ margin: 0, padding: 0 }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: 800,
              boxSizing: "border-box",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {isForEmail && (
              <div
                style={{
                  display: "none",
                }}
              >
                <span>{`Your appointment for ${appointmentDateTimeDisplay} at ${orderStore.displayName} has been confirmed.`}</span>
              </div>
            )}
            <div
              style={{
                boxSizing: "border-box",
                backgroundColor: theme.palette.footer1.main,
                paddingTop: 15,
                paddingBottom: 15,
                textAlign: "center",
              }}
            >
              {isForEmail && <img src={LOGO_IMAGE[APP_ENV.appBrandId]} />}
              {!isForEmail && (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 28,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  <CheckCircleOutlineIcon
                    style={{
                      fontSize: 28,
                      color: theme.palette.primary.contrastText,
                      marginRight: 10,
                    }}
                  />
                  Thank you!
                </span>
              )}
            </div>
            <div
              style={{
                boxSizing: "border-box",
                backgroundColor: theme.palette.grey[100],
                padding: 10,
              }}
            >
              <p
                style={{
                  fontSize: 28,
                  fontWeight: 600,
                  marginTop: 10,
                  marginBottom: 8,
                }}
              >
                {`Hello ${customerFirstName}!`}
              </p>
              <p style={{ fontSize: 16, marginBottom: 20, marginTop: 0 }}>
                Thank you so much for your order and we look forward to seeing
                you at our service center.
              </p>
              <div
                style={{
                  boxSizing: "border-box",
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 8,
                  marginTop: 20,
                  marginBottom: 20,
                  padding: 10,
                  paddingBottom: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 28,
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  Order Confirmation
                </p>
                {invoiceHeaderId && (
                  <p
                    style={{
                      fontSize: 16,
                      textAlign: "center",
                      fontWeight: 600,
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  >
                    {`Order: ${orderStore.storeNumber}-${invoiceHeaderId}`}
                  </p>
                )}

                <hr />

                <table
                  style={{
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          style={{
                            width: "100%",
                            maxWidth: 320,
                            minWidth: 200,
                            borderRadius: 8,
                            backgroundColor: theme.palette.background.paper,
                            float: "left",
                            boxSizing: "border-box",
                            marginRight: 5,
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  textAlign: "center",
                                  backgroundColor: theme.palette.grey[100],
                                  padding: 5,
                                  marginRight: 5,
                                }}
                              >
                                Guest
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              [`${customerFirstName} ${customerLastName}`],
                              [customerPhoneNumber],
                              [customerEmail],
                            ].map(([value], i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    style={{
                                      paddingLeft: 15,
                                      paddingTop: 10,
                                    }}
                                  >
                                    {value}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td style={{ padding: 15 }} />
                            </tr>
                          </tbody>
                        </table>

                        <table
                          style={{
                            width: "100%",
                            maxWidth: 320,
                            minWidth: 200,
                            borderRadius: 8,
                            backgroundColor: theme.palette.background.paper,
                            float: "left",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  textAlign: "center",
                                  backgroundColor: theme.palette.grey[100],
                                  padding: 5,
                                }}
                              >
                                Appointment Details
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              [
                                `Type: ${
                                  appointmentDropOffTypeId == 1
                                    ? "Drop Off"
                                    : "Waiting"
                                }`,
                                ,
                              ],
                              [`Date: ${appointmentDateTimeDisplay}`],
                            ].map(([label], i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    style={{
                                      paddingLeft: 15,
                                      paddingTop: 10,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {label}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td style={{ padding: 15 }} />
                            </tr>
                          </tbody>
                          <thead>
                            <tr style={{ marginTop: 10 }}>
                              <th
                                style={{
                                  textAlign: "center",
                                  backgroundColor: theme.palette.grey[100],
                                  padding: 5,
                                }}
                              >
                                Service Center
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              [orderStore.displayName],
                              [orderStore.phoneNumber],
                              [
                                `${orderStore.streetAddress} ${orderStore.city}, ${orderStore.stateAbbreviation} ${orderStore.zipCode}`,
                              ],
                            ].map(([label], i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    style={{
                                      paddingLeft: 15,
                                      paddingTop: 10,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {label}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td style={{ padding: 10 }} />
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              style={{
                boxSizing: "border-box",
                backgroundColor: theme.palette.background.paper,
                paddingTop: 20,
                paddingBottom: 20,
                paddingLeft: 12,
                paddingRight: 12,
              }}
            >
              <table
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              >
                <thead>
                  <tr
                    style={{
                      borderBottom: `2px solid ${theme.palette.divider}`,
                    }}
                  >
                    {[
                      ["Item", { paddingLeft: 8, textAlign: "left" }],
                      ["Qty", {}],
                      ["Price", { paddingRight: 8, textAlign: "right" }],
                    ].map(([label, props]) => {
                      return (
                        <th key={label} style={{ paddingBottom: 15, ...props }}>
                          {label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map(
                    ({ description, qty, price, itemSubtotal }, i) => {
                      const showTotal = itemSubtotal !== price;
                      const isDiscount = qty < 0;
                      const discountColor = !isDiscount
                        ? theme.palette.common.black
                        : theme.palette.error.main;
                      const makeMoney = (num) =>
                        `${isDiscount ? "-$" : "$"}${roundMoney(
                          num >= 0 ? num : num * -1
                        ).toFixed(2)}`;

                      return (
                        <React.Fragment key={i}>
                          <tr key={i}>
                            {[
                              [
                                description,
                                {
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  fontWeight: 600,
                                  color: discountColor,
                                  textAlign: "left",
                                },
                              ],
                              [
                                qty > 0 ? qty : qty * -1,
                                { color: discountColor },
                              ],
                              [
                                makeMoney(price),
                                {
                                  paddingRight: 8,
                                  textAlign: "right",
                                  color: discountColor,
                                  color: isDiscount
                                    ? discountColor
                                    : theme.palette.grey[700],
                                  fontSize: 14,
                                },
                              ],
                            ].map(([value, props = {}]) => (
                              <td
                                key={value}
                                style={{
                                  paddingTop: 8,
                                  paddingBottom: showTotal ? 8 : 16,

                                  ...props,
                                }}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                          {showTotal && (
                            <tr
                              style={{
                                borderBottom: `1px solid ${theme.palette.divider}`,
                              }}
                            >
                              <td />
                              <td />
                              <td
                                style={{
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  textAlign: "right",
                                  color: discountColor,
                                  fontWeight: 600,
                                }}
                              >
                                {makeMoney(itemSubtotal)}
                              </td>
                            </tr>
                          )}
                          <tr
                            style={{
                              borderBottom: `1px solid ${theme.palette.divider}`,
                            }}
                          >
                            <td />
                            <td />
                            <td />
                          </tr>
                        </React.Fragment>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
            <div
              style={{
                backgroundColor: theme.palette.grey[100],
                padding: 10,
                paddingTop: 20,
                boxSizing: "border-box",
              }}
            >
              <table
                style={{
                  width: "100%",
                  maxWidth: 450,
                  maxWidth: 350,
                  borderRadius: 8,
                  backgroundColor: theme.palette.background.paper,
                  marginLeft: "auto",
                }}
              >
                <tbody>
                  {[
                    ["Subtotal", subtotal],
                    ["Tax", tax],
                    ["Total", totalPrice, { paddingBottom: 15 }],
                  ].map(([label, value, props = {}]) => {
                    return (
                      <tr key={label}>
                        <td
                          style={{ paddingLeft: 15, paddingTop: 10, ...props }}
                        >
                          {label}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            paddingRight: 15,
                            paddingTop: 10,
                            fontWeight: 600,
                            ...props,
                          }}
                        >{`$${roundMoney(value).toFixed(2)}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div
              style={{
                backgroundColor: theme.palette.grey[100],
                padding: 10,
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  boxSizing: "border-box",
                  border: `2px solid ${theme.palette.divider}`,
                  borderRadius: 8,
                  padding: 10,
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {appointmentArrivalMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RetailOrderConfirmationTemplate;
