import MetaTags from "../../reusable/ReactMetaTags";
import APP_ENV from "../../../appEnvironment";

const CoolingSystemCheck = () => {
  return (
    <>
      <MetaTags
        description={`At ${APP_ENV.appBrandName} we have highly trained mechanics that 
                specialize in cooling system inspection and repair. Whether it's your raditor, water pump, or thermostat,
                we can help get you back on the road quickly.`}
        canonical="/car-services/cooling-system-check"
        title={`Cooling System Check | ${APP_ENV.appBrandName}`}
      />
      <div>
        <p>
          <span>
            <strong>What is the cooling system?</strong>
          </span>
        </p>
        <p>
          {`One of the most common reasons why vehicles breakdown or experience
          problems is because of problems with the engine cooling system. The
          purpose of your vehicle's cooling system is to maintain the
          temperature of the engine so that is does not get too hot. If your
          engine is running above or below the recommended temperature, your
          engine can quickly break and lead to expensive repairs.`}
        </p>
        <span>
          <strong>Common cooling system problems include:</strong>
        </span>
        <ol>
          <li>Bad Thermostat</li>
          <li>Leaking Head Gasket</li>
          <li>Bad Temperature Sensor</li>
          <li>Radiator leaks</li>
          <li>Water Pump leaks</li>
          <li>Leaking or broken coolant hoses</li>
        </ol>
        <p>
          <span>
            <strong>How much will it cost to inspect my cooling system?</strong>
          </span>
        </p>
        <p>
          {`At ${APP_ENV.appBrandName} we do many cooling system inspections
          for free. Some cooling system problems take longer than others to
          diagnose, and we do sometimes need to charge an inspection fee for
          more difficult inspections. If you would like to discuss your
          vehicle's cooling problems, please call us at ${APP_ENV.appPhoneNumber} and one
          of our trained staff members may be able to determine over the phone
          the difficulty of your inspection.`}
        </p>
      </div>
    </>
  );
};

export default CoolingSystemCheck;
