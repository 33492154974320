import { Typography, Grid, Paper, Button, List } from "@material-ui/core";
import {
  NormalListItem,
  FreeListItem,
} from "./widgets/oil-change-card-list-items";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const OilChangeCardNoPrice = ({
  label,
  image,
  description,
  isBest = false,
  openDialog,
  addToCart,
}) => {
  return (
    <Grid item xs={12} md={4} style={{ paddingLeft: 8, paddingRight: 8 }}>
      <Paper elevation={isBest ? 6 : 3}>
        {isBest && (
          <Paper
            elevation={3}
            style={{
              backgroundColor: theme.palette.success.main,
              padding: 10,
              width: "fit-content",
            }}
          >
            <Typography
              style={{
                color: theme.palette.common.white,
                fontWeight: "bold",
              }}
            >
              Highest Quality
            </Typography>
          </Paper>
        )}
        <Grid
          container
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Grid container style={{ textAlign: "center", display: "block" }}>
            <Typography variant="h4">{label}</Typography>
            <Typography variant="h4">Oil Change</Typography>
          </Grid>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <img width={"50%"} src={image} />
          </Grid>

          <Grid
            container
            style={{
              paddingTop: 15,
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography style={{ fontWeight: 600 }} gutterBottom>
              {description}
            </Typography>
          </Grid>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Button color="primary" onClick={openDialog}>
              Learn more
            </Button>
          </Grid>
          <Grid container style={{ paddingLeft: 15, paddingTop: 15 }}>
            <Typography style={{ fontWeight: "bold" }}>
              Service Includes:
            </Typography>
          </Grid>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <List>
              {[
                "Vehicle Specific Quarts of Oil",
                "New Engine Oil Filter",
                "Tire Rotation",
              ].map((text, i) => (
                <NormalListItem key={i} text={text} />
              ))}
              {[
                "Basic Underhood Inspection",
                "Brake Inspection",
                "Tire Air Pressure Check",
              ].map((text, i) => (
                <FreeListItem key={i} text={text} />
              ))}
            </List>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 20,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ width: 300, height: 50 }}
              onClick={addToCart}
            >
              Add To Cart
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default OilChangeCardNoPrice;
