import { Box, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router";
import Rating from "@material-ui/lab/Rating";
import { TIRE_REVIEW_PARAM } from "../../tire-reviews/tire-review-controller";

const ReviewStars = ({
  reviewCount = 0,
  reviewAvg = 0,
  fontSize = 0,
  linkHref = "",
}) => {
  const { pathname } = useLocation();

  const linkText = !reviewCount
    ? "Not Yet Rated"
    : `${reviewCount} Review${reviewCount == 1 ? "" : "s"}`;

  return (
    <>
      <Box
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Rating
          value={reviewAvg}
          precision={0.5}
          style={{ fontSize: 20, marginRight: 10 }}
          readOnly
        />
        <Link
          style={{ fontSize: fontSize || 13 }}
          component={RouterLink}
          to={{
            pathname: linkHref || pathname,
            search: TIRE_REVIEW_PARAM,
          }}
        >
          {linkText}
        </Link>
      </Box>
    </>
  );
};

export default ReviewStars;
