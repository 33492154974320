import { useHistory, useLocation } from "react-router-dom";
import LayoutStore from "../store/client/layout-store";
import ModalStore from "../store/modal-store";
import { TIRES_ROUTE_DIRECTORY } from "../../routes";

const useOpenTireSizeSelector = ({ pushToTiresPage = true }) => {
  const history = useHistory();
  const location = useLocation();
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const [openMobile, openDesktop] = ModalStore((st) => [
    st.VehicleTireSizeSelectionDrawerMobile.openModal,
    st.VehicleTireSizeSelectionModalDesktop.openModal,
  ]);

  const openModal = () => {
    isMobile ? openMobile() : openDesktop();

    if (pushToTiresPage && location.pathname !== TIRES_ROUTE_DIRECTORY) {
      history.push(TIRES_ROUTE_DIRECTORY);
    }
  };

  return openModal;
};

export default useOpenTireSizeSelector;
