import { useEffect } from "react";
import { useRetailOrderConfirmation } from "../../hooks/useRetailOrder";
import RetailOrderConfirmationTemplate from "../../components/order-confirmation/retail-order-confirmation-template";
import DefaultContainer from "../../components/generic/default-container";
import { Box, Paper } from "@material-ui/core";
import APP_ENV from "../../appEnvironment";
import MetaTags from "../../components/reusable/ReactMetaTags";

const { theme } = APP_ENV;

const OrderConfirmationRetail = () => {
  const { getOrder, isReady, order } = useRetailOrderConfirmation();

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <>
      <MetaTags
        canonical="/order-confirmation"
        title={`Order Confirmation | ${APP_ENV.appBrandName}`}
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Box
          style={{
            paddingTop: 25,
            paddingBottom: 25,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Paper style={{ width: "fit-content" }} elevation={5}>
            {Boolean(isReady && order) && (
              <RetailOrderConfirmationTemplate isEmail={false} {...order} />
            )}
          </Paper>
        </Box>
      </DefaultContainer>
    </>
  );
};

export default OrderConfirmationRetail;
