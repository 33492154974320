import { useState, useEffect, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import useTireItemsByBrandModel from "../../hooks/useTireItemsByBrandModel";
import MetaTags from "../../components/reusable/ReactMetaTags";
import BreadCrumb from "../../components/generic/bread-crumb";
import DefaultContainer from "../../components/generic/default-container";
import SemiCollapseableDescription from "../../components/generic/semi-collapseable-description";
import TireBrandModelHeader from "../../components/tires/tire-brand-model-header";
import APP_ENV from "../../appEnvironment";
import SaveXAmountBox from "../../components/tires/widgets/save-x-amount-box";
import TireReviewController, {
  TIRE_REVIEW_PARAM,
} from "../../components/tire-reviews/tire-review-controller";
import { useLocation } from "react-router";
import Skeleton from "@material-ui/lab/Skeleton";

const { theme } = APP_ENV;

const TiresByBrandModel = () => {
  const { tireBrand, tireModel, tiresByRimSize, breadCrumbPaths, isLoading } =
    useTireItemsByBrandModel();

  const [tabValue, setTabValue] = useState(0);

  const location = useLocation();

  useEffect(() => {
    if (location?.search?.includes(TIRE_REVIEW_PARAM)) {
      setTabValue(1);
    }
  }, [location?.search]);

  return (
    <>
      <MetaTags
        description={`Shop the lowest prices on ${tireBrand?.brandName} ${tireModel.itemModelName} tires.`}
        canonical={tireModel.itemModelSlug}
        title={`${tireBrand?.brandName} ${tireModel.itemModelName} Tires | ${APP_ENV.appBrandName}`}
      />
      <BreadCrumb paths={breadCrumbPaths} />

      {isLoading && (
        <>
          <DefaultContainer>
            <Box style={{ width: "100%", paddingTop: 40 }}>
              <Skeleton variant="rect" width={186} height={55} />
            </Box>
          </DefaultContainer>
          <DefaultContainer>
            <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
              <Skeleton
                variant="rect"
                style={{
                  width: "100%",
                  height: 450,
                }}
              />
            </Grid>
          </DefaultContainer>
          <DefaultContainer backgroundColor={theme.palette.background.default}>
            <Grid container style={{ padding: 100 }}></Grid>
          </DefaultContainer>
        </>
      )}

      {!isLoading && (
        <>
          <TireBrandModelHeader tireBrand={tireBrand} tireModel={tireModel}>
            <SaveXAmountBox
              maximumSavings={tireModel?.maximumSavings ?? 0}
              tireText={`${tireBrand?.brandName} ${tireModel?.itemModelName}`}
            />
          </TireBrandModelHeader>

          <DefaultContainer>
            <SemiCollapseableDescription
              description={tireModel?.itemModelDescription}
            />
          </DefaultContainer>
          <DefaultContainer backgroundColor={theme.palette.background.default}>
            <Grid container style={{ paddingBottom: 50 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Tabs
                    value={tabValue}
                    onChange={(_, newVal) => setTabValue(newVal)}
                  >
                    <Tab value={0} label={"Tire Sizes"} />
                    <Tab value={1} label={"Reviews"} />
                  </Tabs>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  backgroundColor: theme.palette.background.paper,
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                {tabValue == 0 && (
                  <Box
                    style={{
                      display: "flex",
                      width: "100%",
                      flexWrap: "wrap",
                      textAlign: "left",
                    }}
                  >
                    {Object.entries(tiresByRimSize).map(([rimSize, tires]) => {
                      return (
                        <List
                          key={rimSize}
                          style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            marginRight: 15,
                          }}
                        >
                          <ListItem>
                            <Typography
                              style={{ fontWeight: "bold" }}
                              variant={"h4"}
                            >
                              {rimSize}
                            </Typography>
                          </ListItem>
                          <Divider />
                          {tires.map(
                            (
                              {
                                tireSizeDisplay,
                                loadIndexSpeedRatingDisplay,
                                totalDiscountOnFour,
                                itemSlug,
                              },
                              i
                            ) => {
                              return (
                                <Fragment key={i}>
                                  <ListItem
                                    button
                                    style={{ display: "block" }}
                                    component={RouterLink}
                                    to={itemSlug}
                                  >
                                    <ListItemText
                                      secondary={`${tireSizeDisplay} ${loadIndexSpeedRatingDisplay}`}
                                    />
                                    {Boolean(totalDiscountOnFour > 0) && (
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          color: theme.palette.error.main,
                                        }}
                                      >
                                        <MonetizationOnRoundedIcon
                                          style={{ marginRight: 8 }}
                                        />
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`Save $${totalDiscountOnFour} on 4 Tires`}
                                        </Typography>
                                      </Box>
                                    )}
                                  </ListItem>
                                  <Divider style={{ height: 1 }} />
                                </Fragment>
                              );
                            }
                          )}
                        </List>
                      );
                    })}
                  </Box>
                )}
                {tabValue == 1 && <TireReviewController />}
              </Grid>
            </Grid>
          </DefaultContainer>
        </>
      )}
    </>
  );
};

export default TiresByBrandModel;
