import { Typography } from "@material-ui/core";
import { CART_ITEM_TYPE } from "../../../hooks/store/cart-store";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const StaticCartItemDescription = ({ cartItemTypeId, itemName }) => {
  return (
    <>
      {cartItemTypeId == CART_ITEM_TYPE.freeService && (
        <Typography variant="h5" style={{ color: theme.palette.error.main }}>
          FREE
        </Typography>
      )}
      <Typography style={{ fontWeight: "bold" }} variant="h5">
        {itemName}
      </Typography>
    </>
  );
};

export default StaticCartItemDescription;
