import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

const EL_IDS = {
  wheelVizScriptId: "autosync-script",
  wheelVizContainerId: "wheel-viz-container",
};

const WheelVisualizer = ({ entryEndPoint }) => {
  const [state, setState] = useState({
    wheelVizMounted: false,
    currentEntryPoint: null,
  });

  useEffect(() => {
    const wheelVizMounted = document.getElementById(EL_IDS.wheelVizScriptId);

    if (wheelVizMounted) {
      setState((prev) => ({ ...prev, wheelVizMounted: true }));
    } else {
      const script = document.createElement("script");
      script.id = EL_IDS.wheelVizScriptId;
      script.src = "https://vvs.autosyncstudio.com/js/Autosync.js";
      script.onload = () => {
        setState((prev) => ({ ...prev, wheelVizMounted: true }));
      };
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (!state.wheelVizMounted || !entryEndPoint) return;

    if (entryEndPoint !== state.currentEntryPoint) {
      document.getElementById(EL_IDS.wheelVizContainerId).innerHTML = "";

      new window.Autosync({
        id: EL_IDS.wheelVizContainerId,
        key: "e6Qk0GzA72eypHRGx2ZM",
        startPage: entryEndPoint,
      });

      setState((prev) => ({ ...prev, currentEntryPoint: entryEndPoint }));
    }
  }, [state.wheelVizMounted, entryEndPoint]);

  return (
    <Box>
      <Box id={EL_IDS.wheelVizContainerId} />
    </Box>
  );
};

export default WheelVisualizer;
