import { Box, Paper, Button, Grid, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import APP_ENV from "../../../appEnvironment";
import useAppCreditInformation from "../../../hooks/store/credit-store";

const { theme } = APP_ENV;

const SyncronyRebateCouponsPage = () => {
  const APP_CREDIT = useAppCreditInformation();

  return (
    <>
      {APP_CREDIT.mainImage && (
        <Box>
          <Box
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Paper
              onClick={() => window.open(APP_CREDIT.synchronyRewardCenterLink, '_blank')}
              style={{
                marginLeft: 10,
                marginRight: 10,
                width: "fit-content",
                height: "fit-content",
              }}
              elevation={8}
            >
              <img
                src={APP_CREDIT.mainImage}
                style={{
                  maxWidth: 500,
                  width: "100%",
                  height: "auto",
                }}
              />
            </Paper>
          </Box>
          {APP_CREDIT.currentRebate && (
            <Grid container style={{ marginTop: 25, justifyContent: "center" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={{ padding: 10, textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ height: 50, maxWidth: 300 }}
                  component={RouterLink}
                  to={APP_CREDIT.creditPageEndPoint}
                >
                  Learn More
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={{ padding: 10, textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={{
                    height: 50,
                    color: theme.palette.secondary.contrastText,
                    maxWidth: 300,
                  }}
                  component={Link}
                  href={APP_CREDIT.currentRebate}
                  target="_blank"
                >
                  Print Rebate
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};

export default SyncronyRebateCouponsPage;
