import { Popover } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DayJsAdatper from "@date-io/dayjs";
import dayjs from "dayjs";
import useAnchorEl from "../../../hooks/util/useAnchorEl";
import { cloneElement } from "react";

const AppointmentDatePicker = ({
  theDateISO,
  appointmentSchedule = [],
  handleActiveDateChange,
  children,
}) => {
  const { anchorEl, isOpen, setClosed, toggle } = useAnchorEl();

  const activeDatesISO = appointmentSchedule.map((i) => i.theDateISO);

  const minDate = activeDatesISO?.[0];
  const maxDate = activeDatesISO.slice(-1)?.[0];

  return (
    <>
      {cloneElement(children, { isSelected: isOpen, onSelect: toggle })}
      {theDateISO && (
        <Popover
          open={isOpen}
          onClose={setClosed}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MuiPickersUtilsProvider utils={DayJsAdatper}>
            <KeyboardDatePicker
              disableToolbar
              variant="static"
              format="MM/DD/YYYY"
              margin="normal"
              label="Appointment Date"
              value={theDateISO}
              onChange={(day) => {
                handleActiveDateChange(day.toISOString());
                setClosed();
              }}
              minDate={dayjs(minDate)}
              maxDate={dayjs(maxDate)}
              shouldDisableDate={(date) =>
                !activeDatesISO.includes(dayjs(date).toISOString())
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
              inputProps={{ style: { cursor: "pointer" } }}
            />
          </MuiPickersUtilsProvider>
        </Popover>
      )}
    </>
  );
};

export default AppointmentDatePicker;
