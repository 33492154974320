import { useHistory, useLocation } from "react-router";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { CAR_SERVICES_ROUTE_DIRECTORY } from "../../static/cart-items";
import { SERVICE_GROUPS } from "./car-services-main";
import StandardPageHeader from "../../components/generic/standard-page-header";
import DefaultContainer from "../../components/generic/default-container";
import APP_ENV from "../../appEnvironment";
import BreadCrumb from "../../components/generic/bread-crumb";

const { theme } = APP_ENV;

const ACTIVE_SERVICES = SERVICE_GROUPS.map(([, , services]) => services).flat();

const CarServiceSingle = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const service = ACTIVE_SERVICES.find((i) => i.endPoint == pathname);

  if (!service) {
    history.push(CAR_SERVICES_ROUTE_DIRECTORY);
    return null;
  }

  const { itemName, itemDescription, Component, addToCart } = service;

  return (
    <>
      <BreadCrumb
        paths={[["Car Services", CAR_SERVICES_ROUTE_DIRECTORY], [itemName]]}
      />
      <StandardPageHeader title={itemName} subtitle={itemDescription}>
        <Box style={{ paddingLeft: 30 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={addToCart}
            style={{ width: 300, height: 50 }}
          >
            Add To Cart
          </Button>
        </Box>
      </StandardPageHeader>
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid container style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Grid item sm={12} md={8} style={{ padding: 20 }}>
            <Paper style={{ padding: 20 }}>
              <Component />
            </Paper>
          </Grid>
          <Grid item sm={12} md={4} style={{ padding: 20 }}>
            <Paper style={{ paddingTop: 20 }}>
              <ListItem>
                <Typography style={{ fontWeight: "bold" }}>
                  View Other Services
                </Typography>
              </ListItem>
              <List>
                {ACTIVE_SERVICES.filter(
                  (i) => i.webPartNumber !== service.webPartNumber
                ).map(({ itemName, image, endPoint }) => (
                  <ListItem
                    key={itemName}
                    button
                    component={RouterLink}
                    to={endPoint}
                  >
                    <ListItemIcon>
                      <img width={"30px"} src={image} />
                    </ListItemIcon>
                    <ListItemText primary={itemName} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default CarServiceSingle;
