import * as React from "react";
import MetaTags from "../components/reusable/ReactMetaTags.jsx";
import APP_ENV from "../appEnvironment";
import StandardPageHeader from "../components/generic/standard-page-header.js";
import DefaultContainer from "../components/generic/default-container.js";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
const theme = APP_ENV.theme;

const AboutUs = () => {
  const phone = APP_ENV.appPhoneNumber;
  return (
    <>
      <MetaTags
        description={`${APP_ENV.appBrandName} is a new tire and car service provider in your area with fast and friendly service.${APP_ENV.appBrandName} guarantees the low prices. Shop now!`}
        canonical="/about-us"
        title={`About Us | ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title="About Us"
        subtitle="Our goal is complete customer satisfaction"
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 25,
            paddingBottom: 40,
          }}
        >
          <Grid item xs={11} sm={8} md={8} lg={10}>
            <Paper elevation={8} style={{ padding: "1.7em" }}>
              <Box my="3rem">
                <Typography
                  variant="h5"
                  gutterBottom
                >{`${APP_ENV.appBrandName} Vision`}</Typography>
                <Typography gutterBottom>
                  To be recognized as the best one-stop tire and auto repair
                  provider. We deliver our customers the highest level of
                  satisfaction while investing in the lives of the hardworking,
                  dedicated people who make up our team.
                </Typography>
                <Typography gutterBottom>
                  Value is our objective, we will not be undersold. We want a
                  chance to offer you the best deal for all your auto service
                  needs.
                </Typography>
                <Typography gutterBottom>
                  Let us know your impressions of our stores, good or bad!{" "}
                  <span className="phoneNumber">
                    <a href={"tel:" + phone}>{phone}</a>
                  </span>
                  .
                </Typography>
              </Box>
              <Box my="3rem">
                <Typography
                  variant="h5"
                  gutterBottom
                >{`Why Choose ${APP_ENV.appBrandName}?`}</Typography>
                <Typography gutterBottom>
                  {`Here at ${APP_ENV.appBrandName} our goal is
                                            to provide complete customer satisfaction and great value for our customers. Low prices
                                            on inferior merchandise can be expensive and not money saving. ${APP_ENV.appBrandName} offers
                                            great deals on all the best tire brands.`}
                </Typography>
                <Typography gutterBottom>
                  {`${APP_ENV.appBrandName} also offers a long list of services including Alignments,
                                            Brakes, Oil Changes, Suspension, Custom Lifting or Lowering of your vehicle and
                                            much more. We also have a list of FREE Services, FREE Alignment Checks, FREE Flat
                                            Repairs, FREE Tire Rotations, and FREE Inspections and FREE Estimates of your vehicle’s
                                            needs. We sell and stock what we advertise. No "bait and switch" tactics. Shop our
                                            competition and see for yourself. ${APP_ENV.appBrandName}'s company policy forbids
                                            high pressure selling of unneeded merchandise or services.`}
                </Typography>
                <Typography gutterBottom>
                  We sincerely appreciate your business. Our Company treasures
                  your support of local merchants, which in turn helps our
                  communities prosper.
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};
export default AboutUs;
