import { useContext } from "react";
import { Box, Button, Typography, Collapse, Link } from "@material-ui/core";
import TirePackageDetails from "../tire-package-details";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import APP_ENV from "../../../appEnvironment";
import { useLocationStore } from "../../../hooks/store/location-store";
import { TireSearchResultsCardContext } from "../tire-search-results-card";
import MonthlyFinanceAmount from "../../cart/widgets/monthy-finance-amount";

const { theme } = APP_ENV;

const TireSearchResultsExpandible = ({ isp = true }) => {
  const { activeTireByQty, contextState } = useContext(
    TireSearchResultsCardContext
  );
  const { showDetails } = contextState;
  const [userStore] = useLocationStore((st) => [st.userStore]);

  return (
    <>
      <Collapse in={showDetails}>
        <Box style={{ width: "100%", paddingTop: 10, paddingBottom: 10 }}>
          <TirePackageDetails activeTireByQty={activeTireByQty} />
        </Box>
      </Collapse>
      <Box
        style={{
          textAlign: "center",
        }}
      >
        <MonthlyFinanceAmount
          amount={
            activeTireByQty._invoicePrice -
            (activeTireByQty._tax +
              activeTireByQty._stfTotal +
              activeTireByQty._fetTotal)
          }
        />
      </Box>

      <Box style={{ width: "100%", paddingTop: 10 }}>
        {[activeTireByQty].map(
          (
            {
              _cartQty = 0,
              _qtyIsAvailable = false,
              _qtyIsInStock = false,
              _availabilityLabel = "",
              _orderBeforeDateTimeDisplay = "",
              _arrivesDateTimeDisplay = "",
            },
            idx
          ) => {
            const isInStock = _qtyIsInStock;
            const isDelivery = !isInStock && _qtyIsAvailable;
            const isNotAvail = !isInStock && !isDelivery;

            return (
              <Box key={idx} style={{ paddingLeft: 10 }}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 8,
                  }}
                  key={idx}
                >
                  {isInStock && (
                    <>
                      <FiberManualRecordIcon
                        style={{
                          color: theme.palette.success.main,
                          fontSize: 25,
                          marginRight: 10,
                        }}
                      />
                      <span style={{ fontSize: 14 }}>
                        <strong style={{ fontSize: 16 }}>In Stock </strong>
                        {` at ${userStore.webAlias}`}
                      </span>
                    </>
                  )}
                  {isDelivery && (
                    <>
                      <LocalShippingIcon
                        style={{
                          color: theme.palette.success.main,
                          fontSize: 25,
                          marginRight: 10,
                        }}
                      />
                      <span style={{ fontSize: 14 }}>
                        <strong style={{ fontSize: 16 }}>In Stock </strong>
                        {` Nearby - FREE DELIVERY`}
                      </span>
                    </>
                  )}
                  {isNotAvail && (
                    <>
                      <FiberManualRecordIcon
                        style={{
                          color: theme.palette.common.black,
                          fontSize: 25,
                          marginRight: 10,
                        }}
                      />
                      <Link
                        style={{ fontWeight: 600, marginLeft: 5 }}
                        href={`tel:${userStore.phoneNumber}`}
                      >
                        Call For Availability
                      </Link>
                    </>
                  )}
                </Box>
                {isDelivery && (
                  <Box
                    style={{
                      width: "100%",
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: 8,
                      padding: 8,
                      marginTop: 5,
                    }}
                  >
                    <Typography style={{ fontSize: 12 }}>
                      {`ARRIVES: ${_arrivesDateTimeDisplay}`}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      {`ORDER BY: ${_orderBeforeDateTimeDisplay}`}
                    </Typography>
                  </Box>
                )}
                {isNotAvail && (
                  <Box
                    style={{
                      width: "100%",
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: 8,
                      padding: 8,
                      marginTop: 5,
                    }}
                  >
                    <span style={{ fontSize: 12 }}>
                      {`Most inventory is available within 3 business days from our vendors. To confirm availability, please call us at `}
                      <Link href={`tel:${userStore.phoneNumber}`}>
                        {`${userStore.phoneNumber}.`}
                      </Link>
                    </span>
                  </Box>
                )}
              </Box>
            );
          }
        )}
      </Box>

      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ width: 300, height: 50 }}
          onClick={activeTireByQty.addToCart}
        >
          Add To Cart
        </Button>
      </Box>
      {!isp && (
        <Box style={{ width: "100%", textAlign: "center", paddingBottom: 10 }}>
          <Typography
            style={{ color: theme.palette.error.main, fontWeight: 600 }}
          >
            This tire is not advertised online.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default TireSearchResultsExpandible;
