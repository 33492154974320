import { Box, Typography } from "@material-ui/core";

const CartItemCard = ({
  itemImage = "",
  itemPrice = 0,
  qtyComponent = null,
  children,
}) => {
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Box style={{ width: "20%", height: 100, padding: 8 }}>
        <img
          src={itemImage}
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
        />
      </Box>
      <Box
        style={{
          width: "60%",
          display: "flex",
          alignItems: "center",
          paddingLeft: 15,
        }}
      >
        <Box>{children}</Box>
      </Box>
      <Box
        style={{
          width: "20%",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        {qtyComponent}
        <Typography
          style={{ fontWeight: 600, marginTop: 10 }}
        >{`$${itemPrice.toFixed(2)}`}</Typography>
      </Box>
    </Box>
  );
};

export default CartItemCard;
