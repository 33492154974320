import { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import Rating from "@material-ui/lab/Rating";
import * as yup from "yup";
import { useFormik } from "formik";
import { postTireReview } from "../../../services/venom-service";
import { useVehicleStore } from "../../../hooks/store/vehicle-store";
import SelectVehicle from "../../reusable/conditional-selection/SelectVehicle";
import APP_ENV from "../../../appEnvironment";
import { useParams } from "react-router";
import ModalStore from "../../../hooks/store/modal-store";
import LayoutStore from "../../../hooks/store/client/layout-store";

const { theme } = APP_ENV;

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required("First Name is required")
    .min(2, "First Name must be more than 2 characters")
    .max(60, "First & Last Name must be less than 60 characters"),
  lastInitial: yup
    .string()
    .required("Last Initial is required")
    .min(1, "Last Initial must be at least 1 character")
    .max(50, "Last Initial must be less than 50 characters"),
  vehicle: yup.string().required("Vehicle is required").min(2),
  rating: yup.number().required("A rating is required"),
  wouldRecommend: yup.bool().required(),
  comment: yup.string().max(1000, `Character limit is 1000`),
});

const StyledTextField = ({ yupKey, label, formik, props = {} }) => {
  const errorMessage = formik.touched?.[yupKey]
    ? formik.errors?.[yupKey] ?? ""
    : "";

  return (
    <TextField
      type="text"
      variant="outlined"
      color="secondary"
      margin="normal"
      name={yupKey}
      label={label}
      required
      value={formik.values?.[yupKey] ?? ""}
      onChange={formik.handleChange}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      {...props}
    />
  );
};

const SpecialSelectionContainer = ({ question, children }) => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        marginTop: 20,
      }}
    >
      <Typography variant="h5">{question}</Typography>
      <Box style={{ marginTop: 15 }}>{children}</Box>
    </Box>
  );
};

const TireReviewSubmitForm = ({ hasReviews = true }) => {
  const [isSelectVehicle, setIsSelectVehicle] = useState(false);

  const [userSubmittedReview, setUserSubmittedReview] = useState(false);

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const vehicleDisplayName = useVehicleStore((st) => {
    const {
      year = "",
      makeName = "",
      modelName = "",
      subModelName = "",
    } = st.userVehicle ?? {};
    return [year, makeName, modelName, subModelName].filter((i) => i).join(" ");
  });

  const { component, handleSubmit } = SelectVehicle(() =>
    setIsSelectVehicle(false)
  );

  const { brandEndPoint = "", itemModelEndPoint = "" } = useParams();

  const [globalLoading] = ModalStore((st) => [st.GlobalLoading]);

  const milesDrivenOptions = ["1,000+", "5,000+", "10,000+", "25,000+"];

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastInitial: "",
      vehicle: "",
      milesDriven: milesDrivenOptions[0],
      rating: 5,
      wouldRecommend: true,
      comment: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      const payLoad = {
        brandEndPoint,
        itemModelEndPoint,
        ...validationSchema.cast(formik.values),
      };

      globalLoading.openModal();

      await postTireReview(payLoad);

      globalLoading.closeModal();

      setUserSubmittedReview(true);
    },
  });

  useEffect(() => {
    if (vehicleDisplayName) {
      formik.setFieldValue("vehicle", vehicleDisplayName);
    }
  }, [vehicleDisplayName]);

  return (
    <>
      <Box mt="2rem">
        {!userSubmittedReview && (
          <>
            <Typography variant="h4">
              {hasReviews
                ? "Write a tire review"
                : "Be the first to review this tire"}
            </Typography>
            <Box spacing={2} display="flex" flexDirection="column">
              <StyledTextField
                yupKey={"firstName"}
                label={"First Name"}
                formik={formik}
              />
              <StyledTextField
                yupKey={"lastInitial"}
                label={"Last Initial"}
                formik={formik}
              />
              <StyledTextField
                yupKey={"vehicle"}
                label={"Vehicle"}
                formik={formik}
                props={{
                  type: "button",
                  onClick: () => setIsSelectVehicle(!isSelectVehicle),
                  inputProps: { style: { textAlign: "left" } },
                  InputProps: {
                    style: {
                      cursor: "pointer",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <ExpandMoreRoundedIcon style={{ fontSize: 12 }} />
                      </InputAdornment>
                    ),
                  },
                }}
              />
              {isSelectVehicle && (
                <Box
                  style={{
                    padding: 8,
                    backgroundColor:
                      theme.palette.background[isMobile ? "paper" : "default"],
                    border: isMobile
                      ? `2px solid ${theme.palette.background.default}`
                      : "",
                  }}
                >
                  {component}
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={handleSubmit}
                      style={{ width: 350, height: 50, margin: 20 }}
                      variant="contained"
                      color="primary"
                    >
                      Save Vehicle
                    </Button>
                  </Box>
                </Box>
              )}

              <SpecialSelectionContainer
                question={"How many miles have you driven on these tires?"}
              >
                <FormControl variant="outlined">
                  <InputLabel>Miles Driven</InputLabel>
                  <Select
                    name="milesDriven"
                    label="Miles Driven"
                    value={formik.values.milesDriven}
                    onChange={formik.handleChange}
                    style={{ width: 200 }}
                  >
                    {milesDrivenOptions.map((val) => (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </SpecialSelectionContainer>

              <SpecialSelectionContainer
                question={"Would you recommend this tire to other customers?"}
              >
                <Button
                  variant="outlined"
                  startIcon={<ThumbUpIcon />}
                  onClick={() => formik.setFieldValue("wouldRecommend", true)}
                  style={{
                    marginRight: 10,
                    ...(formik.values.wouldRecommend
                      ? {
                          color: theme.palette.common.white,
                          backgroundColor: theme.palette.success.main,
                        }
                      : { color: theme.palette.success.main }),
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<ThumbDownIcon />}
                  onClick={() => formik.setFieldValue("wouldRecommend", false)}
                  style={{
                    ...(!formik.values.wouldRecommend
                      ? {
                          color: theme.palette.common.white,
                          backgroundColor: theme.palette.error.main,
                        }
                      : { color: theme.palette.error.main }),
                  }}
                >
                  No
                </Button>
              </SpecialSelectionContainer>

              <SpecialSelectionContainer
                question={"How do you rate this tire?"}
              >
                <Rating
                  name="rating"
                  style={{ fontSize: 50 }}
                  precision={1}
                  value={Number(formik.values.rating)}
                  onChange={formik.handleChange}
                />

                <Typography variant="h3" style={{ textAlign: "center" }}>{`${
                  formik.values.rating
                } Star${formik.values.rating == 1 ? "" : "s"}`}</Typography>
              </SpecialSelectionContainer>

              <StyledTextField
                yupKey={"comment"}
                label={"Write A Review"}
                formik={formik}
                props={{ minRows: 3, multiline: true }}
              />
              <Box style={{ width: "100%", textAlign: "center", padding: 20 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={formik.handleSubmit}
                  style={{ width: 350, height: 50 }}
                >
                  Submit Review
                </Button>
              </Box>
            </Box>
          </>
        )}
        {userSubmittedReview && (
          <Box style={{ textAlign: "center" }}>
            <Box style={{ width: "100%", textAlign: "center" }}>
              <CheckCircleOutlineOutlinedIcon
                style={{ fontSize: 100, color: theme.palette.success.main }}
              />
              <Typography variant="h3">Thank you for your review.</Typography>
              <Typography variant="caption">
                Your submission will be reviewed and posted within a few hours.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TireReviewSubmitForm;
