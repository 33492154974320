import { Grid, Typography, Box, Button, Link } from "@material-ui/core";
import DefaultContainer from "../../components/generic/default-container";
import BreadCrumb from "../../components/generic/bread-crumb";
import Skeleton from "@material-ui/lab/Skeleton";
import APP_ENV from "../../appEnvironment";
import { useEffect, useState } from "react";
import WheelVisualizer from "../../components/wheels/wheel-visualizer";
import { useVehicleStore } from "../../hooks/store/vehicle-store";
import WheelBrandStore from "../../hooks/store/wheel-store";
import { useHistory } from "react-router-dom";
import { WHEELS_ROUTE_DIRECTORY } from "../../routes";
import useOpenVehicleSelector from "../../hooks/util/useOpenVehicleSelector";

const { theme } = APP_ENV;

const WheelByVehicles = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [getValidVehicle] = WheelBrandStore((st) => [st.getValidVehicle]);
  const [userVehicle, getUserVehicle] = useVehicleStore((st) => [st.userVehicle, st.getUserVehicle]);
  const [vehicle, setVehicle] = useState(null);
  const [entryEndPoint, setEntryEndPoint] = useState("");
  const [isVehicleSupported, setIsVehicleSupported] = useState(true);
  const openVehicleSelector = useOpenVehicleSelector();

  const validateAndSetVehicle = async (currentVehicle) => {
    if (!currentVehicle || APP_ENV.appBrandId !== 4) {
      history.push(WHEELS_ROUTE_DIRECTORY);
      return;
    }

    const validatedVehicle = await getValidVehicle({
      make: currentVehicle.makeName,
      model: currentVehicle.modelName,
      year: currentVehicle.year,
    });

    if (validatedVehicle.isValid) {
      setVehicle(validatedVehicle.vehicle);
      setEntryEndPoint(validatedVehicle.entryEndPoint);
      setIsVehicleSupported(true);
    } else {
      setIsVehicleSupported(false);
      setVehicle(currentVehicle);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let mounted = true;

    (async () => {
      const currentVehicle = await getUserVehicle();
      if (mounted) {
        await validateAndSetVehicle(currentVehicle);
      }
    })();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (userVehicle) {
      validateAndSetVehicle(userVehicle);
    }
  }, [userVehicle]);

  const handleVehicleSelect = () => {
    openVehicleSelector();
  };

  if (isLoading) {
    return (
      <Box>
        <Box style={{ height: "50px" }} />
        <DefaultContainer>
          <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
            <Skeleton variant="text" width={342} height={55} />
          </Grid>
          <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
            <Skeleton variant="rect" width={186} height={55} />
          </Grid>
          <Grid container style={{ marginBottom: 20, marginTop: 20 }}></Grid>
        </DefaultContainer>
        <DefaultContainer backgroundColor={theme.palette.background.default}>
          <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Skeleton variant="rect" style={{ height: 700, width: "100%" }} />
          </Box>
        </DefaultContainer>
      </Box>
    );
  }

  if (!isVehicleSupported && vehicle) {
    return (
      <>
        <BreadCrumb
          paths={[["Wheels", WHEELS_ROUTE_DIRECTORY], ["Car Visualizer"]]}
        />
        <DefaultContainer>
          <Grid
            container
            style={{
              padding: 20,
            }}
          >
            <Typography variant="h3">{`${vehicle.year} ${vehicle.makeName} ${vehicle.modelName}`}</Typography>
          </Grid>
          <Box style={{ padding: 20 }}>
            <Box>
              <Typography variant="h5">
                {`We apologize, but we currently don't support wheel visualization for this vehicle.`}
              </Typography>
              <Typography>
                {`Please contact our support team at `}
                <Link
                  href={`tel:${APP_ENV.appPhoneNumber}`}
                  color="primary"
                  component="a"
                >
                  {APP_ENV.appPhoneNumber}
                </Link>
                {` for assistance in finding wheels for your vehicle, or select a
                different vehicle.`}
              </Typography>
            </Box>
            <Box style={{ paddingTop: 40 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleVehicleSelect}
                style={{
                  height: 50,
                  width: 300,
                }}
              >
                Select Your Vehicle
              </Button>
            </Box>
          </Box>
        </DefaultContainer>
      </>
    );
  }

  return (
    <>
      <BreadCrumb
        paths={[["Wheels", WHEELS_ROUTE_DIRECTORY], ["Car Visualizer"]]}
      />
      <DefaultContainer>
        <Grid
          container
          style={{
            marginBottom: 10,
            marginTop: 20,
            padding: 20,
          }}
        >
          <Typography variant="h3">{`Shop Wheels For ${vehicle.year} ${vehicle.Make} ${vehicle.Model}`}</Typography>
        </Grid>
      </DefaultContainer>
      <DefaultContainer>
        <Box style={{ padding: 20 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleVehicleSelect}
            style={{
              height: 50,
              width: 300,
            }}
          >
            Select Your Vehicle
          </Button>
        </Box>
      </DefaultContainer>
      <DefaultContainer>
        <Box style={{ padding: 20 }}>
          <WheelVisualizer entryEndPoint={entryEndPoint} />
        </Box>
      </DefaultContainer>
    </>
  );
};

export default WheelByVehicles;