import MetaTags from "../../reusable/ReactMetaTags";
import APP_ENV from "../../../appEnvironment";

const BrakeCheck = () => {
  return (
    <>
      <MetaTags
        description={`At ${APP_ENV.appBrandName} we have highly trained mechanics available to
                 inspect and replace your brake pads, rotors, and other brake parts as needed. 
                 We offer free brake inspections and quotes. Same day appointments available.`}
        canonical="/car-services/brake-check"
        title={`Brake Check | ${APP_ENV.appBrandName}`}
      />
      <div>
        <p>
          <span>
            <strong>Free Brake Inspections</strong>
          </span>
        </p>
        <p>
          {`Brakes are one the most important parts of your vehicle because they
                    allow you to stop safely. At ${APP_ENV.appBrandName}, we always
                    offer free brake inspections. Our goal is to keep you safe and help
                    advise you of any brake related problems, for free.`}
        </p>
        <p>
          <span>
            <strong>Brake System Overview</strong>
          </span>
        </p>
        <p>
          {`You know that when you push on the brake pedal, your car gradually
          slows, eventually comes to a complete stop. But there are a lot of
          moving parts working together inside your car to make this happen.
          Since your foot can’t physically stop a moving vehicle, your car must
          multiply the force of your foot utilizing brake fluid and a hydraulic
          system, paired with the friction of your car’s brake pads and discs.`}
          <br />
          {`When you press down on the brake pedal, brake fluid travels through
          the brake lines from a master cylinder to the pistons of your brake
          caliper or wheel cylinders. This even pressure is how the force of
          your foot is amplified to create a more impactful deceleration of your
          car. Depending on the type of brakes in your car, the piston system
          will then apply brake pads to the disks/drums, causing friction to
          slow the car to a stop.`}
          <br />
          {`Most cars today utilize an anti-locking braking system, or ABS. These
          complex systems can actually pump the brakes for you in the event of
          an emergency stop.`}
          <br />
        </p>
        <p>
          <span>
            <strong>How often should you check your brakes?</strong>
          </span>
        </p>
        <p>
          It is best practice to have your brakes checked every time the wheels
          are off of your vehicle during service or anytime the feel that
          something is not right. Common brake system issues include:
        </p>
        <ol>
          <li>Squeaking, squealing, or grinding</li>
          <li>Any dashboard lights illuminate</li>
          <li>Jerking or pulling to one side or the other</li>
          <li>Brake pedal feels soft or “squishy”</li>
          <li>Brake pedal feels hard or will not push down</li>
          <li>Leaking brake fluid</li>
        </ol>
        <p>
          {`These are just some common symptoms of brake system related problems,
                    but if you notice anything abnormal we always advise to have your
                    brakes inspected as soon as possible. Again, at ${APP_ENV.appBrandName}, 
                    we will always check your brake system for free.`}
        </p>
      </div>
    </>
  );
};

export default BrakeCheck;
