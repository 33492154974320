import { ListItem, ListItemText, Typography, Box } from "@material-ui/core";
import { useCartStore } from "../../hooks/store/cart-store";
import TaxesAndFeesCollapsible from "./widgets/taxes-and-fees-collapsible";
import APP_ENV from "../../appEnvironment";
import StyledInfoCard from "../quick-appointment/widgets/styled-info-card";
import SubTotalWithStrikeThrough from "./widgets/subtotal-with-strike-through";
import PaymentNotRequired from "./widgets/payment-not-required";
import TireItemMailInRebateCollection from "../promotions/full-site-tire-sale/tire-item-mail-in-rebate-collection";
import WebDepositRequired from "./widgets/web-deposit-required";

const { theme } = APP_ENV;

const OrderSummary = () => {
  const cart = useCartStore();

  const {
    subtotal,
    taxAndFeesTotal,
    tax,
    stfTotal,
    fetTotal,
    instantDiscountTotal,
    invoicePrice,
    mailInRebates,
    mailInRebateTotal,
    requiresSpecialOrder,
  } = cart;

  return (
    <Box style={{ marginTop: 15 }}>
      <StyledInfoCard>
        {Boolean(subtotal) && (
          <ListItem
            style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
          >
            <ListItemText>
              <Typography style={{ fontSize: 14 }}>Subtotal</Typography>
            </ListItemText>
            <Typography
              style={{ marginRight: 5, fontSize: 16, fontWeight: 600 }}
            >{`$${subtotal.toFixed(2)}`}</Typography>
          </ListItem>
        )}

        {Boolean(taxAndFeesTotal) && (
          <TaxesAndFeesCollapsible
            taxAndFeesTotal={taxAndFeesTotal}
            tax={tax}
            stfTotal={stfTotal}
            fetTotal={fetTotal}
          />
        )}
        {Boolean(instantDiscountTotal) && (
          <ListItem
            style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
          >
            <ListItemText>
              <Typography
                style={{
                  fontSize: 14,
                  color: theme.palette.error.main,
                  fontWeight: 600,
                }}
              >
                Instant Savings
              </Typography>
            </ListItemText>
            <Typography
              style={{
                marginRight: 5,
                fontSize: 16,
                color: theme.palette.error.main,
                fontWeight: "bold",
              }}
            >{`-$${instantDiscountTotal.toFixed(2)}`}</Typography>
          </ListItem>
        )}

        <ListItem style={{ paddingRight: 0, paddingTop: 5, paddingBottom: 0 }}>
          <ListItemText>
            <Typography variant="h4">Total</Typography>
          </ListItemText>
          <SubTotalWithStrikeThrough
            subtotal={subtotal + taxAndFeesTotal}
            displayPrice={invoicePrice}
          />
        </ListItem>
      </StyledInfoCard>
      {Boolean(mailInRebates.length) && (
        <StyledInfoCard isGrey={false}>
          <TireItemMailInRebateCollection
            _mailInRebates={mailInRebates}
            _mailInRebateTotal={mailInRebateTotal}
          />
        </StyledInfoCard>
      )}
      {/* {!requiresSpecialOrder ? <PaymentNotRequired /> : <WebDepositRequired />} */}
      <PaymentNotRequired />
    </Box>
  );
};

export default OrderSummary;
