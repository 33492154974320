import { useState } from "react";

const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const isOpen = Boolean(anchorEl);
  const setOpen = (e) => setAnchorEl(e.currentTarget);
  const setClosed = () => setAnchorEl(null);

  return {
    anchorEl,
    isOpen,
    setOpen,
    setClosed,
    toggle: isOpen ? setClosed : setOpen,
  };
};

export default useAnchorEl;
