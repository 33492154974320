import {
  Typography,
  Dialog,
  Box,
  IconButton,
  Button,
  Link,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import SportsVolleyballIcon from "@material-ui/icons/SportsVolleyball";

const TireRecyclingModal = ({ isOpen, closeModal }) => {
  return (
    <Dialog fullWidth maxWidth={"sm"} open={isOpen} onClose={closeModal}>
      <Box style={{ padding: 8, paddingLeft: 16 }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Box style={{ marginTop: 10 }}>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Tire Recycling
            </Typography>
            <Typography variant="caption" style={{ paddingTop: 5 }}>
              Some of the applications for recycled tires
            </Typography>
          </Box>
          <IconButton onClick={closeModal} style={{ height: "fit-content" }}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>

        <Box style={{ padding: 10 }}>
          {[
            [
              EmojiTransportationIcon,
              "Rubberized Asphalt",
              "Longer lasting road surfaces, less road maintenance, reduced road noise and improved vehicle braking distances.",
            ],
            [
              ChildCareIcon,
              "Playground and athletic surfaces",
              `Tiles and other surfaces, under playground equipment and athletic fields, providing high impact resistance and less stress on athlete’s legs.`,
            ],
            [
              SportsVolleyballIcon,
              "Molded Products",
              "Garden pavers, curbing, welcome mats, portable speed bumps, anti-fatigue mats.",
            ],
          ].map(([Icon, title, desc], i) => (
            <Box key={i} style={{ marginBottom: 8 }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <Icon style={{ marginRight: 10, color: "green" }} />
                <Typography variant="h5">{title}</Typography>
              </Box>

              <Typography style={{ fontSize: 14 }}>{desc}</Typography>
            </Box>
          ))}
        </Box>
        <Box
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Our Tire Recycling Partner</Typography>
          <img
            style={{ marginTop: 15, marginBottom: 15 }}
            width={200}
            height={"auto"}
            src={
              "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/LakinTire-logo.png"
            }
          />
          <Link href={"https://www.lakintire.com"} target={"_blank"}>
            Visit LakinTire.com
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TireRecyclingModal;
