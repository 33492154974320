import { roundMoney } from "../helpers/generic";

const endPoint = "/smog-check";
const labor = { edgeItemId: 96911, price: 18.95 };
const certificate = { edgeItemId: 98532, price: 6.0 };
const packageEdgeItemId = 98533;
const activeStoreNumbers = ["3201", "3204", "3205", "3206", "3212"];

const SMOG_CHECK_CONFIG = {
  activeStoreNumbers,
  labor,
  certificate,
  package: {
    edgeItemId: packageEdgeItemId,
    price: roundMoney(labor.price + certificate.price),
  },
  endPoint,
  mapSmogCheckLocations: (locations) => {
    const smogCheckStates = [
      ...new Set(
        locations
          .filter(({ storeNumber }) => activeStoreNumbers.includes(storeNumber))
          .map(({ stateAbbreviation }) => stateAbbreviation)
      ),
    ];

    return locations.map((location) => {
      const isSmogCheckState = smogCheckStates.includes(
        location.stateAbbreviation
      );
      const isSmogCheckStore = activeStoreNumbers.includes(
        location.storeNumber
      );

      return {
        ...location,
        smogCheck: {
          endPoint: isSmogCheckState && endPoint,
          storeCount: activeStoreNumbers.length,
          isSmogCheckState,
          isSmogCheckStore,
        },
      };
    });
  },
};

export default SMOG_CHECK_CONFIG;
