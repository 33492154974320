import { useState } from "react";
import useZState from "../hooks/util/useZState";
import { useHistory, useLocation } from "react-router";
import { useLocationStore } from "./store/location-store";
import AppointmentStore from "./store/appointment-store";
import UserInfoStore from "./store/user-info-store";
import VehicleStore from "./store/vehicle-store";
import CartStore from "./store/cart-store";
import ModalStore from "./store/modal-store";
import {
  createOrderRetail,
  getOrderRetail,
  verifySpecialOrder,
  sendCustomerTextOptIn,
} from "../services/venom-service";
import { RETAIL_ORDER_CONFIRMATION_DIRECTORY } from "../routes";
import ClientTrackingStore from "./store/client/client-tracking-store";
import GoogleEventsStore from "./store/client/google-events-store";
import sendRetailOrderConfirmationEmail from "../services/send-retail-order-confirmation-email";

export const RETAIL_ORDER_PARAM = "appt_id";

export const useRetailOrderSubmit = () => {
  const history = useHistory();

  const [userStore] = useLocationStore((st) => [st.userStore]);

  const [getEdgeUser, clearUserInfoStore] = UserInfoStore((st) => [
    st.getEdgeUser,
    st.clearUserInfoStore,
  ]);

  const [getEdgeVehicle] = VehicleStore((st) => [
    st?.userVehicle?.getEdgeVehicle,
  ]);

  const [getEdgeAppointmentDetails, clearAppointmentStore] = AppointmentStore(
    (st) => [st.getEdgeAppointmentDetails, st.clearAppointmentStore]
  );

  const [
    getEdgeCartSummary,
    getEdgeCartDetails,
    clearCartStore,
    cartItems,
    requiresSpecialOrder,
  ] = CartStore((st) => [
    st.getEdgeCartSummary,
    st.getEdgeCartDetails,
    st.clearCartStore,
    st.cartItems,
    st.requiresSpecialOrder,
  ]);

  const [getEdgeClient] = ClientTrackingStore((st) => [st.getEdgeClient]);

  const [globalLoading] = ModalStore((st) => [st.GlobalLoading]);

  const [getState, setState] = useZState({
    isSubmit: false,
  });

  const handleRetailOrderSubmit = async () => {
    globalLoading.openModal();

    const { isSubmit } = getState();
    if (isSubmit) return;
    setState({ isSubmit: true });

    const client = getEdgeClient();
    const retailCustomer = getEdgeUser();
    const vehicle = getEdgeVehicle();
    const appointmentDetails = getEdgeAppointmentDetails();
    const cartSummary = getEdgeCartSummary();
    const cartDetails = getEdgeCartDetails();

    const payload = {
      storeNumber: userStore.storeNumber,
      client,
      retailCustomer,
      vehicle,
      appointmentDetails,
      cartSummary,
      cartDetails,
    };

    const orderGUID = await createOrderRetail(payload);

    const success = Boolean(orderGUID);

    let onSuccess = () => null;
    if (success) {
      GoogleEventsStore.getState().sendPurchaseEvent(
        orderGUID,
        cartSummary?.TotalPrice ?? 0,
        cartItems
      );

      clearCartStore();
      clearAppointmentStore();
      clearUserInfoStore();
      sendRetailOrderConfirmationEmail({ orderGUID });

      verifySpecialOrder(orderGUID, requiresSpecialOrder);

      sendCustomerTextOptIn(orderGUID, retailCustomer.CustomerTextOptIn);

      const endPoint = `${RETAIL_ORDER_CONFIRMATION_DIRECTORY}?${RETAIL_ORDER_PARAM}=${orderGUID}`;
      onSuccess = () => history.push(endPoint);
    }
    setState({ isSubmit: false });
    globalLoading.closeModal();
    return {
      success,
      onSuccess,
    };
  };

  return handleRetailOrderSubmit;
};

export const useRetailOrderConfirmation = () => {
  const { search } = useLocation();

  const [state, setState] = useState({
    isReady: false,
    order: null,
  });

  const getOrder = async () => {
    const orderGUID =
      search.includes(RETAIL_ORDER_PARAM) &&
      [...search.split(`?${RETAIL_ORDER_PARAM}=`)?.[1]?.split("&")][0];

    const order = orderGUID ? await getOrderRetail({ orderGUID }) : null;

    setState((prev) => ({ ...prev, isReady: true, order }));
  };

  return {
    ...state,
    getOrder,
  };
};
