import create from "zustand";
import APP_ENV from "../../../appEnvironment";
import { useEffect } from "react";

const { theme } = APP_ENV;

const LayoutStore = create((set) => {
  const getScreenWidth = () => {
    const screenWidth = document.documentElement.clientWidth - 1;
    const isMobile = screenWidth <= theme.breakpoints.values.md;
    return { screenWidth, isMobile };
  };

  return {
    ...getScreenWidth(),
    setScreenWidth: () => set(getScreenWidth()),
  };
});

export const useScreenSizeListener = () => {
  const [setScreenWidth] = LayoutStore((st) => [st.setScreenWidth]);

  useEffect(() => {
    setScreenWidth();
    window.addEventListener("resize", setScreenWidth);
    window.addEventListener("load", setScreenWidth);
  }, []);
};

export default LayoutStore;
