import * as React from "react";
import MetaTags from "../components/reusable/ReactMetaTags.jsx";
import { Grid, Paper, Typography } from "@material-ui/core";
import APP_ENV from "../appEnvironment";
import StandardPageHeader from "../components/generic/standard-page-header.js";
import DefaultContainer from "../components/generic/default-container.js";
const theme = APP_ENV.theme;

const TermsOfUse = () => {
  return (
    <>
      <MetaTags
        description={`Explore ${APP_ENV.appBrandName}'s Terms of Use today. Learn more and confidently buy tires online for your vehicle.`}
        canonical="/terms-of-use"
        title={`Terms of Use | ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title="Terms of Use"
        subtitle={`Explore ${APP_ENV.appBrandName}'s Terms of Use today. Learn more and confidently buy tires online for your vehicle.`}
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 25,
            paddingBottom: 40,
          }}
        >
          <Grid item xs={11} sm={8} md={8} lg={10}>
            <Paper elevation={8} style={{ padding: "1.7em" }}>
              <Typography variant="h3" gutterBottom>
                Terms and Conditions
              </Typography>
              <Typography gutterBottom>
                PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE
                USING THIS SITE.
              </Typography>
              <Typography variant="h3" gutterBottom component="h3">
                Introduction:
              </Typography>
              <Typography gutterBottom>
                {`By using this site, you agree to these terms and conditions. If you do not agree
                                to these terms and conditions, do not use this site. ${APP_ENV.appBrandName} reserves
                                the right, at its discretion, to change, modify, add, or remove portions of these
                                terms and conditions at any time. Therefore, please review these terms and conditions
                                often for changes. Your continued use of this site after the posting of changes
                                to these terms and conditions will mean that you accept those changes. In addition,
                                ${APP_ENV.appBrandName} may, at any time, (a) change the format and content of
                                this site, (b) suspend the operation of this site for maintenance or support work
                                for any reason, or (c) terminate access to this site without notice.`}
              </Typography>
              <Typography variant="h3" gutterBottom component="h3">
                Use and Intellectual Property:
              </Typography>
              <Typography gutterBottom>
                {`This site is owned and operated by The ${APP_ENV.appBrandName} Corporation
                                 and contains material that is protected by copyright, trademark, and other
                                intellectual property laws. Nothing contained on this site should be construed as
                                granting, by implication or estoppel, or otherwise, any license or right to use
                                any mark displayed on this site without the written permission of ${APP_ENV.appBrandName},
                                or such third party as may own the mark displayed on the site. You may
                                download one copy of the materials from this site on one computer for your personal,
                                noncommercial home use only, provided that (a) all copyright and other proprietary
                                notices are kept intact, (b) no modifications are made to the materials, (c) the
                                materials are not used in a manner that suggests an association with any ${APP_ENV.appBrandName}
                                products, services, or brands, and (d) you do not download quantities
                                of materials to a database that can be used to avoid future downloads from this
                                site. Your use of the marks displayed on this site and any other content on this
                                site, except as provided in these terms and conditions, is strictly prohibited.
                                ${APP_ENV.appBrandName} will aggressively enforce its intellectual property rights
                                to the fullest extent of the law, including seeking criminal prosecution.`}
              </Typography>
              <Typography variant="h3" gutterBottom component="h3">
                Disclaimers:
              </Typography>
              <Typography gutterBottom>
                {`Information provided on this site is intended to be general in nature and is for
                                your informational purposes only. ${APP_ENV.appBrandName} tries to ensure that
                                all information provided on this site is accurate at the time it is placed on this
                                site but does not guarantee the accuracy of the information at all times. ${APP_ENV.appBrandName}
                                shall not be held responsible or liable for any action or inaction
                                you may take resulting from your reliance on information or tools provided herein
                                or for any damages or loss you may suffer as a result of your actions or inactions.`}
              </Typography>
              <Typography gutterBottom>
                {`EVERYTHING ON THIS SITE IS PROVIDED TO YOU "AS-IS" AND WITHOUT WARRANTIES OF ANY
                                KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE
                                LAW,  DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
                                BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                                PURPOSE. ${APP_ENV.appBrandName.toUpperCase()} DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED
                                IN THE MATERIALS ON THIS SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS
                                WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVERS THAT MAKE SUCH MATERIALS AVAILABLE
                                ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ${APP_ENV.appBrandName.toUpperCase()} DOES NOT
                                WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OF OR THE RESULTS OF THE USE
                                OF THE MATERIAL ON THIS SITE OR ON THIRD PARTY SITES IN TERMS OF THEIR CORRECTNESS,
                                ACCURACY, TIMELINESS, RELIABILITY, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION FACTORY-RECOMMENDED
                                MAINTENANCE INFORMATION. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES,
                                SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.`}
              </Typography>
              <Typography gutterBottom>
                {`UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL ${APP_ENV.appBrandName.toUpperCase()}
                                BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
                                DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THIS SITE OR MATERIALS
                                OR FUNCTIONS ON THIS SITE, EVEN IF ${APP_ENV.appBrandName.toUpperCase()} HAS BEEN ADVISED OF
                                THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR
                                EXCLUSION OF LIABILITY OR INCIDENTIAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION
                                OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL ${APP_ENV.appBrandName.toUpperCase()}' TOTAL
                                LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT,
                                TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR OTHERWISE) EXCEED THE AMOUNT
                                PAID BY YOU, IF ANY, FOR ACCESSING THIS SITE.`}
              </Typography>
              <Typography variant="h3" gutterBottom component="h3">
                External Third-Party Sites:
              </Typography>
              <Typography gutterBottom>
                {`This site may include links and pointers to external sites maintained by third parties.
                                Neither ${APP_ENV.appBrandName}, its parent or subsidiary companies nor their affiliates
                                controls or operates, in any respect, any products, services or information provided
                                on these third-party sites. These links and pointers are provided to you to help
                                find relevant sites, services, and products quickly. ${APP_ENV.appBrandName} explicitly
                                disclaims any responsibility for the accuracy, content or availability of information
                                found on sites that link to or from this site. ${APP_ENV.appBrandName} cannot ensure
                                that you will be satisfied with any products or services that you purchase from
                                a third-party site. ${APP_ENV.appBrandName} does not endorse any of the merchandise,
                                nor has it taken any steps to confirm the accuracy or reliability of any of the
                                information or content contained on such third-party sites. ${APP_ENV.appBrandName}
                                does not make any representations or warranties as to the security of any information
                                (including, without limitation, credit card and other personal information) you
                                might be requested to give any third-party, and you hereby irrevocably waive any
                                claim against ${APP_ENV.appBrandName} with respect to such sites and third-party
                                content. ${APP_ENV.appBrandName} strongly encourages you to make whatever investigation
                                you feel is necessary or appropriate before proceeding with any online or offline
                                transaction with any of these third parties.`}
              </Typography>
              <Typography variant="h3" gutterBottom component="h3">
                Links:
              </Typography>
              <Typography gutterBottom>
                {`You may not provide a hypertext link or other link to this site without ${APP_ENV.appBrandName}'s
                                 written permission. ${APP_ENV.appBrandName} will consider your
                                request to link to this site. However, it is ${APP_ENV.appBrandName}'s sole decision
                                as to whether or not ${APP_ENV.appBrandName} agrees to your request. If you are
                                interested in creating a link to this site, please contact ${APP_ENV.appBrandName}
                                and provide the following information: (a) the URL(s) of the web site pages from
                                which you are proposing to link to this site, and (b) the URL(s) of the web site
                                pages on this site to which you are proposing to link.`}
              </Typography>
              <Typography variant="h3" gutterBottom component="h3">
                Communications:
              </Typography>
              <Typography gutterBottom>
                {`Any communication or material you transmit to ${APP_ENV.appBrandName} via electronic
                                mail or otherwise is unsolicited by ${APP_ENV.appBrandName} and is deemed non-confidential.
                                By transmitting information to ${APP_ENV.appBrandName}, including, but not limited
                                to, posting messages, uploading files or engaging in any other form of communication
                                through this site, you are granting ${APP_ENV.appBrandName} a royalty-free, perpetual,
                                non-exclusive, unrestricted, worldwide license to:`}
              </Typography>
              <Typography gutterBottom>
                (a) copy, use, adapt, transmit, sublicense, publicly perform or
                display such communication, and
              </Typography>
              <Typography gutterBottom>
                (b) sublicense to third parties the unrestricted right to
                exercise any of the foregoing rights granted regarding this
                communication.
              </Typography>
              <Typography gutterBottom>
                The foregoing grants include the right to exploit any
                proprietary rights in such communication, including, but not
                limited to, rights under copyright, trademark, servicemark, or
                patent laws under any relevant jurisdiction.
              </Typography>
              <Typography variant="h3" gutterBottom component="h3">
                General:
              </Typography>
              <Typography gutterBottom>
                {`This agreement constitutes the entire agreement between ${APP_ENV.appBrandName}
                                and you with respect to the subject matter contained in this agreement and supersedes
                                all previous and contemporaneous agreements, proposals, and communications, written
                                or verbal. These terms and conditions are governed by and shall be construed in
                                accordance with the laws of the State of California without giving effect to any
                                principles of conflicts of law. If any provision of this agreement shall be unlawful,
                                void, or for any reason unenforceable, then that provision shall be deemed severable
                                from this agreement and shall not affect the validity and enforceability of any
                                remaining provisions. In the event of any dispute arising regarding these terms
                                and conditions, whether in contract or tort or otherwise, the California courts
                                will have non-exclusive jurisdiction over such dispute.`}
              </Typography>
              <Typography gutterBottom>
                {`${APP_ENV.appBrandName} is a registered trademark of ${APP_ENV.appBrandName} Corporation. All other company and product names may be trademarks of their respective
                                companies.`}
              </Typography>
              <Typography variant="h3" gutterBottom component="h3">
                {`Contact ${APP_ENV.appBrandName}:`}
              </Typography>
              <Typography gutterBottom>
                {`If you have any questions about this site, you can contact ${APP_ENV.appBrandName}
                                by email at: webmaster@bigbrandtire.com or by telephone at ${APP_ENV.appPhoneNumber}.`}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default TermsOfUse;
