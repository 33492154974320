import {
  Tabs,
  Tab,
  Box,
  Paper,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
  IconButton,
  Button,
} from "@material-ui/core";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import useSelectTireSizeByVehicle from "../../../hooks/useSelectTireSizeByVehicle";
import SelectCustomTireSize from "./SelectCustomTireSize";
import APP_ENV from "../../../appEnvironment";
import { Fragment } from "react";

const { theme } = APP_ENV;

const SelectTireSizeByVehicle = (
  onComplete = (tireSize) =>
    console.warn(
      "You need to pass an onComplete function to SelectTireSizeByVehicleMobile",
      tireSize
    )
) => {
  const state = useSelectTireSizeByVehicle();

  const { component, handleSubmit } = SelectCustomTireSize(onComplete);

  return {
    component: (
      <>
        <Paper>
          <Tabs
            value={state.currentIndex}
            onChange={(_, v) => state.setCurrentIndex(v)}
          >
            {state.fitmentLabels.map(({ label }) => (
              <Tab key={label} label={label} style={{ fontSize: 12 }} />
            ))}
          </Tabs>
        </Paper>
        {Boolean(
          !state.showCustomTireSizeComp && state.fitmentOptions?.length
        ) && (
          <Box
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: theme.palette.background.default,
              padding: 10,
            }}
          >
            <Typography
              style={{
                paddingBottom: 15,
                paddingTop: 15,
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              {state.fitmentLabels[state.currentIndex].message}
            </Typography>
            {state.fitmentOptions[state.currentIndex].map((item, idx) => {
              return (
                <Paper
                  key={idx}
                  component={Button}
                  fullWidth
                  onClick={() => {
                    const userTireSize = item.setSelected();
                    onComplete(userTireSize);
                  }}
                  style={{
                    backgroundColor: theme.palette.background.paper,
                    marginBottom: 8,
                  }}
                >
                  <ListItem
                    component={Box}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box style={{ width: "100%" }}>
                      {[
                        {
                          label: "Front",
                          tireSize: item.front,
                        },
                        item.isStaggered && {
                          label: "Rear",
                          tireSize: item.rear,
                        },
                      ]
                        .filter((opt) => opt)
                        .map(({ label, tireSize }, i) => (
                          <Fragment key={i}>
                            {i !== 0 && <Divider />}
                            <ListItem>
                              <ListItemIcon
                                style={{
                                  fontWeight: "bold",
                                  color: theme.palette.primary.main,
                                }}
                              >
                                {`${tireSize.rim}"`}
                              </ListItemIcon>
                              <ListItemText>
                                {item.isStaggered && (
                                  <Typography
                                    style={{ fontSize: 10, fontWeight: "bold" }}
                                  >
                                    {label}
                                  </Typography>
                                )}
                                <Typography>
                                  {tireSize.tireDisplaySize}
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          </Fragment>
                        ))}
                    </Box>
                    <ListItemIcon style={{ justifyContent: "flex-end" }}>
                      <ArrowForwardIosRoundedIcon style={{ fontSize: 28 }} />
                    </ListItemIcon>
                  </ListItem>
                </Paper>
              );
            })}
          </Box>
        )}

        {state.showCustomTireSizeComp && <>{component}</>}
      </>
    ),
    handleSubmit: state.showCustomTireSizeComp ? handleSubmit : null,
  };
};

export default SelectTireSizeByVehicle;
