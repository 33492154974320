import { Paper, Grid } from "@material-ui/core";
import useOpenTireSizeSelector from "../../../hooks/util/useOpenTireSizeSelector";

const TTYPromo = () => {
  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: true });

  return (
    <Grid container justifyContent={"flex-start"}>
      {[
        {
          image:
            "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/AT-Mt-tire.jpg",
        },
        {
          image:
            "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/wheel-package.jpg",
        },
      ].map(({ image }, i) => (
        <Grid
          item
          xs={12}
          md={6}
          style={{
            padding: 25,
            margin: "inherit",
          }}
          key={i}
        >
          <Paper
            style={{
              width: "fit-content",
              cursor: "pointer",
              textAlign: "center",
            }}
            elevation={8}
          >
            <img
              onClick={openTireSizeModal}
              src={image}
              style={{
                width: "100%",
                height: "auto",
                cursor: "pointer",
                maxWidth: 750,
              }}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default TTYPromo;
