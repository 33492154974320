import React, { useState, useContext } from "react";
import StandardPageHeader from "../components/generic/standard-page-header";
import DefaultContainer from "../components/generic/default-container";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Divider,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { SetLocalStorage } from "../components/reusable/LocalStorageObjects";
import ModalStore from "../hooks/store/modal-store";
import APP_ENV from "../appEnvironment";

const { theme } = APP_ENV;

const WholesaleLogin = () => {
  const GlobalLoading = ModalStore((st) => st.GlobalLoading);

  const history = useHistory();

  const [state, setState] = useState({
    hasError: false,
    userName: "",
    password: "",
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState((prev) => {
      return {
        ...prev,
        hasError: false,
        [name]: value,
      };
    });
  };

  const handleSubmit = async () => {
    const { userName, password } = state;
    if (!userName || !password) {
      setState((prev) => {
        return {
          ...prev,
          hasError: true,
        };
      });
      return;
    }

    GlobalLoading.openModal();

    const res = await fetch(
      `/api/wholesale/login?userName=${userName}&password=${password}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    let token = await res?.json();

    const { valid = false } = token;

    GlobalLoading.closeModal();

    if (valid) {
      SetLocalStorage("access_token", token);
      history.push("/wholesale");
      return;
    }

    setState((prev) => {
      return {
        ...prev,
        hasError: !valid,
      };
    });
  };

  return (
    <>
      <StandardPageHeader
        title="Wholesale Dealer Login"
        subtitle={`Welcome to ${APP_ENV.appBrandName} wholesale. Enter your username and password below for access.`}
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Grid item xs={12} sm={8} md={6} lg={5} xl={5}>
            <Paper style={{ padding: 25 }} elevation={5}>
              <Grid
                container
                style={{
                  justifyContent: "center",
                  paddingBottom: 15,
                }}
              >
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  Wholesale Dealer Login
                </Typography>
              </Grid>
              <Divider />
              <Grid container style={{ padding: 15 }}>
                <TextField
                  id="userName"
                  name="userName"
                  label="Username"
                  value={state.userName}
                  onChange={handleChange}
                  error={state.hasError}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  style={{ marginTop: 10, marginBottom: 10 }}
                />
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  value={state.password}
                  onChange={handleChange}
                  error={state.hasError}
                  helperText={state.hasError && "Username or Password Invalid."}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  style={{ marginTop: 10, marginBottom: 20 }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default WholesaleLogin;
