import * as React from "react";
import APP_ENV from "../../appEnvironment";
import { Container, Grid, Box } from "@material-ui/core";
const { theme } = APP_ENV;

const DefaultContainer = ({
  backgroundColor = theme.palette.background.paper,
  children,
}) => {
  return (
    <Box style={{ width: "100%", backgroundColor }}>
      <Container style={{ maxWidth: theme.breakpoints.values.lg }}>
        {children}
      </Container>
    </Box>
  );
};

export default DefaultContainer;
