import { Paper, Typography } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";
import StarIcon from "@material-ui/icons/Star";

const { theme } = APP_ENV;

const TireItemChipSecondary = ({ promotion = {} }) => {
  return (
    <>
      {promotion?.promoType && (
        <Paper
          elevation={3}
          style={{
            backgroundColor: promotion?.sitePromoColors?.primary,
            color: theme.palette.primary.contrastText,
            padding: 5,
            paddingLeft: 7,
            paddingRight: 7,
            width: "fit-content",
            display: "flex",
            alignItems: "center",
          }}
        >
          <StarIcon style={{ marginRight: 5, fontSize: 13 }} />
          <Typography style={{ fontWeight: "bold", fontSize: 13 }}>
            {promotion?.displayText?.tirePromoBox}
          </Typography>
        </Paper>
      )}
    </>
  );
};

export default TireItemChipSecondary;
