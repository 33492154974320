import { Typography, Button, Box } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import APP_ENV from "../../../appEnvironment";
import ModalStore from "../../../hooks/store/modal-store";
import LayoutStore from "../../../hooks/store/client/layout-store";
const { theme } = APP_ENV;

const BrandModelSize = ({
  brandName,
  itemModelName,
  tireSizeDisplay,
  loadIndexSpeedRatingDisplay,
  itemSlug,
}) => {
  const { closeAllModals } = ModalStore();
  const [isMobile] = LayoutStore((st) => [st.isMobile]);
  return (
    <Button
      component={RouterLink}
      to={itemSlug}
      style={{ color: theme.palette.common.black }}
      onClick={closeAllModals}
    >
      <Box>
        <Typography style={{ fontWeight: 100, fontSize: 18 }}>
          {brandName}
        </Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginBottom: 3 }}
        >
          {itemModelName}
        </Typography>
        <Typography variant="caption">{`${tireSizeDisplay} ${loadIndexSpeedRatingDisplay}`}</Typography>
      </Box>
    </Button>
  );
};

export default BrandModelSize;
