import { Divider, Typography } from "@material-ui/core";
import { useAppointmentStore } from "../../hooks/store/appointment-store";
import { useCollectUserInfo } from "../../hooks/store/user-info-store";
import OrderDetails from "../cart/order-details";
import OrderSummary from "../cart/order-summary";
import YourAppointment from "./appointment-selection/your-appointment";
import CollectUserInfo from "./user-info/collect-user-info";
import PrimaryActionSelectButton from "./widgets/primary-action-select-button";
import SelectSection from "./widgets/select-section";
import SelectSectionContainer from "./widgets/selection-section-container";
import StyledInfoCard from "./widgets/styled-info-card";

const QuickAppointmentUserInfo = ({
  onComplete = () => null,
  onEditCartClick = () => null,
}) => {
  const _state = useCollectUserInfo({ onComplete });

  const [userAppointment] = useAppointmentStore((st) => [st.userAppointment]);

  return (
    <>
      <SelectSectionContainer>
        <SelectSection title="Order" subtitle="Review your order totals.">
          <StyledInfoCard>
            <OrderDetails onEditCartClick={onEditCartClick} />
            <OrderSummary />
          </StyledInfoCard>
        </SelectSection>
      </SelectSectionContainer>
      <SelectSectionContainer>
        <SelectSection
          title="Appointment"
          subtitle="Review your appointment details."
        >
          <StyledInfoCard isGrey={false}>
            <YourAppointment {...userAppointment} />
          </StyledInfoCard>
        </SelectSection>

        <SelectSection
          title="Customer"
          subtitle="Please complete the form below."
        >
          <StyledInfoCard>
            <CollectUserInfo {..._state} />
          </StyledInfoCard>

          <Typography style={{ marginTop: 20, fontSize: 12, padding: 10 }}>
            *Your personal information will not be shared with any unaffiliated
            third parties or otherwise sold, transferred, disclosed, or
            disseminated to any third party.
          </Typography>
          <PrimaryActionSelectButton
            buttonTitle={"Submit Appointment"}
            onClick={_state.formik.handleSubmit}
          />
        </SelectSection>
      </SelectSectionContainer>
    </>
  );
};

export default QuickAppointmentUserInfo;
