import dayjs from "dayjs";
import APP_ENV from "../../../appEnvironment";

const singleLocationSchema = ({
  displayName,
  publicWebEndPoint,
  cleanPhoneNumber,
  city,
  stateAbbreviation,
  streetAddress,
  zipCode,
  latitude,
  longitude,
  storeSchedule,
  yelpPageUrl,
  facebookPageUrl,
}) => {
  const dayOrderLookup = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
  };

  return {
    "@context": "https://schema.org/",
    "@type": "TireShop",
    url: `${APP_ENV.appSlug}/locations/${publicWebEndPoint}`,
    currenciesAccepted: "USD",
    name: `${APP_ENV.appBrandName} - ${displayName}`,
    priceRange: "$",
    logo: APP_ENV.appBrandLogoImg,
    sameAs: [yelpPageUrl, facebookPageUrl],
    address: {
      "@type": "PostalAddress",
      addressLocality: city,
      addressRegion: stateAbbreviation,
      postalCode: zipCode,
      streetAddress: streetAddress,
      addressCountry: "US",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude,
      longitude,
    },
    telephone: `+1${cleanPhoneNumber}`,
    openingHoursSpecification: storeSchedule.map(
      ({ dayOrder, openDateTime, closeDateTime, isNormallyBusinessDay }) => {
        return {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: dayOrderLookup[dayOrder],
          opens: dayjs(openDateTime).format("HH:mm"),
          closes: dayjs(closeDateTime).format("HH:mm"),
          ...(isNormallyBusinessDay ? {} : { opens: "00:00", closes: "00:00" }),
        };
      }
    ),
  };
};

export default singleLocationSchema;
