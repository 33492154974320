import { Grid, Typography, Card, Button, Box } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import {
  QUICK_APPOINTMENT_DIRECTORY,
  TIRES_ROUTE_DIRECTORY,
} from "../../routes";
import useOpenTireSizeSelector from "../../hooks/util/useOpenTireSizeSelector";
import APP_ENV from "../../appEnvironment";

const ShopNowCardCollection = () => {
  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: false });
  return (
    <Box style={{ width: "100%" }}>
      <Typography variant="h3" style={{ paddingBottom: 20, paddingLeft: 10 }}>
        Shop Online Now
      </Typography>

      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        {[
          [
            "Shop Tires",
            "Find all the big name brand tires at our guaranteed lowest prices.",
            "Shop Tires",
            "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/tire.png",
            TIRES_ROUTE_DIRECTORY,
            openTireSizeModal,
          ],
          [
            "Shop Oil Change",
            "Oil changes, fast. Includes free tire rotation and multi-point inspection.",
            "Shop Now",
            "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/tw/oil-change/oil-synthetic.jpg",
            "/oilrecommendations",
          ],
          [
            "Quick Appointment",
            "Save time and money with our online quick appointments",
            "Start Now",
            "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/appointment-calendar.png",
            QUICK_APPOINTMENT_DIRECTORY,
          ],
        ]
          .filter(([title]) => {
            if (APP_ENV.appBrandId === 4) {
              return !["Shop Oil Change", "Quick Appointment"].includes(title);
            }
            return true;
          })
          .map(([title, text, buttonText, img, link, onClick = () => null]) => {
            return (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={title}>
                <Card
                  style={{ width: "100%", height: 210, display: "flex" }}
                  elevation={3}
                >
                  <Box style={{ width: "80%", height: "100%", padding: 20 }}>
                    <Typography variant="h5" gutterBottom>
                      {title}
                    </Typography>
                    <Typography style={{ height: 100, paddingTop: 10 }}>
                      {text}
                    </Typography>

                    <Button
                      component={RouterLink}
                      onClick={onClick}
                      to={link}
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{
                        fontSize: "13px",
                        paddingLeft: 20,
                        paddingRight: 20,
                        maxWidth: 300,
                        height: 50,
                      }}
                    >
                      {buttonText}
                    </Button>
                  </Box>
                  <Box
                    style={{
                      width: "20%",
                      height: "100%",
                      paddingLeft: 10,
                      display: "flex",
                      justifyContent: "flex-end",
                      overflow: "hidden",
                    }}
                  >
                    <Box>
                      <img height={210} src={img} />
                    </Box>
                  </Box>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default ShopNowCardCollection;
