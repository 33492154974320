import { Typography, Box } from "@material-ui/core";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const SaveXAmountBox = ({ maximumSavings = 0, tireText = "" }) => {
  return (
    <>
      {Boolean(maximumSavings) && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            border: `1px solid ${theme.palette.error.main}`,
            padding: 10,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Box
            style={{
              display: "flex",
              color: theme.palette.error.main,
            }}
          >
            <MonetizationOnRoundedIcon style={{ marginRight: 8 }} />
            <Typography>
              <strong>{`Save $${maximumSavings} `}</strong>
              <span
                style={{ fontSize: 14 }}
              >{`on 4 select ${tireText} tires. Limited time offer.`}</span>
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SaveXAmountBox;
