import { Button, Box, Grid, Typography, Paper } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import LayoutStore from "../../hooks/store/client/layout-store";
import MetaTags from "../../components/reusable/ReactMetaTags.jsx";
import DefaultContainer from "../../components/generic/default-container";

import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const wheelBrands = [
  "AMERICAN RACING",
  "ASANTI BLACK",
  "ASANTI OFF ROAD",
  "ATX OTR SERIES",
  "ATX SERIES",
  "FAIRWAY ALLOYS",
  "HELO",
  "KMC",
  "MOTEGI",
  "MOTO METAL",
  "XD ATV",
  "XD SERIES BY KMC WHEELS",
];

const WheelUnderConstruction = () => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  return (
    <>
      <MetaTags
        description="From solid steel wheels or new alloy wheels, we've got you covered with the biggest brands for your Jeep, SUV or Truck!"
        canonical="/wheels"
        title={`Wheels | ${APP_ENV.appBrandName}`}
      />
      <Box
        style={{
          width: "100%",
          backgroundColor: theme.palette.common.black,
          backgroundImage: isMobile
            ? "url(https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4/hero-wheels-small.png)"
            : "url(https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4/hero-wheels.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: isMobile ? "right -80px center" : "right",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <DefaultContainer backgroundColor="transparent">
          <Grid container>
            <Grid container style={{ padding: 20 }}>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  gutterBottom
                  style={{ color: theme.palette.common.white }}
                >
                  Shop For Wheels
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Typography style={{ color: theme.palette.common.white }}>
                  Find the perfect aftermarket wheels from the best brands in
                  the industry.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DefaultContainer>
      </Box>
      <DefaultContainer>
        <Grid container style={{ padding: 20 }}>
          <Typography variant="h4" style={{ fontWeight: "bold" }} gutterBottom>
            {`${APP_ENV.appBrandName} is proud to bring you the hottest wheel brands on the road today.`}
          </Typography>
          <Typography>
            {`We know all the big brand names in wheels. From solid steel wheels,
            or the hottest new alloy and forged-style wheels for your truck,
            Jeep or SUV, ${APP_ENV.appBrandName} partners with the best wheel brands in the
            world.`}
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 25,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            lg={7}
            xl={7}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              border: "1px solid black",
              padding: 15,
            }}
          >
            <Box style={{ display: "block" }}>
              <Box style={{ display: "flex" }}>
                <ErrorOutlineOutlinedIcon
                  style={{
                    color: theme.palette.secondary.main,
                    marginRight: 8,
                  }}
                />
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                  This page is under construction. Please call for wheel quotes.
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 15, width: 250, height: 50 }}
                  href={`tel:${APP_ENV.appPhoneNumber}`}
                >
                  Call Now
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DefaultContainer>
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid container style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Grid container style={{ paddingBottom: 20 }}>
            <Typography variant="h3">Wheel Brands</Typography>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            {wheelBrands.map((s, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{ padding: 15 }}
                  key={i}
                >
                  <Button
                    component={Paper}
                    elevation={5}
                    style={{
                      paddingTop: 25,
                      paddingBottom: 25,
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    <img
                      src={`https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images-wheels/${s
                        .replace(/ /g, "-")
                        .toLowerCase()}.png`}
                      alt={s}
                    />
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default WheelUnderConstruction;
