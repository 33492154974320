import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { useParams, useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { getEasySurveyDetails } from "../../services/venom-service";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EasySurveyForm from "../../components/easy-survey/easy-survey-form";
import ReviewUsOn from "../../components/easy-survey/widgets/review-us-on";
import AnimatedThumbs from "../../components/easy-survey/widgets/animated-thumbs";
import StillHaveAConcern from "../../components/easy-survey/widgets/still-have-a-concern";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const THUMB_TYPES = {
  up: "thumbs-up",
  down: "thumbs-down",
};

const EasySurveyMain = () => {
  const { thumbType } = useParams();

  const [state, setState] = useState({
    isReady: false,
    thumbsUp: Boolean(thumbType !== THUMB_TYPES.down),
    formSubmitRequired: false,
    customerFirstName: "",
    easySurveyCategories: [],
    externalReviewOptions: [],
    formHasBeenSubmitted: false,
  });

  const { search } = useLocation();

  useEffect(() => {
    const invoiceHashKey = search.split(`?inv_hash=`)?.[1] ?? "";

    if (invoiceHashKey) {
      (async () => {
        const data = await getEasySurveyDetails({
          invoiceHashKey,
        });
        setState((prev) => ({
          ...prev,
          ...data,
          thumbsUp: prev.thumbsUp,
          formSubmitRequired: data?.thumbsUp === false || !prev.thumbsUp,
          isReady: true,
        }));
      })();
    } else {
      setState((prev) => ({ ...prev, isReady: true }));
    }
  }, []);

  const onFormSubmit = (thumbsUp) =>
    setState((prev) => ({ ...prev, formHasBeenSubmitted: true, thumbsUp }));

  return (
    <>
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: 25,
        }}
        component={RouterLink}
        to={"/"}
      >
        <img src={APP_ENV.appBrandLogoImg} />
      </Box>
      <AnimatedThumbs {...state} />
      {state.isReady && (
        <Box style={{ width: "100%", textAlign: "center" }}>
          {state.formSubmitRequired && (
            <>
              {!state.formHasBeenSubmitted && (
                <EasySurveyForm onFormSubmit={onFormSubmit} {...state} />
              )}
              {state.formHasBeenSubmitted && (
                <>
                  <CheckCircleIcon
                    style={{ fontSize: 100, color: theme.palette.success.main }}
                  />
                  <Typography
                    variant="h3"
                    style={{ margin: 10, marginBottom: 25 }}
                  >
                    Thank you!
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ margin: 10, marginBottom: 25 }}
                  >
                    Your review has been submitted.
                  </Typography>
                  {state.thumbsUp && <ReviewUsOn {...state} />}
                  {!state.thumbsUp && <StillHaveAConcern />}
                </>
              )}
            </>
          )}

          {!state.formSubmitRequired && (
            <>
              {state.thumbsUp && (
                <>
                  <Rating
                    value={5}
                    precision={1}
                    style={{ fontSize: 40, margin: 15 }}
                    readOnly
                  />
                  <Typography variant="h3">Yay!</Typography>
                  <ReviewUsOn {...state} />
                </>
              )}
              {!state.thumbsUp && <StillHaveAConcern />}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default EasySurveyMain;
