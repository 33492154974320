import { GoogleMap, Marker } from "@react-google-maps/api";
import GoogleServicesStore from "../../hooks/store/client/google-services-store";

const LocationMap = ({ height = 500, markers = [] }) => {
  const { latitude, longitude } = markers?.[0] ?? {};

  if (!latitude || !longitude) return null;

  const [isReady] = GoogleServicesStore((st) => [st.isReady]);

  return (
    <>
      {isReady && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height }}
          center={{ lat: latitude, lng: longitude }}
          zoom={10}
        >
          {markers.map(
            ({
              latitude,
              longitude,
              storeNumber,
              googleDirectionsUrl = "",
            }) => {
              const clickable = googleDirectionsUrl ? true : false;
              return (
                <Marker
                  key={storeNumber}
                  position={{ lat: latitude, lng: longitude }}
                  clickable={clickable}
                  onClick={() =>
                    clickable &&
                    window.open(googleDirectionsUrl, "_blank").focus()
                  }
                />
              );
            }
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default LocationMap;
