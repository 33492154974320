import * as React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";

const GlobalLoading = ({ isOpen }) => {
  return (
    <Backdrop open={isOpen} style={{ zIndex: 1 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default GlobalLoading;
