import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSessionTimerStore } from "./session-timer-store";
import { useScreenSizeListener } from "./layout-store";
import { useClientTrackingStore } from "./client-tracking-store";
import { useGoogleEventsStore } from "./google-events-store";
import { GoogleLibrariesLoadAsync } from "./google-services-store";
import { useOlarkChat } from "./olark-chat";

const GlobalClientContoller = () => {
  useSessionTimerStore();
  useScreenSizeListener();
  useClientTrackingStore();
  useGoogleEventsStore();
  useOlarkChat();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <GoogleLibrariesLoadAsync />;
};

export default GlobalClientContoller;
