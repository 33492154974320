import MetaTags from "../../reusable/ReactMetaTags";
import APP_ENV from "../../../appEnvironment";

const TireConsultation = () => {
  return (
    <>
      <MetaTags
        description={`At ${APP_ENV.appBrandName} we offer tire consultation reservations. Same day appointments available.`}
        canonical="/car-services/tire-consultation"
        title={`Tire Consultation | ${APP_ENV.appBrandName}`}
      />
      <div>
        <span>
          <strong>Looking for help find the right tires?</strong>
        </span>
        <p>
          {`At ${APP_ENV.appBrandName} we offer tire consultation appointments. We will secure a time for you to meet with one of our expert 
          sale staff and they will help guide you in finding the right tires for your vehicle and budget. Same-day appointments are available at
          most locations.
          `}
        </p>
      </div>
    </>
  );
};

export default TireConsultation;
