import { useEffect, useState } from "react";
import { useTireSizeStore } from "./store/tire-size-store";
import useConditionalSelection, {
  createSelectionField,
  createSelectionOption,
} from "./util/useConditionalSelection";

const useSelectCustomTireSize = (
  onSubmit = (tireSize) =>
    console.warn(
      "Warning: You need to pass onSubmit function to useSelectCustomTireSize",
      tireSize
    )
) => {
  const [state, setState] = useState({
    isReady: false,
    isStaggered: false,
    position: "front",
  });

  const [getTireSizeStoreAsync, setUserTireSize] = useTireSizeStore((st) => [
    st.getTireSizeStoreAsync,
    st.setUserTireSize,
  ]);

  useEffect(() => {
    (async () => {
      const { userTireSize } = await getTireSizeStoreAsync();

      setState((prev) => ({
        ...prev,
        isReady: true,
        isStaggered: userTireSize.isStaggered,
      }));
    })();
  }, []);

  const handleSubmit = async () => {
    const frontTireSizeEndPoint = (await front.handleSubmit())
      ?.tireSizeEndPoint;

    const rearTireSizeEndPoint = state.isStaggered
      ? (await rear.handleSubmit())?.tireSizeEndPoint
      : frontTireSizeEndPoint;

    if (frontTireSizeEndPoint && rearTireSizeEndPoint) {
      const userTireSize = setUserTireSize({
        frontTireSizeEndPoint,
        rearTireSizeEndPoint,
      });

      onSubmit(userTireSize);
    }
  };

  const setPosition = (position) => setState((prev) => ({ ...prev, position }));

  const front = useSelectSingleCustomTireSize({
    position: "front",
    setPosition,
  });

  const rear = useSelectSingleCustomTireSize({
    position: "rear",
    setPosition,
  });

  return {
    ...state,
    front,
    rear: state.isStaggered && rear,
    handleSubmit,
    toggleStaggered: () =>
      setState((prev) => ({ ...prev, isStaggered: !prev.isStaggered })),
  };
};

const useSelectSingleCustomTireSize = ({ position = "", setPosition }) => {
  const [getTireSizeStoreAsync, tireSizeController] = useTireSizeStore((st) => [
    st.getTireSizeStoreAsync,
    st.tireSizeController,
  ]);

  const getInitialSelection = async () => {
    const ts = (await getTireSizeStoreAsync()).userTireSize?.[position];
    return [ts.width, ts.ratio, ts.rim].filter((i) => i);
  };

  const createOption = (arr) =>
    arr.map((val) => createSelectionOption(val, val));

  const getWidths = async () => {
    const widths = await tireSizeController();

    return createOption(widths);
  };

  const getRatios = async ([width]) => {
    const ratios = await tireSizeController({ width });
    return createOption(ratios);
  };

  const getRims = async ([width, ratio]) => {
    const rims = await tireSizeController({ width, ratio });
    return createOption(rims);
  };

  const getFinalSelection = async ([width, ratio, rim]) => {
    const selection = await tireSizeController({ width, ratio, rim });

    return selection;
  };

  const onUserSubmit = (tireSize) => tireSize;

  const state = useConditionalSelection(
    getInitialSelection,
    [
      createSelectionField("Width", "width", getWidths),
      createSelectionField("Ratio", "ratio", getRatios),
      createSelectionField("Rim", "rim", getRims),
    ],
    getFinalSelection,
    onUserSubmit
  );

  useEffect(() => {
    state.handleInit();
  }, []);

  return {
    ...state,
    fields: state.fields.map((f) => ({
      ...f,
      setActive: () => {
        f.setActive();
        setPosition(position);
      },
    })),
  };
};

export default useSelectCustomTireSize;
