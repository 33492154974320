import { useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  Box,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseRounded from "@material-ui/icons/CloseRounded";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ModalStore from "../../../hooks/store/modal-store";
import { useHistory } from "react-router-dom";
import useAppCreditInformation from "../../../hooks/store/credit-store";
import APP_ENV from "../../../appEnvironment";
import { roundMoney } from "../../../helpers/generic";

const { theme } = APP_ENV;

const MonthlyFinanceAmount = ({ amount }) => {
  const { creditCardName, monthlyCreditCalculator, creditPageEndPoint } =
    useAppCreditInformation();

  const { amountQualifies, monthlyPaymentAmount, financeMonths } =
    monthlyCreditCalculator(amount);

  const [CartDrawer] = ModalStore((st) => [st.CartDrawer]);

  const [state, setState] = useState({
    modalOpen: false,
  });

  const closeModal = () => setState({ modalOpen: false });

  const history = useHistory();

  return (
    <>
      <div style={{ display: amountQualifies ? "block" : "none" }}>
        <Typography
          style={{
            fontSize: 11,
            display: "inline",
          }}
        >
          {`Only `}
          <span style={{ fontWeight: 700 }}>{`$${roundMoney(
            monthlyPaymentAmount
          ).toFixed(2)}/mo. `}</span>
          {`suggested monthly payments with ${financeMonths}-month special financing.*`}
          <Button
            color="primary"
            onClick={() => {
              setState({ modalOpen: true });
            }}
            style={{
              fontSize: 11,
              color: theme.palette.primary.main,
              paddingTop: 0,
              paddingLeft: 2,
              paddingRight: 0,
              paddingBottom: 2,
            }}
          >
            {`Learn How`}
          </Button>
        </Typography>
      </div>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={state.modalOpen}
        onClose={closeModal}
      >
        <Box style={{ width: "100%", padding: 15 }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <Box>
              <Typography style={{ fontSize: 22, fontWeight: 600 }}>
                {`${creditCardName} Special Financing`}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={closeModal}>
                <CloseRounded />
              </IconButton>
            </Box>
          </Box>
          <Grid container style={{ marginBottom: 10 }}>
            {[
              "Amount Financed",
              "Suggested Equal Monthly Payment",
              "Promotional Period",
            ].map((header, i) => (
              <Grid
                key={i}
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: theme.palette.primary.main,
                  border: "1px solid white",
                  padding: 2,
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.contrastText,
                    fontSize: 13,
                  }}
                >
                  {header}
                </Typography>
              </Grid>
            ))}
            {[
              `$${roundMoney(amount).toFixed(2)}`,
              `$${roundMoney(monthlyPaymentAmount).toFixed(2)}`,
              financeMonths,
            ].map((value, i) => (
              <Grid
                key={i}
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: theme.palette.background.default,
                  border: "1px solid white",
                  padding: 2,
                }}
              >
                <Typography style={{}}>{value}</Typography>
              </Grid>
            ))}
          </Grid>
          <Box style={{ marginBottom: 10 }}>
            <Typography style={{ fontWeight: 600, fontSize: 13 }}>
              {`The Suggested Equal Monthly Payment shown above (which may exclude
                taxes and delivery) may be greater than the required minimum monthly
                payment that will be on your billing statement when you use the 6-month
                promotional financing offer.`}
            </Typography>
          </Box>
          <Box style={{ marginBottom: 10 }}>
            <Typography style={{ fontWeight: 600 }}>
              {`This estimated payment:`}
            </Typography>

            {[
              `Equals the amount financed divided by ${financeMonths} rounded to the next highest whole cent.`,
              `Would pay off the amount financed within ${financeMonths} months, but only if there are no other 
               balances on your account at any time during the ${financeMonths}-month promotional period and you 
               make your payments on time. Your total payments will equal the amount financed.`,
            ].map((item, i) => (
              <Grid container key={i} style={{ marginTop: 10 }}>
                <Grid item xs={1} style={{ paddingTop: 2 }}>
                  <FiberManualRecordIcon />
                </Grid>
                <Grid item xs={11}>
                  <Typography style={{ fontSize: 13 }}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
          <Box style={{ marginBottom: 10 }}>
            <Typography style={{ fontWeight: 600, fontSize: 13 }}>
              {`If the amount financed is not paid in full within ${financeMonths} months, interest will be
                charged to your account from the purchase date and your total payments
                will be greater than the amount financed.`}
            </Typography>
          </Box>
          <Box style={{ marginBottom: 10 }}>
            <Typography style={{ fontWeight: 600, fontSize: 13 }}>
              {`IMPORTANT: The information about the Suggested Equal Monthly Payment
                shown assumes the following promotional financing offer is applied to the
                amount financed:`}
            </Typography>
          </Box>
          <Box style={{ marginBottom: 10 }}>
            <Typography style={{ fontWeight: 600, fontSize: 13 }}>
              {`No Interest if Paid in Full Within ${financeMonths} Months*`}
            </Typography>
            <Typography style={{ fontSize: 13 }}>
              {`On purchases of $199 or more with your ${creditCardName} credit card.
                Interest will be charged to your account from the purchase date if the
                promotional purchase is not paid in full within ${financeMonths} months. Minimum monthly
                payments required.`}
            </Typography>
          </Box>
          <Box style={{ marginBottom: 10 }}>
            <Button
              color="primary"
              endIcon={
                <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 2 }} />
              }
              onClick={() => {
                closeModal();
                CartDrawer.closeModal();
                history.push(creditPageEndPoint);
              }}
            >{`*Click for details`}</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default MonthlyFinanceAmount;
