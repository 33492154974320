class AccquistionCompany {
  constructor(displayName) {
    this.displayName = displayName;
    this.logoImage = "";
    this.newStoreLandingEndPoint = "";
    this.storeOriginId = null;
    this.storeNumberList = null;
  }

  filterAllStores = (allStores = []) => {
    if (this.storeNumberList) {
      return allStores.filter((s) =>
        this.storeNumberList.includes(s?.storeNumber)
      );
    }
    if (this.storeOriginId) {
      return allStores.filter((s) => s?.storeOriginId == this.storeOriginId);
    }
    return [];
  };

  setLogoImage(img) {
    this.logoImage = img;
    return this;
  }

  setNewStoreLandingEndPoint(endPoint) {
    this.newStoreLandingEndPoint = endPoint;
    return this;
  }

  setStoreOriginId(id) {
    this.storeOriginId = id;
    return this;
  }

  setStoreNumberList(list) {
    this.storeNumberList = list;
    return this;
  }
}

const ACCQUISTION_COMPANIES = {
  purcell: new AccquistionCompany("Purcell")
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/purcell-logo.png"
    )
    .setNewStoreLandingEndPoint("purcell-retail-tires")
    .setStoreOriginId(3),
  integrityTire: new AccquistionCompany("Integrity Tire")
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/integritylogo2.png"
    )
    .setNewStoreLandingEndPoint("integrity-tire")
    .setStoreOriginId(4),
  ssTire: new AccquistionCompany("S&S Tire & Auto")
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/sstirelogo.png"
    )
    .setNewStoreLandingEndPoint("ss-tire")
    .setStoreOriginId(5),
  communityTire: new AccquistionCompany("Community Tire Pros")
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/communityLogo.png"
    )
    .setNewStoreLandingEndPoint("community-tire-az")
    .setStoreOriginId(6),
  cAndRTire: new AccquistionCompany("C&R Tire")
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/canrtireLogo.png"
    )
    .setNewStoreLandingEndPoint("c-and-r-tire")
    .setStoreOriginId(7),
  briansTire: new AccquistionCompany(`Brian's Tire & Automotive Pros`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/briansLogo.png"
    )
    .setNewStoreLandingEndPoint("brians-tire-az")
    .setStoreOriginId(8),
  chatsworthTire: new AccquistionCompany(`Chatsworth Tire & Service Center`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/chatsworthtireLogo.png"
    )
    .setNewStoreLandingEndPoint("chatsworth-tire")
    .setStoreNumberList(["1040"]),
  delcoTire: new AccquistionCompany(`Delco Tire & Automotive Center`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/delcoTireLogo.png"
    )
    .setNewStoreLandingEndPoint("delco-tire")
    .setStoreNumberList(["1041"]),
  usaExpress: new AccquistionCompany(`USA Express Tire & Service`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/useExpressLogo.png"
    )
    .setNewStoreLandingEndPoint("usa-express-tire")
    .setStoreNumberList(["1037", "1038"]),
  discountTireTO: new AccquistionCompany(`Discount Tire & Service Centers`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/discountTireToLogo.png"
    )
    .setNewStoreLandingEndPoint("discount-tire-thousand-oaks")
    .setStoreNumberList(["1036"]),
  californiaTire: new AccquistionCompany(`California Tire & Service`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/logo%20california%20tire.png"
    )
    .setNewStoreLandingEndPoint("california-tire-pico-blvd")
    .setStoreNumberList(["1035"]),
  mpgAutomotive: new AccquistionCompany(`MPG Automotive`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/bbt_stroke_logo.png"
    )
    .setNewStoreLandingEndPoint("mpg-tire-and-auto-az")
    .setStoreOriginId(11),
  bestPerformance: new AccquistionCompany("Best Performance Tire & Service")
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/best_performance_logo.png"
    )
    .setNewStoreLandingEndPoint("best-performance-tire-la")
    .setStoreOriginId(13),
  christensenAutomotive: new AccquistionCompany(`Christensen Automotive`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/Christensen%20Automotive%20Logo.svg"
    )
    .setNewStoreLandingEndPoint("christensen-automotive")
    .setStoreOriginId(15),
  aaAutomotive: new AccquistionCompany(`AA Automotive`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/AA-Auto-Care-Logo.jpg"
    )
    .setNewStoreLandingEndPoint("aa-automotive")
    .setStoreOriginId(16),
  plazaTire: new AccquistionCompany(`Plaza Tire & Auto Service`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/plaza-tire-logo.png"
    )
    .setNewStoreLandingEndPoint("plaza-tire-ca")
    .setStoreOriginId(17),
  dAndDTire: new AccquistionCompany(`D & D Tire Service`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/dandd-tire-service-logo.png"
    )
    .setNewStoreLandingEndPoint("d-and-d-tire-service")
    .setStoreOriginId(19),
  elderAuto: new AccquistionCompany(`Elder Auto`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/edler-auto-logo.png"
    )
    .setNewStoreLandingEndPoint("elder-auto-co")
    .setStoreOriginId(20),
  pickeringsAuto: new AccquistionCompany(`Pickering's Auto`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/pickerings-auto-logo.png"
    )
    .setNewStoreLandingEndPoint("pickerings-auto-co")
    .setStoreOriginId(21),
  schultzTire: new AccquistionCompany(`Schultz Tire`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/schultz-tire-logo.png"
    )
    .setNewStoreLandingEndPoint("schultz-tire")
    .setStoreOriginId(22),
  tandtTire: new AccquistionCompany(`T&T OK Tire`)
    .setLogoImage(
      "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/t-and-t-tire-inc-logo.jpg"
    )
    .setNewStoreLandingEndPoint("t-and-t-tire")
    .setStoreOriginId(23),
};

export default ACCQUISTION_COMPANIES;
