import { Typography, Grid, Box, Paper } from "@material-ui/core";
import useTireItemsByBrandModel from "../../hooks/useTireItemsByBrandModel";
import MetaTags from "../../components/reusable/ReactMetaTags";
import BreadCrumb from "../../components/generic/bread-crumb";
import APP_ENV from "../../appEnvironment";
import LayoutStore from "../../hooks/store/client/layout-store";
import DefaultContainer from "../../components/generic/default-container";
import SemiCollapseableDescription from "../../components/generic/semi-collapseable-description";
import TireReviewController from "../../components/tire-reviews/tire-review-controller";
import Skeleton from "@material-ui/lab/Skeleton";
import TireSearchResultsCard from "../../components/tires/tire-search-results-card";

const { theme } = APP_ENV;

const TireItemDetails = () => {
  const state = useTireItemsByBrandModel();

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const { breadCrumbPaths, tireModel, tireBrand, tires, isLoading } = state;

  const tire = tires?.[0];

  const { brandName = "", brandImage = "" } = tireBrand;
  const { itemModelName = "", itemModelSlug = "" } = tireModel;

  return (
    <>
      <MetaTags
        description={`Shop the lowest prices on ${brandName} ${itemModelName} tires.`}
        canonical={itemModelSlug}
        title={`${brandName} ${itemModelName} Tires | ${APP_ENV.appBrandName}`}
      />
      {isLoading && (
        <>
          <DefaultContainer>
            <Box style={{ width: "100%", paddingTop: 40 }}>
              <Skeleton variant="rect" width={186} height={55} />
            </Box>
          </DefaultContainer>
          <DefaultContainer>
            <Grid
              container
              style={{
                marginTop: 30,
                marginBottom: 20,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="rect"
                style={{
                  width: "900px",
                  height: "590px",
                }}
              />
            </Grid>
          </DefaultContainer>
          <DefaultContainer backgroundColor={theme.palette.background.default}>
            <Grid container style={{ padding: 100 }}></Grid>
          </DefaultContainer>
        </>
      )}

      {Boolean(!isLoading && tire) && (
        <>
          <BreadCrumb paths={breadCrumbPaths} />
          <DefaultContainer>
            <Box style={{ width: "100%", paddingTop: 20, paddingBottom: 30 }}>
              {Boolean(brandImage) && <img src={brandImage} />}
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box style={{ width: "100%", maxWidth: isMobile ? 600 : 900 }}>
                <TireSearchResultsCard tire={tire} />
              </Box>
            </Box>
          </DefaultContainer>
          <DefaultContainer backgroundColor={theme.palette.background.default}>
            <Box style={{ paddingTop: 25, paddingBottom: 25 }}>
              <Box
                style={{
                  backgroundColor: theme.palette.background.paper,
                  padding: 15,
                }}
              >
                {tireModel?.itemModelDescription && (
                  <>
                    <Typography
                      variant="h5"
                      gutterBottom
                    >{`About the ${brandName} ${itemModelName}`}</Typography>
                    <SemiCollapseableDescription
                      description={tireModel?.itemModelDescription}
                    />
                  </>
                )}
              </Box>

              <TireReviewController />
            </Box>
          </DefaultContainer>
        </>
      )}
    </>
  );
};

export default TireItemDetails;
