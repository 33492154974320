import {
  Paper,
  IconButton,
  Box,
  Button,
  Tabs,
  Tab,
  Avatar,
  Typography,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import DrawerOffsetTop from "./widgets/DrawerOffsetTop";
import useVehicleTireSizeSelectControl, {
  VehicleTireSizeSelectComponentControl,
} from "../../hooks/useVehicleTireSizeSelectControl";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const VehicleTireSizeSelectionDrawerMobile = ({ isOpen, closeModal }) => {
  const { allSteps, tabs, currentStep, tabIndex, setTabIndex, activeStep } =
    useVehicleTireSizeSelectControl({
      isOpen,
      closeModal,
    });

  return (
    <DrawerOffsetTop
      isOpen={isOpen}
      closeModal={closeModal}
      anchor={"right"}
      PaperProps={{ style: { width: "100%" } }}
    >
      <Paper
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {currentStep == 0 && (
          <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)}>
            {tabs.map(({ tabLabel }) => {
              return (
                <Tab key={tabLabel} label={tabLabel} style={{ fontSize: 12 }} />
              );
            })}
          </Tabs>
        )}

        {currentStep !== 0 && (
          <Button
            color="primary"
            startIcon={<ArrowBackIosRoundedIcon />}
            onClick={tabs[0].onClick}
          >
            Back
          </Button>
        )}

        <IconButton onClick={closeModal}>
          <CloseRoundedIcon />
        </IconButton>
      </Paper>
      <Box style={{ padding: 15 }}>
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{
              marginRight: 15,
              width: 25,
              height: 25,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            {currentStep + 1}
          </Avatar>
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            {activeStep.sectionTitle}
          </Typography>
        </Box>

        <Typography style={{ fontSize: 12, marginTop: 15, marginBottom: 15 }}>
          {activeStep.sectionDesc}
        </Typography>
        <VehicleTireSizeSelectComponentControl
          allSteps={allSteps}
          activeStep={activeStep}
        />
      </Box>
    </DrawerOffsetTop>
  );
};

export default VehicleTireSizeSelectionDrawerMobile;
