import { Typography, Button, Box, Link } from "@material-ui/core";
import { useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import useZState from "../../../hooks/util/useZState";
import { useVehicleStore } from "../../../hooks/store/vehicle-store";
import APP_ENV from "../../../appEnvironment";
import ModalStore from "../../../hooks/store/modal-store";

const { theme } = APP_ENV;

const VehicleDisplayNameWithDesktopModal = () => {
  const [userVehicle, getUserVehicle] = useVehicleStore((st) => [
    st.userVehicle,
    st.getUserVehicle,
  ]);

  const [openModal] = ModalStore((st) => [
    st.SelectVehicleOnlyModalDesktop.openModal,
  ]);

  const [getState, setState] = useZState({
    isLoading: false,
  });

  useEffect(() => {
    setState({ isLoading: true });
    (async () => {
      await getUserVehicle();
      setState({ isLoading: false });
    })();
  }, []);

  const { isLoading } = getState();

  return (
    <>
      {isLoading && (
        <Skeleton
          variant="rect"
          width={200}
          height={25}
          style={{ marginBottom: 8 }}
        />
      )}

      {!isLoading && (
        <>
          <Button
            onClick={openModal}
            style={{
              color: theme.palette.common.black,
              fontWeight: "bold",
              padding: 0,
              textAlign: "left",
            }}
          >
            {!userVehicle && "Select Your Vehicle"}
            {userVehicle && (
              <Box style={{ maxWidth: 250, justifyContent: "flex-start" }}>
                <Typography noWrap style={{ fontWeight: "bold" }}>
                  {[
                    userVehicle.year,
                    userVehicle.makeName,
                    userVehicle.modelName,
                  ]
                    .filter((i) => i)
                    .join(" ")}
                </Typography>

                <Typography noWrap style={{ fontWeight: "bold" }}>
                  {[
                    [userVehicle.subModelId, userVehicle.subModelName],
                    [userVehicle.engineId, userVehicle.engineName],
                  ]
                    .filter(([val]) => val)
                    .map(([val, label]) => label)
                    .join(" ")}
                </Typography>
              </Box>
            )}
          </Button>
        </>
      )}
    </>
  );
};

export default VehicleDisplayNameWithDesktopModal;
