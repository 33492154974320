import { Box, Container, Grid, Typography, Paper } from "@material-ui/core";
import { useState } from "react";
import Swal from "sweetalert2";
import VyperBeforeStoreSelectCard from "../components/promotions/vyper/template/widgets/vyper-before-store-select-card";
import DefaultContainer from "../components/generic/default-container";
import AboutBrandInfoGraphics from "../components/reusable/about-brand-info-graphics";
import FiveReasonsToShopWithUs from "../components/reusable/five-reasons-to-shop-with-us";
import PromoStoreSelectDrawer from "../components/promotions/widgets/promo-store-select-drawer";
import VyperAfterStoreSelectCard from "../components/promotions/vyper/template/widgets/vyper-after-store-select-card";
import LayoutStore from "../hooks/store/client/layout-store";
import SMOG_CHECK_CONFIG from "../static/smog-check-config";
import { STATIC_CART_ITEMS } from "../static/cart-items";
import APP_ENV from "../appEnvironment";

const { theme } = APP_ENV;

const SmogCheck = () => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const [state, setState] = useState({
    isLocationDrawerOpen: false,
    hasStoreSelected: false,
  });

  const locationDrawer = (() => {
    const action = (bool) => () =>
      setState((prev) => ({ ...prev, isLocationDrawerOpen: bool }));
    return {
      open: action(true),
      close: action(false),
    };
  })();

  const handleLocationSelection = async () => {
    await Swal.fire({
      title: `Checking Smog Check availability at service center...`,
      timer: 1000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        locationDrawer.close();
      },
    });

    setState((prev) => ({ ...prev, hasStoreSelected: true }));
    await STATIC_CART_ITEMS.SMOG_CHECK_NEVADA.addToCart(false);
  };

  return (
    <>
      <Grid
        container
        style={{
          backgroundImage: `linear-gradient(${theme.palette.footer1.main}, ${theme.palette.background.default})`,
        }}
      >
        <Container style={{ paddingTop: 10, paddingBottom: 50 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Box style={{ width: "100%", maxWidth: 450 }}>
                <Box style={{ marginTop: 25 }}>
                  <Typography
                    variant="h2"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    SMOG Check Service
                  </Typography>
                  <Typography
                    variant="h3"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    $24.95 for SMOG Check & Certificate
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: theme.palette.primary.contrastText,
                      fontWeight: 600,
                      paddingTop: 5,
                    }}
                  >
                    {`*Certificate issued based on SMOG Check results.`}
                  </Typography>
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  {!state.hasStoreSelected && (
                    <VyperBeforeStoreSelectCard
                      openLocationDrawer={locationDrawer.open}
                      primaryText="Find SMOG Check Location"
                      secondaryText={`SMOG available at ${SMOG_CHECK_CONFIG.activeStoreNumbers.length} NV locations.`}
                      buttonText={"Choose Your Location"}
                    />
                  )}
                  {state.hasStoreSelected && (
                    <VyperAfterStoreSelectCard
                      showSharingLink={false}
                      storeCount={SMOG_CHECK_CONFIG.activeStoreNumbers.length}
                      openLocationDrawer={locationDrawer.open}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: 500,
                }}
              >
                <img
                  src={
                    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/smog-check-vyper.png"
                  }
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <DefaultContainer>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <AboutBrandInfoGraphics />
          </Grid>

          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <FiveReasonsToShopWithUs />
          </Grid>
        </Grid>
      </DefaultContainer>
      <PromoStoreSelectDrawer
        isOpen={state.isLocationDrawerOpen}
        closeModal={locationDrawer.close}
        drawerHeader={`*Showing ${SMOG_CHECK_CONFIG.activeStoreNumbers.length} SMOG Certified locations`}
        promoStores={SMOG_CHECK_CONFIG.activeStoreNumbers}
        handleLocationSelection={handleLocationSelection}
        hasLocation={state.hasStoreSelected}
      />
    </>
  );
};

export default SmogCheck;
