import { Grid, Typography, Paper, Box, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useTireBrandModelsStore } from "../../hooks/store/tire-brand-model-store";
import MetaTags from "../../components/reusable/ReactMetaTags";
import DefaultContainer from "../../components/generic/default-container";
import SemiCollapseableDescription from "../../components/generic/semi-collapseable-description";
import ReviewStars from "../../components/tires/widgets/review-stars";
import SaveXAmountBox from "../../components/tires/widgets/save-x-amount-box";
import BreadCrumb from "../../components/generic/bread-crumb";
import { TIRES_ROUTE_DIRECTORY } from "../../routes";
import APP_ENV from "../../appEnvironment";
import TireItemChipMain from "../../components/promotions/full-site-tire-sale/tire-item-chip-main";
import { useLocationStore } from "../../hooks/store/location-store";
import Skeleton from "@material-ui/lab/Skeleton";

const { theme } = APP_ENV;

const TiresModelsByBrand = () => {
  const [tireBrand, tireModels, maximumSavings, isReady] =
    useTireBrandModelsStore((st) => [
      st.tireBrand,
      st.tireModels,
      st.maximumSavings,
      st.isReady,
    ]);

  const {
    brandName = "",
    brandImage = "",
    brandDescription = "",
    brandSlug = "",
  } = tireBrand ?? {};

  const [userStore] = useLocationStore((st) => [st.userStore]);

  return (
    <>
      <MetaTags
        description={`Shop the lowest prices on ${brandName} tires.`}
        canonical={brandSlug}
        title={`${brandName} Tires | ${APP_ENV.appBrandName}`}
      />
      {!isReady && (
        <div>
          <div
            style={{
              height: "50px",
            }}
          />
          <DefaultContainer>
            <Grid
              container
              style={{
                padding: 20,
              }}
            >
              <Skeleton variant="text" width={342} height={55} />
            </Grid>
            <Grid
              container
              style={{
                padding: 20,
              }}
            >
              <Skeleton variant="rect" width={186} height={55} />
            </Grid>
            <Grid
              container
              style={{
                padding: 20,
              }}
            >
              <div
                style={{
                  width: "1200px",
                  height: "90px",
                }}
              />
              <div
                style={{
                  width: "1200px",
                  height: "90px",
                }}
              />
            </Grid>
          </DefaultContainer>
          <DefaultContainer backgroundColor={theme.palette.background.default}>
            <Grid
              container
              style={{
                padding: 10,
              }}
            >
              {Array.from({ length: 4 }).map((_, i) => (
                <Grid
                  key={i}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    padding: 5,
                  }}
                >
                  <Skeleton
                    variant="rect"
                    style={{
                      height: 300,
                      width: "100%",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </DefaultContainer>
        </div>
      )}

      {Boolean(isReady && tireBrand) && (
        <>
          <BreadCrumb paths={[["Tires", TIRES_ROUTE_DIRECTORY], [brandName]]} />
          <DefaultContainer>
            <Grid
              container
              style={{
                marginBottom: 20,
                marginTop: 20,
                padding: 20,
              }}
            >
              <Typography
                variant="h3"
                gutterBottom
              >{`Shop ${brandName} Tires`}</Typography>
            </Grid>
          </DefaultContainer>
          <DefaultContainer>
            <Grid
              container
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 20,
              }}
            >
              <>
                <img
                  src={brandImage}
                  alt={`${brandName} tires logo`}
                  style={{ marginBottom: 20 }}
                />
                <SemiCollapseableDescription description={brandDescription} />

                <SaveXAmountBox
                  maximumSavings={maximumSavings}
                  tireText={brandName}
                />
              </>
            </Grid>
          </DefaultContainer>
        </>
      )}
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            marginTop: 20,
            marginBottom: 20,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          {tireModels.map(
            ({
              itemModelName,
              itemModelImage,
              isOnSale,
              totalDiscountOnFour,
              itemModelDescription,
              itemModelSlug,
              reviewCount,
              reviewAvg,
            }) => {
              return (
                <Grid
                  item
                  sm={12}
                  md={6}
                  lg={6}
                  key={itemModelName}
                  style={{ width: "100%" }}
                >
                  <Paper style={{ margin: 5 }} elevation={5}>
                    <Grid container style={{ height: 325 }}>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={4}
                        style={{ overflow: "hidden" }}
                      >
                        <img
                          src={itemModelImage}
                          style={{
                            width: "100%",
                            height: 325,
                            objectFit: "contain",
                            marginLeft: -30,
                          }}
                        />
                      </Grid>
                      <Grid item xs={9} sm={9} md={8}>
                        <Box
                          style={{
                            width: "100%",
                            height: 25,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <TireItemChipMain
                            isOnSale={isOnSale}
                            promotion={userStore?.promotion ?? {}}
                          />
                        </Box>
                        <Box
                          style={{
                            width: "100%",
                            height: 300,
                            paddingTop: 10,
                            paddingBottom: 20,
                            paddingRight: 10,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Typography
                              variant={"h5"}
                              style={{ marginTop: 10, marginBottom: 10 }}
                            >
                              {itemModelName}
                            </Typography>
                            <ReviewStars
                              reviewCount={reviewCount}
                              reviewAvg={reviewAvg}
                              linkHref={itemModelSlug}
                            />
                            {isOnSale && (
                              <SaveXAmountBox
                                maximumSavings={totalDiscountOnFour}
                                tireText={`${brandName} ${itemModelName}`}
                              />
                            )}
                            {!isOnSale && (
                              <Typography noWrap style={{ fontSize: 12 }}>
                                {itemModelDescription}
                              </Typography>
                            )}
                          </Box>
                          <Button
                            component={RouterLink}
                            to={itemModelSlug}
                            variant="contained"
                            color="primary"
                            style={{ maxWidth: "300px", height: 50 }}
                          >
                            Shop Now
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            }
          )}
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default TiresModelsByBrand;
