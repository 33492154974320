import create from "zustand";
import TireSizeStore from "../tire-size-store";
import TireItemsStore from "../tire-items-store";
import TireSearchFilterStore from "./tire-search-filter-store";

let _promise;
let _tireSearchEndPoint;

const AllTiresStore = create((set, get) => {
  const initialState = {
    isReady: false,
    tireCounts: [],
    allTires: [],
    tireSearchEndPoint: "",
  };

  const getAllTiresStoreAsync = async (tireSizeEndPoints = [""], forceUpdate = false) => {

    const { tireSizes, createTireSearchEndPoint } =
      await TireSizeStore.getState().getTireSizeStoreAsync();

    const matchedTireSizes = tireSizeEndPoints
      .map((endPoint) =>
        tireSizes.find((tire) => tire.tireSizeEndPoint == endPoint)
      )
      .filter((i) => i);

    const matchedTireSizeEndPoints = matchedTireSizes.map(
      ({ tireSizeEndPoint }) => tireSizeEndPoint
    );

    const tireSearchEndPoint = createTireSearchEndPoint(
      matchedTireSizeEndPoints
    );

    if (_tireSearchEndPoint !== tireSearchEndPoint || forceUpdate) {
      _tireSearchEndPoint = tireSearchEndPoint;
      _promise = null;
      set({ isReady: false });
    }

    const _state = get();
    
    if (!_state.isReady && !_promise) {
      _promise = (async () => {
        const tires = await Promise.all(
          matchedTireSizeEndPoints.map((endpoint) =>
            TireItemsStore.getState().getActiveTireItems({
              tireSizeEndPoint: endpoint,
            })
          )
        );
        
        clearAllTiresStore();

        set({
          isReady: true,
          tireCounts: tires.map((tireSet) => tireSet.length),
          allTires: tires,
          tireSearchEndPoint,
        });

        TireSearchFilterStore.getState().initializeTireFilters(
          tires,
          matchedTireSizes
        );
        _promise = null;
      })();
    }

    if (!_state.isReady) {
      await _promise;
    }

    return get();
  };

  const clearAllTiresStore = () => {
    set(initialState);
    TireSearchFilterStore.getState().initializeTireFilters();
  }

  return {
    ...initialState,
    clearAllTiresStore,
    getAllTiresStore: get,
    getAllTiresStoreAsync,
  };
});

export default AllTiresStore;
