import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Button, Collapse } from "@material-ui/core";
import OpenClosedDisplayMessage from "./widgets/OpenClosedDisplayMessage";
import StackedDisplayName from "./widgets/StackedDisplayName";
import StoreSchedule from "./widgets/StoreSchedule";
import APP_ENV from "../../appEnvironment";
import UserDistance from "./widgets/UserDistance";
import AddressWithLink from "./widgets/AddressWithLink";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import SmogCheckOfferedLocationWidget from "../smog-check/smog-check-offered-location-widget";

const { theme } = APP_ENV;

const LocationInfoCard = (location) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box style={{ width: "100%" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <StackedDisplayName {...location} />
        <UserDistance {...location} />
      </Box>
      <Box style={{ paddingTop: 5 }}>
        <OpenClosedDisplayMessage {...location} fontSize={13} />
      </Box>
      <Box style={{ paddingTop: 5 }}>
        <SmogCheckOfferedLocationWidget {...location.smogCheck} />
      </Box>
      <AddressWithLink {...location} />

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Link style={{ fontSize: 14 }} href={`tel:${location.phoneNumber}`}>
          {location.phoneNumber}
        </Link>
        <Button
          onClick={() => setIsOpen((prev) => !prev)}
          size="small"
          color="primary"
          style={{ fontSize: 14 }}
          endIcon={
            isOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />
          }
        >
          Store Hours
        </Button>
      </Box>

      <Collapse in={isOpen}>
        <Box
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <StoreSchedule storeSchedule={location.storeSchedule} />
        </Box>
      </Collapse>
      <Box style={{ width: "100%", paddingTop: 8, paddingBottom: 8 }}>
        <Link
          style={{ fontSize: 16 }}
          underline="always"
          component={RouterLink}
          to={`/locations/${location.publicWebEndPoint}`}
        >
          Store Details
        </Link>
      </Box>
    </Box>
  );
};

export default LocationInfoCard;
