import { GetLocalStorage, SetLocalStorage, RemoveLocalStorageItem } from './reusable/LocalStorageObjects.js'

class Auth {

    url = '/api/wholesale/login'
    signIn(username, password) {
        return fetch(`${this.url}?userName=${username}&password=${password}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) throw new Error(response.status);
                return response.json();
            })
            .then(token => {
                SetLocalStorage("access_token", token);
                return true
            });
    }

    signOut(his) {
        if (typeof window !== "undefined") {

            RemoveLocalStorageItem("access_token");
            his.push('/')
        }
    }

    getAccessToken() {
        if (GetLocalStorage("access_token").isDefined && GetLocalStorage("access_token").object.firstName != "" && GetLocalStorage("access_token").object.firstName != null) {
            let user = GetLocalStorage("access_token").object
            return { 'Authorization': 'Bearer ' + user.token };
        } else {
            return null
        }
    }
    getAuthObject() {
        if (GetLocalStorage("access_token").isDefined) {
            let user = GetLocalStorage("access_token").object
            return user;
        } else {
            return null
        }
    }

    hasAccessToken() {
        return this.getAccessToken() != null;
    }
}

export default new Auth();