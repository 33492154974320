import { Typography, Grid, Paper, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import LayoutStore from "../../hooks/store/client/layout-store";
import MetaTags from "../../components/reusable/ReactMetaTags";
import DefaultContainer from "../../components/generic/default-container";
import StandardPageHeader from "../../components/generic/standard-page-header";
import { CART_ITEM_TYPE } from "../../hooks/store/cart-store";
import { STATIC_CART_ITEMS } from "../../static/cart-items";

import APP_ENV from "../../appEnvironment";
import FreeCVI from "../../components/promotions/widgets/free-cvi";

const { theme } = APP_ENV;

const {
  TIRE_ROTATION,
  FLAT_TIRE_REPAIR,
  WHEEL_ALIGNMENT_CHECK,
  BRAKE_CHECK,
  VEHICLE_FLIUD_CHECK,
  BATTERY_CHECK,
  ENGINE_CHECK,
  COOLING_SYSTEM_CHECK,
  TIRE_CONSULTATION,
  OIL_CONSULTATION,
} = STATIC_CART_ITEMS;

export const SERVICE_GROUPS = [
  [
    "Tire Services",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/circle-tireservices.webp",
    [TIRE_CONSULTATION, FLAT_TIRE_REPAIR, TIRE_ROTATION],
  ],
  [
    "Routine Maintenance",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/circle-routinemaint.webp",
    [WHEEL_ALIGNMENT_CHECK],
  ],
  [
    "Fluid Services",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/circle-fluidservices.webp",
    [OIL_CONSULTATION, VEHICLE_FLIUD_CHECK],
  ],
  [
    "Brake Services",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/circle-brakeservices.webp",
    [BRAKE_CHECK],
  ],
  [
    "Battery Services",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/circle-batteryservices.webp",
    [BATTERY_CHECK],
  ],
  [
    "Engine Services",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/circle-engineservices.webp",
    [ENGINE_CHECK, COOLING_SYSTEM_CHECK],
  ],
]
.map(([groupName, groupImage, groupItems]) => [
  groupName,
  groupImage,
  groupItems.filter((item) => item?.display),
])
.filter((group) => group[2].length);

const CarServicesMain = () => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  return (
    <>
      <MetaTags
        description="Schedule your maintenance service appointment online today. Our expert technicians will get you back on the road in no time."
        canonical="/car-services"
        title={`Car Maintenance Services | ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title="Shop Vehicle Services"
        subtitle={`At ${APP_ENV.appBrandName}, we offer many services to keep you moving. Our expert technicians will get you
         in, taken care of and back on the road in no time.`}
      >
        {APP_ENV.appBrandId !== 4 && (
          <Grid container style={{ paddingLeft: 10, paddingRight: 10 }}>
            <FreeCVI />
          </Grid>
        )}
      </StandardPageHeader>

      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid container style={{ padding: 25 }}>
          {SERVICE_GROUPS.map(
            ([serviceGroupTitle, serviceGroupImage, services]) => {
              return (
                <Grid
                  container
                  key={serviceGroupTitle}
                  component={Paper}
                  elevation={3}
                  style={{ marginBottom: 15, padding: 15 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid
                      container
                      style={{
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="h4"
                        style={{ fontWeight: "bold", marginBottom: 15 }}
                      >
                        {serviceGroupTitle}
                      </Typography>
                      <img
                        width={isMobile ? "25%" : "50%"}
                        src={serviceGroupImage}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <Grid container>
                      {services.map(
                        (
                          {
                            itemName,
                            itemDescription,
                            image,
                            endPoint,
                            addToCart,
                            cartItemTypeId,
                          },
                          i
                        ) => {
                          const isFreeService =
                            cartItemTypeId == CART_ITEM_TYPE.freeService;
                          const notLastItem = i !== services.length - 1;
                          return (
                            <Grid
                              key={i}
                              container
                              style={{
                                paddingTop: 25,
                                paddingBottom: 25,
                                borderBottom:
                                  notLastItem &&
                                  `1px solid ${theme.palette.divider}`,
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={1}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: 10,
                                }}
                              >
                                <img width="35" src={image} />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={8}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Grid
                                  container
                                  style={{
                                    display: "block",
                                    textAlign: isMobile ? "center" : "left",
                                  }}
                                >
                                  {isFreeService && (
                                    <Typography
                                      style={{
                                        color: theme.palette.error.main,
                                        fontWeight: 600,
                                        paddingLeft: 10,
                                      }}
                                    >
                                      FREE
                                    </Typography>
                                  )}
                                  <Button
                                    component={RouterLink}
                                    to={endPoint}
                                    color="primary"
                                  >
                                    {itemName}
                                  </Button>
                                  <Typography style={{ padding: 10 }}>
                                    {itemDescription}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: 10,
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={addToCart}
                                  style={{ width: 300, height: 50 }}
                                >
                                  Add To Cart
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          )}
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default CarServicesMain;
