import { Box, Typography } from "@material-ui/core";

const CartHeader = ({ cartItemQty = 0, children }) => {
  let itemsLabel = cartItemQty == 1 ? "Item" : "Items";

  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 10,
      }}
    >
      {Boolean(cartItemQty) && (
        <>
          <Typography>
            <strong>{cartItemQty}</strong> {`${itemsLabel} In Cart`}
          </Typography>
          {children}
        </>
      )}
    </Box>
  );
};

export default CartHeader;
