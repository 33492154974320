import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { Grid, Paper, Box, Link, Typography, Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import PhoneRoundedIcon from "@material-ui/icons/PhoneRounded";
import WatchLaterRoundedIcon from "@material-ui/icons/WatchLaterRounded";
import MetaTags from "../../components/reusable/ReactMetaTags";
import { useLocationStore } from "../../hooks/store/location-store";
import ModalStore from "../../hooks/store/modal-store";
import LayoutStore from "../../hooks/store/client/layout-store";
import {
  SingleLocationHeaderDesktop,
  SingleLocationHeaderMobile,
} from "../../components/locations/single-location-page/SingleLocationHeader";
import singleLocationSchema from "../../components/locations/single-location-page/singleLocationSchema";
import AddressWithLink from "../../components/locations/widgets/AddressWithLink";
import LocationMap from "../../components/locations/LocationMap";
import StoreSchedule from "../../components/locations/widgets/StoreSchedule";
import ShopNowCardCollection from "../../components/reusable/shop-now-card-collection";
import BreadCrumb from "../../components/generic/bread-crumb";
import DefaultContainer from "../../components/generic/default-container";
import APP_ENV from "../../appEnvironment";
import AboutOurCompany from "../../components/locations/widgets/about-our-company";
import LocationContentSection from "./location-content-section";

const { theme } = APP_ENV;

const LocationsSingle = () => {
  const params = useParams();
  const history = useHistory();

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const globalLoading = ModalStore((st) => ({ ...st.GlobalLoading }));

  const [locations] = useLocationStore((st) => [st.locations]);

  let location;

  if (locations.length) {
    location = locations.find((loc) => loc.publicWebEndPoint == params?.pageId);
  }

  useEffect(() => {
    switch (true) {
      case !locations.length && !globalLoading.isOpen:
        globalLoading.openModal();
        break;
      case locations.length && globalLoading.isOpen:
        globalLoading.closeModal();
        if (!location) {
          history.push("/");
        }
        break;
    }
  }, [locations.length]);

  const {
    displayName = "",
    displayCity = "",
    displayCityEndPoint = "",
    city = "",
    stateAbbreviation = "",
    zipCode = "",
    publicWebEndPoint = "",
    phoneNumber = "",
    googleReviewUrl = "",
    yelpReviewUrl = "",
    facebookPageUrl = "",
    isSelected = () => false,
    setSelected = () => null,
    storesNearby = {},
  } = location ?? {};

  const _isSelected = isSelected();

  const cityState = `${city}, ${stateAbbreviation}`;

  let breadCrumbPaths = [["Locations", "/locations"], [displayName]];
  if (storesNearby?.storeList?.length > 1) {
    breadCrumbPaths = [
      breadCrumbPaths[0],
      [
        `${displayCity}, ${stateAbbreviation}`,
        `/tire-shop/${displayCityEndPoint}`,
      ],
      breadCrumbPaths[1],
    ];
  }

  return (
    <>
      {location && (
        <>
          <MetaTags
            description={`Vist ${APP_ENV.appBrandName} ${displayName} for new tires, oil change, wheel alignment, & more. Your one stop automotive shop in ${city}, ${stateAbbreviation} ${zipCode}`}
            keywords={[
              `tires in ${cityState}`,
              `tire shop in ${displayCity}`,
              `oil change ${cityState}`,
              `oil change ${displayCity}`,
              `brakes ${cityState}`,
              `brakes ${displayCity}`,
              `alignment ${cityState}`,
              `alignment ${displayCity}`,
              `tune-up ${cityState}`,
              `tune-up ${displayCity}`,
              `battery ${cityState}`,
              `battery ${displayCity}`,
              `wheels ${cityState}`,
              `wheels ${displayCity}`,
            ].join(", ")}
            canonical={`/locations/${publicWebEndPoint}`}
            title={`${APP_ENV.appBrandName} | ${displayCity}, ${stateAbbreviation} | ${zipCode} | Tire Shop Near Me`}
            script={
              location ? JSON.stringify(singleLocationSchema(location)) : null
            }
          />

          <BreadCrumb paths={breadCrumbPaths} />

          {isMobile ? (
            <SingleLocationHeaderMobile {...location} />
          ) : (
            <SingleLocationHeaderDesktop {...location} />
          )}

          <DefaultContainer backgroundColor={theme.palette.background.default}>
            <Grid
              container
              style={{
                paddingTop: 25,
                paddingBottom: 25,
                justifyContent: "space-evenly",
              }}
            >
              <Grid
                item
                xs={12}
                sm={8}
                md={5}
                lg={4}
                xl={4}
                style={{ marginBottom: 15 }}
              >
                <Paper>
                  {_isSelected && (
                    <Paper
                      style={{
                        backgroundColor: theme.palette.secondary.main,
                        paddingLeft: 8,
                        paddingRight: 8,
                        width: "fit-content",
                      }}
                    >
                      <Typography
                        style={{ color: theme.palette.primary.contrastText }}
                      >
                        Your Store
                      </Typography>
                    </Paper>
                  )}
                  <Box
                    style={{
                      padding: 10,
                      paddingBottom: 20,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <LocationOnRoundedIcon
                          style={{
                            color: theme.palette.primary.dark,
                            fontSize: 40,
                            margin: 12,
                          }}
                        />
                        <AddressWithLink {...location} />
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <PhoneRoundedIcon
                          style={{
                            color: theme.palette.primary.dark,
                            fontSize: 40,
                            margin: 12,
                          }}
                        />
                        <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
                      </Box>

                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <WatchLaterRoundedIcon
                          style={{
                            color: theme.palette.primary.dark,
                            fontSize: 35,
                            margin: 12,
                          }}
                        />
                        <Typography variant="h5" style={{ fontWeight: 600 }}>
                          Store Hours
                        </Typography>
                      </Box>
                      <Box style={{ marginLeft: 60 }}>
                        {location && <StoreSchedule {...location} />}
                      </Box>

                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          style={{
                            maxWidth: 350,
                            paddingTop: 25,
                            paddingBottom: 25,
                          }}
                        >
                          <Button
                            onClick={() =>
                              _isSelected
                                ? history.push("/tires")
                                : setSelected()
                            }
                            variant={_isSelected ? "contained" : "outlined"}
                            color="primary"
                            fullWidth
                            style={{ height: 50 }}
                          >
                            {_isSelected ? "Shop Now" : "Make This Your Store"}
                          </Button>
                          <Button
                            style={{ marginTop: 15, height: 50 }}
                            variant={"contained"}
                            color="secondary"
                            fullWidth
                            onClick={() => history.push("/car-appointment")}
                          >
                            Quick Appointment
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
                <LocationMap markers={[location ?? {}]} height={400} />
                {location && (
                  <Paper
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: 15,
                      marginTop: 15,
                      marginBottom: 15,
                    }}
                  >
                    <Box>
                      <Rating
                        value={5}
                        precision={1}
                        readOnly
                        style={{ fontSize: 25 }}
                      />
                    </Box>
                    <Box style={{ margin: 10 }}>
                      <Link href={googleReviewUrl} target="_blank">
                        Leave us a review on Google
                      </Link>
                    </Box>

                    <Box style={{ margin: 10 }}>
                      <Link href={yelpReviewUrl} target="_blank">
                        Leave us a review on Yelp
                      </Link>
                    </Box>

                    <Link href={facebookPageUrl} target="_blank">
                      Leave us a review on Facebook
                    </Link>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </DefaultContainer>
          <DefaultContainer>
            <Grid
              container
              style={{
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <ShopNowCardCollection />
            </Grid>
          </DefaultContainer>
          <DefaultContainer backgroundColor={theme.palette.background.default}>
            <Grid container style={{ paddingTop: 20 }}>
              <LocationContentSection location={location} />
            </Grid>
          </DefaultContainer>
        </>
      )}
    </>
  );
};

export default LocationsSingle;
