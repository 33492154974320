import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DrawerOffsetTop from "../../modals/widgets/DrawerOffsetTop";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const QuickSelectOptionDrawer = ({
  isOpen,
  closeModal,
  drawerTitle,
  children,
}) => {
  return (
    <DrawerOffsetTop
      isOpen={isOpen}
      closeModal={closeModal}
      anchor="right"
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: 500,
        },
      }}
    >
      <Box
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          maxWidth: 500,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
          paddingRight: 5,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Typography variant="h4">{drawerTitle}</Typography>
        <IconButton onClick={closeModal} style={{ zIndex: 2 }}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>

      <Grid
        container
        style={{
          paddingTop: 25,
          paddingLeft: 15,
          paddingRight: 15,
          paddingBottom: 200,
          marginTop: 65,
        }}
      >
        {children}
      </Grid>
    </DrawerOffsetTop>
  );
};

export default QuickSelectOptionDrawer;
