import * as React from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const StoreSchedule = ({ storeSchedule }) => {
  return (
    <Box>
      {storeSchedule.map(
        ({
          dayOrder,
          dayName,
          displayHours,
          storeClosed,
          isNormallyBusinessDay,
          isHoliday,
          holidayName,
          isCurrentDay,
        }) => {
          return (
            <Box key={dayOrder}>
              <Box
                style={{
                  paddingBottom: isHoliday ? 1 : 3,
                  display: "flex",
                  alignItems: "center",
                  color: theme.palette.common.black,
                  fontSize: 14,
                  ...(isCurrentDay && { fontWeight: "bold" }),
                  ...(storeClosed &&
                    isNormallyBusinessDay && {
                      textDecoration: "line-through",
                    }),
                }}
              >
                <Box style={{ width: 70 }}>{`${dayName}:`}</Box>
                <Box>{displayHours}</Box>
              </Box>
              {isHoliday && (
                <Typography
                  style={{
                    fontSize: 12,
                    marginLeft: 70,
                    paddingBottom: 8,
                    fontWeight: "bold",
                    color: storeClosed ? "red" : "green",
                  }}
                >
                  {`${storeClosed ? "Closed" : "Open"} - ${holidayName}`}
                </Typography>
              )}
            </Box>
          );
        }
      )}
    </Box>
  );
};

export default StoreSchedule;
