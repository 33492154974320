import { Box, Typography } from "@material-ui/core";

const TireSpecsCollection = ({
  tireSizeDisplay = "",
  loadIndexSpeedRatingDisplay = "",
  loadRange = "",
  utqg = "",
  partNumberEndPoint = "",
}) => {
  return (
    <Box style={{ marginTop: 8, marginBottom: 8 }}>
      {[
        ["Tire Size", tireSizeDisplay],
        ["Serv. Desc", loadIndexSpeedRatingDisplay],
        ["Load Range", loadRange],
        ["UTQG", utqg?.replaceAll(" ", "") ?? utqg],
        ["SKU", partNumberEndPoint?.toUpperCase()],
      ]
        .filter(([_, value]) => Boolean(value))
        .map(([label, value]) => (
          <Typography key={label} style={{ fontSize: 12 }}>
            {label}: <strong>{value}</strong>
          </Typography>
        ))}
    </Box>
  );
};

export default TireSpecsCollection;
