import { StandardWidthContainer } from "../../generic/containers";
import APP_ENV from "../../../appEnvironment";
import { Box, Typography, Button, Grid, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import { useLocationStore } from "../../../hooks/store/location-store";
import { CREDIT_PAGE_ENDPOINT } from "../../../hooks/store/credit-store";

const { theme } = APP_ENV;

const SyncronyRebateHero = () => {
  const [userStore] = useLocationStore((st) => [st.userStore]);

  const { currentRebate, mainImage } = userStore?.promotion?.credit ?? {};

  return (
    <>
      {currentRebate && (
        <Box style={{ marginTop: 10 }}>
          <StandardWidthContainer
            backgroundColor={theme.palette.background.default}
          >
            <Box
              style={{
                textAlign: "center",
                paddingTop: 25,
                paddingBottom: 25,
              }}
            >
              <Typography
                variant="h3"
                style={{ fontWeight: "bold", paddingBottom: 25 }}
              >
                {`${dayjs().format("MMMM")} Exclusive Offer`}
              </Typography>

              <RouterLink to={CREDIT_PAGE_ENDPOINT}>
                <img
                  src={mainImage}
                  style={{
                    width: "100%",
                    height: "auto",
                    marginTop: 10,
                    cursor: "pointer",
                    maxWidth: 750,
                  }}
                />
              </RouterLink>
              <Grid
                container
                style={{ marginTop: 25, justifyContent: "center" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ padding: 10, textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ height: 50, maxWidth: 300 }}
                    component={RouterLink}
                    to={CREDIT_PAGE_ENDPOINT}
                  >
                    Learn More
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ padding: 10, textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    style={{
                      height: 50,
                      color: theme.palette.secondary.contrastText,
                      maxWidth: 300,
                    }}
                    component={Link}
                    href={currentRebate}
                    target="_blank"
                  >
                    Print Rebate
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </StandardWidthContainer>
        </Box>
      )}
    </>
  );
};

export default SyncronyRebateHero;
