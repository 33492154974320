import { Box } from "@material-ui/core";
import PackageSubtotalWithInfo from "../../cart/widgets/package-subtotal-with-info";
import SubTotalWithStrikeThrough from "../../cart/widgets/subtotal-with-strike-through";

const TirePackageSubtotal = ({ subtotal = 0, packageSubtotal = 0 }) => {
  return (
    <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <Box>
        <PackageSubtotalWithInfo />
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 15,
          }}
        >
          <SubTotalWithStrikeThrough
            subtotal={subtotal}
            displayPrice={packageSubtotal}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TirePackageSubtotal;
