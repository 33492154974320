import APP_ENV from "../appEnvironment";

const fetchData = async (endPoint, payload = {}) => {
  let data = [];

  try {
    const res = await fetch(`/api/publicWeb/${endPoint}`, {
      method: "POST",
      body: JSON.stringify({
        appBrandId: APP_ENV.appBrandId,
        ...payload,
      }),
      headers: { "Content-Type": "application/json" },
    });
    data = await res.json();
  } catch (e) {
    console.error({
      endPoint,
      payload,
      error: e,
    });
    data = [];
  }

  return data;
};

export default fetchData;
