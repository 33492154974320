import { useEffect } from "react";
import { useLocation } from "react-router";
import ModalStore from "../../hooks/store/modal-store";
import {
  Popover,
  Typography,
  Paper,
  Box,
  IconButton,
  Button,
} from "@material-ui/core";
import { useLocationStore } from "../../hooks/store/location-store";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Skeleton from "@material-ui/lab/Skeleton";
import OpenClosedDisplayMessage from "./widgets/OpenClosedDisplayMessage";
import APP_ENV from "../../appEnvironment";
import LocationInfoCard from "./LocationInfoCard";
import useAnchorEl from "../../hooks/util/useAnchorEl";

const { theme } = APP_ENV;

const UserLocationPopover = () => {
  const [userStore] = useLocationStore((st) => [st.userStore]);

  const locationSelectDrawer = ModalStore((st) => ({
    ...st.LocationSelectDrawer,
  }));

  const location = useLocation();

  useEffect(() => {
    setClosed();
  }, [location.pathname]);

  const { anchorEl, isOpen, setOpen, setClosed, toggle } = useAnchorEl();

  const handleChangeStoreClick = () => {
    setClosed();
    locationSelectDrawer.openModal();
  };

  return (
    <>
      <Box style={{ display: "flex", cursor: "pointer" }} onClick={toggle}>
        <Box style={{ maxWidth: "90%", paddingRight: 15 }}>
          {userStore && (
            <>
              <Typography
                noWrap
                style={{
                  color: theme.palette.common.black,
                  fontWeight: "bold",
                }}
              >
                {userStore.displayName}
              </Typography>
              <OpenClosedDisplayMessage {...userStore} fontSize={13} />
            </>
          )}
          {!userStore && (
            <>
              <Skeleton
                variant="rect"
                width={250}
                height={25}
                style={{ marginBottom: 8 }}
              />
              <Skeleton variant="rect" width={250} height={25} />
            </>
          )}
        </Box>
        <Box style={{ maxWidth: "10%", color: theme.palette.common.black }}>
          {userStore && (
            <IconButton onClick={toggle}>
              {
                <ExpandMoreRoundedIcon
                  style={{ visibility: isOpen && "hidden" }}
                />
              }
            </IconButton>
          )}
          {!userStore && (
            <IconButton>
              <Skeleton variant="circle" width={25} height={25} />
            </IconButton>
          )}
        </Box>
      </Box>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={setClosed}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper style={{ padding: 20, minWidth: 350 }}>
          <LocationInfoCard {...userStore} />
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            style={{ marginTop: 10, height: 50 }}
            onClick={handleChangeStoreClick}
          >
            Change Your Store
          </Button>
        </Paper>
      </Popover>
    </>
  );
};

export default UserLocationPopover;
