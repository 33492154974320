import { StandardWidthContainer } from "../generic/containers";
import { Box, Typography, Button, Paper } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useLocationStore } from "../../hooks/store/location-store";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const SmogCheckHero = () => {
  const [userStore] = useLocationStore((st) => [st.userStore]);

  return (
    <>
      {userStore?.smogCheck?.isSmogCheckState && (
        <Box style={{ marginTop: 10 }}>
          <StandardWidthContainer
            backgroundColor={theme.palette.background.default}
          >
            <Box
              style={{
                textAlign: "center",
                paddingTop: 25,
                paddingBottom: 25,
              }}
            >
              <Typography
                variant="h3"
                style={{ fontWeight: "bold", paddingBottom: 25 }}
              >
                {`Smog Check Available at ${userStore.smogCheck.storeCount} Locations`}
              </Typography>

              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <RouterLink to={userStore.smogCheck.endPoint}>
                  <Paper style={{ width: "fit-content" }} elevation={8}>
                    <img
                      src={
                        "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/smog-check-hero.jpg"
                      }
                      style={{
                        width: "100%",
                        height: "auto",

                        cursor: "pointer",
                        maxWidth: 750,
                      }}
                    />
                  </Paper>
                </RouterLink>
              </Box>
              <Box style={{ paddingTop: 25 }}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  style={{
                    width: 300,
                    height: 50,
                    color: theme.palette.primary.contrastText,
                  }}
                  component={RouterLink}
                  to={userStore.smogCheck.endPoint}
                >
                  Find Smog Check Location
                </Button>
              </Box>
            </Box>
          </StandardWidthContainer>
        </Box>
      )}
    </>
  );
};

export default SmogCheckHero;
