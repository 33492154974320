import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid, Link, Card } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import MetaTags from "../../components/reusable/ReactMetaTags";
import { useLocationStore } from "../../hooks/store/location-store";
import APP_ENV from "../../appEnvironment";
import ACCQUISTION_COMPANIES from "../../static/accquistion-companies";

const NewStoreLandingPage = () => {
  const history = useHistory();
  const { slug } = useParams();

  const [locations] = useLocationStore((st) => [st.locations]);

  const [state, setState] = useState({
    origin: {},
    stores: [],
  });

  useEffect(() => {
    if (locations.length) {
      const origin = Object.values(ACCQUISTION_COMPANIES).find(
        (i) => i.newStoreLandingEndPoint == slug
      );

      const stores = origin?.filterAllStores(locations) ?? [];
      if (!stores.length) {
        history.push("/");
        return;
      }
      setState((prev) => ({ ...prev, origin, stores }));
    }
  }, [locations]);

  const { logoImage = "", displayName = "" } = state.origin;

  const displayMessage = `We are excited to announce that the following ${displayName} retail locations are now part of the ${APP_ENV.appBrandName} Family.`;

  return (
    <>
      <MetaTags
        description={displayMessage}
        canonical={`/new-locations/${slug}`}
        title={`${displayName} | ${APP_ENV.appBrandName}`}
      />
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundImage:
            "url('https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pag-BG.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          padding: "1rem",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={10}
          xl={10}
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            borderRadius: "20px",
            boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.37",
            backdropFilter: "blur(6px)",
            position: "relative",
            marginLeft: "1rem",
            marginRight: "1rem",
            padding: "2rem",
          }}
        >
          {Boolean(state.stores.length) && (
            <>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Box mt="2rem">
                  <img
                    src={logoImage}
                    style={{ maxHeight: 300, width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "3vh",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h4"
                  style={{ fontWeight: "700", textAlign: "center" }}
                >
                  {displayMessage}
                </Typography>
              </Grid>
              <Box my="2rem" mx="1rem">
                <Grid container spacing={4}>
                  {state.stores.map((store, ind) => {
                    const handleStoreSelection = () => {
                      store.setSelected();
                      history.push("/");
                    };
                    return (
                      <Grid
                        item
                        key={ind}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={state.stores.length === 1 ? 12 : 4}
                        xl={state.stores.length === 1 ? 12 : 4}
                      >
                        <Card
                          style={{
                            backgroundColor: "#efefef",
                            borderRadius: 5,
                            padding: "1vw",
                            maxWidth: "500px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            height: "100%",
                          }}
                          elevation={3}
                        >
                          <Box
                            width="100%"
                            height="10hv"
                            padding={3}
                            paddingLeft={5}
                            paddingRight={5}
                          >
                            <Grid item container alignItems="center">
                              <Grid
                                item
                                xs={12}
                                style={{ textAlign: "center" }}
                              >
                                <Typography
                                  color="error"
                                  variant="h4"
                                  gutterBottom
                                >
                                  {store.displayName}
                                </Typography>
                                <Typography style={{ fontSize: 14 }}>
                                  {store.streetAddress}
                                </Typography>
                                <Typography
                                  style={{ fontSize: 14 }}
                                >{`${store.city}, ${store.stateAbbreviation} ${store.zipCode}`}</Typography>

                                <Link
                                  color="primary"
                                  href={`tel:${store.phoneNumber}`}
                                >
                                  <p
                                    style={{
                                      marginTop: 10,
                                      fontSize: "1.2vh",
                                    }}
                                  >
                                    {store.phoneNumber}
                                  </p>
                                </Link>
                              </Grid>
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "end",
                                  width: "100%",
                                  padding: 20,
                                  height: "100%",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleStoreSelection}
                                  fullWidth
                                  style={{ maxWidth: 300, height: 50 }}
                                >
                                  Shop Now
                                </Button>
                              </Box>
                            </Grid>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default NewStoreLandingPage;
