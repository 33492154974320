import { Box, Container, Grid, Typography, Paper } from "@material-ui/core";
import APP_ENV from "../../../../appEnvironment";
import LayoutStore from "../../../../hooks/store/client/layout-store";
import VyperBeforeStoreSelectCard from "./widgets/vyper-before-store-select-card";
import DefaultContainer from "../../../generic/default-container";
import AboutBrandInfoGraphics from "../../../reusable/about-brand-info-graphics";
import FiveReasonsToShopWithUs from "../../../reusable/five-reasons-to-shop-with-us";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import PromoStoreSelectDrawer from "../../widgets/promo-store-select-drawer";
import Confetti from "react-confetti";
import Swal from "sweetalert2";
import VyperAfterStoreSelectCard from "./widgets/vyper-after-store-select-card";
import useEventStore from "../../../../hooks/store/client/event-store";
import { useLocationStore } from "../../../../hooks/store/location-store";

const { theme } = APP_ENV;

const VyperLandingPage = ({
  lineOneText = "",
  lineTwoText = "",
  lineThreeText = "",
  buttonText = "Redeem Offer",
  promotionImage = "",
  promotionCartItem,
  cardPrimaryText = "",
  cardSecondaryText = "",
  activePromotions = [],
  promotionDirectory = "",
}) => {
  const [isMobile, screenWidth] = LayoutStore((st) => [
    st.isMobile,
    st.screenWidth,
  ]);

  const vyperPageViewEvent = useEventStore((state) => state.vyperPageViewEvent);

  const params = useParams();
  const history = useHistory();

  const [state, setState] = useState({
    promotion: { endPoint: "", stores: [] },
    isLocationDrawerOpen: false,
    hasStoreSelected: false,
    throwConfetti: false,
  });

  useEffect(() => {
    const promotion = activePromotions.find(
      (i) => i.endPoint == params?.promotionEndPoint
    );

    const url = new URL(window?.location);
    const searchParams = new URLSearchParams(url.search);

    if (promotion) {
      setState((prev) => ({ ...prev, promotion }));

      vyperPageViewEvent({
        promotionDirectory,
        promotionEndpoint: promotion.endPoint,
        edgeItemId: promotionCartItem.edgeItemId,
        source: searchParams.get("utm_source") || "",
        medium: searchParams.get("utm_medium") || "",
        campaign: searchParams.get("utm_campaign") || "",
        fullUrl: url.href,
      });
    } else {
      history.push("/");
    }
  }, []);

  const locationDrawer = (() => {
    const action = (bool) => () =>
      setState((prev) => ({ ...prev, isLocationDrawerOpen: bool }));
    return {
      open: action(true),
      close: action(false),
    };
  })();

  const handleLocationSelection = () => {
    (async () => {
      await Swal.fire({
        title: `Checking promotion availability at service center...`,
        timer: 1000,
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          locationDrawer.close();
        },
      });

      const throwConfetti = !state.hasStoreSelected;

      setState((prev) => ({ ...prev, hasStoreSelected: true, throwConfetti }));
      await promotionCartItem.addToCart(false);

      if (throwConfetti) {
        (async () => {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setState((prev) => ({ ...prev, throwConfetti: false }));
        })();
      }
    })();
  };

  return (
    <>
      <Grid
        container
        style={{
          backgroundImage: `linear-gradient(${theme.palette.footer1.main}, ${theme.palette.background.default})`,
        }}
      >
        <Container style={{ paddingTop: 10, paddingBottom: 50 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Box style={{ width: "100%", maxWidth: 450 }}>
                <Box style={{ marginTop: 25 }}>
                  <Typography
                    variant="h2"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    {lineOneText}
                  </Typography>
                  <Typography
                    variant="h2"
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    {lineTwoText}
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{
                      color: theme.palette.primary.contrastText,
                      fontWeight: 600,
                      paddingTop: 5,
                    }}
                  >
                    {lineThreeText}
                  </Typography>
                  {state.promotion.story && !state.hasStoreSelected && (
                    <Typography
                      style={{
                        color: theme.palette.primary.contrastText,
                        marginTop: 15,
                        marginBottom: 25,
                      }}
                    >
                      {state.promotion.story}
                    </Typography>
                  )}
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  {!state.hasStoreSelected && (
                    <VyperBeforeStoreSelectCard
                      openLocationDrawer={locationDrawer.open}
                      buttonText={buttonText}
                      primaryText={
                        cardPrimaryText || "Don't miss out! Ending soon"
                      }
                      secondaryText={
                        cardSecondaryText || "Limited quantity available"
                      }
                    />
                  )}
                  {state.hasStoreSelected && (
                    <VyperAfterStoreSelectCard
                      storeCount={state.promotion.stores.length}
                      openLocationDrawer={locationDrawer.open}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: 500,
                }}
              >
                <img
                  src={promotionImage}
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <DefaultContainer>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <AboutBrandInfoGraphics />
          </Grid>

          <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
            <FiveReasonsToShopWithUs />
          </Grid>
        </Grid>
      </DefaultContainer>
      <PromoStoreSelectDrawer
        isOpen={state.isLocationDrawerOpen}
        closeModal={locationDrawer.close}
        drawerHeader={`*Showing ${state.promotion.stores.length} stores included in promotion`}
        promoStores={state.promotion.stores}
        handleLocationSelection={handleLocationSelection}
        hasLocation={state.hasStoreSelected}
      />
      <Confetti
        width={screenWidth}
        gravity={0.15}
        numberOfPieces={state.throwConfetti ? 200 : 0}
        recycle={state.throwConfetti}
      />
    </>
  );
};

export default VyperLandingPage;
