import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Box, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import APP_ENV from "../../../appEnvironment";
import useFooterLinks from "../../../hooks/use-footer-links";

const { theme } = APP_ENV;

const MobileAccordion = () => {
  const {
    commonLinksSection,
    automotiveServices,
    tireBrands,
    careers,
    wholesale,
    partnerships,
    about,
  } = useFooterLinks();

  return (
    <>
      {[
        commonLinksSection,
        automotiveServices,
        tireBrands,
        careers,
        wholesale,
        partnerships,
        about,
      ].map(({ display = false, title = "", links = [] }, key) => {
        return (
          display && (
            <Accordion
              key={key}
              style={{
                border: 0,
                boxShadow: "none",
                backgroundColor: theme.palette.footer2.main,
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: theme.palette.footer2.main,
                  color: "white",
                }}
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <Typography variant="h3">{title}</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ backgroundColor: theme.palette.footer2.main }}
              >
                <Box display="flex" flexDirection="column" my="2rem">
                  {links.map(({ href = "", linkText = "" }, i) => (
                    <Link
                      key={i}
                      to={href}
                      component={RouterLink}
                      style={{
                        color: "white",
                        textDecoration: "none",
                        margin: "0.5rem",
                        "&:hover": {
                          textDecoration: "none",
                          color: theme.palette.info.dark,
                          cursor: "pointer",
                        },
                      }}
                    >
                      {linkText}
                    </Link>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          )
        );
      })}
    </>
  );
};

export default MobileAccordion;
