import {
  Dialog,
  Box,
  Badge,
  Fab,
  ClickAwayListener,
  Button,
  ButtonGroup,
  Avatar,
  Typography,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const VehicleTireSelectionModal = ({
  isOpen,
  closeModal,
  tabs,
  currentStep,
  sectionTitle,
  sectionDesc,
  children,
}) => {
  return (
    <Dialog
      open={isOpen}
      maxWidth={"md"}
      onClose={closeModal}
      PaperProps={{
        style: {
          width: 700,
          backgroundColor: "transparent",
          overflow: "hidden",
          padding: 30,
          minHeight: 500,
          top: -30,
          zIndex: 999999,
        },
        elevation: 0,
      }}
    >
      <Badge
        overlap="rectangular"
        badgeContent={
          <Fab size={"small"} color="primary" onClick={closeModal}>
            <CloseRoundedIcon
              style={{ color: theme.palette.primary.contrastText }}
            />
          </Fab>
        }
      >
        <ClickAwayListener onClickAway={closeModal}>
          <Box style={{ width: "100%" }}>
            <Box style={{ width: "100%" }}>
              <ButtonGroup fullWidth>
                {[...tabs, {}, {}]
                  .filter((_, i) => i < 2)
                  .map(
                    (
                      {
                        tabLabel = "",
                        onClick = () => null,
                        isActive = false,
                        isBackButton = false,
                      },
                      i
                    ) => (
                      <Button
                        key={i}
                        onClick={onClick}
                        style={{
                          backgroundColor:
                            theme.palette.info[isActive ? "main" : "dark"],
                          padding: 10,
                          borderBottom: 0,
                          borderRadius: 0,
                          marginTop: isActive ? -2 : 0,
                          zIndex: isActive ? 1 : 0,
                        }}
                        elevation={isActive ? 5 : 0}
                        startIcon={
                          isBackButton ? <ArrowBackIosRoundedIcon /> : null
                        }
                      >
                        {tabLabel}
                      </Button>
                    )
                  )}
              </ButtonGroup>
            </Box>
            <Box
              style={{
                width: "100%",
                backgroundColor: theme.palette.background.default,
                padding: 15,
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{
                    marginRight: 15,
                    width: 25,
                    height: 25,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  {currentStep}
                </Avatar>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                  {sectionTitle}
                </Typography>
              </Box>

              <Typography
                style={{ fontSize: 12, marginTop: 15, marginBottom: 15 }}
              >
                {sectionDesc}
              </Typography>
              {children}
            </Box>
          </Box>
        </ClickAwayListener>
      </Badge>
    </Dialog>
  );
};

export default VehicleTireSelectionModal;
