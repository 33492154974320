import create from "zustand";
import { useState } from "react";

const useZState = (initState = {}) => {
  const [state] = useState(() =>
    create((set, get) => ({ set, get, ...initState }))
  );
  const { get, set } = state();

  return [get, set];
};

export default useZState;
