import { Box, Typography, Paper, Grid } from "@material-ui/core";
import APP_ENV from "../../appEnvironment";

const REASONS_IMAGES = {
  1: [
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/online-appts.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/text-to-pay.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/technicians.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/lowest-price.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/financing.png",
  ],
  2: [
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/online-appts.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/text-to-pay.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/technicians.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/lowest-price.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/financing.png",
  ],
  3: [
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/atd/landing-pages/ATD_Online-appts.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/atd/landing-pages/ATD_text-to-pay.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/atd/landing-pages/ATD_cert-technicians.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/atd/landing-pages/ATD_lowest-price.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/atd/landing-pages/ATD_financing.png",
  ],
  4: [
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/online-appts.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/text-to-pay.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/technicians.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/lowest-price.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/financing.png",
  ],
};

const { theme } = APP_ENV;

const FiveReasonsToShopWithUs = () => {
  const images = REASONS_IMAGES[APP_ENV.appBrandId];

  return (
    <Box style={{ textAlign: "center" }}>
      <Typography
        variant="h3"
        style={{
          marginBottom: 25,
          color: theme.palette.footer2.main,
        }}
      >
        5 Reasons to Shop With Us
      </Typography>
      <Grid container spacing={5} style={{ justifyContent: "center" }}>
        {images.map((image, i) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            key={i}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Paper
              elevation={4}
              style={{
                width: 150,
                height: 150,
                borderRadius: "50%",
                padding: 20,
              }}
            >
              <img
                style={{
                  height: "100%",
                  objectFit: "contain",
                }}
                src={image}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FiveReasonsToShopWithUs;
