import { Grid, Box, Typography, Button } from "@material-ui/core";
import { useLocationStore } from "../../hooks/store/location-store";
import ModalStore from "../../hooks/store/modal-store";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SelectSection from "./widgets/select-section";
import SelectSectionContainer from "./widgets/selection-section-container";
import StyledInfoCard from "./widgets/styled-info-card";
import StyledSelectCard from "./widgets/styled-select-card";
import LocationInfoCard from "../locations/LocationInfoCard";
import {
  useAppointmentStore,
  WEB_APPOINTMENT_TYPES,
} from "../../hooks/store/appointment-store";
import { useEffect } from "react";
import useZState from "../../hooks/util/useZState";
import AppointmentDatePicker from "./appointment-selection/appointment-date-picker";
import AppointmentInventoryArrivalInfo from "./appointment-selection/appointment-inventory-arrival-info";
import APP_ENV from "../../appEnvironment";
import QuickSelectOptionDrawer from "./widgets/quick-select-option-drawer";
import YourAppointment from "./appointment-selection/your-appointment";
import PrimaryActionSelectButton from "./widgets/primary-action-select-button";

const { theme } = APP_ENV;

const AppointmentSelection = ({ onComplete = () => null }) => {
  const [userStore] = useLocationStore((st) => [st.userStore]);

  const [LocationSelectDrawer] = ModalStore((st) => [st.LocationSelectDrawer]);

  const appointmentStore = useAppointmentStore();

  const {
    getAppointmentStoreAsync,
    appointmentSchedule,
    clearUserAppointment,
    userAppointment,
  } = useAppointmentStore();

  const initialState = {
    selectedDateISO: null,
    selectedApptTypeId: null,
    isTimeOptionDrawerOpen: false,
    hasError: false,
  };

  const [getState, setState] = useZState(initialState);

  useEffect(() => {
    setState(initialState);
    (async () => {
      const state = await getAppointmentStoreAsync();
      const { theDateISO } = state.appointmentSchedule[0];
      handleActiveDateChange(theDateISO);
    })();
  }, [appointmentSchedule]);

  const handleActiveDateChange = async (incomingDateISO) => {
    let schedule = appointmentSchedule;
    if (!schedule.length) {
      const state = await getAppointmentStoreAsync();
      schedule = state.appointmentSchedule;
    }
    const theDateISO = schedule.find(
      (i) => i.theDateISO == incomingDateISO
    )?.theDateISO;
    if (theDateISO) {
      clearUserAppointment();
      setState({
        ...initialState,
        selectedDateISO: theDateISO,
        hasError: false,
      });
    }
  };

  const handleSubmit = () => {
    const hasError = Boolean(!userAppointment);
    if (!hasError) {
      onComplete();
      return;
    }

    setState({ hasError });
  };

  const state = getState();

  const activeDay = appointmentSchedule.find(
    (i) => i.theDateISO == state.selectedDateISO
  );

  const isLoading = !userStore?.storeNumber || !activeDay?.displayDate;

  return (
    <>
      <SelectSectionContainer>
        <SelectSection title="Store" subtitle="Confirm your appointment store.">
          <StyledInfoCard>
            <StyledInfoCard
              isGrey={false}
              isLoading={isLoading}
              isLoadingHeight={200}
            >
              {userStore?.storeNumber && <LocationInfoCard {...userStore} />}
            </StyledInfoCard>
            <Grid container style={{ justifyContent: "center", padding: 15 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={LocationSelectDrawer.openModal}
              >
                Change Store
              </Button>
            </Grid>
          </StyledInfoCard>
        </SelectSection>
      </SelectSectionContainer>
      <SelectSectionContainer>
        <SelectSection
          title="Date & Type"
          subtitle="Select your appointment date & type."
        >
          {!isLoading && (
            <Box style={{ width: "100%", marginBottom: 20 }}>
              <StyledInfoCard>
                <AppointmentInventoryArrivalInfo
                  {...appointmentStore}
                  userStore={userStore}
                />
              </StyledInfoCard>
            </Box>
          )}
          <AppointmentDatePicker
            theDateISO={activeDay?.theDateISO}
            appointmentSchedule={appointmentSchedule}
            handleActiveDateChange={handleActiveDateChange}
          >
            <StyledSelectCard isLoading={isLoading} isLoadingHeight={25}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <DateRangeIcon
                  style={{
                    marginRight: 8,
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography variant="h6">{activeDay?.displayDate}</Typography>
              </Box>
            </StyledSelectCard>
          </AppointmentDatePicker>
          <Grid container spacing={10} style={{ marginTop: 10 }}>
            {[
              [
                "Waiting",
                "Enjoy our waiting room with wifi while we work on your car.",
                !activeDay?.waitingApptOptions?.length,
                () => {
                  setState({
                    isTimeOptionDrawerOpen: !state.isTimeOptionDrawerOpen,
                  });
                  if (
                    userAppointment?.apptTypeId == WEB_APPOINTMENT_TYPES.dropOff
                  ) {
                    clearUserAppointment();
                  }
                },
                state.isTimeOptionDrawerOpen ||
                  userAppointment?.apptTypeId == WEB_APPOINTMENT_TYPES.waiting,
              ],
              [
                "Drop Off",
                `Leave your vehicle and we will notify you when we're done.`,
                !activeDay?.dropOffAppointment,
                () => {
                  activeDay.dropOffAppointment.setAppointment();
                },
                userAppointment?.apptTypeId == WEB_APPOINTMENT_TYPES.dropOff,
              ],
            ].map(([title, message, isDisabled, onSelect, isSelected], i) => {
              return (
                <Grid item xs={6} key={i} style={{ height: "100%" }}>
                  <StyledSelectCard
                    isSelected={isSelected}
                    onSelect={() => {
                      setState({ hasError: false });
                      onSelect();
                    }}
                    isDisabled={isDisabled}
                  >
                    <Typography variant="h6">{title}</Typography>
                    <Typography style={{ fontSize: 12 }}>{message}</Typography>
                  </StyledSelectCard>
                </Grid>
              );
            })}
          </Grid>
        </SelectSection>

        {userAppointment && (
          <SelectSection
            title="Appointment"
            subtitle="Your selected Appointment"
          >
            <StyledInfoCard isGrey={false}>
              <YourAppointment {...userAppointment} />
            </StyledInfoCard>
          </SelectSection>
        )}
        {state.hasError && (
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: theme.palette.error.main,
              paddingTop: 10,
            }}
          >
            Please select an appointment date and type.
          </Typography>
        )}
        <PrimaryActionSelectButton
          buttonTitle={"Next"}
          onClick={handleSubmit}
        />
      </SelectSectionContainer>
      <QuickSelectOptionDrawer
        drawerTitle="Select Appointment Time"
        isOpen={state.isTimeOptionDrawerOpen}
        closeModal={() => {
          setState({ isTimeOptionDrawerOpen: false });
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
              {activeDay?.displayDate}
            </Typography>
          </Grid>
          {activeDay?.waitingApptOptions.map((item, i) => {
            const isActive = item.isAvailable;
            return (
              <Grid item xs={4} key={i}>
                <StyledSelectCard
                  isSelected={
                    item.apptDateTime == userAppointment?.apptDateTime
                  }
                  onSelect={() => {
                    item.setAppointment();
                    setState({ isTimeOptionDrawerOpen: false });
                  }}
                  styleProps={{
                    justifyContent: "center",
                    color: isActive && theme.palette.primary.dark,
                    fontSize: 15,
                  }}
                  isDisabled={!isActive}
                >
                  {item.displayTime}
                </StyledSelectCard>
              </Grid>
            );
          })}
        </Grid>
      </QuickSelectOptionDrawer>
    </>
  );
};

export default AppointmentSelection;
