import { useEffect } from "react";
import { useVehicleStore } from "./store/vehicle-store";
import useZState from "./util/useZState";
import useConditionalSelection, {
  createSelectionField,
  createSelectionOption,
} from "./util/useConditionalSelection";
import {
  getVehicleYears,
  getVehicleMakes,
  getVehicleModels,
  getVehicleSubModelsAndEngines,
} from "../services/whi-service";

const useVehicleSelection = (
  onSubmit = (userVehicle) =>
    console.warn(
      "Warning: You need to pass onSubmit function to useSelectCustomTireSize",
      userVehicle
    )
) => {
  const [getUserVehicle, setUserVehicle] = useVehicleStore((st) => [
    st.getUserVehicle,
    st.setUserVehicle,
  ]);

  const getInitialSelection = async () => {
    const uv = await getUserVehicle();
    return [uv?.year, uv?.makeId, uv?.modelId, uv?.subModelId, uv?.engineId];
  };

  const getYears = async () => {
    const years = await getVehicleYears();
    return years.map((year) => createSelectionOption(year, year));
  };

  const getMakes = async ([year]) => {
    const makes = await getVehicleMakes(year);
    return makes.map(({ makeId, makeName }) =>
      createSelectionOption(makeId, makeName)
    );
  };

  const getModels = async ([year, makeId]) => {
    const models = await getVehicleModels(year, makeId);
    return models.map(({ modelId, modelName }) =>
      createSelectionOption(modelId, modelName)
    );
  };

  const [getState, setState] = useZState();

  const getSubModels = async ([year, makeId, modelId]) => {
    setState({
      subModelsAndEngines: getVehicleSubModelsAndEngines(year, makeId, modelId),
    });

    const { distinctSubModels } = await getState().subModelsAndEngines;

    return distinctSubModels.map(({ subModelId, subModelName }) =>
      createSelectionOption(subModelId, subModelName)
    );
  };

  const getEngines = async ([year, makeId, modelId, subModelId]) => {
    const { getEnginesBySubModelId } = await getState().subModelsAndEngines;

    return getEnginesBySubModelId(subModelId).map(({ engineId, engineName }) =>
      createSelectionOption(engineId, engineName)
    );
  };

  const onFinalSelection = async ([
    year,
    makeId,
    modelId,
    subModelId,
    engineId,
  ]) => {
    const { getVehicleByYMME } = await getState().subModelsAndEngines;
    return getVehicleByYMME(year, makeId, modelId, subModelId, engineId);
  };

  const onUserSubmit = async (userVehicle, selectedValues) => {
    const _userVehicle = setUserVehicle(userVehicle, selectedValues);
    onSubmit(_userVehicle);
  };

  const conditionalSelectionState = useConditionalSelection(
    getInitialSelection,
    [
      createSelectionField("Year", "year", getYears),
      createSelectionField("Make", "makeId", getMakes),
      createSelectionField("Model", "modelId", getModels),
      createSelectionField("SubModel", "subModelId", getSubModels),
      createSelectionField("Engine", "engineId", getEngines),
    ],
    onFinalSelection,
    onUserSubmit
  );

  useEffect(() => {
    conditionalSelectionState.handleInit();
  }, []);

  return conditionalSelectionState;
};

export default useVehicleSelection;
