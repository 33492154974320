import { Grid, Typography, Box, Button } from "@material-ui/core";
import MetaTags from "../../components/reusable/ReactMetaTags";
import DefaultContainer from "../../components/generic/default-container";
import BreadCrumb from "../../components/generic/bread-crumb";
import Skeleton from "@material-ui/lab/Skeleton";
import APP_ENV from "../../appEnvironment";
import { useParams, useHistory } from "react-router-dom";
import { useWheelBrandStore } from "../../hooks/store/wheel-store";
import { useEffect, useState } from "react";
import SemiCollapseableDescription from "../../components/generic/semi-collapseable-description";
import WheelVisualizer from "../../components/wheels/wheel-visualizer";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

import { WHEELS_ROUTE_DIRECTORY } from "../../routes";

const { theme } = APP_ENV;

const WheelModelsByBrand = () => {
  const history = useHistory();
  if (APP_ENV.appBrandId !== 4) {
    history.push(WHEELS_ROUTE_DIRECTORY);
  }

  const [wheelBrand, setWheelBrand] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [wheelBrands = []] = useWheelBrandStore((st) => [st.wheelBrands]);
  const { brandEndPoint = null } = useParams();

  useEffect(() => {

    if (!wheelBrands.length || !brandEndPoint) {
      setIsReady(false);
      return;
    }

    const filteredBrand = wheelBrands.find(
      (item) => item.brandEndPoint == brandEndPoint
    );

    if (filteredBrand) {
      setWheelBrand(filteredBrand);
      setIsReady(true);
    } else {
      history.push("/wheels");
    }
  }, [wheelBrands, brandEndPoint]);

  return (
    <>
      <MetaTags
        description={`Shop the lowest prices on ${wheelBrand?.Brand} wheels.`}
        canonical={wheelBrand?.brandEndPoint}
        title={`${wheelBrand?.Brand} Tires | ${APP_ENV.appBrandName}`}
      />
      {!isReady && (
        <div>
          <div style={{ height: "50px" }} />
          <DefaultContainer>
            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
              <Skeleton variant="text" width={342} height={55} />
            </Grid>
            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
              <Skeleton variant="rect" width={186} height={55} />
            </Grid>
            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
              <div style={{ width: "1200px", height: "90px" }} />
              <div style={{ width: "1200px", height: "90px" }} />
            </Grid>
          </DefaultContainer>
          <DefaultContainer backgroundColor={theme.palette.background.default}>
            <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Skeleton variant="rect" style={{ height: 700, width: "100%" }} />
            </Box>
          </DefaultContainer>
        </div>
      )}
      {Boolean(isReady && wheelBrand) && (
        <>
          <BreadCrumb
            paths={[["Wheels", WHEELS_ROUTE_DIRECTORY], [wheelBrand.Brand]]}
          />
          <DefaultContainer>
            <Grid
              container
              style={{
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              <Typography variant="h3">{`Shop ${wheelBrand.Brand} Wheels`}</Typography>
            </Grid>
          </DefaultContainer>
          <DefaultContainer>
            <Grid
              container
              style={{
                paddingBottom: 20,
              }}
            >
              <>
                <img
                  src={wheelBrand.Logo}
                  alt={`${wheelBrand.Brand} tires logo`}
                  style={{ marginBottom: 20 }}
                />
                <SemiCollapseableDescription description={wheelBrand.About} />
              </>
            </Grid>
            <Box style={{ marginBottom: 20 }}>
              <Button
                startIcon={<ArrowBackIosRoundedIcon/>}
                color="primary"
                onClick={() => history.push(WHEELS_ROUTE_DIRECTORY)}
              >
                Back to Wheels
              </Button>
            </Box>
          </DefaultContainer>
          <DefaultContainer>
            <WheelVisualizer entryEndPoint={wheelBrand.VisualizerSlug} />
          </DefaultContainer>
        </>
      )}
    </>
  );
};

export default WheelModelsByBrand;
