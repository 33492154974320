import React from "react";
import { Grid } from "@material-ui/core";
import AboutOurCompany from "../../components/locations/widgets/about-our-company";
import SingleLocationOfferings from "../../components/locations/single-location-page/SingleLocationOfferings";
import APP_ENV from "../../appEnvironment";

const { theme } = APP_ENV;

const LocationContentSection = ({ location }) => {
  const { city = "", stateName = "", storeNumber = "" } = location ?? {};
  
  const isTiresToYouStore = ["4001", "4002", "4003", "4004", "4005", "4006", "4007"].includes(
    storeNumber
  );

  const AboutSection = (
    <Grid container style={{ padding: 20 }}>
      <AboutOurCompany
        cityState={`${city}, ${stateName}`}
        storeNumber={storeNumber}
      />
    </Grid>
  );

  const OfferingsSection = (
    <Grid
      container
      style={{
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        justifyContent: "center",
        marginBottom: isTiresToYouStore ? 30 : 0
      }}
    >
      <SingleLocationOfferings {...location} />
    </Grid>
  );

  return (
    <>
      {isTiresToYouStore ? (
        <>
          {AboutSection}
          {OfferingsSection}
        </>
      ) : (
        <>
          {OfferingsSection}
          {AboutSection}
        </>
      )}
    </>
  );
};

export default LocationContentSection;