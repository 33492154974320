import { cloneElement } from "react";
import LayoutStore from "../../../hooks/store/client/layout-store";
import ModalStore from "../../../hooks/store/modal-store";

const VehicleTireSizeSelectButtonWrapper = ({ children }) => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const [openMobile, openDesktop] = ModalStore((st) => [
    st.VehicleTireSizeSelectionDrawerMobile.openModal,
    st.VehicleTireSizeSelectionModalDesktop.openModal,
  ]);

  return cloneElement(children, {
    onClick: isMobile ? openMobile : openDesktop,
  });
};

export default VehicleTireSizeSelectButtonWrapper;
