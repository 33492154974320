import { Typography, Box } from "@material-ui/core";
import { WEB_APPOINTMENT_TYPES } from "../../../hooks/store/appointment-store";
import InfoIcon from "@material-ui/icons/Info";
import StyledInfoCard from "../widgets/styled-info-card";

const YourAppointment = ({
  apptDateTimeDisplay = "",
  apptTypeId,
  apptMessage = "",
}) => {
  return (
    <>
      <Typography
        style={{ textAlign: "center", marginBottom: 10 }}
        variant="h5"
      >
        Your Appointment
      </Typography>
      <Box style={{ marginTop: 10, marginBottom: 20 }}>
        {[
          [
            "Type",
            apptTypeId == WEB_APPOINTMENT_TYPES.waiting
              ? "Waiting"
              : "Drop Off",
          ],
          ["Date", apptDateTimeDisplay],
        ].map(([label, value], i) => {
          return (
            <Box
              style={{ display: "flex", alignItems: "center", marginBottom: 3 }}
              key={i}
            >
              <Typography style={{ fontSize: 14 }}>{label}:</Typography>
              <Typography
                style={{ marginLeft: 8, fontWeight: 600, fontSize: 15 }}
              >
                {value}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <StyledInfoCard>
        <Box style={{ display: "flex" }}>
          <InfoIcon style={{ marginRight: 5, marginTop: 2 }} />
          <Typography style={{ fontSize: 13 }}>{apptMessage}</Typography>
        </Box>
      </StyledInfoCard>
    </>
  );
};

export default YourAppointment;
