import React from "react";
import * as ReactDOM from "react-dom";
import Favicon from "react-favicon";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import GlobalClientContoller from "./hooks/store/client/_global-client-controller";
import Routes from "./routes";
import APP_ENV from "./appEnvironment";

const App = () => {
  return (
    <>
      <Favicon url={APP_ENV.favicon} />
      <ThemeProvider theme={APP_ENV.theme}>
        <BrowserRouter>
          <GlobalClientContoller />
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
