import {
  Typography,
  Paper,
  Box,
  IconButton,
  Button,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DrawerOffsetTop from "./widgets/DrawerOffsetTop";
import APP_ENV from "../../appEnvironment";
import CartSummary from "../cart/cart-summary";
import CartDetails from "../cart/cart-details";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useCartStore } from "../../hooks/store/cart-store";
import { QUICK_APPOINTMENT_DIRECTORY } from "../../routes";
import EmptyCart from "../cart/empty-cart";

const { theme } = APP_ENV;

const CartDrawer = ({ isOpen, closeModal }) => {
  const history = useHistory();

  const [cartItems, getCartStoreAsync] = useCartStore((st) => [
    st.cartItems,
    st.getCartStoreAsync,
  ]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getCartStoreAsync();
      setIsLoading(false);
    })();
  }, [cartItems]);

  return (
    <DrawerOffsetTop
      anchor="right"
      isOpen={isOpen}
      closeModal={closeModal}
      PaperProps={{
        style: {
          maxWidth: "100%",
          width: 500,
          backgroundColor: theme.palette.background.default,
          padding: 8,
        },
      }}
    >
      <Box
        style={{ height: "fit-content", paddingBottom: 100, overflow: "auto" }}
        className="NoScrollbar"
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            Your Cart
          </Typography>

          <IconButton onClick={closeModal}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>

        {isLoading && <LinearProgress />}

        {Boolean(!cartItems.length && !isLoading) && (
          <Paper style={{ padding: 8 }}>
            <EmptyCart closeModal={closeModal} />
          </Paper>
        )}

        {Boolean(cartItems.length && !isLoading) && (
          <>
            <Paper style={{ padding: 8 }}>
              <CartSummary>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginBottom: 15, height: 50 }}
                  onClick={() => {
                    closeModal();
                    history.push(QUICK_APPOINTMENT_DIRECTORY);
                  }}
                >
                  Schedule Appointment Now
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={{ marginBottom: 15, height: 50 }}
                  onClick={closeModal}
                >
                  Keep Shopping
                </Button>
              </CartSummary>
            </Paper>
            <Box
              style={{
                marginTop: 10,
                marginBottom: 150,
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <CartDetails />
            </Box>
          </>
        )}
      </Box>
    </DrawerOffsetTop>
  );
};

export default CartDrawer;
